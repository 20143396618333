import gql from "graphql-tag";

let response = `response{
   id
   status
   code
   message
}`;
let data = `data {
    applicationCategoryUniqueId
    applicationDescription
    applicationCategoryName
}`;

export const getApplicationCategories = gql`
query getApplicationCategories{
  getApplicationCategories{
    ${response}
    ${data}
  }
}`;

export const createApplicationCategoryMutation = gql`
mutation createApplicationCategoryMutation($input: ApplicationCategoryInputObject){
  createApplicationCategoryMutation(input: $input) {
    ${response}
    ${data}
  }
}`;

export const updateApplicationCategoryMutation = gql`
mutation updateApplicationCategoryMutation($input: ApplicationCategoryInputObject){
  updateApplicationCategoryMutation(input: $input) {
    ${response}
    ${data}
  }
}`;

export const deleteApplicationCategoryMutation = gql`
mutation deleteApplicationCategoryMutation($uniqueId: String!){
  deleteApplicationCategoryMutation(uniqueId: $uniqueId) {
    ${response}
    ${data}
  }
}`;
