import gql from "graphql-tag";

export const getAllCountries = gql`query getAllCountries($uniqueId: String){
  getAllCountries(uniqueId: $uniqueId) {
    response{
      code
      status
      message
    }
    data{
      countryCreatedDate
      countryName
      countryUniqueId
    }
  }
}`

export const createCountry = gql`mutation createCountry($input: CountryInputObject){
  createCountry(input: $input) {
    response{
      code
      status
      message
    }
    data{
      countryCreatedDate
      countryName
      countryUniqueId
    }
  }
}`

export const updateCountry = gql`mutation updateCountry($input: CountryInputObject){
  updateCountry(input: $input) {
    response{
      code
      status
      message
    }
    data{
      countryCreatedDate
      countryName
      countryUniqueId
    }
  }
}`

export const deleteCountry = gql`mutation deleteCountry($uniqueId: String!){
  deleteCountry(uniqueId: $uniqueId) {
    response{
      code
      status
      message
    }
    data{
      countryCreatedDate
      countryName
      countryUniqueId
    }
  }
}`
