import { notifyError } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";
import { getProjectWeeklyReport } from "@/graphql/reports/projectReport.graphql.js";

export default {
  state: {
    weeklyReports: {},
  },
  getters: {
    getProjectWeeklyReport: (state) => state.weeklyReports,
  },
  actions: {
    async getProjectWeeklyReport(context, year) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getProjectWeeklyReport,
          variables: { year },
        })
        .then((output) => {
          let { response, data } = output.data.getProjectWeeklyReport;
          if (response.status) {
            context.commit("setStoreItem", {
              module: "projectReportManagement",
              entity: "weeklyReports",
              data,
            });
            return data;
          } else {
            notifyError(response.message || "Failed to fetch");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
  },
};
