import {createProjectTask, deleteProjectTask, getProjectTasks, updateProjectTask} from "@/graphql/projects/projectTask.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
        projectTasks: [],
        projectTask: {}
    },
    getters: {
        getProjectTasks: (state) => (state.projectTasks),
        getProjectTask: (state) => (state.projectTask)
    },
    actions: {
        async getProjectTasks(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getProjectTasks,
                variables : {...input}
            })
            .then((output) => {
                
                let {response, data} = output.data.getTaskDetails

                if (response.status) {
                        context.commit("setStoreItem", {
                            module : 'projectTaskManagement',
                            entity : 'projectTasks',
                            data
                        })
                        return data
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },


        async createProjectTask(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createProjectTask,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.createProjectTask
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'projectTaskManagement',
                        entity : 'projectTasks',
                        uniqueKey : 'projectTaskUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        
        async updateProjectTask(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateProjectTask,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.updateProjectTask
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'projectTaskManagement',
                        entity : 'projectTasks',
                        uniqueKey : 'projectTaskUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },


        async deleteProjectTask(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteProjectTask,
                variables: {uniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.deleteProjectTask
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'projectTaskManagement',
                        entity : 'projectTasks',
                        uniqueKey : 'projectTaskUniqueId',
                        data,
                        remove : true,
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
    }
}