// export default [
//   {
//     name: "User-Information",
//     path: "user-information",
//     props: true,
//     component: () => import("./userInformation.vue"),
//     meta: {
//       permissions: [],
//     },
//   },
// ];

export default [
  {
    path: "/user-information",
    name: "User-Information",
    component: () => import("./userInformation.vue"),
    meta: {
      permissions: [],
    },
    children: [
      {
        path: "information/:informationUniqueId",
        name: "Information",
        component: () => import("./adduserInUserInformation.vue"),
        meta: {
          permissions: [],
        },
      },
    ],
  },
];
