import gql from "graphql-tag";

const data = `
      totalAttendanceEntries
      earlyEntry
      lateEntry
      lateExit
      extraHours
      earlyExit
`;

const responseFields = `
    code
    id
    message
    status
`;


export const getIndividualAttendanceDashboard = gql`
    query GetIndividualAttendanceDashboard($filter: IndividualAttendanceFilter) {
        getIndividualAttendanceDashboard(filter: $filter) {
            data {
                ${data}
            }
            response {
                ${responseFields}
            }
        }
    }
`;