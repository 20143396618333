import diasporaRequestsRouter from "@/components/memberships/Diaspora/DiasporaRequest/diasporaRequestsRouter";
import myDiasporaRouter from "@/components/memberships/Diaspora/MyDiasporaDetails/myDiasporaRouter";
export default [
    {
        name: "DiasporaMain",
        path: "/diaspora-panel",
        component: () =>
            import("@/components/memberships/Diaspora/DiasporaMain.vue"),
        meta: {
            permissions: [],
        },

        children: [
            {
                name: "Diaspora Index",
                path: "index",
                // component: index
                component: () => import("./Index.vue"),

                meta: {
                    permissions: [

                    ],
                },
            },
            ...diasporaRequestsRouter,
            ...myDiasporaRouter

        ],
    },
];
