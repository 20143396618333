import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";
import { createGallery, deleteGallery, getGalleryDetails, updateGallery } from "../../graphql/repository/gallery/gallery.graphql";

export default {
    state: {
        galleries: [],
        gallery: {}
    },
    getters: {
        getGalleryDetails: (state) => (state.galleries),
        getGallery: (state) => (state.gallery)
    },
    actions: {
        async getGalleryDetails(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getGalleryDetails,
                variables : input
            })
            .then((output) => {
                let {response, data} = output.data.getGalleryDetails
                
                if (response.status) {
                    if (input?.galleryUniqueId?.length) {
                        context.commit("setStoreItem", {
                            module : 'galleryManagement',
                            entity : 'gallery',
                            data   : data[0]
                        })
                        return data[0];
                    } else {
                        context.commit("setStoreItem", {
                            module : 'galleryManagement',
                            entity : 'galleries',
                            data
                        })
                        return data;
                    }

                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async createGallery(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createGallery,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.createGallery
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'galleryManagement',
                        entity : 'galleries',
                        uniqueKey : 'galleryUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async updateGallery(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateGallery,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.updateGallery
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'galleryManagement',
                        entity : 'galleries',
                        uniqueKey : 'galleryUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async deleteGallery(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteGallery,
                variables: {uniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.deleteGallery
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'galleryManagement',
                        entity : 'galleries',
                        uniqueKey : 'galleryUniqueId',
                        data,
                        remove : true,
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
    }
}
