import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";
import {
    createOrUpdateReportPtMutation,
    deleteReportPtMutation,
    getWeeklyReport
} from "@/graphql/projects/projectReport";

export default {
    state: {
        ProjectPT: [],
    },
    getters: {
        getProjectPT: (state) => state.ProjectPT,
    },
    actions: {
        async getWeeklyReport(context, data) {
            context.commit("setLoadingState", true);
            return await apolloClient
                .query({
                    fetchPolicy: "network-only",
                    query: getWeeklyReport,
                })
                .then((output) => {
                    let { response, data } = output.data.getWeeklyReport;

                    if (response.status) {
                        context.commit("setStoreItem", {
                            module: "ProjectPtReport",
                            entity: "ProjectPT",
                            data,
                        });
                        return data;
                    } else {
                        notifyError(response.message || "Failed to fetch");
                    }
                })
                .finally(() => context.commit("setLoadingState", false));
        },

        async createOrUpdateReportPtMutation(context, input) {
            context.commit("setLoadingState", true);
            return await apolloClient
                .mutate({
                    fetchPolicy: "no-cache",
                    mutation: createOrUpdateReportPtMutation,
                    variables:  input ,
                })
                .then((output) => {
                    let { response, data } = output.data.createOrUpdateReportPtMutation;

                    if (response.status) {
                            context.commit("updateStoreItem", {
                                module: "ProjectPtReport",
                                entity: "ProjectPT",
                                uniqueKey: "id",
                                data: data,
                            });
                        notifySuccess(
                            response.message || i18n.t("labels.operationSuccessful")
                        );
                        return data;
                    } else {
                        notifyError(response.message || i18n.t("labels.operationFailed"));
                    }
                })
                .finally(() => context.commit("setLoadingState", false));
        },


        async deleteReportPtMutation(context, input) {
            context.commit("setLoadingState", true);
            return await apolloClient
                .mutate({
                    fetchPolicy: "no-cache",
                    mutation: deleteReportPtMutation,
                    variables: { id:input },
                })
                .then((output) => {
                    let { response, data } = output.data.deleteReportPtMutation;

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: "ProjectPtReport",
                            entity: "ProjectPT",
                            uniqueKey: "id",
                            data: data,
                            remove : true,
                        });
                        notifySuccess(
                            response.message || i18n.t("labels.operationSuccessful")
                        );
                        return data;
                    } else {
                        notifyError(response.message || i18n.t("labels.operationFailed"));
                    }
                })
                .finally(() => context.commit("setLoadingState", false));
        },

    },
};