import { createProjectTaskProgress, deleteProjectTaskProgress, getProjectTaskProgressDetails, updateProjectTaskProgress } from "@/graphql/projects/progress/taskProgress.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";

import { apolloClient } from "@/vue-apollo";

export default {
    state:{
        taskProgresses:[],
        taskProgress:{}
    },
    getters:{
        getTaskProgresses: (state) =>(state.taskProgresses),
        getTaskProgress:(state)=>(state.taskProgress)
    },
    actions:{
        async getProjectTaskProgressDetails(context, taskProgressTaskUniqueId) {
            context.commit("setLoadingState", true);
            return await apolloClient
              .query({
                fetchPolicy: "network-only",
                query: getProjectTaskProgressDetails,
                variables: { taskProgressTaskUniqueId },
              })
              .then((output) => {
                let { response, data } = output.data.getProjectTaskProgressDetails;
      
                if (response.status) {
                    context.commit("setStoreItem", {
                      module: 'taskProgressManagement',
                      entity: 'taskProgresses',
                      data,
                    });
                    return data;
                } else {
                  notifyError(response.message || "Failed to fetch");
                }
              })
              .finally(() => context.commit("setLoadingState", false));
          },


        async createProjectTaskProgress(context, input){
            context.commit("setLoadingState",true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createProjectTaskProgress,
                variables: {input}
            })
            .then((output)=>{
                let{response, data} = output.data.createProjectTaskProgress
                if (response.status){
                    context.commit("updateStoreItem",{
                        module:'taskProgressManagement',
                        entity:'taskProgresses',
                        uniqueKey:'taskProgressUniqueId',
                        data
                    });
                    notifySuccess(response.massage || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    let message = response.message
                    notifyError( message )
                }
            }).finally(()=>context.commit("setLoadingState", false))
        },
        async updateProjectTaskProgress(context, input){
            context.commit("setLoadingState",true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateProjectTaskProgress,
                variables: {input}
            })
            .then((output)=>{
                let{response, data} = output.data.updateProjectTaskProgress
                if (response.status){
                    context.commit("updateStoreItem",{
                        module:'taskProgressManagement',
                        entity:'taskProgresses',
                        uniqueKey:'taskProgressUniqueId',
                        data
                    });
                    notifySuccess(response.massage || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.massage || i18n.t('labels.operationFailed'))
                }
            }).finally(()=>context.commit("setLoadingState", false))
        },
      
        async deleteProjectTaskProgress(context, taskProgressUniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteProjectTaskProgress,
                variables: {taskProgressUniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.deleteProjectTaskProgress
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'taskProgressManagement',
                        entity : 'taskProgresses',
                        uniqueKey : 'taskProgressUniqueId',
                        data,
                        remove : true,
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
    }

}