import Advertisement from "@/components/communication/advertisement/AdverisementList.vue"

export default [
  {
    path: "/communication",
    name: "Communication",
    component: () => import("@/components/communication/CommunicationMain.vue"),
    meta: {
      permissions: [],
    },
    children: [
      {
        name: "Communication Index",
        path: "index",
        component: () => import("./Index.vue"),
        meta: {
          permissions: [],
        },
      },
      {
        name: "All Requests",
        path: "all-requests/:requestType?",
        component: () =>
          import("@/components/communication/request/AllRequests.vue"),
        props: true,
        meta: {
          permissions: [],
        },
      },
      {
        path: "my-requests",
        name: "My Requests",
        component: () =>
          import("@/components/communication/request/MyRequests.vue"),
        meta: {
          permissions: [],
        },
      },
      {
        path: "request/:requestUniqueId",
        name: "Request",
        component: () =>
          import("@/components/communication/request/Request.vue"),
        meta: {
          permissions: [],
        },
      },
      {
        path: "announcements/:type",
        name: "Announcements",
        component: () =>
          import("@/components/communication/announcement/Announcements.vue"),
        props: true,
        meta: {
          permissions: [],
        },
      },
      {
        path: "awareness",
        name: "Awareness",
        component: () =>
          import(
            "@/components/communication/awareness_session/awarenessSession.vue"
          ),
        meta: {
          permissions: [],
        },
      },
      {
        path: "conducted-training",
        name: "Conducted Training",
        component: () =>
          import(
            "@/components/communication/conducted_training/conductedTraining.vue"
          ),
        meta: {
          permissions: [],
        },
      },
      {
        path: "awareness-view/:awarenessSessionUniqueId",
        name: "AwarenessView",
        component: () =>
          import(
            "@/components/communication/awareness_session/awerenessView.vue"
          ),
        meta: {
          permissions: [],
        },
      },
      {
        path: "advertisements",
        name: "Advertisements",
        component: Advertisement,
        meta: {
          permissions: [],
        },
      },
      {
        path: "/alumn",
        name: "Alumn",
        component: () =>
          import("@/components/communication/alumn/aluminum.vue"),
        meta: {
          permissions: [],
        },
      },
      {
        path: "aluminum-view/:aluminumUniqueId",
        name: "aluminumView",
        component: () =>
          import("@/components/communication/alumn/aluminunView.vue"),
        meta: {
          permissions: [],
        },
      },

      {
        path: "events",
        name: "events",
        component: () =>
          import("@/components/communication/Events/EventsList.vue"),
        meta: {
          permissions: [],
        },
      },
      {
        path: "security",
        name: "Security",
        component: () => import("@/components/communication/security_trends/SecurityMain.vue"),
        meta: {
          permissions: []
        }
      },
      {
        path: "security/trends",
        name: "SecurityTrends",
        component: () =>
          import("@/components/communication/security_trends/SecurityTrends.vue"),
        meta: {
          permissions: [],
        },
      },{
        path: "security/trends/:securityTrendUniqueId",
        name: "ViewSecurityTrend",
        component: () =>
          import("@/components/communication/security_trends/ViewSecurityTrend.vue"),
        meta: {
          permissions: [],
        },
      },
      {
        path: "security/categories",
        name: "SecurityCategories",
        component: () =>
          import("@/components/communication/security_trends/SecurityCategories.vue"),
        meta: {
          permissions: [],
        },
      },
    ],
  },
];
