import { 
        createWorkExperienceDetail, getMyWorkExperienceDetail, updateWorkExperienceDetail,
        deleteWorkExperienceDetail, getAllWorkExperiencesByUserProfileOrUniqueId
        
} from "@/graphql/profile_details/workingExperienceDetail.graphql.js"
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
        workingExperienceDetails: [],
        workingExperienceDetail: {}
    },
    getters: {
        getAllWorkingExperienceDetails: (state) => (state.workingExperienceDetails),
        getWorkingExperienceDetail: (state) => (state.workingExperienceDetail)
    },
    actions: {
        async getAllWorkExperiencesByUserProfileOrUniqueId(context, data) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getAllWorkExperiencesByUserProfileOrUniqueId,
                variables : {...data}
            })
            .then((output) => {
                let {response, data} = output.data.getAllWorkExperiencesByUserProfileOrUniqueId
                
                if (response.status) {
                    if (_.isNil(uniqueId)) {
                        context.commit("setStoreItem", {
                            module : 'workingExperienceDetailManagement',
                            entity : 'workingExperienceDetails',
                            data
                        })
                        return data;   
                    }else {
                        context.commit("setStoreItem", {
                            module : 'workingExperienceDetailManagement',
                            entity : 'workingExperienceDetail',
                            data   : data[0]
                        })
                        return data[0]
                    }
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        async getMyWorkExperienceDetail(context,data) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getMyWorkExperienceDetail,
                variables: {...data}
            })
            .then((output) => {
                let {response, data} = output.data.getMyExperiences
                
                if (response.status) {
                        context.commit("setStoreItem", {
                            module : 'workingExperienceDetailManagement',
                            entity : 'workingExperienceDetails',
                            data
                        })
                        return data;   
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },


        async createWorkExperienceDetail(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createWorkExperienceDetail,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.createWorkExperienceDetail
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'workingExperienceDetailManagement',
                        entity : 'workingExperienceDetails',
                        uniqueKey : 'workExperienceUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    context.dispatch("getUserProfile")
                    return data
                } else {
                    notifyError("Please fill mandatory * fields" ||i18n.t("labels.operationFailed")
                    );
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        async updateWorkExperienceDetail(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateWorkExperienceDetail,
                variables: {input:input}
            })
            .then((output) => {
                let {response, data} = output.data.updateWorkExperienceDetail;
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'workingExperienceDetailManagement',
                        entity : 'workingExperienceDetails',
                        uniqueKey : 'workExperienceUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    context.dispatch("getUserProfile")
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async deleteWorkExperienceDetail(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteWorkExperienceDetail,
                variables: {uniqueId:uniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.deleteWorkExperienceDetail
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'workingExperienceDetailManagement',
                        entity : 'workingExperienceDetails',
                        uniqueKey : 'workExperienceUniqueId',
                        data,
                        remove : true,
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    context.dispatch("getUserProfile")
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
    }
}