import { createDistrict, deleteDistrict, getAllDistricts, updateDistrict, getDistricts } from "@/graphql/location/district.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
        districts: [],
        district: {}
    },
    getters: {
        getAllDistricts: (state) => (state.districts),
        getDistrict: (state) => (state.district)
    },
    actions: {
        async getAllDistricts(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getAllDistricts,
                variables : {uniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.getAllDistricts
                
                if (response.status) {
                    if (_.isNil(uniqueId)) {
                        context.commit("setStoreItem", {
                            module : 'districtManagement',
                            entity : 'districts',
                            data
                        })
                        return data;   
                    }else {
                        context.commit("setStoreItem", {
                            module : 'districtManagement',
                            entity : 'district',
                            data   : data[0]
                        })
                        return data[0]
                    }
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        async getDistricts(context, regionUniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getDistricts,
                variables : {regionUniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.getDistricts
                if (response.status) {
                    context.commit("setStoreItem", {
                        module : 'districtManagement',
                        entity : 'districts',
                        data
                    })
                    return data;   

                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async createDistrict(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createDistrict,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.createDistrict
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'districtManagement',
                        entity : 'districts',
                        uniqueKey : 'uniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async updateDistrict(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateDistrict,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.updateDistrict
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'districtManagement',
                        entity : 'districts',
                        uniqueKey : 'uniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async deleteDistrict(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteDistrict,
                variables: {uniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.deleteDistrict
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'districtManagement',
                        entity : 'districts',
                        uniqueKey : 'uniqueId',
                        data,
                        remove : true,
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
    }
}