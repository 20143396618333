import { createAnnouncement, deleteAnnouncement, deleteAnnouncementUser, getAnnouncements, getPublicAnnouncement, updateAnnouncement } from  "@/graphql/communication/announcenent/announcement.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
        announcements : [],
        announcement : {},
        PublicAnnouncements: []
    },
    getters: {
        getAnnouncements: (state) => (state.announcements),
        getAnnouncement: (state) => (state.announcement),
        getPublicAnnouncements: (state) => (state.PublicAnnouncements)
    },
    actions: {
        async getAnnouncements(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getAnnouncements,
                variables : {input}
            })
            .then((output) => {
                let {response, data} = output.data.getAnnouncement;

                if (response.status) {
                        context.commit("setStoreItem", {
                            module : 'announcementManagement',
                            entity : 'announcements',
                            data
                        })
                        return data
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async deleteAnnouncementUser(context, uniqueId) {
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteAnnouncementUser,
                variables: {uniqueId}
            })
            .then((output) => {
                let {data, response} = output.data.deleteAnnouncementSelectedUser
                if (response.status){
                    context.dispatch("getAnnouncements", {announcementType: "ANNOUNCEMENT"})
                    notifySuccess(response.message || 'successful')
                    return data;
                } else {
                    notifyError(response.message || "failed")
                }
            })
        },
        async createAnnouncement(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createAnnouncement,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.createAnnouncement;
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'announcementManagement',
                        entity : 'announcements',
                        uniqueKey : 'announcementUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        async updateAnnouncement(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateAnnouncement,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.updateAnnouncement;
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'announcementManagement',
                        entity : 'announcements',
                        uniqueKey : 'announcementUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        async deleteAnnouncement(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteAnnouncement,
                variables: {uniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.deleteAnnouncement;
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'announcementManagement',
                        entity : 'announcements',
                        uniqueKey : 'announcementUniqueId',
                        data,
                        remove : true,
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        async getPublicAnnouncement(context) {
            // context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getPublicAnnouncement,
                
            })
            .then((output) => {
                let {response, data} = output.data.getPublicAnnouncement;

                if (response.status) {
                        context.commit("setStoreItem", {
                            module : 'announcementManagement',
                            entity : 'PublicAnnouncements',
                            data
                        })
                        return data
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
    }
}