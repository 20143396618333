import Locations from "./Locations.vue"
import Wards from "./Wards.vue"
import Districts from "./Districts.vue"
import Regions from "./Regions.vue"
export default [
    {
        path: 'locations',
        name: 'Locations',
        component: Locations,
        meta: {
            permissions: ["can_add_locations"]
        }
    },
    {
        name : "Regions",
        path : "locations-region/:countryUniqueId",
        component : Regions,
        props: true,
        meta: {
            permissions: ["can_add_locations"]
        }
    },
    {
        name : "Wards",
        path : "locations-ward/:countryUniqueId/:regionUniqueId/:districtUniqueId",
        component : Wards,
        props: true,
        meta: {
            permissions: ["can_add_locations"]
        }
    },
    {
        name : "Districts",
        path : "locations-district/:countryUniqueId/:regionUniqueId",
        component : Districts,
        props: true,
        meta: {
            permissions: ["can_add_locations"]
        }
    },
]