import gql from "graphql-tag";

const userFields = `
    id
    profileUniqueId
    username
    firstName
    middleName
    lastName
    gender
    nidaNumber
    dateOfBirth
    designation
    profilePicture
    professionalismTitle
    professionalismYearsOfExperience
`;

const response = `
    code
    id
    message
    status
`;

export const getAllNonStaffUsers = gql`
    query GetAllNonStaffUsers($userRoleUniqueId: String) {
        getAllNonStaffUsers(userRoleUniqueId: $userRoleUniqueId) {
            data {
                ${userFields}
            }
            response {
                ${response}
            }
        }
    }
`;
