import gql from "graphql-tag";


const response = `
response{
id
status
code
message
}
`

export const researchProposaldata = `
data{
      id
      proposalUniqueId
      proposalTitle
      proposalDescription
      proposalArea {
        id
        researchAreaUniqueId
        researchAreaName
        researchAreaPhoto
        researchAreaDescription
        researchAreaIsActive
        researchAreaCreateddate
        researchAreaCreatedBy {
          id
          profileUniqueId
          username
          firstName
          middleName
          lastName
          profilePicture
          gender
          institution
          role
          dateJoined
        }
      }
      proposalStage
      proposalStatus
      researchProposalDoc
      proposalAuthor {
        id
        profileUniqueId
        username
        firstName
        middleName
        lastName
        profilePicture
        gender
        institution
        role
        dateJoined
      }
      proposalAuthorInstitution {
        institutionUniqueId
        institutionName
        institutionType
        institutionCreatedDate
      }
      proposalAuthorCountry {
        countryUniqueId
        countryName
        countryCreatedDate
      }
      proposalIsActive
      proposalCreatedDate
      proposalAttachments {
        id
        proposalAttachmentUniqueId
        proposalAttachmentName
        proposalAttachmentPath
        proposalAttachmentFileType
        proposal
        proposalAttachmentIsActive
        proposalAttachmentIsDownloaded
        proposalAttachmentCreatedDate
      }
    }`

export const proposalCommentdata = `
 data {
      id
      commentUniqueId
      comment
      proposal {
        id
        proposalUniqueId
        proposalTitle
        proposalDescription
        proposalArea {
          id
          researchAreaUniqueId
          researchAreaName
          researchAreaPhoto
          researchAreaDescription
          researchAreaIsActive
          researchAreaType
          researchAreaCreateddate
          researchAreaCreatedBy {
            id
            profileUniqueId
            username
            firstName
            middleName
            lastName
            profilePicture
            gender
            institution
            role
            dateJoined
          }
        }
        proposalStage
        proposalStatus
        researchProposalDoc
        proposalAuthor {
          id
          profileUniqueId
          username
          firstName
          middleName
          lastName
          profilePicture
          gender
          institution
          role
          dateJoined
        }
        proposalAuthorInstitution {
          institutionUniqueId
          institutionName
          institutionType
          institutionCreatedDate
        }
        proposalAuthorCountry {
          countryUniqueId
          countryName
          countryCreatedDate
        }
        proposalIsActive
        proposalCreatedDate
        proposalAttachments {
          id
          proposalAttachmentUniqueId
          proposalAttachmentName
          proposalAttachmentPath
          proposalAttachmentFileType
          proposal
          proposalAttachmentIsActive
          proposalAttachmentIsDownloaded
          proposalAttachmentCreatedDate
        }
        proposalComments {
          id
          commentUniqueId
          comment
          commentIsActive
          commentCreatedDate
        }
      }
      commentedBy {
        id
        profileUniqueId
        username
        firstName
        middleName
        lastName
        profilePicture
        gender
        institution
        role
        dateJoined
      }
      commentIsActive
      commentCreatedDate
      commentReplies {
        id
        repliesUniqueId
        repliesDescription
        repliesAttachmentPath
        repliesIsActive
        repliesCreatedDate
      }
    }
`
export const commentReplydata = `
data {
      id
      repliesUniqueId
      repliesDescription
      repliedComment {
        id
        commentUniqueId
        comment
        proposal {
          id
          proposalUniqueId
          proposalTitle
          proposalDescription
          proposalArea {
            id
            researchAreaUniqueId
            researchAreaName
            researchAreaPhoto
            researchAreaDescription
            researchAreaIsActive
            researchAreaType
            researchAreaCreateddate
            researchAreaCreatedBy {
              id
              profileUniqueId
              username
              firstName
              middleName
              lastName
              profilePicture
              gender
              institution
              role
              dateJoined
            }
          }
          proposalStage
          proposalStatus
          researchProposalDoc
          proposalAuthor {
            id
            profileUniqueId
            username
            firstName
            middleName
            lastName
            profilePicture
            gender
            institution
            role
            dateJoined
          }
          proposalAuthorInstitution {
            institutionUniqueId
            institutionName
            institutionType
            institutionCreatedDate
          }
          proposalAuthorCountry {
            countryUniqueId
            countryName
            countryCreatedDate
          }
          proposalIsActive
          proposalCreatedDate
          proposalAttachments {
            id
            proposalAttachmentUniqueId
            proposalAttachmentName
            proposalAttachmentPath
            proposalAttachmentFileType
            proposal
            proposalAttachmentIsActive
            proposalAttachmentIsDownloaded
            proposalAttachmentCreatedDate
          }
          proposalComments {
            id
            commentUniqueId
            comment
            commentIsActive
            commentCreatedDate
          }
        }
        commentedBy {
          id
          profileUniqueId
          username
          firstName
          middleName
          lastName
          profilePicture
          gender
          institution
          role
          dateJoined
        }
        commentIsActive
        commentCreatedDate
        commentReplies {
          id
          repliesUniqueId
          repliesDescription
          repliesAttachmentPath
          repliesIsActive
          repliesCreatedDate
        }
      }
      repliesAttachmentPath
      repliesIsActive
      repliesCreatedDate
    }
`
export const commentRepliesdata = ` 
data {
      id
      repliesUniqueId
      repliesDescription
      repliedComment {
        id
        commentUniqueId
        comment
        proposal {
          id
          proposalUniqueId
          proposalTitle
          proposalDescription
          proposalArea {
            id
            researchAreaUniqueId
            researchAreaName
            researchAreaPhoto
            researchAreaDescription
            researchAreaIsActive
            researchAreaType
            researchAreaCreateddate
            researchAreaCreatedBy {
              id
              profileUniqueId
              username
              firstName
              middleName
              lastName
              profilePicture
              gender
              institution
              role
              dateJoined
            }
          }
          proposalStage
          proposalStatus
          researchProposalDoc
          proposalAuthor {
            id
            profileUniqueId
            username
            firstName
            middleName
            lastName
            profilePicture
            gender
            institution
            role
            dateJoined
          }
          proposalAuthorInstitution {
            institutionUniqueId
            institutionName
            institutionType
            institutionCreatedDate
          }
          proposalAuthorCountry {
            countryUniqueId
            countryName
            countryCreatedDate
          }
          proposalIsActive
          proposalCreatedDate
          proposalAttachments {
            id
            proposalAttachmentUniqueId
            proposalAttachmentName
            proposalAttachmentPath
            proposalAttachmentFileType
            proposal
            proposalAttachmentIsActive
            proposalAttachmentIsDownloaded
            proposalAttachmentCreatedDate
          }
          proposalComments {
            id
            commentUniqueId
            comment
            commentIsActive
            commentCreatedDate
          }
        }
        commentIsActive
        commentCreatedDate
        commentReplies {
          id
          repliesUniqueId
          repliesDescription
          repliesAttachmentPath
          repliesIsActive
          repliesCreatedDate
        }
      }
      repliesAttachmentPath
      repliesIsActive
      repliesCreatedDate
      commentedBy {
        id
        profileUniqueId
        username
        firstName
        middleName
        lastName
        profilePicture
        gender
        institution
        role
        dateJoined
      }
    }`

const page = `
page{
number
hasNextPage
hasPreviousPage
nextPageNumber
previousPageNumber
}
`



export const registerResearchProposal = gql`
mutation RegisterResearchProposal($input: ResearchProposalInputObject) {
  registerResearchProposal(input: $input){
    ${response}
    ${researchProposaldata} 
  }
}
 `

export const updateResearchProposal = gql`
mutation UpdateResearchProposal($input: ResearchProposalInputObject) {
  updateResearchProposal(input: $input) {
    ${response}
    ${researchProposaldata} 
  }
}
`

export const deleteResearchProposal = gql`
mutation DeleteResearchProposal($proposalUniqueId: String!) {
  deleteResearchProposal(proposalUniqueId: $proposalUniqueId) {
    ${response}
    ${researchProposaldata} 
  }
}
`

export const approveResearchProposal = gql`
mutation ApproveResearchProposal($proposalUniqueId: String!) {
  approveResearchProposal(proposalUniqueId: $proposalUniqueId) {
    ${response}
    ${researchProposaldata}
  }
}
`

export const disApproveResearchProposal = gql`
mutation DisApproveResearchProposal($proposalUniqueId: String!) {
  disApproveResearchProposal(proposalUniqueId: $proposalUniqueId) {
    ${response}
    ${researchProposaldata}
  }
}
`

export const getOnGoingResearchData = gql`
query GetOnGoingResearchData($filtering: ResearchProposalFilteringbject) {
  getOnGoingResearchData(filtering: $filtering) {
    ${response} 
    ${researchProposaldata}
    ${page}
  }
}
`

export const getMyResearchProposalData = gql`
query GetMyResearchProposalData {
  getMyResearchProposalData {
    ${response}
    ${researchProposaldata} 
    ${page} 
  }
}
`


export const createCommentsOnResearchProposalMutation = gql`
mutation CreateCommentsOnResearchProposalMutation($input: ResearchProposalCommentsInputObject!) {
  createCommentsOnResearchProposalMutation(input: $input){
    ${response}
    ${proposalCommentdata} 
  }
}
 `
export const create_RepliesOnProposalCommentsMutation = gql`
mutation Create_RepliesOnProposalCommentsMutation($input: ResearchProposalCommentsRepliesInputObject!) {
  create_RepliesOnProposalCommentsMutation(input: $input){
    ${response}
    ${commentReplydata} 
  }
}
`
export const deleteProposalCommentsRepliesMutation = gql`
mutation DeleteProposalCommentsRepliesMutation($repliesUniqueId: String!) {
  deleteProposalCommentsRepliesMutation(repliesUniqueId: $repliesUniqueId) {
    ${response}
    ${commentReplydata} 
  }
}
`
export const deleteResearchProposalCommentsMutation = gql`
mutation DeleteResearchProposalCommentsMutation($commentUniqueId: String!) {
  deleteResearchProposalCommentsMutation(commentUniqueId: $commentUniqueId) {
    ${response}
    ${proposalCommentdata} 
  }
}
`

export const getCommentsOnResearchProposal = gql`
query GetCommentsOnResearchProposal($filtering: ResearchProposalCommentsFilteringbject) {
  getCommentsOnResearchProposal(filtering: $filtering) {
    ${response}
    ${proposalCommentdata} 
  }
}
`
export const getRepliesOnProposalComments = gql`
query GetRepliesOnProposalComments($filtering: ResearchProposalCommentsRepliesFilteringbject) {
  getRepliesOnProposalComments(filtering: $filtering) {
    ${response}
    ${commentRepliesdata} 
  }
}`