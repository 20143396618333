export default [
  {
    name: "Project Member Roles",
    path: "project-member-role-list",
    component: () => import("./MemberRoleList.vue"),
    props: true,
    meta: {
      permissions: ["can_add_project_member_roles"]
    }
  },
];
