import alumniRouter from "@/components/memberships/Alumni/alumniRouter";
import diasporaRouter from "@/components/memberships/Diaspora/diasporaRouter";

export default [
    {
        path: "/membership",
        name: "MembershipMain",
        component: () => import("@/components/memberships/membershipsMain.vue"),
        meta: {
            permissions: [],
        },
        children: [
            {
                name: "MemberShip Index",
                path: "index",
                // component: index
                component: () => import("./Index.vue"),

                meta: {
                    permissions: [

                    ],
                },
            },
            {
                name: "Current",
                path: "current-members:roles?",
                component: () =>
                    import("@/components/memberships/CurrentMembers/CurrentMembers.vue"),
                meta: {
                    permissions: ["can_view_attendance_list"],
                },
            },
            ...alumniRouter,
            ...diasporaRouter
        ],
    },
];


