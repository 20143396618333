import { createAluminumProfessionSkillsCategories, updateAluminumProfessionSkillsCategories, getAluminumSkillCategories, deleteAluminumProfessionSkillsCategories } from "@/graphql/settings/alumniSkillCategories.graphql"
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";
import alumniSubSkillsCategories from "@/store/Settings/alumniSubSkillsCategories";

export default {
    state: {
        AllAlumniSkillCategories: [],
        AlumniSkillCategory: {}
    },
    getters: {
        getAluminumSkillCategories: (state) => (state.AllAlumniSkillCategories),
        getOneAlumniSkillCategory: (state) => (state.AlumniSkillCategory)
    },
    actions: {
        async getAluminumSkillCategories(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getAluminumSkillCategories,
                variables : {uniqueId}
            })
                .then((output) => {
                    let {response, data} = output.data?.getAluminumSkillCategories

                    if (response.status) {
                        if (_.isNil(uniqueId)) {
                            context.commit("setStoreItem", {
                                module : 'alumniSkillCategories',
                                entity : 'AllAlumniSkillCategories',
                                data
                            })
                            return data
                        }else {
                            context.commit("setStoreItem", {
                                module : 'alumniSkillCategories',
                                entity : 'AlumniSkillCategory',
                                data   : data[0]
                            })
                            return data[0]
                        }
                    } else {
                        notifyError(response.message || "Failed to fetch")
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },

        async createAlumniSkillCategory(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createAluminumProfessionSkillsCategories,
                variables: {input}
            })
                .then((output) => {
                    let {response, data} = output.data.createAluminumProfessionSkillsCategories
                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: "alumniSkillCategories",
                            entity: "AllAlumniSkillCategories",
                            uniqueKey: "aluminSkillCategoryUniqueId",
                            data,
                        });
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },
        async updateAluminumSkillsCategories(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateAluminumProfessionSkillsCategories,
                variables: {input}
            })
                .then((output) => {
                    let {response, data} = output.data.updateAluminumProfessionSkillsCategories

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: "alumniSkillCategories",
                            entity: "AllAlumniSkillCategories",
                            uniqueKey: "aluminSkillCategoryUniqueId",
                            data,
                        });
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },

        async deleteAluminumSkillsCategories(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteAluminumProfessionSkillsCategories,
                variables: {uniqueId}
            })
                .then((output) => {
                    let {response, data} = output.data.deleteAluminumProfessionSkillsCategories

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: "alumniSkillCategories",
                            entity: "AllAlumniSkillCategories",
                            uniqueKey: "skillCategoryUnaluminSkillCategoryUniqueIdiqueId",
                            data,
                            remove: true,
                        });
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },
    }
}
