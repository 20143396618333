import gql from "graphql-tag";
export const getAllUsersInUserInformation = gql`
  query getAllUsersInUserInformation($informationUniqueId: String!) {
    getAllUsersInUserInformation(informationUniqueId: $informationUniqueId) {
      data {
        users {
          id
          profileUniqueId
          username
          firstName
          middleName
          lastName
          profilePicture
          gender
          institution
        }
        userInformation {
          userInformationUniqueId
          userInformationDescription
          userInformationAttachment
          userInformationCreatedDate
          userInformationType
          userInformationCategory
        }
      }
      response {
        id
        status
        code
        message
      }
    }
  }
`;
