import gql from "graphql-tag";

let response = `response{
   id
   status
   code
   message
}`;


let data = `
data{
aluminSkillSubCategoryUniqueId
aluminSkillSubCategoryName
}
`

export const getAluminumSkillSubCategories = gql`
query GetAluminumSkillSubCategories($uniqueId: String, $aluminSkillCategoryUniqueId: String) {
  getAluminumSkillSubCategories(uniqueId: $uniqueId, aluminSkillCategoryUniqueId: $aluminSkillCategoryUniqueId) {
    ${response}
    ${data}
  }
}
`

export const createAluminumProfessionSkillsSubCategories = gql`
mutation CreateAluminumProfessionSkillsSubCategories($input: AluminumProfessionSkillsSubCategoriesInputObject!) {
  createAluminumProfessionSkillsSubCategories(input: $input){
    ${response}
    ${data}
  }
}
`

export const updateAluminumProfessionSkillsSubCategories = gql`
mutation UpdateAluminumProfessionSkillsSubCategories($input: AluminumProfessionSkillsSubCategoriesInputObject!) {
  updateAluminumProfessionSkillsSubCategories(input: $input){
    ${response}
    ${data}
  }
}
`
export const deleteAluminumprofessionSkillsSubCategories = gql`
mutation DeleteAluminumprofessionSkillsSubCategories($uniqueId: String!) {
  deleteAluminumprofessionSkillsSubCategories(uniqueId: $uniqueId) {
   ${response}
   ${data}
  }
}
`
