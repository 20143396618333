import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";
import { getSecurityReportData,createSecurityReport,updateSecurityReport,deleteSecurityReport, deleteSecurityTrendsAndUpdates,updateSecurityTrendsAndUpdates,createSecurityTrendsAndUpdates,createSecurityReportCategory, getSecurityTrendsAndUpdatesData, getSecurityReportCategoryData, updateSecurityReportCategory, deleteSecurityReportCategory } from "../../graphql/communication/security/security.graphql"
export default {
    state: {
        securityReportCategories: [],
        securityTrends: [],
        securityReports: [],
    },
    getters: {
        getSecurityReportCategoryData: (state) => (state.securityReportCategories),
        getSecurityTrendsData: (state) => (state.securityTrends),
        getSecurityReportData: (state) => (state.securityReports),
    },
    actions: {
        async getSecurityReportCategoryData(context, filtering) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getSecurityReportCategoryData,
                variables: { filtering }
            })
                .then((output) => {
                    let { response, data } = output.data.getSecurityReportCategoryData

                    if (response.status) {
                        context.commit("setStoreItem", {
                            module: 'securityManagement',
                            entity: 'securityReportCategories',
                            data
                        })
                        return data;
                    } else {
                        notifyError(response.message || "Failed to fetch")
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },

        async createSecurityReportCategory(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createSecurityReportCategory,
                variables: { input }
            })
                .then((output) => {
                    let { response, data } = output.data.createSecurityReportCategory

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: 'securityManagement',
                            entity: 'securityReportCategories',
                            uniqueKey: 'securityReportCategoryUniqueId',
                            data
                        })
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },

        async updateSecurityReportCategory(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateSecurityReportCategory,
                variables: { input }
            })
                .then((output) => {
                    let { response, data } = output.data.updateSecurityReportCategory

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: 'securityManagement',
                            entity: 'securityReportCategories',
                            uniqueKey: 'securityReportCategoryUniqueId',
                            data
                        })
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },

        async deleteSecurityReportCategory(context, securityReportCategoryUniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteSecurityReportCategory,
                variables: { securityReportCategoryUniqueId }
            })
                .then((output) => {
                    let { response, data } = output.data.deleteSecurityReportCategory

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: 'securityManagement',
                            entity: 'securityReportCategories',
                            uniqueKey: 'securityReportCategoryUniqueId',
                            data,
                            remove: true,
                        })
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },



        //security trends 

        async getSecurityTrendsAndUpdatesData(context, filtering) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getSecurityTrendsAndUpdatesData,
                variables: { filtering }
            })
                .then((output) => {
                    let { response, data } = output.data.getSecurityTrendsAndUpdatesData

                    if (response.status) {
                        context.commit("setStoreItem", {
                            module: 'securityManagement',
                            entity: 'securityTrends',
                            data
                        })
                        return data;
                    } else {
                        notifyError(response.message || "Failed to fetch")
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },

        async createSecurityTrendsAndUpdates(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createSecurityTrendsAndUpdates,
                variables: { input }
            })
                .then((output) => {
                    let { response, data } = output.data.createSecurityTrendsAndUpdates

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: 'securityManagement',
                            entity: 'securityTrends',
                            uniqueKey: 'securityTrendsUniqueId',
                            data
                        })
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },

        async updateSecurityTrendsAndUpdates(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateSecurityTrendsAndUpdates,
                variables: { input }
            })
                .then((output) => {
                    let { response, data } = output.data.updateSecurityTrendsAndUpdates

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: 'securityManagement',
                            entity: 'securityTrends',
                            uniqueKey: 'securityTrendUniqueId',
                            data
                        })
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },

        async deleteSecurityTrendsAndUpdates(context, securityTrendUniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteSecurityTrendsAndUpdates,
                variables: { securityTrendUniqueId }
            })
                .then((output) => {
                    let { response, data } = output.data.deleteSecurityTrendsAndUpdates

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: 'securityManagement',
                            entity: 'securityTrends',
                            uniqueKey: 'securityTrendUniqueId',
                            data,
                            remove: true,
                        })
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },




        async getSecurityReportData(context, filtering) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getSecurityReportData,
                variables: { filtering }
            })
                .then((output) => {
                    let { response, data } = output.data.getSecurityReportData

                    if (response.status) {
                        context.commit("setStoreItem", {
                            module: 'securityManagement',
                            entity: 'securityReports',
                            data
                        })
                        return data;
                    } else {
                        notifyError(response.message || "Failed to fetch")
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },

        async createSecurityReport(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createSecurityReport,
                variables: { input }
            })
                .then((output) => {
                    let { response, data } = output.data.createSecurityReport

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: 'securityManagement',
                            entity: 'securityReports',
                            uniqueKey: 'securityReportUniqueId',
                            data
                        })
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },

        async updateSecurityReport(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateSecurityReport,
                variables: { input }
            })
                .then((output) => {
                    let { response, data } = output.data.updateSecurityReport

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: 'securityManagement',
                            entity: 'securityReports',
                            uniqueKey: 'securityReportUniqueId',
                            data
                        })
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },

        async deleteSecurityReport(context, securityReportUniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteSecurityReport,
                variables: { securityReportUniqueId }
            })
                .then((output) => {
                    let { response, data } = output.data.deleteSecurityReport

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: 'securityManagement',
                            entity: 'securityReports',
                            uniqueKey: 'securityReportUniqueId',
                            data,
                            remove: true,
                        })
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },


    }
}


