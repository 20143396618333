import { createAluminumProfessionSkillsSubCategories, deleteAluminumprofessionSkillsSubCategories, getAluminumSkillSubCategories, updateAluminumProfessionSkillsSubCategories } from "@/graphql/settings/AlumniSkillSubCategories.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";
import alumniSkillCategories from "@/store/Settings/alumniSkillCategories";

export default {
    state: {
        AlumniSkillSubCategories: [],
        AlumniSkillSubCategory: {}
    },
    getters: {
        getAluminumSubSkillCategories: (state) => (state.AlumniSkillSubCategories),
        getOneAlumniSubSkillCategory: (state) => (state.AlumniSkillSubCategory)
    },
    actions: {
        async getAluminumSubSkillCategories(context, aluminSkillCategoryUniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getAluminumSkillSubCategories,
                variables : {aluminSkillCategoryUniqueId}
            })
                .then((output) => {
                    let {response, data} = output.data.getAluminumSkillSubCategories

                    if (response.status) {
                        context.commit("setStoreItem", {
                            module : 'alumniSubSkillsCategories',
                            entity : 'AlumniSkillSubCategories',
                            data
                        })
                        return data
                    } else {
                        notifyError(response.message || "Failed to fetch")
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },
        async createAluminumProfessionSkillsSubCategories(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createAluminumProfessionSkillsSubCategories,
                variables: {input}
            })
                .then((output) => {
                    let {response, data} = output.data.createAluminumProfessionSkillsSubCategories

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module : 'alumniSubSkillsCategories',
                            entity : 'AlumniSkillSubCategories',
                            uniqueKey : 'aluminSkillSubCategoryUniqueId',
                            data
                        })
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },
        async updateAluminumProfessionSkillsSubCategories(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateAluminumProfessionSkillsSubCategories,
                variables: {input}
            })
                .then((output) => {
                    let {response, data} = output.data.updateAluminumProfessionSkillsSubCategories

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module : 'alumniSubSkillsCategories',
                            entity : 'AlumniSkillSubCategories',
                            uniqueKey : 'aluminSkillSubCategoryUniqueId',
                            data
                        })
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },
        async deleteAluminumprofessionSkillsSubCategories(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteAluminumprofessionSkillsSubCategories,
                variables: {
                    uniqueId
                }
            })
                .then((output) => {
                    let {response, data} = output.data.deleteAluminumprofessionSkillsSubCategories

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module : 'alumniSubSkillsCategories',
                            entity : 'AlumniSkillSubCategories',
                            uniqueKey : 'aluminSkillSubCategoryUniqueId',
                            data,
                            remove : true,
                        })
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },
    }
}
