import gql from "graphql-tag";

const data = `data {
  attachmentPath
  profileWithAttachmentAttachment {
    attachmentName
    attachmentUniqueId
    createdDate
  }
  profileWithAttachmentCreatedDate
  profileWithAttachmentUniqueId
}`
export const getProfileWithAttachment = gql`query GetProfileWithAttachment($uniqueId: String) {
    getProfileWithAttachment(uniqueId: $uniqueId) {
      ${data}
      response {
        id
        status
        code
        message
      }
    }
  }`

export const createProfileWithAttachment = gql`mutation CreateProfileWithAttachment($input: ProfileWithAttachmentInputObject) {
    createProfileWithAttachment(input: $input) {
      ${data} 
      response {
        id
        status
        code
        message
      }
    }
  }`

export const updateProfileWithAttachment = gql`mutation UpdateProfileWithAttachment($input: ProfileWithAttachmentInputObject) {
    updateProfileWithAttachment(input: $input) {
      ${data} 
      response {
        id
        status
        code
        message
      }
    }
  }`

export const deleteProfileWithAttachment = gql`mutation DeleteProfileWithAttachment($profileWithAttachmentUniqueId: String!) {
    deleteProfileWithAttachment(profileWithAttachmentUniqueId: $profileWithAttachmentUniqueId) {
      ${data}
      response {
        id
        status
        code
        message
      }
    }
  }`