import gql from "graphql-tag";

let response = `response{
   id
   status
   code
   message
}`;
let data = `    
data {
      diasporaInterestsUniqueId
      diasporaInterestsName
    }
`;

export const  getDiasporaFieldOfInterests = gql`
query GetDiasporaFieldOfInterests {
  getDiasporaFieldOfInterests {
    ${response}
    ${data}
  }
}
`

export const  createDiasporaFieldInterests = gql`
mutation CreateDiasporaFieldInterests($input: DiasporaFieldInterestsInputObject!) {
  createDiasporaFieldInterests(input: $input) {
    ${response}
    ${data}
  }
}
`


export const updateDiasporaFieldInterests = gql `
mutation UpdateDiasporaFieldInterests($input: DiasporaFieldInterestsInputObject!) {
  updateDiasporaFieldInterests(input: $input) {
   ${response}
   ${data}
  }
}
`

export  const  deleteDiasporaFieldInterests = gql`
mutation DeleteDiasporaFieldInterests($uniqueId: String!) {
  deleteDiasporaFieldInterests(uniqueId: $uniqueId) {
    ${response}
    ${data}
  }
}
`



