import gql from "graphql-tag";


const attachmentFields = `
  attachmentName
  attachmentUniqueId
  createdDate
`;

const response = `
  code
  id
  message
  status
`;


export const getAllAttachments = gql`query getAllAttachments($uniqueId: String) {
  getAllAttachments(uniqueId:$uniqueId){
    response{
      ${response}
    }
    data {
      ${attachmentFields}
    }
  }
}`

export const updateAttachment = gql`mutation updateAttachment($input: AttachmentInputObject) {
  updateAttachment(input:$input){
    response{
      ${response}
    }
    data {
      ${attachmentFields}
    }
  }
}`

export const createAttachment = gql`mutation createAttachment($input: AttachmentInputObject) {
  createAttachment(input:$input){
    response{
      ${response}
    }
    data {
      ${attachmentFields}
    }
  }
}`

export const deleteAttachment = gql`mutation deleteAttachment($uniqueId: String!) {
  deleteAttachment(uniqueId:$uniqueId){
    response{
      ${response}
    }
    data {
      ${attachmentFields}
    }
  }
}`


// New Atachment Graphql

export const UploadFile = gql`
  mutation UploadFile($input: UbunifuFileServerAttachmentsInputObjects!) {
    uploadFile(input: $input) {
      data {
        documentName
        id
      }
      response {
        code
        id
        message
        status
      }
    }
  }
`;
export const multipleUploadFile = gql`
  mutation multipleUploadFile($input: UbunifuFileServerAttachmentsListInputObjects!) {
    multipleUploadFile(input: $input) {
      response {
        id
        status
        code
        message
      }
      data {
        id
        documentName
      }
    }
  }
`;


export const openFile = gql`
  query OpenFile($documentPath: String!) {
    openFile(documentPath: $documentPath) {
      response {
        id
        status
        code
        message
      }
      data {
        id
        documentName
        base64String
      }
    }
  }
`;


