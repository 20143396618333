import gql from "graphql-tag";


export const updateAcademicQualification = gql`
  mutation updateAcademicQualification($input: AcademicQualificationInputObject){
    updateAcademicQualification(input: $input) {
      data {
      academicQualificationUniqueId
      academicQualificationStartDate
      academicQualificationEndDate
      academicQualificationCertificate
      academicQualificationTranscript
      academicQualificationStatus
      academicQualificationCountry {
        countryUniqueId
        countryName
        countryCreatedDate
      }
      academicQualificationAcademicInstitution {
        institutionUniqueId
        institutionName
        institutionType
        institutionCreatedDate
      }
      academicQualificationEducationLevel
      academicQualificationProgram {
        programUniqueId
        programName
        programCategory {
          categoryUniqueId
          categoryName
          createdDate
        }
        createdDate
      }
      academicQualificationCreatedDate
      academicQualificationCurrentYear
      academicQualificationCourseDuration
      academicQualificationSchoolName
      academicQualificationOtherInstitutions
      academicQualificationPerformance
      academicQualificationDivision
      academicQualificationMerit
      academicQualificationGpa
      otherProgramName
    }
      response {
        id
        status
        code
        message
      }
    }
  }
`;

export const deleteAcademicQualification = gql`
  mutation deleteAcademicQualification($uniqueId: String!) {
    deleteAcademicQualification(uniqueId: $uniqueId) {
      data {
      academicQualificationUniqueId
      academicQualificationStartDate
      academicQualificationEndDate
      academicQualificationCertificate
      academicQualificationTranscript
      academicQualificationStatus
      academicQualificationCountry {
        countryUniqueId
        countryName
        countryCreatedDate
      }
      academicQualificationAcademicInstitution {
        institutionUniqueId
        institutionName
        institutionType
        institutionCreatedDate
      }
      academicQualificationEducationLevel
      academicQualificationProgram {
        programUniqueId
        programName
        programCategory {
          categoryUniqueId
          categoryName
          createdDate
        }
        createdDate
      }
      academicQualificationCreatedDate
      academicQualificationCurrentYear
      academicQualificationCourseDuration
      academicQualificationSchoolName
      academicQualificationOtherInstitutions
      academicQualificationPerformance
      academicQualificationDivision
      academicQualificationMerit
      academicQualificationGpa
      otherProgramName
    }
      response {
        id
        status
        code
        message
      }
    }
  }
`;
export const createAcademicQualification = gql`
  mutation createAcademicQualification(
    $input: AcademicQualificationInputObject
  ) {
    createAcademicQualification(input: $input) {
      data {
      academicQualificationUniqueId
      academicQualificationStartDate
      academicQualificationEndDate
      academicQualificationCertificate
      academicQualificationTranscript
      academicQualificationStatus
      academicQualificationCountry {
        countryUniqueId
        countryName
        countryCreatedDate
      }
      academicQualificationAcademicInstitution {
        institutionUniqueId
        institutionName
        institutionType
        institutionCreatedDate
      }
      academicQualificationEducationLevel
      academicQualificationProgram {
        programUniqueId
        programName
        programCategory {
          categoryUniqueId
          categoryName
          createdDate
        }
        createdDate
      }
      academicQualificationCreatedDate
      academicQualificationCurrentYear
      academicQualificationCourseDuration
      academicQualificationSchoolName
      academicQualificationOtherInstitutions
      academicQualificationPerformance
      academicQualificationDivision
      academicQualificationMerit
      academicQualificationGpa
      otherProgramName
    }
    response {
      id
      status
      code
      message
    }
      }
    }
  
`;


export const getAcademicQualifications = gql`
  query getAcademicQualifications($profileUniqueId: String, $uniqueId: String) {
    getAcademicQualifications(profileUniqueId: $profileUniqueId, uniqueId: $uniqueId) {
      response {
        id
        status
        code
        message
      }
      data {
      academicQualificationUniqueId
      academicQualificationStartDate
      academicQualificationEndDate
      academicQualificationCertificate
      academicQualificationTranscript
      academicQualificationStatus
      academicQualificationCountry {
        countryUniqueId
        countryName
        countryCreatedDate
      }
      academicQualificationAcademicInstitution {
        institutionUniqueId
        institutionName
        institutionType
        institutionCreatedDate
      }
      academicQualificationEducationLevel
      academicQualificationProgram {
        programUniqueId
        programName
        programCategory {
          categoryUniqueId
          categoryName
          createdDate
        }
        createdDate
      }
      academicQualificationCreatedDate
      academicQualificationCurrentYear
      academicQualificationCourseDuration
      academicQualificationSchoolName
      academicQualificationOtherInstitutions
      academicQualificationPerformance
      academicQualificationDivision
      academicQualificationMerit
      academicQualificationGpa
      otherProgramName
    }
    }
  }
`;