import gql from "graphql-tag";


const projectFolderFields = `
    folderUniqueId
    folderName
    folderDescription
    subFolders {
    folderUniqueId
    folderName
    folderDescription
    }
    folderProject {
        projectUniqueId
    }

`;

const responseFields = `
    code
    id
    message
    status
`;

export const createProjectFolder = gql`
    mutation CreateProjectFolder($input: ProjectFolderInputObject) {
        createProjectFolder(input: $input) {
            data {
                ${projectFolderFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const getFolderDetails = gql`
    query getFolderDetails($folderUniqueId: String, $projectUniqueId: String) {
        getFolderDetails(folderUniqueId: $folderUniqueId, projectUniqueId: $projectUniqueId) {
            data {
                ${projectFolderFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;



export const deleteProjectFolder = gql`
    mutation DeleteProjectFolder($uniqueId: String) {
        deleteProjectFolder(uniqueId: $uniqueId) {
            data {
                ${projectFolderFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;