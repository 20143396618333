import gql from "graphql-tag";
export const getConductedTraining = gql`
  query getConductedTraining($input: FilterInputObject) {
    getConductedTraining(input: $input) {
      data {
        conductedTrainingAttachment
        conductedTrainingAttendance
        conductedTrainingCreatedDate
        conductedTrainingDate
        conductedTrainingDescription
        conductedTrainingTitle
        conductedTrainingUniqueId
        conductedTrainingVenue
      }
      response {
        code
        id
        message
        status
      }
    }
  }
`;
export const  createConductedTraining = gql`
mutation CreateConductedTraining($input: ConductedTrainingsInputObject) {
  createConductedTraining(input: $input) {
    data {
      conductedTrainingAttachment
      conductedTrainingAttendance
      conductedTrainingCreatedDate
      conductedTrainingDate
      conductedTrainingDescription
      conductedTrainingTitle
      conductedTrainingUniqueId
      conductedTrainingVenue
    }
    response {
      code
      id
      message
      status
    }
  }
}`
export const deleteConductedTraining = gql`
  mutation deleteConductedTraining($uniqueId: String!) {
    deleteConductedTraining(uniqueId: $uniqueId) {
      data {
        conductedTrainingAttachment
        conductedTrainingAttendance
        conductedTrainingCreatedDate
        conductedTrainingDate
        conductedTrainingDescription
        conductedTrainingTitle
        conductedTrainingUniqueId
        conductedTrainingVenue
      }
      response {
        code
        id
        message
        status
      }
    }
  }
`;
export const updateConductedTraining = gql`
  mutation updateConductedTraining($input: ConductedTrainingsInputObject) {
    updateConductedTraining(input: $input) {
      data {
        conductedTrainingAttachment
        conductedTrainingAttendance
        conductedTrainingCreatedDate
        conductedTrainingDate
        conductedTrainingDescription
        conductedTrainingTitle
        conductedTrainingUniqueId
        conductedTrainingVenue
      }
      response {
        code
        id
        message
        status
      }
    }
  }
`;