import gql from "graphql-tag";
export const getIdeaDetails = gql`
  query getIdeaDetails($uniqueId: String) {
    getIdeaDetails(uniqueId: $uniqueId) {
      data {
        ideaApprovalStatus
        ideaApprovedBy {
          firstName
          gender
          id
          lastName
          middleName
          profilePicture
          profileUniqueId
          username
        }
        ideaBenefits
        ideaName
        ideaNature
        ideaObjectives
        ideaPurpose
        ideaRemark
        ideaStage
        ideaType
        ideaUniqueId
        ideaWeight
      }
      response {
        code
        id
        message
        status
      }
    }
  }
`;

export const createIdeaDetails = gql`
  mutation createIdeaDetails($input: IdeaDetailsInputObject) {
    createIdeaDetails(input: $input) {
      data {
        ideaApprovalStatus
        ideaApprovedBy {
          firstName
          gender
          id
          lastName
          middleName
          profilePicture
          profileUniqueId
          username
        }
        ideaBenefits
        ideaName
        ideaNature
        ideaObjectives
        ideaPurpose
        ideaRemark
        ideaStage
        ideaType
        ideaUniqueId
        ideaWeight
      }
      response {
        code
        id
        message
        status
      }
    }
  }
`;
export const updateIdeaDetails = gql`
  mutation updateIdeaDetails($input: IdeaDetailsInputObject) {
    updateIdeaDetails(input: $input) {
      data {
        ideaApprovalStatus
        ideaApprovedBy {
          firstName
          gender
          id
          lastName
          middleName
          profilePicture
          profileUniqueId
          username
        }
        ideaBenefits
        ideaName
        ideaNature
        ideaObjectives
        ideaPurpose
        ideaRemark
        ideaStage
        ideaType
        ideaUniqueId
        ideaWeight
      }
      response {
        code
        id
        message
        status
      }
    }
  }
`;
export const deleteIdeaDetails = gql`
  mutation DeleteIdeaDetails($uniqueId: String!) {
    deleteIdeaDetails(uniqueId: $uniqueId) {
      data {
        ideaApprovalStatus
        ideaApprovedBy {
          firstName
          gender
          id
          lastName
          middleName
          profilePicture
          profileUniqueId
          username
        }
        ideaBenefits
        ideaName
        ideaNature
        ideaObjectives
        ideaPurpose
        ideaRemark
        ideaStage
        ideaType
        ideaUniqueId
        ideaWeight
      }
      response {
        code
        id
        message
        status
      }
    }
  }
`;

export const ideaDetailsApproval = gql`
  mutation ideaDetailsApproval($input: IdeaApprovalInputObject) {
    ideaDetailsApproval(input: $input) {
      data {
        ideaApprovalStatus
        ideaApprovedBy {
          firstName
          gender
          id
          lastName
          middleName
          profilePicture
          profileUniqueId
          username
        }
        ideaBenefits
        ideaName
        ideaNature
        ideaObjectives
        ideaPurpose
        ideaRemark
        ideaStage
        ideaType
        ideaUniqueId
        ideaWeight
      }
      response {
        code
        id
        message
        status
      }
    }
  }
`;