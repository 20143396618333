import gql from "graphql-tag";


const projectCategoryFields = `
    projectCategoryName
    projectCategoryUniqueId
`;

const responseFields = `
    code
    id
    message
    status
`;

export const createProjectCategory = gql`
    mutation CreateProjectCategory($input: ProjectCategoryInputObject) {
        createProjectCategory(input: $input) {
            data {
                ${projectCategoryFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const updateProjectCategory = gql`
    mutation UpdateProjectCategory($input: ProjectCategoryInputObject) {
        updateProjectCategory(input: $input) {
            data {
                ${projectCategoryFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const getProjectCategory = gql`
    query GetProjectCategoryDetails($projectCategoryUniqueId: String) {
        getProjectCategoryDetails(projectCategoryUniqueId: $projectCategoryUniqueId) {
            data {
                ${projectCategoryFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;


export const deleteProjectCategory = gql`
    mutation DeleteProjectCategory($uniqueId: String!) {
        deleteProjectCategory(uniqueId: $uniqueId) {
            data {
                ${projectCategoryFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;



