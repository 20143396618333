import gql from "graphql-tag";
const data = `data {
 hasSubTask
      ideaTaskDescription
      ideaTaskName
      ideaTaskUniqueId
      ideaTaskWeight
      ideaTasksIdea {
        ideaApprovalStatus
        ideaApprovedBy {
          firstName
          gender
          id
          lastName
          middleName
          profilePicture
          profileUniqueId
          username
        }
        ideaBenefits
        ideaName
        ideaNature
        ideaObjectives
        ideaPurpose
        ideaRemark
        ideaStage
        ideaType
        ideaUniqueId
        ideaWeight
      }
}`;
export const updateIdeaTask = gql`
  mutation updateIdeaTask($input: IdeaTaskInputObject) {
    updateIdeaTask(input: $input) {
      ${data}
      response {
        code
        id
        message
        status
      }
    }
  }
`;

export const createIdeaTask = gql`
mutation createIdeaTask($input: IdeaTaskInputObject) {
  createIdeaTask(input: $input) {
    ${data}
    response {
      code
      id
      message
      status
    }
  }
}
`;

export const deleteIdeaTask = gql`
mutation deleteIdeaTask($uniqueId: String!) {
  deleteIdeaTask(uniqueId: $uniqueId) {
    ${data}
    response {
      code
      id
      message
      status
    }
  }
}`
;

export const getIdeaTask = gql`
  query GetIdeaTask($ideaUniqueId: String, $uniqueId: String) {
    getIdeaTask(ideaUniqueId: $ideaUniqueId, uniqueId: $uniqueId) {
      ${data}
      response {
        code
        id
        message
        status
      }
    }
  }
`;