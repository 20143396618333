import gql from "graphql-tag";

const announcementFields = `
    announcementAttachment
    announcementCreatedBy {
        id
        profileUniqueId
        firstName
        middleName
        lastName
        username
        gender
        profilePicture
    }
    announcementAudiences {
        id
        profileUniqueId
        firstName
        middleName
        lastName
        username
        gender
        profilePicture
    }
    announcementCreatedDate
    announcementDescription
    announcementEndDate
    announcementStartDate
    announcementTitle
    announcementType
    announcementAccess
    announcementUniqueId
    audiencesUniqueId
`;

const response = `
    code
    id
    message
    status
`;

export const createAnnouncement = gql`
    mutation CreateAnnouncement($input: AnnouncementDetailsInputObject) {
        createAnnouncement(input: $input) {
            data {
                ${announcementFields}
            }
            response {
                ${response}
            }
        }
    }
`;

export const updateAnnouncement = gql`
    mutation UpdateAnnouncement($input: AnnouncementDetailsInputObject) {
        updateAnnouncement(input: $input) {
            data {
                ${announcementFields}
            }
            response {
                ${response}
            }
        }
    }
`;

export const getAnnouncements = gql`
    query getAnnouncement($input: AnnouncementFilterInputObject) {
        getAnnouncement(input: $input) {
            data {
                ${announcementFields}
            }
            response {
                ${response}
            }
        }
    }
`;

export const deleteAnnouncement = gql`
    mutation DeleteAnnouncement($uniqueId: String!) {
        deleteAnnouncement(uniqueId: $uniqueId) {
            data {
                ${announcementFields}
            }
            response {
                ${response}
            }
        }
    }
`;
export const deleteAnnouncementUser = gql`
    mutation deleteAnnouncementSelectedUser($uniqueId: String!) {
        deleteAnnouncementSelectedUser(uniqueId: $uniqueId) {
        data {
            announcementSelectedUserUniqueId
            announcementSelectedUserId {
                firstName
                gender
                id
                lastName
                middleName
                profilePicture
                profileUniqueId
                username
            }
        }
        response {
            code
            id
            message
            status
            }
        }
    }
`;

export const getPublicAnnouncement = gql`query GetPublicAnnouncement {
    getPublicAnnouncement {
      response {
        id
        status
        code
        message
      }
      data {
        announcementUniqueId
        announcementTitle
        announcementDescription
        announcementType
        announcementAccess
        announcementAttachment
        announcementStartDate
        announcementEndDate
        audiencesUniqueId
        announcementAudiences {
          id
          profileUniqueId
          username
          firstName
          middleName
          lastName
          profilePicture
          gender
          institution
        }
        announcementCreatedBy {
          id
          profileUniqueId
          username
          firstName
          middleName
          lastName
          profilePicture
          gender
          institution
        }
        announcementCreatedDate
      }
    }
  }`;