import { declareProfileDetails } from "@/graphql/profile_details/declaration.graphql.js";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
        declarationStatus: false
    },
    getters: {
        getDeclarationStatus: (state) => (state.declarationStatus)
    },
    mutations: {
        setDeclarationStatus: (state, value) => (state.declarationStatus = value)
    },
    actions: {
        async declareProfileDetails(context, declarationStatus) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: declareProfileDetails,
                variables: {declarationStatus}
            })
            .then((output) => {
                let {response} = output.data.declareProfileDetails;
                if (response.status) {
                    context.commit("setStoreItem", {
                        module : 'declaration',
                        entity : 'declarationStatus',
                        data: declarationStatus
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    context.dispatch("getUserProfile")
                    return declarationStatus
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
    }
}