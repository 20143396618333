import {
  deleteIdeaTaskProgress,
  createIdeaTaskProgress,
  updateIdeaTaskProgress,
  getIdeaTaskProgress,
} from "@/graphql/ideas/IdeaProgress.graphql.js";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
  state: {
    ideatasks: [],
    ideatask: {},
  },
  getters: {
    getIdeaTaskProgresss: (state) => state.ideatasks,
    getIdeaTaskProgress: (state) => state.ideatask,
  },
  actions: {
    async getIdeaTaskProgresss(context, data) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getIdeaTaskProgress,
          variables: {
            taskUniqueId: data?.ideaTaskUniqueId,
            uniqueId: data?.ideaTaskProgressUniqueId,
          },
        })
        .then((output) => {
          let { response, data } = output.data.getIdeaTaskProgress;

          if (response.status) {
            context.commit("setStoreItem", {
              module: "ideaProgress",
              entity: "ideatasks",
              data,
            });
            return data;
          } else {
            notifyError(response.message || "Failed to fetch");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async createIdeaTaskProgress(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: createIdeaTaskProgress,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.createIdeaTaskProgress;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "ideaProgress",
              entity: "ideatasks",
              uniqueKey: "ideaTaskProgressUniqueId",
              data,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async updateIdeaTaskProgress(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: updateIdeaTaskProgress,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.updateIdeaTaskProgress;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "ideaProgress",
              entity: "ideatasks",
              uniqueKey: "ideaTaskProgressUniqueId",
              data,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async deleteIdeaTaskProgress(context, uniqueId) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: deleteIdeaTaskProgress,
          variables: { uniqueId },
        })
        .then((output) => {
          let { response, data } = output.data.deleteIdeaTaskProgress;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "ideaProgress",
              entity: "ideatasks",
              uniqueKey: "ideaTaskProgressUniqueId",
              data,
              remove: true,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
  },
};
