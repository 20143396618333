import gql from "graphql-tag";

const  response = `
response{
id
status
code
message
}
`

export  const  surveyData = `
data {
      id
      surveyUniqueId
      surveyLink
      surveyProposal {
        id
        proposalUniqueId
        proposalTitle
        proposalDescription
        proposalArea {
          id
          researchAreaUniqueId
          researchAreaName
          researchAreaPhoto
          researchAreaDescription
          researchAreaIsActive
          researchAreaCreateddate
          researchAreaCreatedBy {
            id
            profileUniqueId
            username
            firstName
            middleName
            lastName
            profilePicture
            gender
            institution
            role
            dateJoined
          }
        }
        proposalStage
        proposalStatus
        researchProposalDoc
        proposalAuthor {
          id
          profileUniqueId
          username
          firstName
          middleName
          lastName
          profilePicture
          gender
          institution
          role
          dateJoined
        }
        proposalAuthorInstitution {
          institutionUniqueId
          institutionName
          institutionType
          institutionCreatedDate
        }
        proposalAuthorCountry {
          countryUniqueId
          countryName
          countryCreatedDate
        }
        proposalIsActive
        proposalCreatedDate
        proposalAttachments {
          id
          proposalAttachmentUniqueId
          proposalAttachmentName
          proposalAttachmentPath
          proposalAttachmentFileType
          proposal
          proposalAttachmentIsActive
          proposalAttachmentIsDownloaded
          proposalAttachmentCreatedDate
        }
      }
      surveyIsActive
      surveyCretedDate
    }
`


export  const  getOnGoingSurveyData = gql`
query GetOnGoingSurveyData($filtering: OngoingSurveyFilteringObject) {
  getOnGoingSurveyData(filtering: $filtering){
    ${response}
    ${surveyData}
  }
}
`

export  const  createOnGoingSurvey = gql`
mutation CreateOnGoingSurvey($input: OngoingSurveyInputObject) {
  createOnGoingSurvey(input: $input) {
    ${response}
    ${surveyData}
  }
}
`

export  const  deleteOnGoingSurvey = gql`
mutation DeleteOnGoingSurvey($surveyUniqueId: String!) {
  deleteOnGoingSurvey(surveyUniqueId: $surveyUniqueId) {
    ${response}
    ${surveyData}
  }
}
`

export  const  updateOngoingSurvey = gql`
mutation UpdateOngoingSurvey {
  updateOngoingSurvey {
    ${response}
    ${surveyData}
  }
}
`



