import gql from "graphql-tag";

const data = `
totalFemaleEntries
totalMaleEntries
totalOtherEntries
totalAttendanceEntries
maleEarlyEntry
maleLateEntry
femaleLateEntry
femaleEarlyEntry
maleEarlyExit
femaleEarlyExit
earlyEntry
lateEntry
extraHours
maleExtraHours
femaleExtraHours
`;

const responseFields = `
    code
    id
    message
    status
`;


export const getGeneralAttendanceDashboard = gql`
query GetGeneralAttendanceDashboard($filter: AttendanceDashBoardFilter) {
    getGeneralAttendanceDashboard(filter: $filter) {
            data {
                ${data}
            }
            response {
                ${responseFields}
            }
        }
    }
`;