import gql from "graphql-tag";

const memberRoleFields = `
    teamMemberRoleName
    teamMemberRoleUniqueId
`;

const response = `
    code
    id
    message
    status
`;

export const createProjectMemberRole = gql`
    mutation CreateProjectMemberRole($input: ProjectTeamMemberRoleInputObject) {
        createProjectMemberRole(input: $input) {
            data {
                ${memberRoleFields}
            }
            response {
                ${response}
            }
        }
    }
`;

export const updateProjectMemberRole = gql`
    mutation UpdateProjectMemberRole($input: ProjectTeamMemberRoleInputObject) {
        updateProjectMemberRole(input: $input) {
            data {
                ${memberRoleFields}
            }
            response {
                ${response}
            }
        }
    }
`;

export const getTeamMemberRoles = gql`
    query GetTeamMemberRoleDetails($roleUniqueId: String) {
        getTeamMemberRoleDetails(roleUniqueId: $roleUniqueId) {
            data {
                ${memberRoleFields}
            }
            response {
                ${response}
            }
        }
    }
`;

export const deleteProjectMemberRole = gql`
    mutation DeleteProjectMemberRole($uniqueId: String!) {
        deleteProjectMemberRole(uniqueId: $uniqueId) {
            data {
                ${memberRoleFields}
            }
            response {
               ${response}
            }
        }
    }
`;