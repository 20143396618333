import gql from "graphql-tag";

export const deleteUserRole = gql`mutation deleteUserRole($roleUniqueId:  String!){
  deleteUserRole(roleUniqueId: $roleUniqueId){
    response{
      code
      message
      status
    }
  }
}`

export const getRoles = gql`query getRoles{
  getRoles{
    response{
      code
      message
      status
    }
   data {
      id
      roleUniqueId
      roleName
      roleDescription
      rolePermissions{
        id
        permissionUniqueId
        permissionName
        permissionCode
      }
    }
  }
}`

export const getRolesWithoutPermissions = gql`query getRoles{
  getRoles{
    response{
      code
      message
      status
    }
    data {
      roleUniqueId
      roleName
      roleDescription
    }
  }
}`

export const updateUserRole = gql`mutation updateUserRole($input: RoleInputObjects!) {
  updateUserRole(input:$input){
    response{
      code
      status
      message
    }
    data {
      id
      roleUniqueId
      roleName
      roleDescription
      rolePermissions{
        id
        permissionUniqueId
        permissionName
        permissionCode
      }
    }
  }
}`

export const createUserRole = gql`mutation createUserRole($input: RoleInputObjects!) {
  createUserRole(input:$input){
    response{
      code
      status
      message
    }
    data {
      id
      roleUniqueId
      roleName
      roleDescription
      rolePermissions{
        id
        permissionUniqueId
        permissionName
        permissionCode
      }
    }
  }
}`
