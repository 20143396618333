import { createAttachment, getAllAttachments, updateAttachment, deleteAttachment, UploadFile, openFile, multipleUploadFile } from "@/graphql/settings/attachment.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
        attachments: [],
        fileUploadResponse: [],
        attachment: {}
    },
    getters: {
        getAllAttachments: (state) => (state.attachments),
        getAttachment: (state) => (state.attachment),
        getFileUploadResponse: (state) => state.fileUploadResponse
    },
    actions: {
        async getAllAttachments(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getAllAttachments,
                variables : {uniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.getAllAttachments
                
                if (response.status) {
                    if (_.isNil(uniqueId)) {
                        context.commit("setStoreItem", {
                            module : 'attachmentManagement',
                            entity : 'attachments',
                            data
                        })
                        return data
                    }else {
                        context.commit("setStoreItem", {
                            module : 'attachmentManagement',
                            entity : 'attachment',
                            data   : data[0]
                        })
                        return data[0]
                    }
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async createAttachment(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createAttachment,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.createAttachment
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'attachmentManagement',
                        entity : 'attachments',
                        uniqueKey : 'attachmentUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        async updateAttachment(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateAttachment,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.updateAttachment
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'attachmentManagement',
                        entity : 'attachments',
                        uniqueKey : 'attachmentUniqueId',
                        data  : data || input
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async deleteAttachment(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteAttachment,
                variables: {uniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.deleteAttachment
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'attachmentManagement',
                        entity : 'attachments',
                        uniqueKey : 'attachmentUniqueId',
                        data,
                        remove : true,
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        async UploadFile(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: UploadFile,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.uploadFile
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'attachmentManagement',
                        entity : 'fileUploadResponse',
                        uniqueKey : 'id',
                        data
                    })
                   
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            })
            .catch((error) => {console.log(error)})
            .finally(() => context.commit("setLoadingState", false))
        },

        async multipleUploadFile(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: multipleUploadFile,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.multipleUploadFile
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'attachmentManagement',
                        entity : 'fileUploadResponse',
                        uniqueKey : 'id',
                        data
                    })
                   
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            })
            .catch((error) => {console.log(error)})
            .finally(() => context.commit("setLoadingState", false))
        },

        async openFile(context, documentPath) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: openFile,
                variables : {documentPath}
            })
            .then((output) => {
                let {response, data} = output.data.openFile;
                
                if (response.status) {
                    context.commit("setStoreItem", {
                        module : 'attachmentManagement',
                        entity : 'attachment',
                        data   : _.omit(data, ['base64String'])
                    })
                    return data
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
    }
}