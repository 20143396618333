import gql from "graphql-tag";


const assignMemberField = `
subTaskAssignmentSubTask {
  projectSubtaskDescription
  projectSubtaskName
  projectSubtaskTask {
    projectTaskDescription
    projectTaskHasSubTask
    projectTaskName
    projectTaskProject {
      projectBenefits
      projectCategory {
        projectCategoryName
        projectCategoryUniqueId
      }
      projectDescription
      projectIsActive
      projectName
      projectNature {
        projectNatureName
        projectNatureUniqueId
      }
      projectObjectives
      
      projectSource  
      projectType
      projectUniqueId
      projectWeight
    }
    projectTaskProjectUniqueId
    projectTaskUniqueId
    projectTaskWeight
  }
  projectSubtaskUniqueId
  projectSubtaskWeight
}
subTaskAssignmentUniqueId
subTaskProjectMember {
  firstName
  gender
  id
  lastName
  middleName
  profilePicture
  profileUniqueId
  username
}
`;

const responseFields = `
    code
    id
    message
    status
`;

export const createProjectSubTaskAssignment = gql`
mutation CreateProjectSubTaskAssignment($input: ProjectSubTaskAssignmentInputObjectType) {
  createProjectSubTaskAssignment(input: $input) {
      data {
        ${assignMemberField}
      }
      response {
        ${responseFields}
      }
    }
  }
`;

export const getSubTaskAssignmentDetails= gql`
query GetSubTaskAssignmentDetails($filter: SubTaskAssignmentFilter) {
  getSubTaskAssignmentDetails(filter: $filter) {
            data {
                ${assignMemberField}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const deleteProjectSubtaskAssignment = gql`
mutation DeleteProjectSubtaskAssignment($subTaskAssignmentUniqueId: String) {
  deleteProjectSubtaskAssignment(subTaskAssignmentUniqueId: $subTaskAssignmentUniqueId) {
            data {
                ${assignMemberField}
            }
            response {
                ${responseFields}
            }
        }
    }
`;