import gql from 'graphql-tag';

const response = `response {
    status
    code
    message
}`;

const SecurityReportCategoryData = ` data {
      id
      securityReportCategoryUniqueId
      securityReportCategoryName
      securityReportCategoryDescription
      securityReportCategoryIsActive
      securityReportCategoryCreatedDate
      securityReportCategoryCreatedBy {
        id
        profileUniqueId
        username
        firstName
        middleName
        lastName
        profilePicture
        gender
        institution
        role
        dateJoined
      }
  }`;
const SecurityTrendsData = `data{
  id
  securityTrendCreatedDate
  securityTrendDescriptions
  securityTrendImage
  securityTrendIsActive
  securityTrendTitle
  securityTrendUniqueId
  securityTrendCreatedby{
  dateJoined
  firstName
  gender
  id
  institution
  lastName
  middleName
  profilePicture
  profileUniqueId
  role
  username
  }
}`
const SecurityReportData = `data{
  securityReportCategory{
    id
    securityReportCategoryCreatedBy{
    dateJoined
    firstName
    gender
    id
    institution
    lastName
    middleName
    profilePicture
    profileUniqueId
    role
    username
  }
  securityReportCategoryCreatedDate
  securityReportCategoryDescription
  securityReportCategoryIsActive
  securityReportCategoryName
  securityReportCategoryUniqueId
  }
  securityReportComments
  securityReportNews
  securityReportNewsCreatedDate
  securityReportSeverityOfBug
  securityReportSolution
  securityReportSource
  securityReportSummary
  securityReportTechnique
  securityReportUniqueId
  securityTrendAndUpdate {
        id
        securityTrendUniqueId
        securityTrendTitle
        securityTrendDescriptions
        securityTrendImage
        securityTrendCreatedDate
        securityTrendIsActive
      }
  }`
const page = `page {
    number
    hasNextPage
    hasPreviousPage
    nextPageNumber
    previousPageNumber
  }`


export const getSecurityReportCategoryData = gql`query getSecurityReportCategoryData($filtering: SecurityReportCategoryFilteringObject) {
  getSecurityReportCategoryData(filtering: $filtering) {
      ${response}
      ${SecurityReportCategoryData}
    }
}`;

export const createSecurityReportCategory = gql`mutation createSecurityReportCategory($input: SecurityReportCategoryInputObject) {
    createSecurityReportCategory(input: $input) {
      ${response}
      ${SecurityReportCategoryData}
    }
}`;

export const updateSecurityReportCategory = gql`mutation updateSecurityReportCategory($input: SecurityReportCategoryInputObject) {
    updateSecurityReportCategory(input: $input) {
        ${response}
        ${SecurityReportCategoryData}
    }
}`;

export const deleteSecurityReportCategory = gql`mutation deleteSecurityReportCategory($securityReportCategoryUniqueId: String!) {
    deleteSecurityReportCategory(securityReportCategoryUniqueId: $securityReportCategoryUniqueId) {
      ${response}
      ${SecurityReportCategoryData}
    }
}`;




export const getSecurityTrendsAndUpdatesData = gql`query getSecurityTrendsAndUpdatesData($filtering: SecurityTrendsAndUpdatesReportFilteringObject) {
  getSecurityTrendsAndUpdatesData(filtering: $filtering) {
      ${response}
      ${SecurityTrendsData}
    }
}`;

export const createSecurityTrendsAndUpdates = gql`mutation createSecurityTrendsAndUpdates($input: SecurityTrendsAndUpdatesReportInputObject) {
    createSecurityTrendsAndUpdates(input: $input) {
      ${response}
      ${SecurityTrendsData}
    }
}`;

export const updateSecurityTrendsAndUpdates = gql`mutation updateSecurityTrendsAndUpdates($input: SecurityTrendsAndUpdatesReportInputObject) {
    updateSecurityTrendsAndUpdates(input: $input) {
        ${response}
        ${SecurityTrendsData}
    }
}`;

export const deleteSecurityTrendsAndUpdates = gql`mutation deleteSecurityTrendsAndUpdates($securityTrendUniqueId: String!) {
    deleteSecurityTrendsAndUpdates(securityTrendUniqueId: $securityTrendUniqueId) {
      ${response}
      ${SecurityTrendsData}
    }
}`;




export const getSecurityReportData = gql`query getSecurityReportData($filtering: SecurityReportFilteringObject) {
  getSecurityReportData(filtering: $filtering) {
      ${response}
      ${SecurityReportData}
    }
}`;

export const createSecurityReport = gql`mutation createSecurityReport($input: SecurityReportInputObject!) {
    createSecurityReport(input: $input) {
      ${response}
      ${SecurityReportData}
    }
}`;

export const updateSecurityReport = gql`mutation updateSecurityReport($input: SecurityReportInputObject) {
    updateSecurityReport(input: $input) {
        ${response}
        ${SecurityReportData}
    }
}`;

export const deleteSecurityReport = gql`mutation deleteSecurityReport($securityReportUniqueId: String!) {
    deleteSecurityReport(securityReportUniqueId: $securityReportUniqueId) {
      ${response}
      ${SecurityReportData}
    }
}`;
