import gql from "graphql-tag";

let response = `response{
   id
   status
   code
   message
}`;
let data = `    
data {
      diasporaFieldOfExpertUniqueId
      diasporaFieldOfExpertName
    }
`;

 export  const getDiasporaFieldOfExpert =gql`
 query GetDiasporaFieldOfExpert($uniqueId: String) {
  getDiasporaFieldOfExpert(uniqueId: $uniqueId){
    ${response}
    ${data}
  }
}
 `

export const createDiasporaFieldOfExpert = gql`
mutation CreateDiasporaFieldOfExpert($input: DiasporaFieldOfExpertInputObject!) {
  createDiasporaFieldOfExpert(input: $input) {
    ${response}
    ${data}
  }
}
`

export const updateDiasporaFieldOfExpert  = gql`
mutation UpdateDiasporaFieldOfExpert($input: DiasporaFieldOfExpertInputObject!) {
  updateDiasporaFieldOfExpert(input: $input) {
    ${response}
    ${data}
  }
}
`

export const  deleteDiasporaFieldOfExpert = gql`
mutation DeleteDiasporaFieldOfExpert($uniqueId: String!) {
  deleteDiasporaFieldOfExpert(uniqueId: $uniqueId) {
    ${response}
    ${data}
  }
}
`



