import { createProjectMemberRole, deleteProjectMemberRole, getTeamMemberRoles, updateProjectMemberRole } from "@/graphql/settings/projectConfigurations/projectMemberRole.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
        memberRoles: [],
        memberRole: {}
    },
    getters: {
        getMemberRoles: (state) => (state.memberRoles),
        getMemberRole: (state) => (state.memberRole)
    },
    actions: {
        async getTeamMemberRoles(context, roleUniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getTeamMemberRoles,
                variables : {roleUniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.getTeamMemberRoleDetails;

                if (response.status) {
                        context.commit("setStoreItem", {
                            module : 'projectMemberRoleManagement',
                            entity : 'memberRoles',
                            data
                        })
                        return data
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        async createProjectMemberRole(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createProjectMemberRole,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.createProjectMemberRole;
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'projectMemberRoleManagement',
                        entity : 'memberRoles',
                        uniqueKey : 'teamMemberRoleUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        
        async updateProjectMemberRole(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateProjectMemberRole,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.updateProjectMemberRole;
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'projectMemberRoleManagement',
                        entity : 'memberRoles',
                        uniqueKey : 'teamMemberRoleUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },


        async deleteProjectMemberRole(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteProjectMemberRole,
                variables: {uniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.deleteProjectMemberRole;
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'projectMemberRoleManagement',
                        entity : 'memberRoles',
                        uniqueKey : 'teamMemberRoleUniqueId',
                        data,
                        remove : true,
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
    }
}