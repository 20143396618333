import gql from "graphql-tag";

const data = `data {
  subTaskCompletedWeight
  subTaskProgressAttachment
  subTaskProgressComments
  subTaskProgressStatus
  subTaskProgressTask
  subTaskProgressUniqueId
}`;
const response = `response {
  id
  code
  message
  status
}`;

export const getProjectSubTaskProgressDetails = gql`query GetProjectSubTaskProgressDetails($subTaskUniqueId: String, $subTaskProgressUniqueId: String, $taskUniqueId: String) {
  getProjectSubTaskProgressDetails(subTaskUniqueId: $subTaskUniqueId, subTaskProgressUniqueId: $subTaskProgressUniqueId, taskUniqueId: $taskUniqueId) {
    ${data}
    ${response}
  }
}`;
export const createProjectSubTaskProgress = gql`mutation CreateProjectSubTaskProgress($input: ProjectSubTaskProgressDetailsInputObjectType) {
  createProjectSubTaskProgress(input: $input) {
    ${data}
    ${response}
  }
}`;
export const updateProjectSubtaskProgress = gql`mutation UpdateProjectSubtaskProgress($input: ProjectSubTaskProgressDetailsInputObjectType) {
  updateProjectSubtaskProgress(input: $input) {
    ${data}
    ${response}
  }
}`;
export const deleteProjectSubTaskProgress = gql`mutation DeleteProjectSubTaskProgress($subTaskProgressUniqueId: String!) {
  deleteProjectSubTaskProgress(subTaskProgressUniqueId: $subTaskProgressUniqueId) {
    ${data}
    ${response}
  }
}`;