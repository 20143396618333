export default [
    {
        path: "diaspora-interests-settings",
        name: "diaspora interests settings",
        component: () => import("./diasporaInterestsList.vue"),
        meta: {
            permissions: [
                "can_add_skills_sub_category",
            ],
        },
    },
];
