import { createAdvertisement, deleteAdvertisement, updateAdvertisement, getAllAdvertisement } from "@/graphql/communication/advertisement/advertisement.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
        advertisements : [],
        advertisement : {}
    },
    getters: {
        getAdvertisements: (state) => (state.advertisements),
        getAdvertisement: (state) => (state.advertisement)
    },
    actions: {
        async getAdvertisements(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getAllAdvertisement,
                variables : {input}
            })
            .then((output) => {

                let {response, data} = output.data.getAllAdvertisement;

                if (response.status) {
                        context.commit("setStoreItem", {
                            module : 'advertisementManagement',
                            entity : 'advertisements',
                            data
                        })
                        return data
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        async createAdvertisement(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createAdvertisement,
                variables: {input}
            })
            .then((output) => {

                let {response, data} = output.data.createAdvertisement;
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'advertisementManagement',
                        entity : 'advertisements',
                        uniqueKey : 'advertisementUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        async updateAdvertisement(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateAdvertisement,
                variables: {input}
            })
            .then((output) => {

                let {response, data} = output.data.updateAdvertisement;
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'advertisementManagement',
                        entity : 'advertisements',
                        uniqueKey : 'advertisementUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        async deleteAdvertisement(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteAdvertisement,
                variables: {uniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.deleteAdvertisement;
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'advertisementManagement',
                        entity : 'advertisements',
                        uniqueKey : 'advertisementUniqueId',
                        data,
                        remove : true,
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
    }
}