import { getFilteredAttendance , getNotArrivedAttendance } from "@/graphql/attendance/attendance.graphql";
import { notifyError } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
        attendances : [],
        notAttended:[],

    },
    getters: {
        getFilteredAttendance: (state) => (state.attendances),
        getNotArrivedAttendance: (state) => (state.notAttended),
    },
    actions: {
        async getFilteredAttendance(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getFilteredAttendance,
                variables : {input}
            })
            .then((output) => {
                let {response, data} = output.data.getFilteredAttendance;

                if (response.status) {
                        context.commit("setStoreItem", {
                            module : 'attendanceManagement',
                            entity : 'attendances',
                            data
                        })
                        return data
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        async getNotArrivedAttendance(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getNotArrivedAttendance,
                variables : {input}
            })
                .then((output) => {
                    let {response, data} = output.data.getNotArrivedAttendance;

                    if (response.status) {
                        context.commit("setStoreItem", {
                            module : 'attendanceManagement',
                            entity : 'notAttended',
                            data
                        })
                        return data
                    } else {
                        notifyError(response.message || "Failed to fetch")
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },
    }
}
