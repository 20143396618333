import gql from "graphql-tag";
export const getIdeaMembers = gql`
  query getIdeaMembers($ideaUniqueId: String) {
    getIdeaMembers(ideaUniqueId: $ideaUniqueId) {
      data {
        ideaMemberIdea {
          ideaApprovalStatus
          ideaApprovedBy {
            firstName
            gender
            id
            lastName
            middleName
            profilePicture
            profileUniqueId
            username
          }
          ideaBenefits
          ideaName
          ideaNature
          ideaObjectives
          ideaPurpose
          ideaRemark
          ideaStage
          ideaType
          ideaUniqueId
          ideaWeight
        }
        ideaMemberUniqueId
        memberDetails {
          firstName
          gender
          id
          lastName
          middleName
          profilePicture
          profileUniqueId
          username
        }
      }
      response {
        code
        id
        message
        status
      }
    }
  }
`;

export const createIdeaMember = gql`
  mutation createIdeaMember($input: IdeaMembersInputObject) {
    createIdeaMember(input: $input) {
      data {
        ideaMemberIdea {
          ideaApprovalStatus
          ideaApprovedBy {
            firstName
            gender
            id
            lastName
            middleName
            profilePicture
            profileUniqueId
            username
          }
          ideaBenefits
          ideaName
          ideaNature
          ideaObjectives
          ideaPurpose
          ideaRemark
          ideaStage
          ideaType
          ideaUniqueId
          ideaWeight
        }
        ideaMemberUniqueId
        memberDetails {
          firstName
          gender
          id
          lastName
          middleName
          profilePicture
          profileUniqueId
          username
        }
      }
      response {
        code
        id
        message
        status
      }
    }
  }
`;
;

export const updateIdeaMember = gql`
  mutation updateIdeaMember($input: IdeaMembersInputObject) {
    updateIdeaMember(input: $input) {
      data {
        ideaMemberIdea {
          ideaApprovalStatus
          ideaApprovedBy {
            firstName
            gender
            id
            lastName
            middleName
            profilePicture
            profileUniqueId
            username
          }
          ideaBenefits
          ideaName
          ideaNature
          ideaObjectives
          ideaPurpose
          ideaRemark
          ideaStage
          ideaType
          ideaUniqueId
          ideaWeight
        }
        ideaMemberUniqueId
        memberDetails {
          firstName
          gender
          id
          lastName
          middleName
          profilePicture
          profileUniqueId
          username
        }
      }
      response {
        code
        id
        message
        status
      }
    }
  }
`;

export const deleteIdeaMember = gql`
  mutation deleteIdeaMember($uniqueId: String!) {
    deleteIdeaMember(uniqueId: $uniqueId) {
      data {
        ideaMemberIdea {
          ideaApprovalStatus
          ideaApprovedBy {
            firstName
            gender
            id
            lastName
            middleName
            profilePicture
            profileUniqueId
            username
          }
          ideaBenefits
          ideaName
          ideaNature
          ideaObjectives
          ideaPurpose
          ideaRemark
          ideaStage
          ideaType
          ideaUniqueId
          ideaWeight
        }
        ideaMemberUniqueId
        memberDetails {
          firstName
          gender
          id
          lastName
          middleName
          profilePicture
          profileUniqueId
          username
        }
      }
      response {
        code
        id
        message
        status
      }
    }
  }
`;
;