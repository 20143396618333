export default [
  {
    path: "setting-publication-category",
    name: "Setting Publication category",
    component: () => import("./publicationCategories.vue"),
    meta: {
      permissions: [
        "can_add_locations",
      ],
    },
  },
];
