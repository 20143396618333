<template>
  <div class="grid grid-cols-12 gap-5 dark:text-white">
    <CountryCreateUpdateDialog :Dialog="OpenCreateUpdateDialog" :DialogTitle="title" @close="triggerCreateUpdate(false)" :editItemData="selectedItem"/>
    <div class="flex justify-between col-span-12">
      <div>
        <button class="mr-2 btn btn-primary-soft" @click="$router.back()">
          <ArrowLeftCircle class="w-4 h-4 mr-1 stroke-current lucide" />
          {{ $t('labels.back') }}
        </button>
        <span class="text-xl font-semibold">{{ $t('location.labels.countries') }}</span>
      </div>
      <div class="relative w-56 text-slate-500">
        <input type="text" class="w-56 pr-10 form-control box" :placeholder="$t('labels.searchPlaceholder')" v-model="search">
        <Search class="absolute inset-y-0 right-0 w-4 h-4 my-auto mr-3 stroke-current lucide lucide-search" />
      </div>
    </div>
    <div v-if="countries?.length == 0" class="col-span-12 p-5 text-center rounded intro-x zoom-in" :class="isDark ? 'bg-[rgb(var(--color-darkmode-600))] text-white' : 'bg-white'">
        <span class="text-overline">{{ $t("labels.noData") }}</span>
    </div>
    <div class="col-span-12 overflow-auto intro-y lg:overflow-visible" v-else>
      <table class="table -mt-2 table-report" >
        <thead>
          <tr>
            <th v-for="header in $t('iterables.locationTableHeaders')" :key="header.name"
              class="whitespace-nowrap dark:text-white">
              {{ header.name }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="intro-x" v-for="country, index in countries" :key="country.countryUniqueId">
            <td class="w-40">
              <div class="flex">{{ index + 1 }}</div>
            </td>
            <td>
              <a href="javascript:;" class="font-medium whitespace-nowrap">
                {{ country.countryName }} </a>
            </td>
            <td class="w-56 table-report__action">
              <div class="flex items-center justify-center text-slate-600 dark:text-slate-500 dark:bg-darkmode-400/70 dark:border-darkmode-400">
                <template v-if="country?.countryName?.toLowerCase() !== 'tanzania'">
                  <a class="flex items-center mr-3" @click="triggerCreateUpdate(true, country)">
                    <CheckSquare class="w-4 h-4 mr-1 lucide lucide-check-square" />
                    {{ $t('location.buttons.editCountry.title') }}
                  </a>
                  <a class="flex items-center !text-rose-500" @click="deleteItem(country)">
                    <TrashAlt class="w-4 h-4 mr-1 lucide lucide-check-square" />
                    {{ $t('location.buttons.deleteCountry.title') }}
                  </a>
                </template>
                <a class="flex items-center justify-center" v-else @click="showItem(country)">
                  <Eye class="w-4 h-4 mr-1 lucide lucide-check-square" />
                  {{ $t('location.buttons.viewCountry.title') }}
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
  </div>
</div>

  
</template>

<script>
import { confirmDialog } from '@/plugins/notification.service'
import CountryCreateUpdateDialog from './CountryCreateUpdateDialog.vue'

export default {
  data() {
    return {
      OpenCreateUpdateDialog: false,
      selectedItem: {},
      title: "",
      search: "",
    }
  },
  components: {
    CountryCreateUpdateDialog
  },
  computed: {
    countries() {
      if (this.search != "") {
        return this.$store.getters.getAllCountries?.filter((country) => country.countryName?.toLowerCase()?.includes(this.search.toLowerCase()))
      } else {
        return this.$store.getters.getAllCountries
      }
    }
  },
  methods: {
    triggerCreateUpdate(open = true, data = {}) {
      this.OpenCreateUpdateDialog = open
      if (open) {
        this.selectedItem = data
        this.title = JSON.stringify(data) == '{}' ? "Add" : "Update"
      } else {
        this.selectedItem = {}
        this.title = ""
      }
    },
    async deleteItem(data) {
      await confirmDialog(`${data?.countryName} ${this.$t("location.labels.deleteLocation")}`)
        .then(async (agree) => {
          if (agree) {
            await this.$store.dispatch("deleteCountry", data.countryUniqueId)
          }
        })
    },
    showItem(country) {
      this.$router.push({
        name: "Regions",
        params: {
          countryUniqueId: country.countryUniqueId
        }
      })
    }
  },
  async mounted() {
    await this.$store.dispatch("getAllCountries")
  }
}

</script>
