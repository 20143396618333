import { createSkillCategory, updateSkillCategory, getAllSkillCategories, deleteSkillCategory } from "@/graphql/settings/skillCategory.graphql.js"
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
        skillCategories: [],
        skillCategory: {}
    },
    getters: {
        getAllSkillCategories: (state) => (state.skillCategories),
        getSkillCategory: (state) => (state.skillCategory)
    },
    actions: {
        async getAllSkillCategories(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getAllSkillCategories,
                variables : {uniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.getAllSkillCategory
                
                if (response.status) {
                    if (_.isNil(uniqueId)) {
                        context.commit("setStoreItem", {
                            module : 'skillCategoryManagement',
                            entity : 'skillCategories',
                            data
                        })
                        return data
                    }else {
                        context.commit("setStoreItem", {
                            module : 'skillCategoryManagement',
                            entity : 'skillCategory',
                            data   : data[0]
                        })
                        return data[0]
                    }
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async createSkillCategory(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createSkillCategory,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.createSkillCategory
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                      module: "skillCategoryManagement",
                      entity: "skillCategories",
                      uniqueKey: "skillCategoryUniqueId",
                      data,
                    });
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async updateSkillCategory(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateSkillCategory,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.updateSkillCategory
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                      module: "skillCategoryManagement",
                      entity: "skillCategories",
                      uniqueKey: "skillCategoryUniqueId",
                      data,
                    });
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async deleteSkillCategory(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteSkillCategory,
                variables: {uniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.deleteSkillCategory
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                      module: "skillCategoryManagement",
                      entity: "skillCategories",
                      uniqueKey: "skillCategoryUniqueId",
                      data,
                      remove: true,
                    });
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
    }
}