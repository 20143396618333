
import {
  updateAcademicQualification,
  deleteAcademicQualification,
  getAcademicQualifications,
  createAcademicQualification,
} from "@/graphql/profile_details/accademicDetails.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
  state: {
    academicQualifications: [],
    academicQualification: {},
  },
  getters: {
    getAcademicQualifications: (state) => state.academicQualifications,
    getAcademicQualification: (state) => state.academicQualification,
  },
  actions: {
    async getAcademicQualifications(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getAcademicQualifications,
          variables: input,
        })
        .then((output) => {
          let { response, data } = output.data.getAcademicQualifications;

          if (response.status) {
            if (_.isNil(input?.uniqueId)) {
              context.commit("setStoreItem", {
                module: "accademicQualificationManagement",
                entity: "academicQualifications",
                data,
              });
              return data;
            } else {
              context.commit("setStoreItem", {
                module: "accademicQualificationManagement",
                entity: "academicQualification",
                data: data[0],
              });
              return data[0];
            }
          } else {
            notifyError(response.message || "Failed to fetch");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
   
    async createAcademicQualification(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: createAcademicQualification,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.createAcademicQualification;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "accademicQualificationManagement",
              entity: "academicQualifications",
              uniqueKey: "academicQualificationUniqueId",
              data,
            });
            notifySuccess(response.message || i18n.t('labels.operationSuccessful'));
            context.dispatch("getUserProfile")
            return data;
          } else {
            notifyError(response.message || i18n.t('labels.operationFailed'));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async updateAcademicQualification(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: updateAcademicQualification,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.updateAcademicQualification;
          if (response.status) {
            context.commit("updateStoreItem", {
              module: "accademicQualificationManagement",
              entity: "academicQualifications",
              uniqueKey: "academicQualificationUniqueId",
              data,
            });
            notifySuccess(response.message || i18n.t('labels.operationSuccessful'));
            context.dispatch("getUserProfile")
            return data;
          } else {
            notifyError(response.message || i18n.t('labels.operationFailed'));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async deleteAcademicQualification(context, uniqueId) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: deleteAcademicQualification,
          variables: { uniqueId },
        })
        .then((output) => {
          let { response, data } = output.data.deleteAcademicQualification;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "accademicQualificationManagement",
              entity: "academicQualifications",
              uniqueKey: "academicQualificationUniqueId",
              data,
              remove: true,
            });
            notifySuccess(response.message || i18n.t('labels.operationSuccessful'));
            context.dispatch("getUserProfile")
            return data;
          } else {
            notifyError(response.message || i18n.t('labels.operationFailed'));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
  },
};
