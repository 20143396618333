import gql from "graphql-tag";
const projectFolderFields = `
    totalProjectsByPortfolio {
        portfolioName
        totalProjects
      }
      totalProjectsByCompletion {
        completion
        totalProjects
      }

`;
const responseFields = `
    code
    id
    message
    status
`;
export const getProjectPortfolioDashboard = gql`
    query getProjectPortfolioDashboard {
        getProjectPortfolioDashboard {
            data {
                ${projectFolderFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;
