import gql from "graphql-tag"

export const seedUserPermissions = gql`mutation seedUserPermissions($permissions: [UserPermisionInputObjects]){
    seedUserPermissions(permissions: $permissions){
      response{
            code
            message
            status
        }
    }
}`

export const getGroupedPermissions = gql`query getGroupedPermissions {
    getGroupedPermissions {
      response{
            code
            message
            status
        }
      data {
            permissionGroupUniqueId
            permissionGroupName
            permissions{
                permissionUniqueId
                permissionName
                permissionCode
            }
        }
    }
}`
