import { getProjectPortfolioDashboard } from "@/graphql/projects/projectDashboard.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
  state: {
    projects: [],
    project: {},
  },
  getters: {
    getProjectPortfolioDashboard: (state) => state.projects,
    getProjectPortfolios: (state) => state.project,
  },
  actions: {
    async getProjectPortfolioDashboard(context) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getProjectPortfolioDashboard,
        })
        .then((output) => {
          let { response, data } = output.data.getProjectPortfolioDashboard;

          if (response.status) {
            context.commit("setStoreItem", {
              module: "projectDashboard",
              entity: "projects",
              data,
            });
            return data;
          } else {
            notifyError(response.message || "Failed to fetch");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
  },
};
