import {createReacherTypeMutation,deleteReacherTypeMutation, getResearcherType, updateReacherTypeMutation,} from "@/graphql/settings/ResecherType";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
        ResearcherType: [],
    },
    getters: {
        getResearcherType: (state) => state.ResearcherType,
    },
    actions: {
        async getResearcherType(context) {
            context.commit("setLoadingState", true);
            return await apolloClient
                .query({
                    fetchPolicy: "network-only",
                    query: getResearcherType,
                })
                .then((output) => {
                    let { response, data } = output.data.getResearcherType;
                    if (response.status) {
                            context.commit("setStoreItem", {
                                module: "ResecherTypeManagement",
                                entity: "ResearcherType",
                                data: data,
                            });
                            return data;
                    } else {
                        notifyError(response.message || "Failed to fetch");
                    }
                })
                .finally(() => context.commit("setLoadingState", false));
        },
        async createReacherTypeMutation(context, input) {
            context.commit("setLoadingState", true);
            return await apolloClient
                .mutate({
                    fetchPolicy: "no-cache",
                    mutation: createReacherTypeMutation,
                    variables: { input },
                })
                .then((output) => {
                    let { response, data } = output.data.createReacherTypeMutation;

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: "ResecherTypeManagement",
                            entity: "ResearcherType",
                            uniqueKey: "uniqueId",
                            data,
                        });
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'));
                        return data;
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'));
                    }
                })
                .finally(() => context.commit("setLoadingState", false));
        },
        async updateReacherTypeMutation(context, input) {
            context.commit("setLoadingState", true);
            return await apolloClient
                .mutate({
                    fetchPolicy: "no-cache",
                    mutation: updateReacherTypeMutation,
                    variables: { input },
                })
                .then((output) => {
                    let { response, data } = output.data.updateReacherTypeMutation;

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: "ResecherTypeManagement",
                            entity: "ResearcherType",
                            uniqueKey: "uniqueId",
                            data,
                        });
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'));
                        return data;
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'));
                    }
                })
                .finally(() => context.commit("setLoadingState", false));
        },
        async deleteReacherTypeMutation(context, regionUniqueId) {
            context.commit("setLoadingState", true);
            return await apolloClient
                .mutate({
                    fetchPolicy: "no-cache",
                    mutation: deleteReacherTypeMutation,
                    variables: { regionUniqueId },
                })
                .then((output) => {
                    let { response, data } = output.data.deleteReacherTypeMutation;

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: "ResecherTypeManagement",
                            entity: "ResearcherType",
                            uniqueKey: "uniqueId",
                            data,
                            remove: true,
                        });
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'));
                        return data;
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'));
                    }
                })
                .finally(() => context.commit("setLoadingState", false));
        },
    },
};
