import gql from "graphql-tag"


 export const responseFields = `
    response {
      id
      status
      code
      message
    }
`


 export const alumniDataFields = `
 data {
      aluminumUniqueId
      aluminumFirstName
      aluminumMiddleName
      aluminumLastName
      aluminumProfilePicture
      aluminumGender
      alumniEmail
      aluminumDesignation
      aluminumPhoneNumber
      aluminumEmail
      aluminumStartDateAtRidc
      aluminumEndDateAtRid
      ridcPreviousStatus
      alumniDescription
      aluminumHighestEducationLevel
      aluminumEducationCountry {
        countryUniqueId
        countryName
        countryCreatedDate
      }
      aluminumEducationInstitution {
        institutionUniqueId
        institutionName
        institutionType
        institutionCreatedDate
      }     
       aluminumInstitutionName   
      aluminPlaceOfWorkCountry {
        countryUniqueId
        countryName
        countryCreatedDate
      }
      aluminPlaceOfWorkRegion {
        primaryKey
        regionUniqueId
        regionName
        regionCode
      }
      aluminCurrentTitle
      aluminumEmploymentStatus
      alumniApproved
      aluminumHasUpdatedDetails
      aluminLastUpdatedDate
    }
 `

export const   getAllAluminumDetails = gql`
query GetAllAluminumDetails($uniqueId: String) {
  getAllAluminumDetails(uniqueId: $uniqueId) {
   ${responseFields}
    ${alumniDataFields}
  }
}
`


export const  updateAluminum = gql`
mutation UpdateAluminum($input: AluminumInputObject!) {
  updateAluminum(input: $input)  {
   ${responseFields}
    ${alumniDataFields}
  }
}
`

export const  getMyAluminumDetails = gql`
query GetMyAluminumDetails {
  getMyAluminumDetails {
   ${responseFields}
    ${alumniDataFields}
  }
}
`

export const  approveAluminum = gql`
mutation ApproveAluminum($uniqueId: String!) {
  approveAluminum(uniqueId: $uniqueId){
   ${responseFields}
    ${alumniDataFields}
  }
}
`


export const  getAllApprovedAlumni = gql`
query GetAllApprovedAlumni {
  getAllApprovedAlumni {
   ${responseFields}
    ${alumniDataFields}
  }
}
`

export const  disapproveAluminum = gql`
mutation DisapproveAluminum($uniqueId: String!) {
  disapproveAluminum(uniqueId: $uniqueId) {
   ${responseFields}
    ${alumniDataFields}
  }
}
`


