export default [
  {
    path: "institutions",
    name: "Institutions",
    component: () => import("./InstitutionList.vue"),
    meta: {
      permissions: ["can_add_HILS"],
    },
  },
  {
    path: "institutions/institution-program/:institutionUniqueId",
    name: "InstitutionWithProgram",
    component: () => import("./InstitutionWithProgram.vue"),
    meta: {
      permissions: [],
    },
  },
];
