export default [
    {
        path: "skills",
        name: "Skills",
        component: () => import("@/components/setting_management/skill_management/SkillsMain.vue"),
        meta: {
            permissions: ["can_add_skills_category","can_add_skills_sub_category"]
        },
        children : [
            {
                path: "skills-category",
                name: "Skills Category List",
                component: () => import("@/components/setting_management/skill_management/skill_category/SkillCategoryList.vue"),
                meta: {
                    permissions: ["can_add_skills_category"]
                }
            },
            {
                path: "skills-category/sub-categories/:skillUniqueId",
                name: "Skills Subcategory List",
                component: () => import("@/components/setting_management/skill_management/skill_sub_category/SkillSubCategoryList.vue"),
                props: true,
                meta: {
                    permissions: ["can_add_skills_sub_category"]
                }
            }
        ]
    }, 

]