export default [
    {
        path: "diaspora-experts-settings",
        name: "diaspora experts settings",
        component: () => import("./diasporaExpertsList.vue"),
        meta: {
            permissions: [
                "can_add_skills_sub_category",
            ],
        },
    },
];
