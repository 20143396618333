export default [
  {
    path: "setting-application-category",
    name: "Setting Application category",
    component: () => import("./applicationCategories.vue"),
    meta: {
      permissions: [
        "can_view_application_details",
        "can_add_application_details",
      ],
    },
  },
];
