import gql from "graphql-tag";
export const createTaskAssignment = gql`
  mutation createTaskAssignment($input: IdeaTaskAssignmentInputObject) {
    createTaskAssignment(input: $input) {
      data {
        taskAssignmentMemberUniqueId {
          firstName
          gender
          id
          lastName
          middleName
          profilePicture
          profileUniqueId
          username
        }
        taskAssignmentTask {
          hasSubTask
          ideaTaskDescription
          ideaTaskName
          ideaTaskUniqueId
          ideaTaskWeight
          ideaTasksIdea {
            ideaApprovalStatus
            ideaApprovedBy {
              firstName
              gender
              id
              lastName
              middleName
              profilePicture
              profileUniqueId
              username
            }
            ideaBenefits
            ideaName
            ideaNature
            ideaObjectives
            ideaPurpose
            ideaRemark
            ideaStage
            ideaType
            ideaUniqueId
            ideaWeight
          }
        }
        taskAssignmentUniqueId
      }
      response {
        code
        id
        message
        status
      }
    }
  }
`;
export const updateTaskAssignment = gql`
  mutation updateTaskAssignment($input: IdeaTaskAssignmentInputObject) {
    updateTaskAssignment(input: $input) {
      data {
        taskAssignmentMemberUniqueId {
          firstName
          gender
          id
          lastName
          middleName
          profilePicture
          profileUniqueId
          username
        }
        taskAssignmentTask {
          hasSubTask
          ideaTaskDescription
          ideaTaskName
          ideaTaskUniqueId
          ideaTaskWeight
          ideaTasksIdea {
            ideaApprovalStatus
            ideaApprovedBy {
              firstName
              gender
              id
              lastName
              middleName
              profilePicture
              profileUniqueId
              username
            }
            ideaBenefits
            ideaName
            ideaNature
            ideaObjectives
            ideaPurpose
            ideaRemark
            ideaStage
            ideaType
            ideaUniqueId
            ideaWeight
          }
        }
        taskAssignmentUniqueId
      }
      response {
        code
        id
        message
        status
      }
    }
  }
`;
export const deleteTaskAssignment = gql`
  mutation deleteTaskAssignment($uniqueId: String!) {
    deleteTaskAssignment(uniqueId: $uniqueId) {
      data {
        taskAssignmentMemberUniqueId {
          firstName
          gender
          id
          lastName
          middleName
          profilePicture
          profileUniqueId
          username
        }
        taskAssignmentTask {
          hasSubTask
          ideaTaskDescription
          ideaTaskName
          ideaTaskUniqueId
          ideaTaskWeight
          ideaTasksIdea {
            ideaApprovalStatus
            ideaApprovedBy {
              firstName
              gender
              id
              lastName
              middleName
              profilePicture
              profileUniqueId
              username
            }
            ideaBenefits
            ideaName
            ideaNature
            ideaObjectives
            ideaPurpose
            ideaRemark
            ideaStage
            ideaType
            ideaUniqueId
            ideaWeight
          }
        }
        taskAssignmentUniqueId
      }
      response {
        code
        id
        message
        status
      }
    }
  }
`;
export const getIdeaTaskAssignment = gql`
  query getIdeaTaskAssignment($taskUniqueId: String) {
    getIdeaTaskAssignment(taskUniqueId: $taskUniqueId) {
      data {
        taskAssignmentMemberUniqueId {
          firstName
          gender
          id
          lastName
          middleName
          profilePicture
          profileUniqueId
          username
        }
        taskAssignmentTask {
          hasSubTask
          ideaTaskDescription
          ideaTaskName
          ideaTaskUniqueId
          ideaTaskWeight
          ideaTasksIdea {
            ideaApprovalStatus
            ideaApprovedBy {
              firstName
              gender
              id
              lastName
              middleName
              profilePicture
              profileUniqueId
              username
            }
            ideaBenefits
            ideaName
            ideaNature
            ideaObjectives
            ideaPurpose
            ideaRemark
            ideaStage
            ideaType
            ideaUniqueId
            ideaWeight
          }
        }
        taskAssignmentUniqueId
      }
      response {
        code
        id
        message
        status
      }
    }
  }
`;
;