import gql from "graphql-tag";
const data = `data {
    applicationPostUniqueId
    applicationPostDescription
    applicationPostAttachment
    applicationPostBanner
    applicationPostPublishedDate
    applicationPostExpiryDate
    applicationPostApplication {
      applicationUniqueId
      applicationCategory
      applicationDescription
      applicationIntakeStartDate
      applicationIntakeEndDate
      applicationCapacity
      applicationTheme
      applicationCreatedDate
    }
    applicationPostCreatedDate
    hasApplied
    hasExpired
    hasBeenStopped
    intake
}`
const response = `response {
    status
    code
    message
}`

export const getApplicationPostDetails = gql`query getApplicationPostDetails($applicationPostEnum: ApplicationPostTypeEnum, $applicationPostChoice: ApplicationChoiceTypeEnum, $applicationPostPublishedDate: Date, $applicationUniqueId: String, $uniqueId: String , $applicationPostYear: String
) {
    getApplicationPostDetails(ApplicationPostEnum: $applicationPostEnum, applicationPostChoice: $applicationPostChoice, applicationPostPublishedDate: $applicationPostPublishedDate, applicationUniqueId: $applicationUniqueId, uniqueId: $uniqueId , applicationPostYear: $applicationPostYear
) {
      ${response}
      ${data}
    }
}
`

export const createApplicationPost = gql`mutation CreateApplicationPost($input: ApplicationPostInputObject) {
    createApplicationPost(input: $input) {
        ${response}
        ${data}
    }
}`

export const updateApplicationPost = gql`mutation UpdateApplicationPost($input: ApplicationPostInputObject) {
    updateApplicationPost(input: $input) {
        ${response}
        ${data}
    }
}`

export const deleteApplicationPost = gql`mutation DeleteApplicationPost($uniqueId: String!) {
    deleteApplicationPost(uniqueId: $uniqueId) {
        ${response}
        ${data}
    }
}`
