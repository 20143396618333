import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";
import i18n from "@/i18n";
import {getCurrentMembers} from "@/graphql/memberShip/currentMember.graphql";

export default {
    state: {
        currentMembers: {},
    },
    getters: {
        getCurrentMembers: (state) => state.currentMembers,
    },
    actions: {
        async getCurrentMembers(context, filtering) {
            context.commit("setLoadingState", true);
            return await apolloClient
                .query({
                    fetchPolicy: "network-only",
                    query: getCurrentMembers,
                    variables: {filtering},
                })
                .then((output) => {
                    let { response, data } = output.data.getCurrentMembers;

                    if (response.status) {
                        context.commit("setStoreItem", {
                            module: "currentMember",
                            entity: "currentMembers",
                            data,
                        });
                        return data;
                    } else {
                        notifyError(response.message || "Failed to fetch");
                    }
                })
                .finally(() => context.commit("setLoadingState", false));
        },
    }
}
