import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";
import i18n from "@/i18n";
import { getAllUsersInUserInformation } from "../../graphql/user_information/userInUserInformation.graphql";

export default {
  state: {
    userInformations: [],
    userInformation: {},
  },
  getters: {
    getAllUsersInUserInformations: (state) => state.userInformations,
    getAllUsersInUserInformation: (state) => state.userInformation,
  },
  actions: {
    //
    async getAllUsersInUserInformations(context, data) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getAllUsersInUserInformation,
          variables: { ...data },
        })
        .then((output) => {
          let { response, data } = output.data.getAllUsersInUserInformation;

          if (response.status) {
            context.commit("setStoreItem", {
              module: "userInUserInformationManagement",
              entity: "userInformations",
              data,
            });
            return data;
          } else {
            notifyError(response.message || "Failed to fetch");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
    //
  },
};
