import gql from "graphql-tag";
export const createRegion = gql`mutation createRegion($input: RegionInputObject) {
    createRegion(input: $input) {
      data {
        primaryKey
        regionName
        regionCountry {
          regionUniqueId
          countryName
          createdDate
        }
        countryName {
          countryuniqueId
          countryName
          createdDate
        }
        isActive
        createdBy
      }
      response {
        id
        status
        code
        message
      }
    }
  }`

export const getAllRegions = gql`query getAllRegions {
    getAllRegions {
      response {
        id
        status
        message
      }
      data {
        primaryKey
        regionUniqueId
        regionName
        regionCode
      }
    }
  }`

export const deleteRegion = gql`mutation deleteRegion($uniqueId: String!) {
    deleteRegion(uniqueId: $uniqueId) {
      data {
        primaryKey
        regionName
        regionCountry {
          regionuniqueId
          countryName
          createdDate
        }
        isActive
        createdBy
        countryName {
          countryuniqueId
          countryName
          createdDate
        }
        regionuniqueId
        createdDate
      }
    }
  }`
export const updateRegion = gql`mutation updateRegion($input: RegionInputObject) {
    updateRegion(input: $input) {
      data {
        regionName
        regionCountry {
          regionuniqueId
          countryName
          createdDate
        }
        countryName {
          countryuniqueId
          countryName
          createdDate
        }
        regionuniqueId
        createdDate
      }
    }
  }`

