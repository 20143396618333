import { createInstitution, deleteInstitution, getInstitutions, updateInstitution } from "@/graphql/settings/institution.graphql";
import { getAcademicInstitutionWithProgram } from "@/graphql/settings/academicInstitutionWithProgram.js"
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
        institutions: [],
        hils: [],
        institution: {},
        institutionWithPrograms: [],
    },
    getters: {
        getInstitutions: (state) => (state.institutions),
        getHils: (state) => (state.hils),
        getInstitution: (state) => (state.institution),
        getInstitutionWithPrograms: (state) => (state.institutionWithPrograms),
    },
    actions: {
        async getInstitutions(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getInstitutions,
                variables : input
            })
            .then((output) => {
                let {response, data} = output.data.getInstitutions
                                
                if (response.status) {
                    if (_.isNil(input?.uniqueId)) {
                        context.commit("setStoreItem", {
                            module : 'institutionManagement',
                            entity : 'institutions',
                            data
                        })
                        if (input?.instituteType == 'HIGHER_LEARNING'){
                            context.commit("setStoreItem", {
                                module : 'institutionManagement',
                                entity : 'hils',
                                data
                            })
                        }
                        return data;   
                    }else {
                        context.commit("setStoreItem", {
                            module : 'institutionManagement',
                            entity : 'institution',
                            data   : data[0]
                        })
                        return data[0]
                    }
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async getAcademicInstitutionWithProgram(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getAcademicInstitutionWithProgram,
                variables : input
            })
            .then((output) => {
                let {response, data} = output.data.getAcademicInstitutionWithProgram
                                
                if (response.status) {
                    if (input?.uniqueId) {
                        context.commit("setStoreItem", {
                            module : 'institutionManagement',
                            entity : 'institutionWithPrograms',
                            data
                        })
                    } else {
                        context.commit("setStoreItem", {
                            module : 'institutionManagement',
                            entity : 'institutions',
                            data
                        })
                    }
                    return data;   
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async createInstitution(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createInstitution,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.createInstitution
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'institutionManagement',
                        entity : 'institutions',
                        uniqueKey : 'institutionUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async updateInstitution(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateInstitution,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.updateInstitution
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'institutionManagement',
                        entity : 'institutions',
                        uniqueKey : 'institutionUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async deleteInstitution(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteInstitution,
                variables: {uniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.deleteInstitution
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'institutionManagement',
                        entity : 'institutions',
                        uniqueKey : 'institutionUniqueId',
                        data,
                        remove : true,
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
    }
}