import { createRequest, deleteRequest, getRequests, updateRequest, requestApproval, getAllRequests} from "@/graphql/communication/request/request.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
        requests : [],
        request : {}
    },
    getters: {
        getRequests: (state) => (state.requests),
        getRequest: (state) => (state.request)
    },
    actions: {
        async getRequests(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getRequests,
                variables : {uniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.getRequest;

                if (response.status) {
                        context.commit("setStoreItem", {
                            module : 'requestManagement',
                            entity : 'requests',
                            data
                        })
                        return data
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async getAllRequests(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getAllRequests,
                variables : {input}
            })
            .then((output) => {
                let {response, data} = output.data.getAllRequests;

                if (response.status) {
                        context.commit("setStoreItem", {
                            module : 'requestManagement',
                            entity : 'requests',
                            data
                        })
                        return data
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        async createRequest(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createRequest,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.createRequest;
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'requestManagement',
                        entity : 'requests',
                        uniqueKey : 'requestUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        
        async updateRequest(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateRequest,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.updateRequest;
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'requestManagement',
                        entity : 'requests',
                        uniqueKey : 'requestUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        async requestApproval(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: requestApproval,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.requestApproval;
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'requestManagement',
                        entity : 'requests',
                        uniqueKey : 'requestUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },



        async deleteRequest(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteRequest,
                variables: {uniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.deleteRequest;
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'requestManagement',
                        entity : 'requests',
                        uniqueKey : 'requestUniqueId',
                        data,
                        remove : true,
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
    }
}