import gql from "graphql-tag";


const projectFields = `
    projectBenefits
    projectCategory {
        projectCategoryName
        projectCategoryUniqueId
    }
    projectDescription
    projectIsActive
    projectName
    projectNature {
        projectNatureName
        projectNatureUniqueId
    }
    projectObjectives
    projectSource
    projectUniqueId
    projectType
    projectCompletionPercent
`;

const responseFields = `
    code
    id
    message
    status
`;

export const createProject = gql`
    mutation CreateProject($input: ProjectInputObject) {
        createProject(input: $input) {
            data {
                ${projectFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const updateProject = gql`
    mutation UpdateProject($input: ProjectInputObject) {
        updateProject(input: $input) {
            data {
                ${projectFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const getProjectDetails = gql`
query GetProjectDetails($projectUniqueId: String, $domainUniqueId: String, $projectEnumFilter: ProjectEnumTypes) {
    getProjectDetails(projectUniqueId: $projectUniqueId, domainUniqueId: $domainUniqueId, projectEnumFilter:$projectEnumFilter){
            data {
                ${projectFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;



export const deleteProject = gql`
    mutation DeleteProject($uniqueId: String!) {
        deleteProject(uniqueId: $uniqueId) {
            data {
                ${projectFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;