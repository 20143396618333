import {getMaturedIdeas} from "@/graphql/ideas/IdeaMatuarity.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
  state: {
    ideatasks: [],
    ideatask: {},
  },
  getters: {
    getMaturedIdeass: (state) => state.ideatasks,
    getMaturedIdeas: (state) => state.ideatask,
  },
  actions: {
    async getMaturedIdeass(context, data) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getMaturedIdeas,
          variables: { ...data },
        })
        .then((output) => {
          let { response, data } = output.data.getMaturedIdeas;

          if (response.status) {
            context.commit("setStoreItem", {
              module: "ideaMatuarity",
              entity: "ideatasks",
              data,
            });
            return data;
          } else {
            notifyError(response.message || "Failed to fetch");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    }
  }
};