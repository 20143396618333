import gql from "graphql-tag";
export const getUserInformation = gql`
  query GetUserInformation($informationUniqueId: String) {
    getUserInformation(informationUniqueId: $informationUniqueId) {
      response {
        id
        status
        code
        message
      }
      data {
        userInformationUniqueId
        userInformationDescription
        userInformationAttachment
        userInformationCreatedDate
        userInformationType
        userInformationCategory
      }
    }
  }
`;
export const createUserInformationMutation = gql`
  mutation CreateUserInformationMutation($input: UserInformationInputObject) {
    createUserInformationMutation(input: $input) {
      data {
        userInformationUniqueId
        userInformationDescription
        userInformationAttachment
        userInformationCreatedDate
        userInformationType
        userInformationCategory
      }
      response {
        id
        status
        code
        message
      }
    }
  }
`;
export const deleteUserInformationMutation = gql`
  mutation DeleteUserInformationMutation($userInformationUniqueId: String) {
    deleteUserInformationMutation(userInformationUniqueId: $userInformationUniqueId) {
      response {
        id
        status
        code
        message
      }
    }
  }
`;
export const createIndividualUserInformation = gql`
  mutation createIndividualUserInformation(
    $input: IndividualUserInformationInputObject
  ) {
    createIndividualUserInformation(input: $input) {
      data {
        userInformationUniqueId
        userInformationDescription
        userInformationAttachment
        userInformationCreatedDate
        userInformationType
        userInformationCategory
      }
      response {
        id
        status
        code
        message
      }
    }
  }
`;
;