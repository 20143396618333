import {
  getIdeaDetails,
  createIdeaDetails,
  updateIdeaDetails,
  deleteIdeaDetails,
  ideaDetailsApproval,
} from "@/graphql/ideas/ideaDetails.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
  state: {
    ideas: [],
    idea: {},
  },
  getters: {
    getIdeaDetails: (state) => state.ideas,
    getIdeaDetail: (state) => state.idea,
  },
  actions: {
    async getIdeaDetails(context, uniqueId) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getIdeaDetails,
          variables: { uniqueId },
        })
        .then((output) => {
          let { response, data } = output.data.getIdeaDetails;

          if (response.status) {
            context.commit("setStoreItem", {
              module: "ideaDetails",
              entity: "ideas",
              data,
            });
            return data;
          } else {
            notifyError(response.message || "Failed to fetch");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async createIdeaDetails(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: createIdeaDetails,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.createIdeaDetails;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "ideaDetails",
              entity: "ideas",
              uniqueKey: "ideaUniqueId",
              data,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async updateIdeaDetails(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: updateIdeaDetails,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.updateIdeaDetails;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "ideaDetails",
              entity: "ideas",
              uniqueKey: "ideaUniqueId",
              data,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async ideaDetailsApproval(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: ideaDetailsApproval,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.ideaDetailsApproval;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "ideaDetails",
              entity: "ideas",
              uniqueKey: "ideaUniqueId",
              data,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async deleteIdeaDetails(context, uniqueId) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: deleteIdeaDetails,
          variables: { uniqueId },
        })
        .then((output) => {
          let { response, data } = output.data.deleteIdeaDetails;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "ideaDetails",
              entity: "ideas",
              uniqueKey: "ideaUniqueId",
              data,
              remove: true,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
  },
};
