export default [
    {
      path: "program-category-list",
      name: "ProgramCategory",
      component: () => import("./ProgramCategoryList.vue"),
      meta: {
        permissions: ["can_add_program_category"]
      }
    },
    {
      path: "program-category-list/:categoryUuid",
      name: "Program",
      component: () => import("./ProgramList.vue"),
      props: true,
      meta: {
        permissions: ["can_add_program_sub_category"]
      }
    },
]