export default [
  {
    path: "/profile",
    name: "Profile",
    component: () => import("@/components/profile_registration/ProfileRegistrationMain.vue"),
    meta: {
      permissions: []
    },
    children: [
      {
        path: "profile-personal-details",
        name: "Personal Details",
        component: () => import("@/components/profile_registration/profile_details/ProfileDetails.vue"),
        meta: {
          permissions: []
        }
      },
      {
        path: "profile-contact-details",
        name: "Contact Details",
        component: () => import("@/components/profile_registration/contact_details/ContactDetails.vue"),
        meta: {
          permissions: []
        }
      },
      {
        path: "profile-health-insurance-details",
        name: "Health Insurance Details",
        component: () => import("@/components/profile_registration/health_insurance_details/HealthInsuranceDetails.vue"),
        meta: {
          permissions: []
        }
      },
      {
        path: "profile-working-experience-details",
        name: "Working Experience Details",
        component: () => import("@/components/profile_registration/working_experience_details/WorkingExperienceDetails.vue"),
        meta: {
          permissions: []
        }
      },
      {
        path: "profile-skill-experience-details",
        name: "Skill Experience Details",
        component: () => import("@/components/profile_registration/skill_experience_details/SkillExperienceDetails.vue"),
        meta: {
          permissions: []
        }
      },
      {
        path: "profile-referee-list",
        name: "Referee",
        component: () => import("@/components/profile_registration/referee_management/RefereeList.vue"),
        meta: {
          permissions: []
        }
      },
      {
        path: "profile-language-proficiency",
        name: "Language Proficiency",
        component: () => import("@/components/profile_registration/language_proficient/LanguageProficiency.vue"),
        meta: {
          permissions: []
        }
      },
      {
        path: "profile-project-portifolio",
        name: "Project Portifolio",
        component: () => import("@/components/profile_registration/project_portifoilo/ProjectPortifolio.vue"),
        meta: {
          permissions: []
        }
      },
      {
        path: "profile-training-list",
        name: "Training",
        component: () => import("@/components/profile_registration/training_mananagement/TrainingList.vue"),
        meta: {
          permissions: []
        }
      },
      {
        path: "profile-publication-list",
        name: "Publications",
        component: () => import("@/components/profile_registration/publication_management/PublicationList.vue"),
        meta: {
          permissions: []
        }
      },
      {
        path: "profile-professional-qualifications",
        name: "Professional Qualifications",
        component: () => import("@/components/profile_registration/professional_qualifications/ProfessionalDetailView.vue"),
        meta: {
          permissions: []
        }
      },
      {
        path: "profile-attachment-list",
        name: "Attachments",
        component: () => import("@/components/profile_registration/profile_with_attachment/ProfileAttachmentList.vue"),
        meta: {
          permissions: []
        }
      },
      {
        path: "profile-academic-details",
        name: "Accademic Details",
        component: () => import("@/components/profile_registration/accademic_details/AccademicDetails.vue"),
        meta: {
          permissions: []
        }
      },
      {
        path: "profile-declaration",
        name: "Declaration",
        component: () => import("@/components/profile_registration/declaration/Declaration.vue"),
        meta: {
          permissions: []
        }
      },
      {
        path: "view-cv",
        name: "View CV",
        component: () => import("@/components/profile_registration/view_cv/ViewCv.vue"),
        meta: {
          permissions: []
        }
      },

    ]
  },
];