<template>
  <v-overlay :value="$store.getters.getLoadingState" opacity="0.7" color="#fffffe" z-index="9999">
    <v-progress-circular
        indeterminate
        color="primary"
        size="72"
      ></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: "VueLoader",
};
</script>
