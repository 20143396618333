import { createProject, deleteProject, getProjectDetails, updateProject} from "@/graphql/projects/projects.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
        projects : [],
        project : {}
    },
    getters: {
        getProjectDetails: (state) => state.projects,
        getProject: (state) => state.project
    },
    actions: {
        async getProjectDetails(context, projectUniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getProjectDetails,
                variables :  projectUniqueId 
            })
            .then((output) => {
                let {response, data} = output.data.getProjectDetails

                if (response.status) {
                    if (_.isNil(projectUniqueId?.projectUniqueId)) {
                        context.commit("setStoreItem", {
                            module : 'projectManagement',
                            entity : 'projects',
                            data
                        })
                        return data
                        
                    } else {
                            context.commit("setStoreItem", {
                                module : 'projectManagement',
                                entity : 'project',
                                data   : data[0]
                            })
                            return data[0]
                        }
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        async createProject(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createProject,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.createProject
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'projectManagement',
                        entity : 'projects',
                        uniqueKey : 'projectUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        
        async updateProject(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateProject,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.updateProject
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'projectManagement',
                        entity : 'projects',
                        uniqueKey : 'projectUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },


        async deleteProject(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteProject,
                variables: {uniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.deleteProject
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'projectManagement',
                        entity : 'projects',
                        uniqueKey : 'projectUniqueId',
                        data,
                        remove : true,
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
    }
}