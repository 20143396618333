import { createProjectCategory, deleteProjectCategory, getProjectCategory, updateProjectCategory} from '@/graphql/settings/projectConfigurations/projectCategory.graphql.js'
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
        projectCategories: [],
        projectCategory: {}
    },
    getters: {
        getProjectCategories: (state) => (state.projectCategories),
        getProjectCategory: (state) => (state.projectCategory)
    },
    actions: {
        async getProjectCategories(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getProjectCategory,
                variables : {uniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.getProjectCategoryDetails

                if (response.status) {
                        context.commit("setStoreItem", {
                            module : 'projectCategoryManagement',
                            entity : 'projectCategories',
                            data
                        })
                        return data
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },


        async createProjectCategory(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createProjectCategory,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.createProjectCategory
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'projectCategoryManagement',
                        entity : 'projectCategories',
                        uniqueKey : 'projectCategoryUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        
        async updateProjectCategory(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateProjectCategory,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.updateProjectCategory
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'projectCategoryManagement',
                        entity : 'projectCategories',
                        uniqueKey : 'projectCategoryUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },


        async deleteProjectCategory(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteProjectCategory,
                variables: {uniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.deleteProjectCategory
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'projectCategoryManagement',
                        entity : 'projectCategories',
                        uniqueKey : 'projectCategoryUniqueId',
                        data,
                        remove : true,
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
    }
}