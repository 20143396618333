import gql from "graphql-tag";


const OutsideTaskFields = `
    outsideWorkTaskDescription
    outsideWorkTaskEndDate
    outsideWorkTaskName
    outsideWorkTaskStartDate
    outsideWorkTaskUniqueId
`;

const responseFields = `
    code
    id
    message
    status
`;

export const createTaskForOutsideWork = gql`
    mutation CreateTaskForOutsideWork($input: OutsideWorkTaskInputObject) {
        createTaskForOutsideWork(input: $input) {
            data {
                ${OutsideTaskFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const updateTaskForOutsideWork = gql`
    mutation UpdateTaskForOutsideWork($input: OutsideWorkTaskInputObject) {
        updateTaskForOutsideWork(input: $input) {
            data {  
                ${OutsideTaskFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const getOutsideWorkTaskDetails = gql`
    query GetOutsideWorkTaskDetails($outsideWorkUniqueId: String) {
        getOutsideWorkTaskDetails(outsideWorkUniqueId: $outsideWorkUniqueId) {
            response {
                ${responseFields}
            }
            data {
                ${OutsideTaskFields}
            }
        }
    }
`;

export const deleteTaskForOutsideWork = gql`
    mutation DeleteTaskForOutsideWork($outsideWorkTaskUniqueId: String!) {
        deleteTaskForOutsideWork(outsideWorkTaskUniqueId: $outsideWorkTaskUniqueId) {
            data {
                ${OutsideTaskFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;