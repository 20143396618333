import Vue from "vue";
import VueInitialsImg from "vue-initials-img";
import '@/assets/js/root'
import _, { split } from 'lodash'
import jQuery from 'jquery'
import Toast from "vue-toastification";
import store from "@/store";
import { notifyError, notifySuccess } from "@/plugins/notification.service.js";
import VueSweetalert2 from "vue-sweetalert2";
import "vue-toastification/dist/index.css";
import 'sweetalert2/dist/sweetalert2.min.css';
// SVGs

import {
  Camera, Mail, Shield, Cog, Minus, Image as Image2,
  Folder, Send, Paperclip, Grid, Globe, FileText,
  Building, BookOpen, Users, Phone,
  MapPin, User, Award, Flag, Contact, Folders, FolderOpen,
  Eye, EyeOff, UserPlus, MoreHorizontal, Building2, ArchiveRestore, Inbox,
  AlignRight,Redo, Trash2 as TrashAlt, Search, GraduationCap, FileEdit,
  CheckSquare, Edit, Power, Bell, ArrowLeftCircle, SlidersHorizontal, FileSignature,
  Plus, Home, AlertCircle, Briefcase, Activity, ChevronDown, Gavel, Scroll, paperclip, UserCog,
  Boxes, Lightbulb, List, Check, Mails, X, ExternalLink, BookOpenCheck, Table, Table2, Edit3, XCircle as Close,
  Server, ClipboardList, Megaphone, Fingerprint, FileSpreadsheet, FileDown, FolderDown, Archive, PersonStanding, Library, FileCheck, CalendarCheck, Upload, Download, Timer
}
  from "lucide-vue";
import i18n from "@/i18n";
import router from "@/router";
window.$ = window.jQuery = jQuery
window._ = _

Vue.use(Toast, {
  transition: "Vue-Toastification__fade",
  maxToasts: 20,
  newestOnTop: true
});
Vue.use(VueInitialsImg);
Vue.use(VueSweetalert2, {
  buttonsStyling: false,
  allowOutsideClick: false,
  reverseButtons: true,
  confirmButtonClass: "mx-2 btn btn-primary",
  cancelButtonClass: "mx-2 btn btn-danger",
  denyButtonClass: "mx-2 btn-primary-soft",
  actions: "flex justify-between",
});
Vue.config.productionTip = false;

export function canAccess(code) {
  let response;
  let permissionCodes = [];
  let codes = [];
  store.getters["getLoggedUser"]["userRole"]?.map(role => (role?.rolePermissions))
    .map(element => {
      permissionCodes.push(element.map((p) => (p?.permissionCode)));
    });
  permissionCodes = permissionCodes.filter((value, index, array) => array.indexOf(value) === index).flat(1) //get unique
  if (code !== undefined) {
    if (typeof code == 'string') { code?.split(',').forEach((c) => codes.push(c)) }
    if (code instanceof Array) codes = code;
    if (code?.length == 0) return true;
    if (!_.isNull(codes)) codes?.map((c) => (response |= permissionCodes.includes(c)));
    else response = 0
  }
  return !!response;
}

Vue.mixin({
  components: {
    Mail, Camera, Globe, Mails, Shield, Cog, Minus, Folder, FileText, Send, Paperclip, Grid, Building, BookOpen, Users, Phone, MapPin, User, Building2,
    Award, Flag, Eye,Redo, EyeOff, UserPlus, MoreHorizontal, AlignRight, TrashAlt, Search, CheckSquare, Edit, Power, Bell, SlidersHorizontal,
    ArrowLeftCircle, Plus, Home, AlertCircle, Briefcase, Activity, ChevronDown, Contact, GraduationCap, Gavel, Scroll, paperclip, Boxes,
    FileSignature, FileEdit, Folders, Lightbulb, List, Check, X, ExternalLink, BookOpenCheck, Table, Table2, Edit3, Close, UserCog, ArchiveRestore, Inbox,
    Server, ClipboardList, Megaphone, Fingerprint, FolderOpen, Image2, FileSpreadsheet, FileDown, FolderDown, Archive, Library, PersonStanding, FileCheck, CalendarCheck, Upload, Download, Timer
  },
  mounted() {
    const readableMaxSize = this.MAX_FILE_SIZE / (1024 * 1000);
    $('.v-text-field__details').hide();
    $('.v-messages').hide();
    $('.v-file-input >> .v-text-field__details').each(function () {
      $(this).show()
      $(this).find('.v-messages').text(`Maximum File Upload Size : ${readableMaxSize} MB`)
      $(this).find('.v-messages').show()
    });
    $(".swal2-popup .swal2-modal .swal2-show").addClass("dark:bg-darkmode-800")
  },
  data() {
    return {
      verifyTokenInterval: null,
      VERIFY_INTERVAL: 360000, //6 minutes * 60 * 1000
      MAX_FILE_SIZE: Math.ceil(1024 * 1000 * 50),
      ubunifuSupportUrl: "https://oxygen.co.tz/#/room/#ubunifusupport:app.oxygen.co.tz",
      ubunifuDiasporaUrl: "https://oxygen.co.tz/#/room/!qhUWKDWuiAmeXcvrTP:app.oxygen.co.tz",
    }
  },
  computed: {
    hasAccess: () => (code) => canAccess(code),
    appName() {
      return process.env.VUE_APP_NAME;
    },
    fullAppName() {
      return process.env.VUE_APP_FULL_NAME;
    },
    isDark() {
      return store.getters.getNightMode
    },
    MEDIA_SERVER() {
      return process.env.VUE_APP_MEDIA_SERVER
    },
    loggedUser() {
      return store.getters.getLoggedUser
    },
    isAuthenticated() {
      return !!this.$store.getters.getTokenState;
    },
    todayDate() {
      let date = new Date();
      let currentDate = `${date.getFullYear()}-${(date.getMonth() + 1)}-${date.getDate()}`;
      return currentDate;
    },
  },
  methods: {
    imageUrl(path) {
      return `${process.env.VUE_APP_GRAPHQL_HTTP}${path}`
    },
    previewImage(img) {
      let fullPage = $('#image-preview');
      let image = $(document.createElement('div'))
      image.addClass('image-item')
      image.css({
        backgroundImage: `url(${img})`,
        display: 'block',
        backgroundColor: 'transparent',
        width: "80vw",
        height: "80vh",
      })
      fullPage.append(image)
      fullPage.css({
        display: 'block',
        width: '100vw',
        height: '100vh'
      })
    },
    shrink(e) {
      // Trigger browser reflow to start animation
      $('#image-preview').addClass('shrink-down')
        .hide("slow")
        .after(() => {
          $('#image-preview').removeClass('shrink-down')
          $('#image-preview > div').detach()
        })
    },
    async sendAttachment(file, service = "OAS_SERVICE", maxSize = this.MAX_FILE_SIZE) {
      return new Promise((resolve, reject) => {
        if (file instanceof Object) {
          if (file?.size > maxSize) {
            notifyError(`Please check file size not over ${maxSize / (1024 * 1000)} MB.`)
            reject(`Please check file size no over ${maxSize / (1024 * 1000)} MB. Provided ${file?.size / (1024 * 1000)}`);
            throw (`Please check file size no over ${maxSize / (1024 * 1000)} MB. Provided ${file?.size / (1024 * 1000)}`);
          }
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = async () => {
            let base64String = reader.result.split(',')[1];
            await store.dispatch("UploadFile", {
              serviceName: service,
              documentName: file?.name,
              base64String
            }).then((data) => {
              resolve(data?.id);
            })
              .catch((error) => {
                notifyError(error)
                reject(error); return;
              })
          }

          reader.onerror = async (error) => {
            notifyError(error)
            reject(error);
            throw (error);
          }
        }
      })
    },
    async viewAttachment(path, fileName = 'ubunifu_attachment') {
      let base64String
      let allowedFileTypes = store.getters.getAllowedFileTypes;
      let allowed = allowedFileTypes.findIndex((item) => path?.toLowerCase()?.includes(item.type?.toLowerCase()))
      let officeTypes = ['xla', 'xls', 'xls', 'xlc', 'docx', 'doc', 'pps', 'ppt', 'ppz', 'pot'].some((format) => path?.toLowerCase()?.includes(format))
      if (allowed != -1) {
        return new Promise(async (resolve, reject) => {
          await store.dispatch("openFile", path)
            .then((response) => {
              if(!response?.base64String){
                base64String = response?.id
                officeTypes = false
              }else{
                base64String = response?.base64String
              }
              const blob = this.base64ToBlob(base64String, allowedFileTypes[allowed]['mime']);
              const encodedFile = URL.createObjectURL(blob);
              if (officeTypes) {
                let ext = allowedFileTypes[allowed]['type'],
                  docName = split(path, "/")[3] || `${fileName}.${ext}`,
                  linkSource = `data:${mime};base64,${base64String}`,
                  downloadLink = document.createElement("a");
                downloadLink.href = linkSource;
                downloadLink.download = docName;
                downloadLink.click();
                resolve(null)
              } else {
                resolve(encodedFile)
              }

            })
            .catch((error) => reject(error))
        })
      } else {
        notifyError(`file type unsupported`)
      }
    },

    base64ToBlob(base64, type = "application/octet-stream") {
      const binStr = atob(base64);
      const len = binStr.length;
      const arr = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
      }
      return new Blob([arr], { type: type });
    },
    copyContent(text) {
      var $temp = jQuery("<input>");
      jQuery("body").append($temp);
      $temp.val(text.toString()).select();
      document.execCommand("copy");
      $temp.remove();
      notifySuccess(i18n.$t("labels.textCopied"))
    },
    capitalizeFirstLetter(string) {
      if (typeof string == 'string') {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
      }
    },
    alphabetLettersOnly(value) {
      if (value) {
        return value.replaceAll(/[^a-zA-Z\s]*$/g, '');
      }
    },
    verifyTokenData() {
      this.verifyTokenInterval = setInterval(() => {
        store.dispatch('verifyToken')
      }, this.VERIFY_INTERVAL)
    },
    numberOnly(evt, max = 15) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (evt.target.value.length > max && charCode !== 8) {
        evt.preventDefault();
      }
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    getMemberFullName(member) {
      if (!_.isEmpty(member)) {
        let { firstName, lastName, middleName, username } = member
        if (!_.isEmpty(firstName?.trim())) {
          return `${firstName || ''} ${middleName || ''} ${lastName || ''}`
        } else {
          return username
        }
      }
    },
    getEnumReadableName(ENUM, getter) {
      let index = store.getters[getter].findIndex((item) => (item.value == ENUM))
      if (index != -1) {
        return store.getters[getter][index]['name'];
      }
      return ENUM;
    },
    phoneKeydown(e) {
      var charCode = (e.which) ? e.which : e.keyCode;
      if (charCode == 8) {//backspace
        return true;
      }
      if ((charCode > 31 && (charCode < 48 || charCode > 57))
        && charCode !== 46) {
        e.preventDefault();
      }
      if (e.target.value.length >= 12) {
        e.preventDefault();
      }
      else {
        e.target.value.replace(/\D/g, '');
        return true
      }
    },
    gotoRoute(route, params = {}) {
      router.push({ name: route, params }).catch(() => { })
    },
    async showFile(data) {
      let path
      if(data instanceof Object){
        await this.$store.dispatch("getApplicationApprovalLetter", data?.requestUniqueId).then((dataResponse)=>{
          path = dataResponse
        })
      }else{
        path = data
      }
      if (path) {
        let encodedFile = await this.viewAttachment(path)
        if (encodedFile?.length) {
          store.commit("setEncodedFilePreview", encodedFile)
          store.commit("setFileDialog", true)
        }
      } else {
        notifyError("Image path not valid!")
      }
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    checkRequiredField(collection, field, name) {
      if (Object.hasOwn(collection, field) && collection[field]?.length) {
        return true;
      } else {
        notifyError(`${name} cannot be null!`)
        throw (`${field} : ${name} is Required`)
      }
    },
    validateMobile(mobilenumber) {
      var regmm = '^([0|+[0-9]{1,5})?([7-9][0-9]{9})$';
      var regmob = new RegExp(regmm);
      if (regmob.test(mobilenumber)) {
        return true;
      } else {
        return false;
      }
    },
    async loadRegions(country) {
      if (country?.countryName?.toLowerCase() == "tanzania") {
        await store.dispatch("getAllRegions")
      }
    },
    async loadInstitution(country) {
      if (country?.countryName?.toLowerCase() == "tanzania") {
        await store.dispatch("getInstitutions")
      }
    },
    async loadDistricts(regionUniqueId) {
      if (regionUniqueId?.length) await store.dispatch("getDistricts", regionUniqueId)
    },

    async loadWards(districtUniqueId) {
      if (districtUniqueId?.length) await store.dispatch("getWards", districtUniqueId)
    }
  },
  filters: {
    formatFull: function (value) {
      if (value) {
        return new Date(value).toLocaleString('en-UK');
      }
    },
    formatDate: function (value) {
      if (value) {
        return new Date(value).toLocaleDateString('en-US');
      }
    },
    toTitleCase: function (value) {
      if (value) {
        const result = value.replace(/([A-Z])/g, " $1");
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        return finalResult;
      }
    },
  }
});
