import gql from "graphql-tag";


const projectPortfolioFields = `
    domainUniqueId
    domainName
    domainDescription
    totalProjectNumber
`;

const responseFields = `
    code
    id
    message
    status
`;

export const createPortfolio = gql`
    mutation CreatePortfolio($input: ProjectDomainInputObjectType) {
        createPortfolio(input: $input) {
            data {
                ${projectPortfolioFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const updatePortfolio = gql`
    mutation UpdatePortfolio($updatePortfolioInput2: ProjectDomainInputObjectType) {
        updatePortfolio(input: $updatePortfolioInput2) {
            data {
                ${projectPortfolioFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const getProjectDomainDetails = gql`
    query GetProjectDomainDetails($domainUniqueId: String) {
        getProjectDomainDetails(domainUniqueId: $domainUniqueId) {
            data {
                ${projectPortfolioFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;


export const deletePortfolio = gql`
    mutation DeletePortfolio($deletePortfolioDomainUniqueId2: String) {
        deletePortfolio(domainUniqueId: $deletePortfolioDomainUniqueId2) {
            data {
                ${projectPortfolioFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;



