import gql from "graphql-tag";


const memberFields = `
    projectMemberUniqueId
    projectMemberDetails {
        firstName
        gender
        id
        lastName
        middleName
        profilePicture
        profileUniqueId
        username
    }
    
    projectMemberRole {
        teamMemberRoleName
        teamMemberRoleUniqueId
    }
    projectMemberProject {
        projectUniqueId
    }
    projectMemberIsTeamLeader
`;

const responseFields = `
    code
    id
    message
    status
`;



export const createProjectMemberDetails = gql`
    mutation CreateProjectMemberDetails($input: ProjectTeamMemberInputObject) {
        createProjectMemberDetails(input: $input) {
            data {
                ${memberFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const updateProjectMemberDetails = gql`
    mutation UpdateProjectMemberDetails($input: ProjectTeamMemberInputObject) {
        updateProjectMemberDetails(input: $input) {
            data {
                ${memberFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const getProjectMemberDetails = gql`
    query getProjectMemberDetails($teamMemberUniqueId: String, $projectUniqueId: String) {
        getProjectMemberDetails(teamMemberUniqueId: $teamMemberUniqueId, projectUniqueId: $projectUniqueId) {
            data {
                ${memberFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;


export const deleteProjectMemberDetails =  gql`
    mutation DeleteProjectMemberDetails($projectTeamMemberUniqueId: String!) {
        deleteProjectMemberDetails(projectTeamMemberUniqueId: $projectTeamMemberUniqueId) {
            data {
                ${memberFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const assignProjectLeader = gql`
  mutation assignProjectLeader($projectMemberUniqueId: String!, $projectUniqueId: String!) {
    assignProjectLeader(projectMemberUniqueId: $projectMemberUniqueId, projectUniqueId: $projectUniqueId) {
      data {
        ${memberFields}
      }
      response {
        id
        status
        code
        message
      }
    }
  }
`;

export const removeProjectLeader = gql`
  mutation removeProjectLeader($projectMemberUniqueId: String!, $projectUniqueId: String!
  ) {
    removeProjectLeader(projectMemberUniqueId: $projectMemberUniqueId, projectUniqueId: $projectUniqueId) {
      data {
        projectMemberUniqueId
        projectMemberDetails {
          id
          profileUniqueId
          username
          firstName
          middleName
          lastName
          profilePicture
          gender
          institution
        }
        projectMemberRole {
          teamMemberRoleUniqueId
          teamMemberRoleName
        }
        projectMemberProject {
          projectUniqueId
          projectName
          projectType
          projectDescription
          projectDomain {
            domainUniqueId
            domainName
            domainDescription
            totalProjectNumber
          }
          projectSource
          projectCategory {
            projectCategoryUniqueId
            projectCategoryName
          }
          projectNature {
            projectNatureUniqueId
            projectNatureName
          }
          projectWeight
          projectObjectives
          projectBenefits
          projectIsActive
          projectCompletionPercent
          projectExpectedEndDate
        }
        projectMemberIsTeamLeader
      }
      response {
        id
        status
        code
        message
      }
    }
  }
`;
