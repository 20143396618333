import gql from "graphql-tag";

const data = `data {
  completedTaskWeight
  overallPercentageCompletion
  taskProgressAttachment
  taskProgressComments
  taskProgressStatus
  taskProgressTask
  taskProgressUniqueId
}`;
const response = `response {
    id
    code
    message
    status
}`;

export const getProjectTaskProgressDetails = gql`query GetProjectTaskProgressDetails($taskProgressTaskUniqueId: String) {
  getProjectTaskProgressDetails(taskProgressTaskUniqueId: $taskProgressTaskUniqueId) {
      ${data}
      ${response}
    }
}`;
export const createProjectTaskProgress = gql`mutation CreateProjectTaskProgress($input: ProjectTaskProgressDetailsInputObject) {
  createProjectTaskProgress(input: $input) {
      ${data}
      ${response}
    }
}`;
export const updateProjectTaskProgress = gql`mutation UpdateProjectTaskProgress($input: ProjectTaskProgressDetailsInputObject) {
  updateProjectTaskProgress(input: $input) {
      ${data}
      ${response}
    }
}`;
export const deleteProjectTaskProgress = gql`mutation DeleteProjectTaskProgres($taskProgressUniqueId: String!) {
  deleteProjectTaskProgres(taskProgressUniqueId: $taskProgressUniqueId) {
      ${data}
      ${response}
    }
}`;