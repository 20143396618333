import gql from "graphql-tag";

export const getAllWards = gql`query getAllWards($uniqueId: String) {
  getAllWards(uniqueId:$uniqueId){
    response{
      code
      status
      message
    }
    data {
      uniqueId
      wardName
      createdDate
      district{
        uniqueId
        districtName
        districtRegion{
          uniqueId
          regionName
        }
      }
    }
  }
}`;

export const updateWard = gql`mutation updateWard($input: WardInputObject) {
  updateWard(input:$input){
    response{
      code
      status
      message
    }
    data {
      uniqueId
      wardName
      createdDate
      district{
        uniqueId
        districtName
        districtRegion{
          uniqueId
          regionName
        }
      }
    }
  }
}`

export const createWard = gql`mutation createWard($input: WardInputObject) {
  createWard(input:$input){
    response{
      code
      status
      message
    }
    data {
      uniqueId
      wardName
      createdDate
      district{
        uniqueId
        districtName
        districtRegion{
          uniqueId
          regionName
        }
      }
    }
  }
}`

export const deleteWard = gql`mutation deleteWard($uniqueId: String!) {
  deleteWard(uniqueId:$uniqueId){
    response{
      code
      status
      message
    }
    data {
      uniqueId
      wardName
      createdDate
      district{
        uniqueId
        districtName
        districtRegion{
          uniqueId
          regionName
        }
      }
    }
  }
}`


export const getWards = gql`
  query getWards($districtUniqueId: String){
    getWards(districtUniqueId:$districtUniqueId){
      data{
        wardUniqueId
        wardName
        wardCode
        parentDistrict{
          districtUniqueId
        }
      }
      response{
        id
        status
        code
        message
      }
    }
  }
`;