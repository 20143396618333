import gql from "graphql-tag";

let response = `response{
  status
  code
  message
}`
let data = `data {
  institutionUniqueId
  institutionName
  institutionType
  institutionCreatedDate
}`

export const getInstitutions = gql`query getInstitutions($uniqueId: String, $instituteType: InstitutionTypeEnum) {
  getInstitutions(uniqueId: $uniqueId, instituteType :$instituteType) {
    ${response}
    ${data}
  }
}`

export const createInstitution = gql`mutation createInstitution($input: InstitutionInputObject){
  createInstitution(input: $input) {
    ${response}
    ${data}
  }
}`

export const updateInstitution = gql`mutation updateInstitution($input: InstitutionInputObject){
  updateInstitution(input: $input) {
    ${response}
    ${data}
  }
}`

export const deleteInstitution = gql`mutation deleteInstitution($uniqueId: String!){
  deleteInstitution(uniqueId: $uniqueId) {
    ${response}
    ${data}
  }
}`