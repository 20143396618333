import { getProjectDomainDetails, createPortfolio, updatePortfolio, deletePortfolio } from "@/graphql/settings/projectConfigurations/projectPortfolio.graphql.js"
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
        projectDomains: [],
        projectDomain: {}
    },
    getters: {
        getProjectDomainDetails: (state) => (state.projectDomains),
        getProjectDomainDetail: (state) => (state.projectDomain)
    },
    actions: {
        async getProjectDomainDetails(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getProjectDomainDetails,
                variables : {uniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.getProjectDomainDetails

                if (response.status) {
                        context.commit("setStoreItem", {
                            module : 'projectPortfolioManagement',
                            entity : 'projectDomains',
                            data
                        })
                        return data
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },


        async createPortfolio(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createPortfolio,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.createPortfolio
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'projectPortfolioManagement',
                        entity : 'projectDomains',
                        uniqueKey : 'domainUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        
        async updatePortfolio(context, updatePortfolioInput2) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updatePortfolio,
                variables: {updatePortfolioInput2}
            })
            .then((output) => {
                let {response, data} = output.data.updatePortfolio
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'projectPortfolioManagement',
                        entity : 'projectDomains',
                        uniqueKey : 'domainUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },


        async deletePortfolio(context, UniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deletePortfolio,
                variables: {UniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.deletePortfolio
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'projectPortfolioManagement',
                        entity : 'projectDomains',
                        uniqueKey : 'domainUniqueId',
                        data:{domainUniqueId:UniqueId},
                        remove : true,
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
    }
}