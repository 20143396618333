import {
  getAllUserProfiles,
  assignUserRole,
  activateOrDeactivateUser,
} from "@/graphql/settings/userManagement.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
  state: {
    profiles: [],
    profile: {},
  },
  getters: {
    getAllUserProfiles: (state) => state.profiles,
    getUserProfileDetails: (state) => state.profile,
  },
  actions: {
    async getAllUserProfiles(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getAllUserProfiles,
          variables: { ...input },
        })
        .then((output) => {
          let { response, data, paginatorResponse } = output.data.getAllUserProfiles;

          if (response.status) {
            context.commit("setStoreItem", {
              module: "root",
              entity: "paginator",
              data: paginatorResponse,
            });
            if (!_.isNil(input?.userProfileUniqueId)) {
              context.commit("setStoreItem", {
                module: "userManagement",
                entity: "profile",
                data: data[0],
              });
              return data[0];
            } else {
              context.commit("setStoreItem", {
                module: "userManagement",
                entity: "profiles",
                data,
              });
              return data;

            }
          } else {
            notifyError(response.message || "Failed to fetch");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async assignUserRole(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: assignUserRole,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.assignUserRole;

          if (response.status) {
            notifySuccess(response.message || i18n.t("labels.operationSuccessful"));
            context.commit("updateStoreItem", {
              module : 'userManagement',
              entity : 'profiles',
              uniqueKey: 'profileUniqueId',
              data
            })
            return data;  
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
    async activateOrDeactivateUserAccount(context, profile) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: activateOrDeactivateUser,
          variables: { 
            input : {
              activateOrDeactivateChoice: profile?.profileIsActive ? 'DEACTIVATE' : 'ACTIVATE',
              profileUniqueId : profile.profileUniqueId
            }
          },
        })
        .then((output) => {
          let { response } = output.data.activateOrDeactivateUser;

          if (response.status) {
            notifySuccess(response.message || i18n.t("labels.operationSuccessful"));
            return new Promise((resolve, reject) => {
              context.dispatch("getAllUserProfiles")
                .then(() => resolve(true))
                .catch(() => reject(false))
            })
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
  },
};
