import gql from  'graphql-tag';

const response = `response {
    status
    code
    message
}`;

const data = `data {
    galleryCollectionUniqueId
    galleryCollections {
      galleryUniqueId
      galleryDescription
      galleryName
      year
    }
    galleryCollectionPath
    galleryCollectionCreatedDate
    galleryCollectionAccess
}`;

export const getGalleryCollection = gql`query getGalleryCollection($galleryUniqueId: String!) {
    getGalleryCollection(galleryUniqueId: $galleryUniqueId) {
      ${response}
      ${data}
    }
}`;

export const updateGalleryCollection = gql`mutation updateGalleryCollection($input: GalleryCollectionInputObject) {
    updateGalleryCollection(input: $input) {
        ${response}
        ${data}
    }
}`;

export const createGalleryCollection = gql`mutation createGalleryCollection($input: GalleryCollectionInputObject) {
    createGalleryCollection(input: $input) {
      ${response}
      ${data}
    }
}`;

export const deleteGalleryCollection = gql`mutation deleteGalleryCollection($uniqueId: String!) {
    deleteGalleryCollection(uniqueId: $uniqueId) {
      ${response}
      ${data}
    }
}`;
