import gql from 'graphql-tag'; 
export const getYearsWithApplicationRegistrations = gql`query GetYearsWithApplicationRegistrations {
    getYearsWithApplicationRegistrations {
      data
      response {
        code
        id
        message
        status
      }
    }
  }
`;