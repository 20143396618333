// var scrollpos = window.scrollY;
// var header = document.getElementById("header");
// var navcontent = document.getElementById("nav-content");
// var navaction = document.getElementById("navAction");
// var brandname = document.getElementById("brandname");
// var toToggle = document.querySelectorAll(".toggleColour");

// document.addEventListener("scroll", function () {
//   /*Apply classes for slide in bar*/
//   scrollpos = window.scrollY;

//   if (scrollpos >= 10) {
// 	header.classList.add("bg-white");
// 	navaction.classList.remove("bg-white");
// 	navaction.classList.add("gradient");
// 	navaction.classList.remove("text-gray-800");
// 	navaction.classList.add("text-white");
// 	//Use to switch toggleColour colours
// 	for (var i = 0; i < toToggle.length; i++) {
// 	  toToggle[i].classList.add("text-gray-800");
// 	  toToggle[i].classList.remove("text-white");
// 	}
// 	header.classList.add("shadow");
// 	navcontent.classList.remove("bg-gray-100");
// 	navcontent.classList.add("bg-white");
//   } else {
// 	header.classList.remove("bg-white");
// 	navaction.classList.remove("gradient");
// 	navaction.classList.add("bg-white");
// 	navaction.classList.remove("text-white");
// 	navaction.classList.add("text-gray-800");
// 	//Use to switch toggleColour colours
// 	for (var i = 0; i < toToggle.length; i++) {
// 	  toToggle[i].classList.add("text-white");
// 	  toToggle[i].classList.remove("text-gray-800");
// 	}

// 	header.classList.remove("shadow");
// 	navcontent.classList.remove("bg-white");
// 	navcontent.classList.add("bg-gray-100");
//   }
// });


/*Toggle dropdown list*/
/*https://gist.github.com/slavapas/593e8e50cf4cc16ac972afcbad4f70c8*/

// var navMenuDiv = document.getElementById("nav-content");
// var navMenuDiv = $("#nav-content");
// var navMenu = $("#nav-toggle");

// document.onclick = check;
// function check(e) {
//   var target = (e && e.target) || (event && event.srcElement);
	
//   //Nav Menu
//   if (!checkParent(target, navMenuDiv)) {
// 	// click NOT on the menu
// 	console.log(navMenu)
// 	if (checkParent(target, navMenu)) {
		
// 	  // click on the link
// 	  if (navMenuDiv.classList.contains("hidden")) {
// 		navMenuDiv.classList.remove("hidden");
// 	  } else {
// 		navMenuDiv.classList.add("hidden");
// 	  }
// 	} else {
// 	  // click both outside link and outside menu, hide menu
// 	  navMenuDiv.addClass("hidden");
// 	}
//   }
// }
// function checkParent(t, elm) {
//   while (t.parentNode) {
// 	if (t == elm) {
// 	  return true;
// 	}
// 	t = t.parentNode;
//   }
//   return false;
// }


$(window).on('load', function(){

	"use strict";
	$(window).on('scroll', function() {
		let scrollTop = $(this).scrollTop().toFixed()
		$("#back-to-top").css({display : scrollTop >= 500 ? "block" : "none"})
		$(".navbar-brand").css({display : scrollTop >= 500 ? "block" : "none"})
		setTimeout(() => {
			if(scrollTop >= 350) {
				$('.navbarF').addClass('opaque');
				$("#banner-ubunifu").css({display : "none"})
			} else {
				$('.navbarF').removeClass('opaque');
				$("#banner-ubunifu").css({display : "block"})
			}
		}, 200)
	});
	
	$('#navbarCollapse').onePageNav({
		filter: ':not(.external)'
	});

}); 


// 	$(".navbar-nav li a, a.scrool").on('click', function(e) {
// 		if (this.href.indexOf('#') != -1) {
// 			var parts = this.href.split("#");
// 			var target_id = parts[1];
// 			var target_top = $(`#${target_id}`).offset()?.top - 70;
// 			console.log(target_top);
// 			$('html,body').animate({scrollTop:target_top}, 200);
// 			return false;
// 		}
// 		return true
		
// 	});	
	
// }); 