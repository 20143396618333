import { createSkillQualification,updateSkillQualification,getSkillQualification,deleteSkillQualification } from "../../graphql/profile_details/skillQualification.js";
import { notifyError,notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";
import i18n from "@/i18n.js";

export default {
    state: {
        skillQualifications: [],
        skillQualification: {},
    },
    getters: {
        getSkillQualification: (state) => (state.skillQualifications),
        getSkillQualifications: (state) => (state.skillQualification),
    },
    actions: {
        async fetchAllSkillQualifications(context,data) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getSkillQualification,
                variables : {...data}
            })
            .then((output) => {
                let {response, data} = output.data.getSkillQualification
                
                if (response.status) {
                    if (_.isNil(data?.uniqueId)) {
                        context.commit("setStoreItem", {
                            module : 'skillQualification',
                            entity : 'skillQualifications',
                            data
                        })
                        return data
                    }else {
                        context.commit("setStoreItem", {
                            module : 'skillQualification',
                            entity : 'skillQualification',
                            data   : data[0]
                        })
                        return data[0]
                    }
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async createSkillQualification(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createSkillQualification,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.createSkillQualification
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'skillQualification',
                        entity : 'skillQualifications',
                        uniqueKey : 'skillsQualificationUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    context.dispatch("getUserProfile")
                    return data
                } else {
                    notifyError(response.message || i18n.t("labels.operationFailed"));
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async updateSkillQualification(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateSkillQualification,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.updateSkillQualification
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'skillQualification',
                        entity : 'skillQualifications',
                        uniqueKey : 'skillsQualificationUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    context.dispatch("getUserProfile")
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async deleteSkillQualification(context, skillsQualificationUniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteSkillQualification,
                variables: {skillsQualificationUniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.deleteSkillQualification
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'skillQualification',
                        entity : 'skillQualifications',
                        uniqueKey : 'skillsQualificationUniqueId',
                        data,
                        remove : true,
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    context.dispatch("getUserProfile")
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
    }
}
