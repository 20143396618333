import {
  createNewAttendanceEnrollment,
  deleteAttendanceEnrollment,
  getEnrolledUsers,
} from "@/graphql/attendance/enrollment.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
  state: {
    enrolledUsers: [],
    enrolledUser: {},
  },
  getters: {
    getEnrolledUsers: (state) => state.enrolledUsers,
    getEnrolledUser: (state) => state.enrolledUser,
  },
  actions: {
    async getEnrolledUsers(context, uniqueId) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getEnrolledUsers,
          variables: { uniqueId },
        })
        .then((output) => {
          let { response, data } = output.data.getEnrolledUsers;

          if (response.status) {
            if (_.isNil(uniqueId)) {
              context.commit("setStoreItem", {
                module: "enrollmentManagement",
                entity: "enrolledUsers",
                data,
              });
              return data;
            } else {
              context.commit("setStoreItem", {
                module: "enrollmentManagement",
                entity: "enrolledUser",
                data: data[0],
              });
              return data[0];
            }
          } else {
            notifyError(response.message || "Failed to fetch");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async createNewAttendanceEnrollment(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: createNewAttendanceEnrollment,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.createNewAttendanceEnrollment;
          if (response.status) {
            context.commit("updateStoreItem", {
              module: "enrollmentManagement",
              entity: "enrolledUsers",
              uniqueKey: "newAttendanceEnrollmentUniqueId",
              data: data[0],
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async deleteAttendanceEnrollment(context, uniqueId) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: deleteAttendanceEnrollment,
          variables: { uniqueId },
        })
        .then((output) => {
          let { response, data } = output.data.deleteAttendanceEnrollment;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "enrollmentManagement",
              entity: "enrolledUsers",
              uniqueKey: "newAttendanceEnrollmentUniqueId",
              data,
              remove: true,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
  },
};




