import {
  
  getApplicationDetails,
  
} from "@/graphql/application/application.graphql";
import { getApplicationApprovalLetter } from "@/graphql/application/applicationRequest.graphql";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
  state: {
    applicationApprovalLetter: null
  },
  getters: {
    getapplicationApprovalLetter:(state) => state.applicationApprovalLetter
  },
  actions: {
    async getApplicationApprovalLetter(context, requestUniqueId) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getApplicationApprovalLetter,
          variables: { requestUniqueId },
        })
        .then((output) => {
          let { response, data } = output.data.getApplicationApprovalLetter;
          if (response.status) {
              context.commit("setStoreItem", {
                module: "applicationLetter",
                entity: "applicationLetter",
                data: data,
              });
              return data;
          } else {
            notifyError(response.message || "Failed to Load Application Approval Letter");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    }
}
}