import {
  deleteTaskAssignment,
  createTaskAssignment,
  updateTaskAssignment,
  getIdeaTaskAssignment,
} from "@/graphql/ideas/TaskAssignment.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
  state: {
    ideatasks: [],
    ideatask: {},
  },
  getters: {
    getIdeaTaskAssignments: (state) => state.ideatasks,
    getIdeaTaskAssignment: (state) => state.ideatask,
  },
  actions: {
    async getIdeaTaskAssignments(context, data) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getIdeaTaskAssignment,
          variables: {
            taskUniqueId: data?.ideaTaskUniqueId,
            uniqueId: data?.taskAssignmentUniqueId,
          },
        })
        .then((output) => {
          let { response, data } = output.data.getIdeaTaskAssignment;

          if (response.status) {
            context.commit("setStoreItem", {
              module: "TaskAssignment",
              entity: "ideatasks",
              data,
            });
            return data;
          } else {
            notifyError(response.message || "Failed to fetch");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async createTaskAssignment(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: createTaskAssignment,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.createTaskAssignment;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "TaskAssignment",
              entity: "ideatasks",
              uniqueKey: "taskAssignmentUniqueId",
              data,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async updateTaskAssignment(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: updateTaskAssignment,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.updateTaskAssignment;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "TaskAssignment",
              entity: "ideatasks",
              uniqueKey: "taskAssignmentUniqueId",
              data,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async deleteTaskAssignment(context, uniqueId) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: deleteTaskAssignment,
          variables: { uniqueId },
        })
        .then((output) => {
          let { response, data } = output.data.deleteTaskAssignment;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "TaskAssignment",
              entity: "ideatasks",
              uniqueKey: "taskAssignmentUniqueId",
              data,
              remove: true,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
  },
};
