import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";
import i18n from "@/i18n";
import { getYearsWithApplicationRegistrations } from "@/graphql/application/years.graphql";

export default {
  state: {
    years:[],
    applicationRequest:{},
  },
  getters: {
    getYears: (state) => state.years,
    
  },
  actions: {
    async getYearsWithApplicationRegistrations(context) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getYearsWithApplicationRegistrations,
          
        })
        .then((output) => {
          let { response, data } = output.data.getYearsWithApplicationRegistrations;

          if (response.status) {
              context.commit("setStoreItem", {
                module: "yearsManagement",
                entity: "years",
                data,
              });
              return data;

          } else {
            notifyError(response.message || "Failed to fetch");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
}
}
