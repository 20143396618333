import { createLanguage, deleteLanguage, getAllLanguages, updateLanguage } from "@/graphql/settings/language.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
  state: {
    languages: [],
    language: {},
  },
  getters: {
    getAllLanguages: (state) => state.languages,
    getLanguage: (state) => state.language,
  },
  actions: {
    async getAllLanguages(context, languageUniqueId) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getAllLanguages,
          variables: { languageUniqueId },
        })
        .then((output) => {
          let { response, data } = output.data.getAllLanguages;
          if (response.status) {
            if (_.isNil(languageUniqueId)) {
              context.commit("setStoreItem", {
                module: "languageManagement",
                entity: "languages",
                data,
              });
              return data;
            } else {
              context.commit("setStoreItem", {
                module: "languageManagement",
                entity: "language",
                data: data[0],
              });
              return data[0];
            }
          } else {
            notifyError(response.message || "Failed to fetch");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
    async createLanguage(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: createLanguage,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.createLanguage;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "languageManagement",
              entity: "languages",
              uniqueKey: "languageUniqueId",
              data,
            });
            notifySuccess(response.message || i18n.t('labels.operationSuccessful'));
            return data;
          } else {
            notifyError(response.message || i18n.t('labels.operationFailed'));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
    async updateLanguage(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: updateLanguage,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.updateLanguage;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "languageManagement",
              entity: "languages",
              uniqueKey: " languageUniqueId",
              data,
            });
            notifySuccess(response.message || i18n.t('labels.operationSuccessful'));
            return data;
          } else {
            notifyError(response.message || i18n.t('labels.operationFailed'));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
    async deleteLanguage(context, uniqueId) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: deleteLanguage,
          variables: {uniqueId},
        })
        .then((output) => {
          let { response, data } = output.data.deleteLanguage;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "languageManagement",
              entity: "languages",
              uniqueKey: "languageUniqueId",
              data,
              remove: true,
            });
            notifySuccess(response.message || i18n.t('labels.operationSuccessful'));
            return data;
          } else {
            notifyError(response.message || i18n.t('labels.operationFailed'));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
  },
};