import gql from "graphql-tag";

const requestFields = `
     aluminumUniqueId
      aluminumUser {
        id
        profileUniqueId
        username
        firstName
        middleName
        lastName
        profilePicture
        gender
        institution
        role
        dateJoined
      }
      aluminumDescription
      aluminumCurrentOccupation
      aluminumCurrentWorkplace
`;

const response = `
    code
    id
    message
    status
`;
export const getAluminum = gql`
    query getAluminum($uniqueId: String){
        getAluminum(uniqueId: $uniqueId) {
            data {
                ${requestFields}
            }
            response {
                ${response}
            }
        }
    }
`;
export const createAluminum = gql`
    mutation createAluminum($input: AluminumInputObject) {
        createAluminum(input: $input) {
            data {
                ${requestFields}
            }
            response {
                ${response}
            }
        }
    }
`;

export const updateAluminum = gql`
    mutation updateAluminum($input: AluminumInputObject) {
        updateAluminum(input: $input) {
            data {
                ${requestFields}
            }
            response {
                ${response}
            }
        }
    }
`;