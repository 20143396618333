import gql from "graphql-tag";

export const getAllDistricts = gql`
  query getAllDistricts($uniqueId: String) {
    getAllDistricts(uniqueId: $uniqueId) {
      response {
        code
        status
        message
      }
      data {
        districtUniqueId
        districtName
        createdDate
        districtRegion {
          regionName
          regionUniqueId
        }
      }
    }
  }
`;

export const createDistrict = gql`mutation createDistrict($input: DistrictInputObject) {
  createDistrict(input:$input){
    response{
      code
      status
      message
    }
    data {
      uniqueId
      districtName
      createdDate
      districtRegion{
        regionName
        uniqueId
      }
    }
  }
}`

export const updateDistrict = gql`mutation updateDistrict($input: DistrictInputObject) {
  updateDistrict(input:$input){
    response{
      code
      status
      message
    }
    data {
      uniqueId
      districtName
      createdDate
      districtRegion{
        regionName
        uniqueId
      }
    }
  }
}`

export const deleteDistrict = gql`mutation deleteDistrict($uniqueId: String!) {
  deleteDistrict(uniqueId:$uniqueId){
    response{
      code
      status
      message
    }
    data {
      uniqueId
      districtName
      createdDate
      districtRegion{
        regionName
        uniqueId
      }
    }
  }
}`


export const getDistricts = gql`
  query getDistricts($regionUniqueId: String){
    getDistricts(regionUniqueId:$regionUniqueId){
      data{
        districtUniqueId
        districtName
        districtCode
        parentRegion{
          regionUniqueId
          regionName
        }
      }
      response{
        id
        status
        message
        code
      }
    }
  }
`;