import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";
import i18n from "@/i18n";
import {
  createUserInformationMutation,
  getUserInformation,
  deleteUserInformationMutation,
  createIndividualUserInformation,
} from "../../graphql/user_information/userInformation.graphql";

export default {
  state: {
    userInformations: [],
    userInformation: {},
  },
  getters: {
    getUserInformations: (state) => state.userInformations,
    getUserInformation: (state) => state.userInformation,
  },
  actions: {
    async getUserInformations(context, informationUniqueId) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getUserInformation,
          variables: informationUniqueId,
        })
        .then((output) => {
          let { response, data } = output.data.getUserInformation;

          if (response.status) {
            context.commit("setStoreItem", {
              module: "userInformationManagement",
              entity: "userInformations",
              data,
            });
            return data;
          } else {
            notifyError(response.message || "Failed to fetch");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
    async createUserInformationMutation(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: createUserInformationMutation,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.createUserInformationMutation;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "userInformationManagement",
              entity: "userInformations",
              uniqueKey: "userInformationUniqueId",
              data,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async deleteUserInformationMutation(context, userInformationUniqueId) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: deleteUserInformationMutation,
          variables: { userInformationUniqueId },
        })
        .then((output) => {
          let { response } = output.data.deleteUserInformationMutation;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "userInformationManagement",
              entity: "userInformations",
              uniqueKey: "userInformationUniqueId",
              data: { userInformationUniqueId },
              remove: true,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return response.status;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

async createIndividualUserInformation(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: createIndividualUserInformation,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.createIndividualUserInformation;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "userInformationManagement",
              entity: "userInformations",
              uniqueKey: "userInformationUniqueId",
              data,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
  }
}