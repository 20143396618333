import gql from "graphql-tag";
export const getAcademicInstitutionWithProgram = gql`
  query getAcademicInstitutionWithProgram($uniqueId: String) {
    getAcademicInstitutionWithProgram(uniqueId: $uniqueId) {
      response {
        id
        status
        code
        message
      }
      data {
        institutionWithProgramUniqueId
        institutionWithProgramProgram {
          programUniqueId
          programName
          programCategory {
            categoryUniqueId
            categoryName
            createdDate
          }
          createdDate
        }
      }
    }
  }
`;
export const createAcademicInstitutionWithProgram = gql`
mutation createAcademicInstitutionWithProgram($input: AcademicInstitutionWithProgramInputObject) {
  createAcademicInstitutionWithProgram(input: $input) {
    data {
      institutionWithProgramUniqueId
      institutionWithProgramProgram {
        programUniqueId
        programName
        programCategory {
          categoryUniqueId
          categoryName
          createdDate
        }
        createdDate
      }
    }
    response {
      id
      status
      code
      message
    }
  }
}
`;
export const updateAcademicInstitutionWithProgram = gql`
mutation updateAcademicInstitutionWithProgram($input: AcademicInstitutionWithProgramInputObject) {
  updateAcademicInstitutionWithProgram(input: $input) {
    data {
      institutionWithProgramUniqueId
      institutionWithProgramProgram {
        programUniqueId
        programName
        programCategory {
          categoryUniqueId
          categoryName
          createdDate
        }
        createdDate
      }
    }
    response {
      id
      status
      code
      message
    }
  }
}`
;
export const deleteAcademicInstitutionWithProgram = gql`
mutation deleteAcademicInstitutionWithProgram($uniqueId: String!) {
  deleteAcademicInstitutionWithProgram(uniqueId: $uniqueId) {
    data {
      institutionWithProgramUniqueId
      institutionWithProgramProgram {
        programUniqueId
        programName
        programCategory {
          categoryUniqueId
          categoryName
          createdDate
        }
        createdDate
      }
    }
    response {
      id
      status
      code
      message
    }
  }
}`
;
