  import {
    createAwarenessSession,
    deleteAwarenessSession,
    updateAwarenessSession,
    getAwarenessSessions,
  } from "@/graphql/communication/announcenent/awareness.graphql";
  import i18n from "@/i18n";
  import { notifyError, notifySuccess } from "@/plugins/notification.service";
  import { apolloClient } from "@/vue-apollo";

  export default {
    state: {
      awareness: [],
      awarenes: {},
    },
    getters: {
      getAwarenessSessions: (state) => state.awareness,
      getAwarenessSession: (state) => state.awarenes,
    },
    actions: {
      async getAwarenessSessions(context, input) {
        context.commit("setLoadingState", true);
        return await apolloClient
          .query({
            fetchPolicy: "network-only",
            query: getAwarenessSessions,
            variables: {input},
          })
          .then((output) => {
            let { response, data } = output.data.getAwarenessSessions;
            if (response.status) {
              context.commit("setStoreItem", {
                module: "awarenessSession",
                entity: "awareness",
                data,
              });
              return data;
            } else {
              notifyError(response.message || "Failed to fetch");
            }
          })
          .finally(() => context.commit("setLoadingState", false));
      },
      async createAwarenessSession(context, input) {
        context.commit("setLoadingState", true);
        return await apolloClient
          .mutate({
            fetchPolicy: "no-cache",
            mutation: createAwarenessSession,
            variables: { input },
          })
          .then((output) => {
            let { response, data } = output.data.createAwarenessSession;
            if (response.status) {
              context.commit("updateStoreItem", {
                module: "awarenessSession",
                entity: "awareness",
                uniqueKey: "awarenessSessionUniqueId",
                data,
              });
              notifySuccess(
                response.message || i18n.t("labels.operationSuccessful")
              );
              return data;
            } else {
              notifyError(response.message || i18n.t("labels.operationFailed"));
            }
          })
          .finally(() => context.commit("setLoadingState", false));
      },

      async updateAwarenessSession(context, input) {
        context.commit("setLoadingState", true);
        return await apolloClient
          .mutate({
            fetchPolicy: "no-cache",
            mutation: updateAwarenessSession,
            variables: { input },
          })
          .then((output) => {
            let { response, data } = output.data.updateAwarenessSession;

            if (response.status) {
              context.commit("updateStoreItem", {
                module: "awarenessSession",
                entity: "awareness",
                uniqueKey: "awarenessSessionUniqueId",
                data,
              });
              notifySuccess(
                response.message || i18n.t("labels.operationSuccessful")
              );
              return data;
            } else {
              notifyError(response.message || i18n.t("labels.operationFailed"));
            }
          })
          .finally(() => context.commit("setLoadingState", false));
      },

      async deleteAwarenessSession(context, uniqueId) {
        context.commit("setLoadingState", true);
        return await apolloClient
          .mutate({
            fetchPolicy: "no-cache",
            mutation: deleteAwarenessSession,
            variables: { uniqueId },
          })
          .then((output) => {
            let { response, data } = output.data.deleteAwarenessSession;

            if (response.status) {
              context.commit("updateStoreItem", {
                module: "awarenessSession",
                entity: "awareness",
                uniqueKey: "awarenessSessionUniqueId",
                data,
                remove: true,
              });
              notifySuccess(
                response.message || i18n.t("labels.operationSuccessful")
              );
              return data;
            } else {
              notifyError(response.message || i18n.t("labels.operationFailed"));
            }
          })
          .finally(() => context.commit("setLoadingState", false));
      },
    },
  };

