export default [
  {
    path: "setting-application-details",
    name: "Setting Application Details",
    component: () => import("./ApplicationDetails.vue"),
    meta: {
      permissions: ["can_view_application_details","can_add_application_details"]
    }
  },
];
