import Vue from 'vue'
import i18n from "@/i18n";
import store from '@/store';

const toastOptions = {
    position: "top-right",
    timeout: 4000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: false
}
export const notifySuccess = async (text) => await Vue.$toast.success(text,toastOptions);
export const notifyWarning = async (text) => await Vue.$toast.warning(text,toastOptions);
export const notifyError   = async (text) => await Vue.$toast.error(text,toastOptions);
export const notifyInfo    = async (text) => await Vue.$toast.info(text,toastOptions);

export const notifySuccessLarge = async (message, title = i18n.t('labels.success'),timer = 5000) => {
    await Vue.swal({
        icon: 'success',
        title,
        text: message,
        toast: false,
        animation: false,
        timer,
        timerProgressBar: true,
    })
}
export const notifyErrorLarge = async (message, title = i18n.t('labels.alert'), timer = 5000) => {
    await Vue.swal({
        icon: 'error',
        title,
        text: message,
        toast: false,
        animation: false,
        timer,
        timerProgressBar: true,
    })
}
export const notifyInfoLarge = async (message, title = i18n.t('labels.info'), timer = 5000) => {
    await Vue.swal({
        icon: 'info',
        title,
        text: message,
        toast: false,
        animation: false,
        timer,
        timerProgressBar: true,
    })
}
export const notifyVerifyEmail = async (message, email) => {
    const agree = await Vue.swal({
        icon: 'error',
        title: 'Verify Account',
        text: message,
        toast: false,
        animation: false,
        timer: 0,
        timerProgressBar: true,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Resend Verification Email",
    })
    if (agree.value) {
        await store.dispatch("resendActivationEmail", email)
    }
}
export const confirmDialog = async function(
        message,
        title = `${i18n.t('labels.alert')}!`,
        confirmButtonText = i18n.t("labels.yes"), 
        cancelButtonText = i18n.t("labels.no")
    ){
    const agree = await Vue.swal({
        title,
        text: `${message}?`,
        dangerMode: true,
        showConfirmButton: true,
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        motion: top,
        icon: "info",
    })
    return agree?.value
}