import { createProjectSubTaskProgress, deleteProjectSubTaskProgress, getProjectSubTaskProgressDetails, updateProjectSubtaskProgress } from "@/graphql/projects/progress/subtaskProgress.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";

import { apolloClient } from "@/vue-apollo";

export default {
    state:{
        subTaskProgresses:[],
        subTaskProgress:{}
    },
    getters:{
        getSubTaskProgresses: (state) =>(state.subTaskProgresses),
        getSubTaskProgress:(state)=>(state.subTaskProgress)
    },
    actions:{
        async getProjectSubTaskProgressDetails(context, subTaskUniqueId) {
            context.commit("setLoadingState", true);
            return await apolloClient
              .query({
                fetchPolicy: "network-only",
                query: getProjectSubTaskProgressDetails,
                variables: { subTaskUniqueId },
              })
              .then((output) => {
                let { response, data } = output.data.getProjectSubTaskProgressDetails;
      
                if (response.status) {
                  if (_.isNil(subTaskUniqueId)) {
                    context.commit("setStoreItem", {
                      module: 'subTaskProgressManagement',
                      entity: 'subTaskProgresses',
                      data,
                    });
                    return data;
                  } else {
                    context.commit("setStoreItem", {
                      module: 'subTaskProgressManagement',
                      entity: 'subTaskProgress',
                      data: data[0],
                    });
                    return data[0];
                  }
                } else {
                  notifyError(response.message || "Failed to fetch");
                }
              })
              .finally(() => context.commit("setLoadingState", false));
          },


        async createProjectSubTaskProgress(context, input){
            context.commit("setLoadingState",true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createProjectSubTaskProgress,
                variables: {input}
            })
            .then((output)=>{
                let{response, data} = output.data.createProjectSubTaskProgress
                if (response.status){
                    context.commit("updateStoreItem",{
                        module:'subTaskProgressManagement',
                        entity:'subTaskProgresses',
                        uniqueKey:'subTaskProgressUniqueId',
                        data
                    });
                    notifySuccess(response.massage || i18n.t('labels.operationSuccessful'));
                    return data
                } else {
                    notifyError(response.massage);
                }
            }).finally(()=>context.commit("setLoadingState", false))
        },
        async updateProjectSubtaskProgress(context, input){
            context.commit("setLoadingState",true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateProjectSubtaskProgress,
                variables: {input}
            })
            .then((output)=>{
                let{response, data} = output.data.updateProjectSubtaskProgress
                if (response.status){
                    context.commit("updateStoreItem",{
                        module:'subTaskProgressManagement',
                        entity:'subTaskProgresses',
                        uniqueKey:'subTaskProgressUniqueId',
                        data
                    });
                    notifySuccess(response.massage || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.massage || i18n.t('labels.operationSuccessful'))
                }
            }).finally(()=>context.commit("setLoadingState", false))
        },

        async deleteProjectSubTaskProgress(context, subTaskProgressUniqueId){
            context.commit("setLoadingState",true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteProjectSubTaskProgress,
                variables: {subTaskProgressUniqueId}
            })
            .then((output)=>{
                let{response, data} = output.data.deleteProjectSubTaskProgress
                if (response.status){
                    context.commit("updateStoreItem",{
                        module:'subTaskProgressManagement',
                        entity:'subTaskProgress',
                        uniqueKey:'subTaskProgressUniqueId',
                        data
                    });
                    notifySuccess(response.massage || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.massage || i18n.t('labels.operationSuccessful'))
                }
            }).finally(()=>context.commit("setLoadingState", false))
        },
    }

}