import { createProjectComponent, deleteProjectComponent, getProjectComponentDetails, updateProjectComponent} from "@/graphql/projects/projectComponents.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
        projectComponents : [],
        projectComponent : {}
    },
    getters: {
        getProjectComponentDetails: (state) => (state.projectComponents),
        getProjectComponentDetail: (state) => (state.projectComponent)
    },
    actions: {
        async getProjectComponentDetails(context, componentUniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getProjectComponentDetails,
                variables :  componentUniqueId 
            })
            .then((output) => {
                let {response, data} = output.data.getProjectComponentDetails

                if (response.status) {
                        if (componentUniqueId?.length) {
                            context.commit("setStoreItem", {
                                module : 'projectComponentManagement',
                                entity : 'projectComponent',
                                data   : data[0]
                            })
                            return data[0]
                        } else {
                            context.commit("setStoreItem", {
                                module : 'projectComponentManagement',
                                entity : 'projectComponents',
                                data
                            })
                            return data
                        }
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        async createProjectComponent(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createProjectComponent,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.createProjectComponent
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'projectComponentManagement',
                        entity : 'projectComponents',
                        uniqueKey : 'componentUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        
        async updateProjectComponent(context, updateProjectComponentInput2) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateProjectComponent,
                variables: {updateProjectComponentInput2}
            })
            .then((output) => {
                let {response, data} = output.data.updateProjectComponent
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'projectComponentManagement',
                        entity : 'projectComponents',
                        uniqueKey : 'componentUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },


        async deleteProjectComponent(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteProjectComponent,
                variables: {uniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.deleteProjectComponent
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'projectComponentManagement',
                        entity : 'projectComponents',
                        uniqueKey : 'componentUniqueId',
                        data,
                        remove : true,
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
    }
}