import gql from "graphql-tag";

const advertisementFields = `
    advertisementApproval
    advertisementContactEmail
    advertisementContactPhone
    advertisementDescription
    advertisementEndDate
    advertisementHeadline
    advertisementImage
    advertisementRemark
    advertisementStartDate
    advertisementUniqueId
`;

const responseFields = `
    code
    id
    message
    status
`;


export const createAdvertisement = gql`
    mutation CreateAdvertisement($input: AdvertisementDetailsInputObject) {
        createAdvertisement(input: $input) {
            data {
                ${advertisementFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const updateAdvertisement = gql`
    mutation UpdateAdvertisement($input: AdvertisementDetailsInputObject) {
        updateAdvertisement(input: $input) {
            data {
                ${advertisementFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const deleteAdvertisement = gql`
    mutation DeleteAdvertisement($uniqueId: String!) {
        deleteAdvertisement(uniqueId: $uniqueId) {
            data {
                ${advertisementFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const getAllAdvertisement = gql`
    query GetAllAdvertisement($advertisementUniqueId: String, $input: FilterInputObject) {
        getAllAdvertisement(advertisementUniqueId: $advertisementUniqueId, input: $input) {
            data {
                ${advertisementFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;