import {
  createProjectMemberDetails,
  deleteProjectMemberDetails,
  getProjectMemberDetails,
  updateProjectMemberDetails,
  assignProjectLeader,
  removeProjectLeader,
} from "@/graphql/projects/projectMemberDetails.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
  state: {
    members: [],
    member: {},
  },
  getters: {
    getProjectMembers: (state) => state.members,
    getProjectMember: (state) => state.member,
  },
  actions: {
    async getProjectMembers(context, data) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getProjectMemberDetails,
          variables: { ...data },
        })
        .then((output) => {
          let { response, data } = output.data.getProjectMemberDetails;

          if (response.status) {
            context.commit("setStoreItem", {
              module: "projectMemberManagement",
              entity: "members",
              data,
            });
            return data;
          } else {
            notifyError(response.message || "Failed to fetch");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async createProjectMember(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: createProjectMemberDetails,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.createProjectMemberDetails;

          if (response.status) {
            data?.forEach((element) => {
              context.commit("updateStoreItem", {
                module: "projectMemberManagement",
                entity: "members",
                uniqueKey: "projectMemberUniqueId",
                data: element,
              });
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async assignProjectLeader(
      context,
      { projectMemberUniqueId, projectUniqueId }
    ) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: assignProjectLeader,
          variables: { projectMemberUniqueId, projectUniqueId },
        })
        .then((output) => {
          let { response, data } = output.data.assignProjectLeader;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "projectMemberManagement",
              entity: "members",
              uniqueKey: "projectMemberUniqueId",
              data,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async removeProjectLeader(
      context,
      { projectMemberUniqueId, projectUniqueId }
    ) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: removeProjectLeader,
          variables: { projectMemberUniqueId, projectUniqueId },
        })
        .then((output) => {
          let { response, data } = output.data.removeProjectLeader;
          console.log;
          if (response.status) {
            context.commit("updateStoreItem", {
              module: "projectMemberManagement",
              entity: "members",
              uniqueKey: "projectMemberUniqueId",
              data,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async updateProjectMember(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: updateProjectMemberDetails,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.updateProjectMemberDetails;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "projectMemberManagement",
              entity: "members",
              uniqueKey: "projectMemberUniqueId",
              data,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async deleteProjectMember(context, projectTeamMemberUniqueId) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: deleteProjectMemberDetails,
          variables: { projectTeamMemberUniqueId },
        })
        .then((output) => {
          let { response, data } = output.data.deleteProjectMemberDetails;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "projectMemberManagement",
              entity: "members",
              uniqueKey: "projectMemberUniqueId",
              data,
              remove: true,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
  },
};