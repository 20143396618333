import gql from 'graphql-tag';

const response = `response {
    id
    status
    code
    message
}`;

const data = `data {
    galleryUniqueId
    galleryDescription
    galleryName
    year
}`;

export const getGalleryDetails = gql`query getGalleryDetails($year: String, $galleryUniqueId: String) {
  getGalleryDetails(year: $year, galleryUniqueId: $galleryUniqueId) {
      ${response} 
      ${data}
    }
}`;
export const updateGallery = gql`mutation updateGallery($input: GalleryInputObject) {
    updateGallery(input: $input) {
      ${response} 
      ${data}
    }
}`;
export const createGallery = gql`mutation createGallery($input: GalleryInputObject) {
    createGallery(input: $input) {
      ${response} 
      ${data}
    }
}`;
export const deleteGallery = gql`mutation deleteGallery($uniqueId: String!) {
    deleteGallery(uniqueId: $uniqueId) {
      ${response} 
      ${data}
    }
}`;