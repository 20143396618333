<template>
  <v-app>
    <div class="alert bg-white text-primary show p-5 mt-5 !fixed right-0 top-48 !z-50 intro-x border border-primary" v-if="applicationInfo">
      <div class="flex items-center">
        <div class="text-lg font-medium">To apply for a position</div>
        <button class="ml-auto btn btn-sm btn-danger-soft" @click="toggleApplicationInfo()">Close</button>
      </div>
      <ol style="list-style: auto !important;">
        <template>
          <li v-if="!isAuthenticated"><router-link :to="{name: 'Register'}"><strong>Register,</strong></router-link> verify the account through the link sent to your Email.</li>
          <li><router-link :to="{name: 'Login'}" v-if="!isAuthenticated"><strong>Login,</strong></router-link> Complete your profile.</li>
          <li><router-link :to="{name: 'Application Types'}"><strong>Apply</strong></router-link> for the position in the Application Post List</li>
        </template>
      </ol>
      <span>NB: For any <em class="font-semibold">technical issues</em>, please <a target="_blank" :href="ubunifuSupportUrl"><strong>Contact Us</strong></a></span>
    </div>
    <div class="fixed right-0 top-28 !z-50 w-30 inline-flex bg-cyan-700 text-white p-2 text-base text-left rounded-tl-3xl rounded-bl-3xl cursor-pointer" 
      @click="toggleApplicationInfo()" v-if="!applicationInfo">
      <Info class="w-6 h-6 mr-2 stroke-current lucide"/>
    </div>
    <div id="app" class="dark:bg-darkmode-600 bg-primary">
      <FilePreviewDialog />
      <VueLoader />  
      <DarkLightToggler />
      <router-view/>
    </div>
  </v-app>
</template>`

<script>
  import VueLoader from "@/views/shared/VueLoader.vue";
  import DarkLightToggler from "@/views/shared/DarkLightToggler.vue";
  import FilePreviewDialog from "./views/shared/FilePreviewDialog.vue";
  import { Info } from "lucide-vue";
  export default {
    components: {
      DarkLightToggler,
      VueLoader,
      FilePreviewDialog,
      Info
    },
    data() {
      return {
        applicationInfo: true,
      }
    },
    methods: {
      toggleApplicationInfo() {
        this.applicationInfo = !this.applicationInfo
      }
    },
    async mounted() {
      document.title = process.env.VUE_APP_FULL_NAME
      this.$store.commit("setFileDialog", false)
      if (this.$store.getters.getNightMode) {
        $("html").removeClass("light").addClass("dark");
      } else {
        $("html").removeClass("dark").addClass("light");
      }
      if (![null, undefined].includes(this.$store.getters.getTokenState)) {
        this.$nextTick (() => {
          this.verifyTokenData()
        })
      }
    },
    beforeDestroy () {
      clearInterval(this.verifyTokenInterval)
    },
  };
</script>
<style>
* {
  scroll-behavior: smooth;
  /* @apply dark:!text-white */
}
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #006F8B;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d1d1d1;
}
/* handle horizontal scroll */
*::-webkit-scrollbar:horizontal {
  width: 10px;
  height: 10px;
  scrollbar-width: 10px;
}
*::-webkit-scrollbar-thumb:horizontal {
  background: #d1d1d1;
  border-radius: 7px;
  height: 10px;
  width: 10px;
}
svg {
  stroke: currentColor;
}
.swal2-container {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}
.dark .swal2-show {
  background: rgb(var(--color-darkmode-800) / var(--tw-bg-opacity)) !important;
  color: white !important;
}
.v-text-field :focus,  input{
  @apply ring-0 border-transparent bg-transparent border-0 focus:border-transparent focus:ring-0
}
.v-input :defined, .v-text-field :defined{
  @apply bg-transparent border-0 border-transparent ring-0 ring-transparent
}
.v-input--selection-controls {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
#image-preview,.image-item {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 9999;
  display: none;
  background-size: contain;
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-color: rgba(0,0,0,0.6);
  backdrop-filter: saturate(180%) blur(10px);
  animation: go-full-screen forwards 500ms ease-in;
}
.shrink-down {
  animation: go-full-screen reverse 500ms ease-out !important;
}
@keyframes go-full-screen {
  from {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 10vw;
    height: 10vh;
  }
  to {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 100vw;
    height: 100vh;
  }
}
</style>