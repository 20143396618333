import gql from "graphql-tag";

export const getApplicationCategoryReport = gql`query GetApplicationCategoryReport($year: String) {
  getApplicationCategoryReport(year: $year) {
    response {
      id
      status
      code
      message
    }
    data
  }
}`;