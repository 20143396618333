import gql from "graphql-tag";

export const getWeeklyReports = gql`
  query getWeeklyReports($projectUniqueId: String!) {
    getWeeklyReports(projectUniqueId: $projectUniqueId) {
      response {
        id
        status
        code
        message
      }
      data {
        nextWeekPlanTask {
          nextWeekPlanTask
        }
        thisWeekTask {
          thisWeekTask
        }
      }
    }
  }
`;

export const createWeeklyReport = gql`
  mutation createWeeklyReport($input: WeeklyReportInputObject!) {
    createWeeklyReport(input: $input) {
      data {
        nextWeekPlanTask {
          nextWeekPlanTask
        }
        thisWeekTask {
          thisWeekTask
        }
      }
      response {
        id
        status
        code
        message
      }
    }
  }
`;