import gql from "graphql-tag";


export const getWeeklyReport = gql`
query GetWeeklyReport {
  getWeeklyReport {
    data {
      reportDocument
      id
      user {
        id
        profileUniqueId
        username
        firstName
        middleName
        lastName
        profilePicture
        gender
        institution
        role
        dateJoined
      }
    }
    response {
      id
      status
      code
      message
    }
  }
}
`;

export const createOrUpdateReportPtMutation = gql`
mutation CreateOrUpdateReportPtMutation($reportDocument: String!, $createOrUpdateReportPtMutationId: String) {
  createOrUpdateReportPtMutation(reportDocument: $reportDocument, id: $createOrUpdateReportPtMutationId) {
    data {
      reportDocument
      id
      user {
        id
        profileUniqueId
        username
        firstName
        middleName
        lastName
        profilePicture
        gender
        institution
        role
        dateJoined
      }
    }
    response {
      id
      status
      code
      message
    }
  }
}
`;






export const deleteReportPtMutation = gql`
mutation DeleteReportPtMutation($id: String!) {
  deleteReportPtMutation(id: $id) {
    data {
      id
      reportDocument
      user {
        id
        profileUniqueId
        username
        firstName
        middleName
        lastName
        profilePicture
        gender
        institution
        role
        dateJoined
      }
    }
    response {
      id
      status
      code
      message
    }
  }
}
`;
