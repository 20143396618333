<template>
    <CardLayout :cardItems="$t('iterables.settingsSubNavs')" />
</template>
<script>
    import CardLayout from '@/views/layouts/CardLayout.vue'
    export default {
        name: "SettingIndex",
        components: { CardLayout },

    }
</script>