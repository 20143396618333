import { createDevice, deleteDevice, getDeviceDetails, UpdateDevice } from "@/graphql/attendance/deviceEnrollment.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
  state: {
    devices: [],
    device: {},
  },
  getters: {
    getDeviceDetails: (state) => state.devices,
    getDeviceDetail: (state) => state.device,
  },
  actions: {
    async getDeviceDetails(context, deviceUniqueId) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getDeviceDetails,
          variables: { deviceUniqueId },
        })
        .then((output) => {
          let { response, data } = output.data.getDeviceDetails;

          if (response.status) {
            if (_.isNil(deviceUniqueId)) {
              context.commit("setStoreItem", {
                module: "deviceEnrollmentManagement",
                entity: "devices",
                data,
              });
              return data;
            } else {
              context.commit("setStoreItem", {
                module: "deviceEnrollmentManagement",
                entity: "device",
                data: data[0],
              });
              return data[0];
            }
          } else {
            notifyError(response.message || "Failed to fetch");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async createDevice(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: createDevice,
          variables: {input},
        })
        .then((output) => {
          let { response, data } = output.data.createDevice;
          console.log(this.response);
          if (response.status) {
            context.commit("updateStoreItem", {
              module: "deviceEnrollmentManagement",
              entity: "devices",
              uniqueKey: "deviceUniqueId",
              data
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async UpdateDevice(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: UpdateDevice,
          variables: {input},
        })
        .then((output) => {
          let { response, data } = output.data.UpdateDevice;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "deviceEnrollmentManagement",
              entity: "devices",
              uniqueKey: "deviceUniqueId",
              data,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async deleteDevice(context, deleteDeviceUniqueId2) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: deleteDevice,
          variables: { deleteDeviceUniqueId2 },
        })
        .then((output) => {
          let { response, data } = output.data.deleteDevice;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "deviceEnrollmentManagement",
              entity: "devices",
              uniqueKey: "deviceUniqueId",
              data,
              remove: true,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
  },
};




