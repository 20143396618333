import gql from "graphql-tag";

// const languageProficiencyFields = `
//   langProfUniqueId
//   langProfLanguageLevel

export const getLanguageProficiencies = gql`query getLanguageProficiencies($profileUniqueId: String) {
    getLanguageProficiencies(profileUniqueId: $profileUniqueId) {
      response {
        id
        status
        code
        message
      }
    data {
      langProfUniqueId
      langProfLanguageLevel
      
      langProfLanguage {
        languageUniqueId
        languageName
        createdDate
      }
    }
  }
}
`;
  
  export const createLanguageProficiency = gql`mutation createLanguageProficiency($input: LanguageProficiencyInputObject){
    createLanguageProficiency(input: $input){
      data{
        langProfUniqueId
        langProfLanguageLevel
        
        langProfLanguage{
          languageUniqueId
          languageName
          createdDate
        }
      }
      
      response{
        id
        status
        code
        message
      }
      
    }
  }`;
  
  export const updateLanguageProficiency = gql`mutation updateLanguageProficiency($input: LanguageProficiencyInputObject){
    updateLanguageProficiency(input: $input){
      data{
        langProfUniqueId
        langProfLanguageLevel
       
        langProfLanguage{
          languageUniqueId
          languageName
          createdDate
        }
      }
      
      response{
        id
        status
        code
        message
      }
    }
  }`;
  
  export const deleteLanguageProficiency =gql`mutation deleteLanguageProficiency($uniqueId: String!){
    deleteLanguageProficiency(uniqueId: $uniqueId){
      data{
        langProfUniqueId
        langProfLanguageLevel
        langProfLanguage{
          languageUniqueId
          languageName
          createdDate
        }
      }
      
      response{
        id
        status
        code
        message
      }
      
    }
  }`;
  