import { apolloClient } from "@/vue-apollo";
import { getApplicationDashboard } from "../../graphql/application/applicationDashboard.graphql";

export default {
  state: {
    applicationDashboard: [],
  },
  getters: {
    getApplicationDashboard: (state) => state.applicationDashboard,

  },
  actions: {
    async getApplicationDashboard(context, year) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getApplicationDashboard,
          variables: { year },
        })
        .then((output) => {
          let data  = output.data.getApplicationDashboard;
              context.commit("setStoreItem", {
                module: "applicationDashboardManagement",
                entity: "applicationDashboard",
                data,
              });
              return data;
        })
        .finally(() => context.commit("setLoadingState", false));
    }
}
}