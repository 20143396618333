import gql from "graphql-tag";


const projectComponentFields = `
    componentUniqueId
    componentName
    componentDescription
    componentCompletionPercent
    componentProject {
    projectUniqueId
    projectName
        projectType
        projectDescription
        projectDomain {
          domainUniqueId
          domainName
          domainDescription
        }
        projectSource
        projectCategory {
          projectCategoryUniqueId
          projectCategoryName
        }
        projectNature {
          projectNatureUniqueId
          projectNatureName
        }
        projectWeight
        projectObjectives
        projectBenefits
        projectIsActive
        projectCompletionPercent
    }

`;

const responseFields = `
    code
    id
    message
    status
`;

export const createProjectComponent = gql`
mutation CreateProjectComponent($input: ProjectComponentInputObjectType) {
    createProjectComponent(input: $input) {
            data {
                ${projectComponentFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const updateProjectComponent = gql`
mutation UpdateProjectComponent($updateProjectComponentInput2: ProjectComponentInputObjectType) {
    updateProjectComponent(input: $updateProjectComponentInput2) {
            data {
                ${projectComponentFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const getProjectComponentDetails = gql`
query GetProjectComponentDetails($componentUniqueId: String, $projectUniqueId: String) {
    getProjectComponentDetails(componentUniqueId: $componentUniqueId, projectUniqueId: $projectUniqueId) {
            data {
                ${projectComponentFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;



export const deleteProjectComponent = gql`
mutation DeleteProjectComponent($deleteProjectComponentComponentUniqueId2: String) {
    deleteProjectComponent(componentUniqueId: $deleteProjectComponentComponentUniqueId2) {
            data {
                ${projectComponentFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;