import gql from "graphql-tag";

export const getProjectWeeklyReport = gql`
  query getProjectWeeklyReport {
    getProjectWeeklyReport {
      response {
        id
        status
        code
        message
      }
      data
    }
  }
`;
