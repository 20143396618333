import gql from "graphql-tag";


const assignMemberField = `
taskAssignmentTask {
    projectTaskDescription
    projectTaskHasSubTask
    projectTaskName
    projectTaskProject {
      projectBenefits
      projectCategory {
        projectCategoryName
        projectCategoryUniqueId
      }
      projectDescription
      projectIsActive
      projectName
      projectNature {
        projectNatureName
        projectNatureUniqueId
      }
      projectObjectives
      
      projectSource
      projectType
      projectUniqueId
      projectWeight
    }
    projectTaskProjectUniqueId
    projectTaskUniqueId
    projectTaskWeight
  }
  taskAssignmentUniqueId
  taskProjectMember {
    firstName
    gender
    id
    lastName
    middleName
    profilePicture
    profileUniqueId
    username
  }
`;

const responseFields = `
    code
    id
    message
    status
`;

export const createProjectTaskAssignment = gql`
mutation  CreateProjectTaskAssignment($input: ProjectTaskAssignmentInputObjectType) {
    createProjectTaskAssignment(input: $input) {
      data {
        ${assignMemberField}
      }
      response {
        ${responseFields}
      }
    }
  }
`;

export const updateProjectSubTask = gql`
    mutation UpdateProjectSubTask($input: ProjectSubTaskInputObject) {
        updateProjectSubTask(input: $input) {
            data {
                ${assignMemberField}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const getTaskAssignmentDetails= gql`
query GetTaskAssignmentDetails($filter: TaskAssignmentFilter) {
    getTaskAssignmentDetails(filter: $filter) {
            data {
                ${assignMemberField}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const deleteProjectTaskAssignment = gql`
mutation DeleteProjectTaskAssignment($taskAssignmentUniqueId: String) {
  deleteProjectTaskAssignment(taskAssignmentUniqueId: $taskAssignmentUniqueId) {
            data {
                ${assignMemberField}
            }
            response {
                ${responseFields}
            }
        }
    }
`;