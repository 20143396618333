export default [
    {
      name: "Setting Application Post",
      path: "setting-application-post",
      props:true,
      component: () => import("./ApplicationPost.vue"),
      meta: {
        permissions: ["can_view_application_post","can_add_application_post"]
      }
    },
  ];