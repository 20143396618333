<template>
    <v-dialog v-model="dialog" width="544" scrollable persistent attach="div#app" >
      <div class="intro-y bg-slate-200 dark:bg-darkmode-700 dark:text-white">
        <div class="flex flex-col sm:flex-row items-center p-2 border-b border-slate-200/60 dark:border-darkmode-400">
          <h2 class="font-medium text-base mr-auto inline-flex">
            <Plus class="lucide w-5 h-6"/> {{ type }} Country
          </h2>
        </div>
        <form class="p-5" @submit.prevent="createUpdate()">
            <div>
              <v-text-field v-model="country.countryName" outlined dense
                :placeholder="$t('location.labels.countryName')"
                :label="$t('location.labels.countryName')" required
                :background-color="isDark ? 'rgb(var(--color-darkmode-800))' : 'rgb(241 245 249 / var(--tw-bg-opacity))'"
              />
            </div>
            <v-row class="mt-3">
              <button class="btn btn-warning mt-5 white--text" @click.stop="$emit('close')" type="reset">Close</button>
              <v-spacer></v-spacer>
              <button class="btn btn-primary mt-5" type="submit">
                {{$t(`location.buttons.createUpdate.title`)}}
              </button>
            </v-row>
        </form>
      </div>
    </v-dialog>
  </template>
  <script>
import { notifyError } from '@/plugins/notification.service';
  export default {
    props: ["Dialog", "editItemData", "DialogTitle"],
    data: () => ({
      dialog: false,
      country: {},
      type: "add",
    }),
    watch: {
      Dialog(val) {
        this.dialog = val;
      },
      DialogTitle(val) {
        this.type = val || "add";
      },
      editItemData(values) {
        this.country.countryName = Object.hasOwn(values, "countryName") ? values.countryName : ""
        this.country.uniqueId = Object.hasOwn(values, "uniqueId") ? values.uniqueId : ""
      },
    },
    methods :{
        async createUpdate() {
            Object.entries(this.country).forEach(([key, value]) => {
                if (_.isNil(value) || _.isEmpty(value)) {
                if (!['uniqueId'].includes(key)) {
                    notifyError(`${key.replace(/country/gi, '')} required!`)
                    throw `${key} Required`
                }
                delete this.country[key];
                }
            });
            if (Object.hasOwn(this.country, 'uniqueId') && this.country?.uniqueId?.length) {
                await this.$store.dispatch("updateCountry", this.country)
                    .then(() => this.$emit('close'))
            } else {
                await this.$store.dispatch("createCountry", this.country)
                    .then(() => this.$emit('close'))
            }
        }
    }
    
  };
  </script>
  