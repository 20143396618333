import gql from "graphql-tag";

export const tokenAuth = gql`mutation tokenAuth($username: String,$password: String!,$email: String){
  tokenAuth(password: $password,email:$email, username:$username){
    token
    refreshToken
    success
    errors
    user {
      id
      pk
      lastLogin
      username
      firstName
      lastName
      email
      lastLogin
    }
  }
}`

export const verifyToken = gql`mutation verifyToken($token: String!){
  verifyToken(token: $token) {
    success
    payload
    errors
  }
}`

export const revokeToken = gql`mutation revokeToken($refreshToken: String!){
  revokeToken(refreshToken: $refreshToken) {
    success
    revoked
    errors
  }
}`

export const refreshToken = gql`mutation refreshToken($refreshToken: String!) {
  refreshToken(refreshToken: $refreshToken) {
    refreshToken
    errors
    payload
    success
    token
  }
}`

export const createNewUser = gql`mutation createNewUser($email: String!,$username: String!,$password1: String!,$password2: String!) {
  createNewUser(email: $email, username: $username, password1:$password1, password2:$password2) {
    success
    errors
    
  }
}`

export const passwordChange = gql`mutation passwordChange($oldPassword: String!,$newPassword1: String!,$newPassword2: String!){
  passwordChange(oldPassword:$oldPassword, newPassword1: $newPassword1, newPassword2 :$newPassword2){
    success
    errors
    refreshToken
    token
  }
}`

export const passwordReset = gql`mutation passwordReset($token: String!,$newPassword1: String!,$newPassword2: String!){
  passwordReset(token: $token, newPassword1: $newPassword1, newPassword2: $newPassword2){
    success
    errors
  }
}`

export const sendPasswordResetEmail = gql`mutation sendPasswordResetEmail($email: String!) {
  sendPasswordResetEmail(email: $email) {
    success
    errors
  }
}`

export const resendActivationEmail = gql`mutation resendActivationEmail($email: String!) {
  resendActivationEmail(email: $email) {
    success
    errors
  }
}`

export const verifyAccount = gql`mutation verifyAccount($token: String!) {
  verifyAccount(token: $token) {
    success
    errors
  }
}`

export const createCustomUser = gql`mutation createCustomUser($input: CreateCustomUserInputObject) {
  createCustomUser(input: $input) {
    response {
      code
      id
      message
      status
    }
  }
}`

export const customUserActivation = gql`
  mutation CustomUserAccoutActivation($input: CustomUserAccountActivationInput) {
    customUserAccoutActivation(input: $input) {
      data
      response {
        code
        id
        message
        status
      }
    }
  }
`

export const customUserRegistration = gql`
  mutation CustomUserRegistration($input: UserRegistrationInputObject) {
    customUserRegistration(input: $input) {
      data {
        email
        id
      }
      response {
        code
        id
        message
        status
      }
    }
  }
`