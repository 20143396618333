import gql from "graphql-tag";

export const getAllProgramCategories = gql`query getAllProgramCategories($uniqueId: String){
	getAllProgramCategories(uniqueId: $uniqueId) {
    response{
      code
      status
      message
    }
    data{
      categoryUniqueId
      categoryName
      createdDate
    }
  }
}`

export const createProgramCategory = gql`mutation createProgramCategory($input: ProgramCategoryInputObject){
  createProgramCategory(input: $input) {
    response{
      code
      status
      message
    }
    data{
      categoryUniqueId
      categoryName
      createdDate
    }
  }
}`

export const updateProgramCategory = gql`mutation updateProgramCategory($input: ProgramCategoryInputObject){
  updateProgramCategory(input: $input) {
    response{
      code
      status
      message
    }
    data{
      categoryUniqueId
      categoryName
      createdDate
    }
  }
}`

export const deleteProgramCategory = gql`mutation deleteProgramCategory($uniqueId: String!){
  deleteProgramCategory(uniqueId: $uniqueId) {
    response{
      code
      status
      message
    }
    data{
      categoryUniqueId
      categoryName
      createdDate
    }
  }
}`