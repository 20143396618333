import { createReferee, getReferees, updateReferee, deleteReferee } from "@/graphql/profile_details/referee.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
  state: {
    referees: [],
    referee: {},
  },
  getters: {
    getReferees: (state) => state.referees,
    getReferee: (state) => state.referee,
  },
  actions: {
    async getReferees(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getReferees,
          variables: input,
        })
        .then((output) => {
          let { response, data } = output.data.getReferees;

          if (response.status) {
            if (_.isNil(input?.uniqueId)) {
              context.commit("setStoreItem", {
                module: "refereeManagement",
                entity: "referees",
                data,
              });
              return data;
            } else {
              context.commit("setStoreItem", {
                module: "refereeManagement",
                entity: "referee",
                data: data[0],
              });
              return data[0];
            }
          } else {
            notifyError(response.message || "Failed to fetch");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
    async createReferee(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: createReferee,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.createReferee;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "refereeManagement",
              entity: "referees",
              uniqueKey: "refereeUniqueId",
              data,
            });
            notifySuccess(response.message || i18n.t('labels.operationSuccessful'));
            context.dispatch("getUserProfile");
            return data;
          } else {
            notifyError("Please fill mandatory * fields" ||i18n.t("labels.operationFailed")
            );
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
    async updateReferee(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: updateReferee,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.updateReferee;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "refereeManagement",
              entity: "referees",
              uniqueKey: "refereeUniqueId",
              data,
            });
            notifySuccess(response.message || i18n.t('labels.operationSuccessful'));
            context.dispatch("getUserProfile");
            return data;
          } else {
            notifyError(response.message || i18n.t('labels.operationFailed'));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
    async deleteReferee(context, uniqueId) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: deleteReferee,
          variables: { uniqueId },
        })
        .then((output) => {
          let { response, data } = output.data.deleteReferee;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "refereeManagement",
              entity: "referees",
              uniqueKey: "refereeUniqueId",
              data,
              remove: true,
            });
            notifySuccess(response.message || i18n.t('labels.operationSuccessful'));
            context.dispatch("getUserProfile");
            return data;
          } else {
            notifyError(response.message || i18n.t('labels.operationFailed'));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
  },
};