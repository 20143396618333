import {createIdeaConceptualization, deleteIdeaConceptualization, getIdeaConceptualization, updateIdeaConceptualization, conceptualizationApproval } from "@/graphql/ideas/IdeaConceptualization.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
  state: {
    ideatasks: [],
    ideatask: {},
  },
  getters: {
    getIdeaConceptualizations: (state) => state.ideatasks,
    getIdeaConceptualization: (state) => state.ideatask,
  },
  actions: {
    async getIdeaConceptualizations(context, data) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getIdeaConceptualization,
          variables: { ...data },
        })
        .then((output) => {
          let { response, data } = output.data.getIdeaConceptualization;

          if (response.status) {
            context.commit("setStoreItem", {
              module: "ideaConceptualization",
              entity: "ideatasks",
              data,
            });
            return data;
          } else {
            notifyError(response.message || "Failed to fetch");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async createIdeaConceptualization(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: createIdeaConceptualization,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.createIdeaConceptualization;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "ideaConceptualization",
              entity: "ideatasks",
              uniqueKey: "ideaConceptualizationUniqueId",
              data,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async updateIdeaConceptualization(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: updateIdeaConceptualization,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.updateIdeaConceptualization;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "ideaConceptualization",
              entity: "ideatasks",
              uniqueKey: "ideaConceptualizationUniqueId",
              data,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async deleteIdeaConceptualization(context, uniqueId) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: deleteIdeaConceptualization,
          variables: { uniqueId },
        })
        .then((output) => {
          let { response, data } = output.data.deleteIdeaConceptualization;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "ideaConceptualization",
              entity: "ideatasks",
              uniqueKey: "ideaConceptualizationUniqueId",
              data,
              remove: true,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async conceptualizationApproval(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: conceptualizationApproval,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.conceptualizationApproval;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "ideaConceptualization",
              entity: "ideatasks",
              uniqueKey: "ideaConceptualizationUniqueId",
              data,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
  },
};
