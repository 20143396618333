import gql from "graphql-tag";


const workingExperienceFields = `
    workExperienceUniqueId
    workExperienceInstitutionName
    workExperienceInstitutionAddress
    workExperienceDuties
    workExperienceIsCurrentWork
    workExperienceJobTitle
    workExperienceStartDate
    workExperienceEndDate
    workExperienceSupervisorAddress
    workExperienceCreatedDate
`;

const responseFields = `
    id
    status
    code
    message
`;

export const getMyWorkExperienceDetail = gql`
    query GetMyExperiences($profileUniqueId: String) {
        getMyExperiences(profileUniqueId: $profileUniqueId) {
            data {
                ${workingExperienceFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;


export const createWorkExperienceDetail = gql`
    mutation createWorkExperienceDetail($input: WorkExperienceInputObject){
        createWorkExperienceDetail(input:$input){
            data{
                ${workingExperienceFields}
            }
            response{
                ${responseFields}
            }
        }
    }
`;


export const getAllWorkExperiencesByUserProfileOrUniqueId = gql`
    query getAllWorkExperiencesByUserProfileOrUniqueId($userProfileUid: String,$uniqueId: String){
        getAllWorkExperiencesByUserProfileOrUniqueId(uniqueId:$uniqueId,userProfileUid:$userProfileUid){
            data{
                ${workingExperienceFields}
            }
            response{
                ${responseFields}
            }
        }
    }
`;

export const updateWorkExperienceDetail = gql`
    mutation updateWorkExperienceDetail($input: WorkExperienceInputObject){
        updateWorkExperienceDetail(input:$input){
            data{
                ${workingExperienceFields}
            }
            response{
                ${responseFields}
            }
        }
    }
`;

export const deleteWorkExperienceDetail = gql`
    mutation deleteWorkExperienceDetail($uniqueId: String!){
        deleteWorkExperienceDetail(uniqueId:$uniqueId){
            data{
                ${workingExperienceFields}
            }
            response{
                ${responseFields}
            }
        }
    }
`;