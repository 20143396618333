import {
    updateDiasporaFieldOfExpert,
    getDiasporaFieldOfExpert,
    deleteDiasporaFieldOfExpert,
    createDiasporaFieldOfExpert
} from "@/graphql/settings/diasporaExpertSettings.graphql"
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
        AllDiasporaFieldOfExpert: [],
        diasporaFieldOfExpert: {}
    },

    getters: {
        getDiasporaFieldOfExpert: (state) => (state.AllDiasporaFieldOfExpert),
        getOneDiasporaFieldOfExpert: (state) => (state.diasporaFieldOfExpert)
    },

    actions: {
        async getDiasporaFieldOfExpert(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getDiasporaFieldOfExpert,
                variables : {uniqueId}
            })
                .then((output) => {
                    let {response, data} = output.data?.getDiasporaFieldOfExpert

                    if (response.status) {
                        if (_.isNil(uniqueId)) {
                            context.commit("setStoreItem", {
                                module : 'diasporaExpertSettingsManagement',
                                entity : 'AllDiasporaFieldOfExpert',
                                data
                            })
                            return data
                        }else {
                            context.commit("setStoreItem", {
                                module : 'diasporaExpertSettingsManagement',
                                entity : 'diasporaFieldOfExpert',
                                data   : data[0]
                            })
                            return data[0]
                        }
                    } else {
                        notifyError(response.message || "Failed to fetch")
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },

        async createDiasporaFieldOfExpert(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createDiasporaFieldOfExpert,
                variables: {input}
            })
                .then((output) => {
                    let {response, data} = output.data.createDiasporaFieldOfExpert
                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: "diasporaExpertSettingsManagement",
                            entity: "AllDiasporaFieldOfExpert",
                            uniqueKey: "diasporaFieldOfExpertUniqueId",
                            data,
                        });
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },
        async updateDiasporaFieldOfExpert(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateDiasporaFieldOfExpert,
                variables: {input}
            })
                .then((output) => {
                    let {response, data} = output.data.updateDiasporaFieldOfExpert

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: "diasporaExpertSettingsManagement",
                            entity: "AllDiasporaFieldOfExpert",
                            uniqueKey: "diasporaFieldOfExpertUniqueId",
                            data,
                        });
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },

        async deleteDiasporaFieldOfExpert(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteDiasporaFieldOfExpert,
                variables: {uniqueId}
            })
                .then((output) => {
                    let {response, data} = output.data.deleteDiasporaFieldOfExpert

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: "diasporaExpertSettingsManagement",
                            entity: "AllDiasporaFieldOfExpert",
                            uniqueKey: "diasporaFieldOfExpertUniqueId",
                            data,
                            remove: true,
                        });
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },
    }
}
