
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";
import i18n from "@/i18n";
import {
  applicationApproval,
  createApplicationRequest,
  deleteApplicationRequest,
  getApplicationRequest,
  updateApplicationRequest,
  updatePtHasArrived,
  updateInterviewStatusMutation,
} from "@/graphql/application/applicationRequest.graphql";

export default {
  state: {
    applicationRequests: [],
    applicationRequest: {},
    applicationDashboard: {},
  },
  getters: {
    getApplicationRequests: (state) => state.applicationRequests,
    getApplicationRequest: (state) => state.applicationRequest,
    getApplicationCategoryDashboard: (state) => state.applicationDashboard,
  },
  actions: {
    async getApplicationRequests(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getApplicationRequest,
          variables: { ...input },
        })
        .then((output) => {
          let { response, data, paginatorResponse, dashboard } =
            output.data.getApplicationRequest;
          if (response.status) {
            context.commit("setStoreItem", {
              module: "root",
              entity: "paginator",
              data: paginatorResponse,
            });
            context.commit("setStoreItem", {
              module: "applicationRequestManagement",
              entity: "applicationDashboard",
              data: dashboard,
            });
            if (_.isNil(input.uniqueId)) {
              context.commit("setStoreItem", {
                module: "applicationRequestManagement",
                entity: "applicationRequests",
                data,
              });
              return data;
            } else {
              context.commit("setStoreItem", {
                module: "applicationRequestManagement",
                entity: "applicationRequest",
                data: data[0],
              });
              return data[0];
            }
          } else {
            notifyError(response.message || "Failed to fetch");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
    async createApplicationRequest(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: createApplicationRequest,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.createApplicationRequest;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "applicationRequestManagement",
              entity: "applicationRequests",
              uniqueKey: "requestUniqueId",
              data,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return response.status;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async updateApplicationRequest(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: updateApplicationRequest,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.updateApplicationRequest;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "applicationRequestManagement",
              entity: "applicationRequests",
              uniqueKey: "requestUniqueId",
              data,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async deleteApplicationRequest(context, uniqueId) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: deleteApplicationRequest,
          variables: { uniqueId },
        })
        .then((output) => {
          let { response, data } = output.data.deleteApplicationRequest;

          if (response.status) {
            data.isActive = false;
            context.commit("updateStoreItem", {
              module: "applicationRequestManagement",
              entity: "applicationRequests",
              uniqueKey: "requestUniqueId",
              data,
              remove: true,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async applicationApproval(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: applicationApproval,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.applicationApproval;

          if (response.status) {
            context.commit("setStoreItem", {
              module: "applicationRequestManagement",
              entity: "applicationRequest",
              data,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async updatePtHasArrived(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: updatePtHasArrived,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.updatePtHasArrived;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "applicationRequestManagement",
              entity: "applicationRequests",
              uniqueKey: "requestUniqueId",
              data,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return response.status;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async updateInterviewStatusMutation(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: updateInterviewStatusMutation,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.updateInterviewStatusMutation;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "applicationRequestManagement",
              entity: "applicationRequests",
              uniqueKey: "requestUniqueId",
              data,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return response.status;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
  },
};
