import { createProfessionalQualification, deleteProfessionalQualification, getMyProfessionalQualifications, updateProfessionalQualification,  } from "@/graphql/profile_details/professionalQualifications.graphql.js"
import i18n from "@/i18n";

import { notifyError,notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
        professionalQualifications: [],
        professionalQualification: {}
    },
    getters: {
        getProfessionalQualifications: (state) => (state.professionalQualifications),
        getProfessionalQualification: (state) => (state.getProfessionalQualification)
    },
    actions: {
        async getMyProfessionalQualifications(context, data) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getMyProfessionalQualifications,
                variables : {...data}
            })
            .then((output) => {
                let {response, data} = output.data.getMyProfessionalQualifications;
                
                if (response.status) {
                    if (_.isNil(data?.uniqueId)) {
                        context.commit("setStoreItem", {
                            module : 'professionalQualificationsManagement',
                            entity : 'professionalQualifications',
                            data
                        })
                        return data
                    }else {
                        context.commit("setStoreItem", {
                            module : 'professionalQualificationsManagement',
                            entity : 'professionalQualification',
                            data   : data[0]
                        })
                        return data[0]
                    }
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        async createProfessionalQualification(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createProfessionalQualification,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.createProfessionalQualification
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'professionalQualificationsManagement',
                        entity : 'professionalQualifications',
                        uniqueKey : 'qualificationUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    context.dispatch("getUserProfile")
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        async updateProfessionalQualification(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateProfessionalQualification,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.updateProfessionalQualification
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'professionalQualificationsManagement',
                        entity : 'professionalQualifications',
                        uniqueKey : 'qualificationUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    context.dispatch("getUserProfile")
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        async deleteProfessionalQualification(context, qualificationUniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteProfessionalQualification,
                variables: {qualificationUniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.deleteProfessionalQualification
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'professionalQualificationsManagement',
                        entity : 'professionalQualifications',
                        uniqueKey : 'qualificationUniqueId',
                        data,
                        remove : true,
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    context.dispatch("getUserProfile")
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
    }
}