import gql from "graphql-tag"

const skillSubCategoryFields = `
    skillSubCategoryUniqueId
    skillSubCategoryName
    createdDate
`

const responseFields = `
    id
    status
    code
    message
`

export const createSkillSubCategory = gql`mutation createSkillSubCategory($input: SkillSubCategoryInputObject){
        createSkillSubCategory(input: $input){
            data{
                ${skillSubCategoryFields}
            }
            response{
                ${responseFields}
            }
        }
    }
`

export const updateSkillSubCategory = gql`mutation updateSkillSubCategory($input: SkillSubCategoryInputObject){
        updateSkillSubCategory(input: $input){
            data{
                ${skillSubCategoryFields}
            }
            response{
                ${responseFields}
            }
        }
    }
`

export const getSkillSubCategoryByCategoryUniqueId = gql`query getSkillSubCategoryByCategoryUniqueId($skillCategoryUniqueId: String){
        getSkillSubCategoryByCategoryUniqueId(skillCategoryUniqueId: $skillCategoryUniqueId){
            data{
                ${skillSubCategoryFields}
            }
            response{
                ${responseFields}
            }
        }
    }
`

export const deleteSkillSubCategory = gql`mutation DeleteSkillSubCategory($uniqueId: String!) {
    deleteSkillSubCategory(uniqueId: $uniqueId) {
      data {
        ${skillSubCategoryFields}
      }
      response {
        ${responseFields}
      }
    }
  }
`