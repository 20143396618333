import {
  deleteAcademicInstitutionWithProgram,
  createAcademicInstitutionWithProgram,
  updateAcademicInstitutionWithProgram,
  getAcademicInstitutionWithProgram,
} from "@/graphql/settings/institutionWithProgram.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
  state: {
    academicinstitutions: [],
    academicinstitution: {},
  },
  getters: {
    getAcademicInstitutionWithPrograms: (state) => state.academicinstitutions,
    getAcademicInstitutionWithProgram: (state) => state.academicinstitution,
  },
  actions: {
    async getAcademicInstitutionWithPrograms(context, data) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getAcademicInstitutionWithProgram,
          variables: { ...data },
        })
        .then((output) => {
          let { response, data } =
            output.data.getAcademicInstitutionWithProgram;

          if (response.status) {
            context.commit("setStoreItem", {
              module: "institutionWithProgram",
              entity: "academicinstitutions",
              data,
            });
            return data;
          } else {
            notifyError(response.message || "Failed to fetch");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },


    async createAcademicInstitutionWithProgram(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: createAcademicInstitutionWithProgram,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.createAcademicInstitutionWithProgram;
          if (response.status) {
            context.commit("updateStoreItem", {
              module: "institutionWithProgram",
              entity: "academicinstitutions",
              uniqueKey: "institutionWithProgramUniqueId",
              data
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
    async updateAcademicInstitutionWithProgram(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: updateAcademicInstitutionWithProgram,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.updateAcademicInstitutionWithProgram;
          if (response.status) {
            context.commit("updateStoreItem", {
              module: "institutionWithProgram",
              entity: "academicinstitutions",
              uniqueKey: "institutionWithProgramUniqueId",
              data,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async deleteAcademicInstitutionWithProgram(context, uniqueId) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: deleteAcademicInstitutionWithProgram,
          variables: { uniqueId },
        })
        .then((output) => {
          let { response, data } =
            output.data.deleteAcademicInstitutionWithProgram;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "institutionWithProgram",
              entity: "academicinstitutions",
              uniqueKey: "uniqueId",
              data,
              remove: true,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
  },
};
