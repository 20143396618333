import gql from "graphql-tag";
export const createIdeaTaskProgress = gql`
  mutation createIdeaTaskProgress($input: IdeaTaskProgressInputObject) {
    createIdeaTaskProgress(input: $input) {
      data {
        completedTaskWeight
        ideaTaskProgressAttachment
        ideaTaskProgressComments
        ideaTaskProgressCreatedBy {
          firstName
          gender
          id
          lastName
          middleName
          profilePicture
          profileUniqueId
          username
        }
        ideaTaskProgressStatus
        ideaTaskProgressTask {
          hasSubTask
          ideaTaskDescription
          ideaTaskName
          ideaTaskUniqueId
          ideaTaskWeight
          ideaTasksIdea {
            ideaApprovalStatus
            ideaApprovedBy {
              firstName
              gender
              id
              lastName
              middleName
              profilePicture
              profileUniqueId
              username
            }
            ideaBenefits
            ideaName
            ideaNature
            ideaObjectives
            ideaPurpose
            ideaRemark
            ideaStage
            ideaType
            ideaUniqueId
            ideaWeight
          }
        }
        ideaTaskProgressUniqueId
      }
      response {
        code
        id
        message
        status
      }
    }
  }
`;

export const updateIdeaTaskProgress = gql`
  mutation updateIdeaTaskProgress($input: IdeaTaskProgressInputObject) {
    updateIdeaTaskProgress(input: $input) {
      data {
        completedTaskWeight
        ideaTaskProgressAttachment
        ideaTaskProgressComments
        ideaTaskProgressCreatedBy {
          firstName
          gender
          id
          lastName
          middleName
          profilePicture
          profileUniqueId
          username
        }
        ideaTaskProgressStatus
        ideaTaskProgressTask {
          hasSubTask
          ideaTaskDescription
          ideaTaskName
          ideaTaskUniqueId
          ideaTaskWeight
          ideaTasksIdea {
            ideaApprovalStatus
            ideaApprovedBy {
              firstName
              gender
              id
              lastName
              middleName
              profilePicture
              profileUniqueId
              username
            }
            ideaBenefits
            ideaName
            ideaNature
            ideaObjectives
            ideaPurpose
            ideaRemark
            ideaStage
            ideaType
            ideaUniqueId
            ideaWeight
          }
        }
        ideaTaskProgressUniqueId
      }
      response {
        code
        id
        message
        status
      }
    }
  }
`;
export const deleteIdeaTaskProgress = gql`
  mutation deleteIdeaTaskProgress($uniqueId: String!) {
    deleteIdeaTaskProgress(uniqueId: $uniqueId) {
      data {
        completedTaskWeight
        ideaTaskProgressAttachment
        ideaTaskProgressComments
        ideaTaskProgressCreatedBy {
          firstName
          gender
          id
          lastName
          middleName
          profilePicture
          profileUniqueId
          username
        }
        ideaTaskProgressStatus
        ideaTaskProgressTask {
          hasSubTask
          ideaTaskDescription
          ideaTaskName
          ideaTaskUniqueId
          ideaTaskWeight
          ideaTasksIdea {
            ideaApprovalStatus
            ideaApprovedBy {
              firstName
              gender
              id
              lastName
              middleName
              profilePicture
              profileUniqueId
              username
            }
            ideaBenefits
            ideaName
            ideaNature
            ideaObjectives
            ideaPurpose
            ideaRemark
            ideaStage
            ideaType
            ideaUniqueId
            ideaWeight
          }
        }
        ideaTaskProgressUniqueId
      }
      response {
        code
        id
        message
        status
      }
    }
  }
`;

export const getIdeaTaskProgress = gql`
  query getIdeaTaskProgress($taskUniqueId: String) {
    getIdeaTaskProgress(taskUniqueId: $taskUniqueId) {
      data {
        completedTaskWeight
        ideaTaskProgressAttachment
        ideaTaskProgressComments
        ideaTaskProgressCreatedBy {
          firstName
          gender
          id
          lastName
          middleName
          profilePicture
          profileUniqueId
          username
        }
        ideaTaskProgressStatus
        ideaTaskProgressTask {
          hasSubTask
          ideaTaskDescription
          ideaTaskName
          ideaTaskUniqueId
          ideaTaskWeight
          ideaTasksIdea {
            ideaApprovalStatus
            ideaApprovedBy {
              firstName
              gender
              id
              lastName
              middleName
              profilePicture
              profileUniqueId
              username
            }
            ideaBenefits
            ideaName
            ideaNature
            ideaObjectives
            ideaPurpose
            ideaRemark
            ideaStage
            ideaType
            ideaUniqueId
            ideaWeight
          }
        }
        ideaTaskProgressUniqueId
      }
      response {
        code
        id
        message
        status
      }
    }
  }
`;
;