import {
  
  getApplicationDetails,
  
} from "@/graphql/application/application.graphql";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
  state: {
    applicationManagements: [],
    applicationManagement: {},
  },
  getters: {
    getApplicationManagements: (state) => state.applicationManagements,
    getApplicationManagement: (state) => state.applicationManagement,
  },
  actions: {
    async getApplicationDetails(context, uniqueId) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getApplicationDetails,
          variables: { uniqueId },
        })
        .then((output) => {
          let { response, data } = output.data.getApplicationDetails;

          if (response.status) {
            if (_.isNil(uniqueId)) {
              context.commit("setStoreItem", {
                module: "applicationDetails",
                entity: "applicationManagements",
                data,
              });
              return data;
            } else {
              context.commit("setStoreItem", {
                module: "applicationDetail",
                entity: "applicationManagement",
                data: data[0],
              });
              return data[0];
            }
          } else {
            notifyError(response.message || "Failed to fetch");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    }
}
}