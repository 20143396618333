export default [
    {
      name: "My-Application",
      path: "my-applications",
      props:true,
      component: () => import("./MyApplication.vue"),
      meta: {
        permissions: []
      }
    },
    {
        name: "View-My-Application",
        path: "my-applications/:requestUniqueId",
        props:true,
        component: () => import("./viewMyApplication.vue"),
        meta: {
            permissions: []
        }
    },
    
  ];