import { createMeetingMinutes, deleteMeetingMinutes, getMeetingMinutes, updateMeetingMinutes } from "@/graphql/repository/meeting_minute/meetingMinute.graphql.js"
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
        meetingMinutes : [],
        meetingMinute : {}
    },
    getters: {
        getMeetingMinutes: (state) => (state.meetingMinutes),
        getMeetingMinute: (state) => (state.meetingMinute)
    },
    actions: {
        async getMeetingMinutes(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getMeetingMinutes,
                variables : {input}
            })
            .then((output) => {
                let {response, data} = output.data.getMeetingMinutes;

                if (response.status) {
                    context.commit("setStoreItem", {
                        module : 'meetingMinuteManagement',
                        entity : 'meetingMinutes',
                        data
                    })
                    return data
                        
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        async createMeetingMinutes(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createMeetingMinutes,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.createMeetingMinutes;
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'meetingMinuteManagement',
                        entity : 'meetingMinutes',
                        uniqueKey : 'meetingMinutesUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        
        async updateMeetingMinutes(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateMeetingMinutes,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.updateMeetingMinutes;
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'meetingMinuteManagement',
                        entity : 'meetingMinutes',
                        uniqueKey : 'meetingMinutesUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },


        async deleteMeetingMinutes(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteMeetingMinutes,
                variables: {uniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.deleteMeetingMinutes;
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'meetingMinuteManagement',
                        entity : 'meetingMinutes',
                        uniqueKey : 'meetingMinutesUniqueId',
                        data,
                        remove : true,
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
    }
}