import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";
import { getResearchPublicationsData, getPublicationAttachmentsData, getMyResearchPublicationsData, deleteResearchData, addResearchArea, updateResearchArea, createPublicationsCategory, updatePublicationCategory, deletePublicationsCategory, getPublicationCategoryData, getResearchAreasData, createResearchPublications, updateResearchPublications, deleteResearchPublications, publishResearch, unPublishResearch, createResearchPublicationAttachments, deleteResearchPublicationAttachments, getOnGoingResearchData } from "../../graphql/communication/research/research.graphql"
export default {
    state: {
        publicationCollections: [],
        mypublicationCollections: [],
        publicationCategories: [],
        researchAreas: [],
        researchAreasTable: [],
        ongoingResearch: [],
        publicationAttachments: {}
    },
    getters: {
        getResearchPublicationsData: (state) => (state.publicationCollections),
        getMyResearchPublicationsData: (state) => (state.mypublicationCollections),
        getPublicationAttachmentsData: (state) => (state.publicationAttachments),
        getPublicationCategoryData: (state) => (state.publicationCategories),
        getResearchAreasData: (state) => (state.researchAreas),
        getResearchAreasTableData: (state) => (state.researchAreasTable),
        getOnGoingResearchData: (state) => (state.ongoingResearch),
    },
    actions: {
        async getResearchPublicationsData(context, filtering) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getResearchPublicationsData,
                variables: { filtering }
            })
                .then((output) => {
                    let { response, data } = output.data.getResearchPublicationsData

                    if (response.status) {
                        context.commit("setStoreItem", {
                            module: 'researchManagement',
                            entity: 'publicationCollections',
                            data
                        })
                        return data;
                    } else {
                        notifyError(response.message || "Failed to fetch")
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },
        async getMyResearchPublicationsData(context, filtering) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getMyResearchPublicationsData,
                variables: { filtering }
            })
                .then((output) => {
                    let { response, data } = output.data.getMyResearchPublicationsData

                    if (response.status) {
                        context.commit("setStoreItem", {
                            module: 'researchManagement',
                            entity: 'mypublicationCollections',
                            data
                        })
                        return data;
                    } else {
                        notifyError(response.message || "Failed to fetch")
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },

        async getPublicationAttachmentsData(context, filtering) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getPublicationAttachmentsData,
                variables: { filtering }
            })
                .then((output) => {
                    let { response, data } = output.data.getPublicationAttachmentsData

                    if (response.status) {
                        context.commit("setStoreItem", {
                            module: 'researchManagement',
                            entity: 'publicationAttachments',
                            data
                        })
                        return data;
                    } else {
                        notifyError(response.message || "Failed to fetch")
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },
        async getPublicationCategoryData(context, filtering) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getPublicationCategoryData,
                variables: { filtering }
            })
                .then((output) => {
                    let { response, data } = output.data.getPublicationCategoryData

                    if (response.status) {
                        context.commit("setStoreItem", {
                            module: 'researchManagement',
                            entity: 'publicationCategories',
                            data
                        })
                        return data;
                    } else {
                        notifyError(response.message || "Failed to fetch")
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },


        async createResearchPublications(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createResearchPublications,
                variables: { input }
            })
                .then((output) => {
                    let { response, data } = output.data.createResearchPublications

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: 'researchManagement',
                            entity: 'publicationCollections',
                            uniqueKey: 'publicationUniqueId',
                            data
                        })
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },
        async createPublicationsCategory(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createPublicationsCategory,
                variables: { input }
            })
                .then((output) => {
                    let { response, data } = output.data.createPublicationsCategory

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: 'researchManagement',
                            entity: 'publicationCategories',
                            uniqueKey: 'publicationCategoryUniqueId',
                            data
                        })
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },

        async updatePublicationCategory(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updatePublicationCategory,
                variables: { input }
            })
                .then((output) => {
                    let { response, data } = output.data.updatePublicationCategory

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: 'researchManagement',
                            entity: 'publicationCategories',
                            uniqueKey: 'publicationUniqueId',
                            data
                        })
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },

        async updateResearchPublications(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateResearchPublications,
                variables: { input }
            })
                .then((output) => {
                    let { response, data } = output.data.updateResearchPublications

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: 'researchManagement',
                            entity: 'publicationCollections',
                            uniqueKey: 'publicationUniqueId',
                            data
                        })
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },

        async deleteResearchPublications(context, publicationUniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteResearchPublications,
                variables: { publicationUniqueId }
            })
                .then((output) => {
                    let { response, data } = output.data.deleteResearchPublications

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: 'researchManagement',
                            entity: 'publicationCollections',
                            uniqueKey: 'publicationUniqueId',
                            data,
                            remove: true,
                        })
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },
        async deletePublicationsCategory(context, publicationCategoryUniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deletePublicationsCategory,
                variables: { publicationCategoryUniqueId }
            })
                .then((output) => {
                    let { response, data } = output.data.deletePublicationsCategory

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: 'researchManagement',
                            entity: 'publicationCategories',
                            uniqueKey: 'publicationCategoryUniqueId',
                            data,
                            remove: true,
                        })
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },

        async publishResearch(context, publicationUniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: publishResearch,
                variables: { publicationUniqueId }
            })
                .then((output) => {
                    let { response, data } = output.data.publishResearch

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: 'researchManagement',
                            entity: 'publicationCollections',
                            uniqueKey: 'publicationUniqueId',
                            data,
                        })
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },

        async unPublishResearch(context, publicationUniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: unPublishResearch,
                variables: { publicationUniqueId }
            })
                .then((output) => {
                    let { response, data } = output.data.unPublishResearch

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: 'researchManagement',
                            entity: 'publicationCollections',
                            uniqueKey: 'publicationUniqueId',
                            data,
                        })
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },

        async createResearchPublicationAttachments(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createResearchPublicationAttachments,
                variables: { input }
            })
                .then((output) => {
                    let { response, data } = output.data.createResearchPublicationAttachments;

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: 'researchManagement',
                            entity: 'publicationAttachments',
                            uniqueKey: 'publicationUniqueId',
                            data
                        })
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },

        async deleteResearchPublicationAttachments(context, publicationAttachmentUniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteResearchPublicationAttachments,
                variables: { publicationAttachmentUniqueId }
            })
                .then((output) => {
                    let { response, data } = output.data.deleteResearchPublicationAttachments

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: 'researchManagement',
                            entity: 'publicationAttachments',
                            uniqueKey: 'publicationAttachmentUniqueId',
                            data,
                            remove: true,
                        })
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },



        async getResearchAreasData(context, filtering) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getResearchAreasData,
                variables: { filtering }
            })
                .then((output) => {
                    let { response, data } = output.data.getResearchAreasData

                    if (response.status) {
                        context.commit("setStoreItem", {
                            module: 'researchManagement',
                            entity: 'researchAreas',
                            data
                        })
                        return data;
                    } else {
                        notifyError(response.message || "Failed to fetch")
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },
        async getResearchAreasTableData(context, filtering) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getResearchAreasData,
                variables: { filtering }
            })
                .then((output) => {
                    let { response, data } = output.data.getResearchAreasData

                    if (response.status) {
                        context.commit("setStoreItem", {
                            module: 'researchManagement',
                            entity: 'researchAreasTable',
                            data
                        })
                        return data;
                    } else {
                        notifyError(response.message || "Failed to fetch")
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },

        async addResearchArea(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: addResearchArea,
                variables: { input }
            })
                .then((output) => {
                    let { response, data } = output.data.addResearchArea;

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: 'researchManagement',
                            entity: 'researchAreas',
                            uniqueKey: 'researchAreaUniqueId',
                            data
                        })
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },

        async updateResearchArea(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateResearchArea,
                variables: { input }
            })
                .then((output) => {
                    let { response, data } = output.data.updateResearchArea

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: 'researchManagement',
                            entity: 'researchAreas',
                            uniqueKey: 'researchAreaUniqueId',
                            data
                        })
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },

        async deleteResearchData(context, researchAreaUniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteResearchData,
                variables: { researchAreaUniqueId }
            })
                .then((output) => {
                    let { response, data } = output.data.deleteResearchData

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: 'researchManagement',
                            entity: 'researchAreas',
                            uniqueKey: 'researchAreaUniqueId',
                            data,
                            remove: true,
                        })
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },


        async getOnGoingResearchData(context, filtering) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getOnGoingResearchData,
                variables: { filtering }
            })
                .then((output) => {
                    let { response, data } = output.data.getOnGoingResearchData

                    if (response.status) {
                        context.commit("setStoreItem", {
                            module: 'researchManagement',
                            entity: 'ongoingResearch',
                            data
                        })
                        return data;
                    } else {
                        notifyError(response.message || "Failed to fetch")
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },
    }
}


