import { getGroupedPermissions } from "@/graphql/uaa/permission.graphql";
import { createUserRole, deleteUserRole, getRoles, getRolesWithoutPermissions, updateUserRole } from "@/graphql/uaa/role.graphql";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
        roles: [],
        roleWithoutPermission: [],
        permissionGroups: [],
        role: {}
    },
    getters: {
        getAllRoles: (state) => (state.roles),
        getRolesWithoutPermissions: (state) => (state.roleWithoutPermission),
        getPermissionGroups: (state) => (state.permissionGroups),
        getRole: (state) => (state.role)
    },
    actions: {
        async getGroupedPermissions(context) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getGroupedPermissions
            })
            .then((output) => {
                let {response, data} = output.data.getGroupedPermissions
                
                if (response.status) {
                    context.commit("setStoreItem", {
                        module : 'roleManagement',
                        entity : 'permissionGroups',
                        data
                    })
                    return data;   
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async getAllRoles(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getRoles,
                variables : {uniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.getRoles
                
                if (response.status) {
                    if (_.isNil(uniqueId)) {
                        context.commit("setStoreItem", {
                            module : 'roleManagement',
                            entity : 'roles',
                            data
                        })
                        return data;   
                    }else {
                        context.commit("setStoreItem", {
                            module : 'roleManagement',
                            entity : 'role',
                            data   : data[0]
                        })
                        return data[0]
                    }
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async getRolesWithoutPermissions(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getRolesWithoutPermissions,
                variables : {uniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.getRoles
                
                if (response.status) {
                    if (_.isNil(uniqueId)) {
                        context.commit("setStoreItem", {
                            module : 'roleManagement',
                            entity : 'roleWithoutPermission',
                            data
                        })
                        return data;   
                    }else {
                        context.commit("setStoreItem", {
                            module : 'roleManagement',
                            entity : 'role',
                            data   : data[0]
                        })
                        return data[0]
                    }
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async createRole(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createUserRole,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.createUserRole
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'roleManagement',
                        entity : 'roles',
                        uniqueKey : 'roleUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async updateRole(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateUserRole,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.updateUserRole
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'roleManagement',
                        entity : 'roles',
                        uniqueKey : 'roleUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async deleteRole(context, roleUniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteUserRole,
                variables: {roleUniqueId}
            })
            .then((output) => {
                let {response} = output.data.deleteUserRole
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'roleManagement',
                        entity : 'roles',
                        uniqueKey : 'roleUniqueId',
                        data: {roleUniqueId},
                        remove : true,
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'));
                    return response.status 
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
    }
}