import locationRouter from "./location_management/locationRouter";
import roleManagementRouter from "./role_management/roleManagementRouter";
import userManagementRouter from "./user_management/userManagementRouter";
import languageManagementRouter from "./language_management/languageManagementRouter";
import educationLevelRouter from "./education_level_management/educationLevelRouter";
import programRouter from "./program_management/programRouter";
import skillRouter from "../setting_management/skill_management/skillRouter";
import institutionRouter from "./institution_management/institutionRouter";
import AttachmentRouter from "./attachment_details/AttachmentRouter";
import projectConfigurationsRouter from "./project_configurations/projectConfigurationsRouter";
import applicationPostRouter from "./application_post_management/applicationPostRouter";
import applicationDetailsRouter from "./application_details_management/applicationDetailsRouter";
import index from "./Index.vue"
import memberRoleManagementRouter from "./project_member_role_management/memberRoleManagementRouter";
import projectPortfolioRouter from "./project_configurations/project_portfolio_management/projectPortfolioRouter";
import applicationCategoriesRouter from "./application_categories/applicationCategoriesRouter";
import publicationCategoriesRouter from "@/components/setting_management/publication_categories/publicationCategoriesRouter";
import researchAreaRouter from "@/components/setting_management/research_area/researchAreaRouter";
import alumniSettingsRouter from "@/components/setting_management/alumni_skill_categories/alumniSettingsRouter";
import diasporaIntrestsRouter from "@/components/setting_management/diaspora-fields-of-interest/diasporaIntrestsRouter";
import diasporaExpertiesRouter
  from "@/components/setting_management/diaspora-fields-of-experties/diasporaExpertiesRouter";
export default [
  {
    path: "/settings",
    name: "Settings Main",
    component: () => import("@/components/setting_management/settingsMain.vue"),
    meta: {
      permissions: [],
    },
    children: [
      {
        name: "Setting Index",
        path: "index",
        component: index,
        meta: {
          permissions: [
            "can_activate_user_account",
            "can_add_role",
            "can_assign_role",
            "can_revoke_access",
            "can_add_user_category",
            "can_add_education_levels",
            "can_add_program_category",
            "can_add_program_sub_category",
            "can_add_HILS",
            "can_add_skills_category",
            "can_add_skills_sub_category",
            "can_add_language_proficiency",
            "can_add_project_category",
            "can_add_project_nature",
            "can_add_project_nature",
          ],
        },
      },
      ...roleManagementRouter,
      ...userManagementRouter,
      ...applicationDetailsRouter,
      ...memberRoleManagementRouter,
      ...applicationPostRouter,
      ...locationRouter,
      ...programRouter,
      ...institutionRouter,
      ...skillRouter,
      ...AttachmentRouter,
      ...projectConfigurationsRouter,
      ...languageManagementRouter,
      ...educationLevelRouter,
      ...projectPortfolioRouter,
      ...applicationCategoriesRouter,
      ...publicationCategoriesRouter,
      ...alumniSettingsRouter,
      ...diasporaExpertiesRouter,
      ...diasporaIntrestsRouter,
      ...researchAreaRouter
    ],
  },
];


