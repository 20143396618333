import gql from "graphql-tag";

export const getApplicationDashboard = gql`query getApplicationDashboard($year: String) {
    getApplicationDashboard(year: $year) {
        totalApplications
        totalPtApplications
        totalVolunteerApplication
        totalMentorApplications
        totalResearcherApplications
        totalMenteeApplications
        institutionDashboard {
          institution
          totalApplication
          totalFemales
          totalMales
          totalApprovedByEga
          totalRejectedByEga
          appendingApproval
        }
        totalMaleApplicants
        totalFemaleApplicants
        totalTotalApplicantsApprovedByEga
        totalRejectedApplicantsByEga
        appendingApplicationApproval
    }
}
`;
