<template>
    <v-dialog v-model="fileDialog" hide-overlay fullscreen transition="dialog-bottom-transition" content-class="!overflow-y-hidden">
        <v-toolbar dark color="#164E63" height="40" dense>
            <v-btn icon @click="fileDialog = false">
                <v-icon color="white">mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Document Preview</v-toolbar-title>
        </v-toolbar>
        <v-card height="100%">
            <v-card-text id="fileView">
                <iframe width='100%' style="height:100vh;" :src='encodedFile'></iframe>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "FilePreviewDialog",
    computed : {
        encodedFile() {
            return this.$store.getters.getEncodedFilePreview
        },
        fileDialog: {
            get() {
                return this.$store.getters.getFileDialog
            },
            set(value) {
                this.$store.commit("setFileDialog", value)
            }
        }
    },
}
</script>

<style></style>