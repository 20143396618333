import settingsRouter from "./setting_management/settingsRouter";
import registrationRoutes from "./profile_registration/registrationRoutes";
import applicationRoutes from "./application_managements/applicationRouters";
import projectManagementRouter from "./project_management/projectManagementRouter";
import ideaRouter from "./idea_details/ideaRouter"
import communicationRouter from "./communication/communicationRouter";
import attendanceRouter from "@/components/attendance/attendanceRouter";
import repositoryRouter from "./repository/repositoryRouter";
import reportRoutes from "./report_managements/reportRoutes";
import membershipsRouter from "./memberships/membershipsRouter";
import researchRouter from "./research/researchRouter";
export default [
  {
    path: "/main",
    component: () => import("@/views/layouts/MainLayout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import("@/components/Dashboard.vue"),
        meta: {
          permissions: []
        }
      },
      ...settingsRouter,
      ...registrationRoutes,
      ...applicationRoutes,
      ...projectManagementRouter,
      ...ideaRouter,
      ...communicationRouter,
      ...attendanceRouter,
      ...repositoryRouter,
      ...reportRoutes,
      ...membershipsRouter,
      ...researchRouter
    ],
  },

]
