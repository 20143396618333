import gql from "graphql-tag";

export const proposalAttachmentData = `
data{
id
proposalAttachmentUniqueId
proposalAttachmentName
proposalAttachmentPath
proposalAttachmentFileType
proposal
proposalAttachmentIsActive
proposalAttachmentIsDownloaded
proposalAttachmentCreatedDate
}
 `
const response = `
response{
id
status
code
message
}
`

export  const  createProposalAttachment = gql`mutation CreateProposalAttachment($input: ProposalAttachmentsObjectInputObject) {
  createProposalAttachment(input: $input) {
    ${response} 
    ${proposalAttachmentData} 
  }
}
`


export const  deleteProposalAttachment = gql`mutation DeleteProposalAttachment($proposalAttachmentUniqueId: String!) {
  deleteProposalAttachment(proposalAttachmentUniqueId: $proposalAttachmentUniqueId) {
    ${response} 
    ${proposalAttachmentData}
  }
}
`

export  const  getProposalAttachmentsData = gql`query GetProposalAttachmentsData($filtering: ProposalAttachmentsFilteringObject) {
  getProposalAttachmentsData(filtering: $filtering) {
    ${response} 
    ${proposalAttachmentData} 
  }
}
`
