import gql from "graphql-tag";

const meetingMinuteFields = `
    meetingMinutesAttachment
    meetingMinutesCreatedDate
    meetingMinutesDate
    meetingMinutesDescription
    meetingMinutesTitle
    meetingMinutesUniqueId
    meetingMinutesVenue
`;

const responseFields = `
    code
    id
    message
    status
`;

export const createMeetingMinutes = gql`
    mutation CreateMeetingMinutes($input: MeetingMinutesInputObject) {
        createMeetingMinutes(input: $input) {
            data {
                ${meetingMinuteFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const updateMeetingMinutes = gql`
    mutation UpdateMeetingMinutes($input: MeetingMinutesInputObject) {
        updateMeetingMinutes(input: $input) {
            data {
                ${meetingMinuteFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const getMeetingMinutes = gql`
    query GetMeetingMinutes($input: FilterInputObject) {
        getMeetingMinutes(input: $input) {
            data {
                ${meetingMinuteFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const deleteMeetingMinutes = gql`
    mutation DeleteMeetingMinutes($uniqueId: String!) {
        deleteMeetingMinutes(uniqueId: $uniqueId) {
            data {
                ${meetingMinuteFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;