import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";
import { getPublicCollection } from "../../graphql/repository/gallery/galleryPublicCollection.graphql.js";

export default {
  state: {
    galleryCollections: [],
    galleryCollection: {},
    videoCollections: [],
    videoCollection: {},
  },
  getters: {
    getPublicCollections: (state) => state.galleryCollections,
    getPublicCollection: (state) => state.galleryCollection,
    getVideoCollections: (state) => state.videoCollections,
    getVideoCollection: (state) => state.videoCollection,
  },
  actions: {
    async getPublicCollections(context, galleryUniqueId) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getPublicCollection,
          variables: galleryUniqueId,
        })
        .then((output) => {
          let { response, data } = output.data.getPublicCollection;

          if (response.status) {
            context.commit("setStoreItem", {
              module: "galleryPublicCollection",
              entity: "galleryCollections",
              data,
            });
            return data;
          } else {
            notifyError(response.message || "Failed to fetch");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
    async getVideoCollections(context, galleryUniqueId) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getPublicCollection,
          variables: { galleryUniqueId, isVideo: true },
        })
        .then((output) => {
          let { response, data } = output.data.getPublicCollection;

          if (response.status) {
            context.commit("setStoreItem", {
              module: "galleryPublicCollection",
              entity: "videoCollections",
              data,
            });
            return data;
          } else {
            notifyError(response.message || "Failed to fetch");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
  },
};
