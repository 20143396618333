import gql from "graphql-tag";

const response =`response{
  code
  status
  message
}`
const data = `data {
  contactDetails {
    contactDetailsAddress
    contactDetailsMobilePhone
    contactDetailsUniqueId
  }
  dateOfBirth
  designation
  firstName
  gender
  hasDeclared
  healthStatus
  id
  lastName
  username
  maritalStatus
  middleName
  nidaNumber
  profileIsActive
  profilePicture
  profileUniqueId
  professionalismTitle
  professionalismYearsOfExperience
  profileCompletion {
    academicQualification
    attachments
    contactDetails
    languageProficiency
    personalDetails
    professionalQualification
    profileCompletion
    projectPortfolio
    referees
    skillsQualification
    trainingAndWorkshop
    workExperience
  }
  userRole {
    roleName
    roleUniqueId
    rolePermissions {
      permissionUniqueId
      permissionName
      permissionCode
    }
  }
}`

export const revokeUserRole = gql`mutation revokeUserRole($input: RevokeRoleInputObjects!) {
  revokeUserRole(input:$input){
    ${response}
  }
}`

export const getUserProfile = gql`query getUserProfile{
  getUserProfile {
    ${response}
    ${data}
  }
}`

export const updateUserProfile = gql`mutation updateUserProfile($input: UserProfileInputObject) {
  updateUserProfile(input: $input) {
    ${response}
    ${data}
  }
}`