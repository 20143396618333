import gql from "graphql-tag";

const requestFields = `
    requestApprovalStatus
    requestCreatedBy {
        id
        profileUniqueId
        firstName
        lastName
        middleName
        username
        gender
        profilePicture
      }
    requestCreatedDate
    requestDescription
    requestEndDate
    requestPurpose
    requestRemark
    requestStartDate
    requestType
    requestUniqueId
`;

const response = `
    code
    id
    message
    status
`;

export const createRequest = gql`
    mutation CreateRequest($input: RequestDetailsInputObject) {
        createRequest(input: $input) {
            data {
                ${requestFields}
            }
            response {
                ${response}
            }
        }
    }
`;

export const updateRequest = gql`
    mutation UpdateRequest($input: RequestDetailsInputObject) {
        updateRequest(input: $input) {
            data {
                ${requestFields}
            }
            response {
                ${response}
            }
        }
    }
`;

export const getAllRequests = gql`
    query GetAllRequests($input: RequestFilterInputObject) {
        getAllRequests(input: $input) {
            data {
                ${requestFields}
            }
            response {
                ${response}
            }
        }
    }
`;
export const getRequests = gql`
    query GetRequest($uniqueId: String) {
        getRequest(uniqueId: $uniqueId) {
            data {
                ${requestFields}
            }
            response {
                ${response}
            }
        }
    }
`;

export const deleteRequest = gql`
    mutation DeleteRequest($uniqueId: String!) {
        deleteRequest(uniqueId: $uniqueId) {
            data {
                ${requestFields}
            }
            response {
                ${response}
            }
        }
    }
`;

export const requestApproval = gql`
    mutation RequestApproval($input: RequestApprovalInputObject) {
        requestApproval(input: $input) {
            data {
                ${requestFields}
            }
            response {
                ${response}
            }
        }
    }
`;