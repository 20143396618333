import gql from "graphql-tag";

const response = `
response {
id
status
code
message
}
`

const data = `
{
uniqueId
researcherTypeName
}
`

export  const getResearcherType = gql`
query GetResearcherType {
  getResearcherType {
    response {
      id
      status
      code
      message
    }
    data {
      uniqueId
      researcherTypeName
    }
  }
}
`

export  const   createReacherTypeMutation = gql`
mutation CreateReacherTypeMutation($input: ReacherTypeInputObject) {
  createReacherTypeMutation(input: $input) {
    data {
      uniqueId
      researcherTypeName
    }
    response {
      id
      status
      code
      message
    }
  }
}
`

export  const  updateReacherTypeMutation = gql`
mutation UpdateReacherTypeMutation {
  updateReacherTypeMutation {
    data {
      uniqueId
      researcherTypeName
    }
    response {
      id
      status
      code
      message
    }
  }
}
`

export  const  deleteReacherTypeMutation = gql`
mutation DeleteReacherTypeMutation {
  deleteReacherTypeMutation {
    data {
      uniqueId
      researcherTypeName
    }
    response {
      id
      status
      code
      message
    }
  }
}
`
