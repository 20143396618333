import gql from "graphql-tag";
import {response} from "@/graphql/Diaspora/diapora.graphql";

export  const getCurrentMembers = gql`
    query getCurrentMembers($filtering: CurrentMembersFilteringObject){
        getCurrentMembers(filtering: $filtering){
            data {
                firstName
                lastName
                designation
                username
                gender
                contactDetails {
                    contactDetailsAddress
                    contactDetailsMobilePhone
                }
                profileCurrentInstitution {
                    institutionName
                }
                profileUniqueId
                userRole {
                    roleName
                    roleUniqueId
                }
            }
            ${response}
        }
    }
`

