import gql from "graphql-tag";

let response = `response{
  status
  code
  message
}`
let data = `data {
  insuranceUniqueId
  insuranceCardNumber
  insuranceExpiredDate
  insuranceCreatedDate
  InsuranceAttachment
  healthInstitution {
    institutionUniqueId
    institutionName
  }
}`

export const getHealthInsuranceDetails = gql`query getHealthInsuranceDetails($uniqueId: String, $profileUniqueId: String) {
  getHealthInsuranceDetails(uniqueId: $uniqueId, profileUniqueId: $profileUniqueId) {
    ${response}
    ${data}
  }
}`

export const createHealthInsuranceDetails = gql`mutation createHealthInsuranceDetails($input: ProfileWithHealthInsuranceInputObject){
  createHealthInsuranceDetails(input: $input) {
    ${response}
    ${data}
  }
}`

export const updateHealthInsuranceDetails = gql`mutation updateHealthInsuranceDetails($input: ProfileWithHealthInsuranceInputObject){
  updateHealthInsuranceDetails(input: $input) {
    ${response}
    ${data}
  }
}`

export const deleteHealthInsuranceDetails = gql`mutation deleteHealthInsuranceDetails($uniqueId: String!){
  deleteHealthInsuranceDetails(uniqueId: $uniqueId) {
    ${response}
    ${data}
  }
}`