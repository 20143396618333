import gql from "graphql-tag";
export const getIdeaSubTaskProgress = gql`
  query getIdeaSubTaskProgress($subTaskUniqueId: String) {
    getIdeaSubTaskProgress(subTaskUniqueId: $subTaskUniqueId) {
      data {
        ideaSubTask {
          ideaSubTaskDescription
          ideaSubTaskName
          ideaSubTaskUniqueId
          ideaSubTaskWeight
          ideaTask {
            hasSubTask
            ideaTaskDescription
            ideaTaskName
            ideaTaskUniqueId
            ideaTaskWeight
            ideaTasksIdea {
              ideaApprovalStatus
              ideaApprovedBy {
                firstName
                gender
                id
                lastName
                middleName
                profilePicture
                profileUniqueId
                username
              }
              ideaBenefits
              ideaName
              ideaNature
              ideaObjectives
              ideaPurpose
              ideaRemark
              ideaStage
              ideaType
              ideaUniqueId
              ideaWeight
            }
          }
        }
        ideaSubTaskCompletedWeight
        ideaSubTaskProgressAttachment
        ideaSubTaskProgressComments
        ideaSubTaskProgressCreatedBy {
          firstName
          gender
          id
          lastName
          middleName
          profilePicture
          profileUniqueId
          username
        }
        ideaSubTaskProgressStatus
        ideaSubTaskProgressUniqueId
      }
      response {
        code
        id
        message
        status
      }
    }
  }
`;
;

export const createIdeaSubTaskProgress = gql`
  mutation createIdeaSubTaskProgress($input: IdeaSubTaskProgressInputObject) {
    createIdeaSubTaskProgress(input: $input) {
      data {
        ideaSubTask {
          ideaSubTaskDescription
          ideaSubTaskName
          ideaSubTaskUniqueId
          ideaSubTaskWeight
          ideaTask {
            hasSubTask
            ideaTaskDescription
            ideaTaskName
            ideaTaskUniqueId
            ideaTaskWeight
            ideaTasksIdea {
              ideaApprovalStatus
              ideaApprovedBy {
                firstName
                gender
                id
                lastName
                middleName
                profilePicture
                profileUniqueId
                username
              }
              ideaBenefits
              ideaName
              ideaNature
              ideaObjectives
              ideaPurpose
              ideaRemark
              ideaStage
              ideaType
              ideaUniqueId
              ideaWeight
            }
          }
        }
        ideaSubTaskCompletedWeight
        ideaSubTaskProgressAttachment
        ideaSubTaskProgressComments
        ideaSubTaskProgressCreatedBy {
          firstName
          gender
          id
          lastName
          middleName
          profilePicture
          profileUniqueId
          username
        }
        ideaSubTaskProgressStatus
        ideaSubTaskProgressUniqueId
      }
      response {
        code
        id
        message
        status
      }
    }
  }
`;
export const updateIdeaSubTaskProgress = gql`
  mutation updateIdeaSubTaskProgress($input: IdeaSubTaskProgressInputObject) {
    updateIdeaSubTaskProgress(input: $input) {
      data {
        ideaSubTask {
          ideaSubTaskDescription
          ideaSubTaskName
          ideaSubTaskUniqueId
          ideaSubTaskWeight
          ideaTask {
            hasSubTask
            ideaTaskDescription
            ideaTaskName
            ideaTaskUniqueId
            ideaTaskWeight
            ideaTasksIdea {
              ideaApprovalStatus
              ideaApprovedBy {
                firstName
                gender
                id
                lastName
                middleName
                profilePicture
                profileUniqueId
                username
              }
              ideaBenefits
              ideaName
              ideaNature
              ideaObjectives
              ideaPurpose
              ideaRemark
              ideaStage
              ideaType
              ideaUniqueId
              ideaWeight
            }
          }
        }
        ideaSubTaskCompletedWeight
        ideaSubTaskProgressAttachment
        ideaSubTaskProgressComments
        ideaSubTaskProgressCreatedBy {
          firstName
          gender
          id
          lastName
          middleName
          profilePicture
          profileUniqueId
          username
        }
        ideaSubTaskProgressStatus
        ideaSubTaskProgressUniqueId
      }
      response {
        code
        id
        message
        status
      }
    }
  }
`;
export const deleteIdeaSubTaskProgress = gql`
  mutation deleteIdeaSubTaskProgress($uniqueId: String!) {
    deleteIdeaSubTaskProgress(uniqueId: $uniqueId) {
      data {
        ideaSubTask {
          ideaSubTaskDescription
          ideaSubTaskName
          ideaSubTaskUniqueId
          ideaSubTaskWeight
          ideaTask {
            hasSubTask
            ideaTaskDescription
            ideaTaskName
            ideaTaskUniqueId
            ideaTaskWeight
            ideaTasksIdea {
              ideaApprovalStatus
              ideaApprovedBy {
                firstName
                gender
                id
                lastName
                middleName
                profilePicture
                profileUniqueId
                username
              }
              ideaBenefits
              ideaName
              ideaNature
              ideaObjectives
              ideaPurpose
              ideaRemark
              ideaStage
              ideaType
              ideaUniqueId
              ideaWeight
            }
          }
        }
        ideaSubTaskCompletedWeight
        ideaSubTaskProgressAttachment
        ideaSubTaskProgressComments
        ideaSubTaskProgressCreatedBy {
          firstName
          gender
          id
          lastName
          middleName
          profilePicture
          profileUniqueId
          username
        }
        ideaSubTaskProgressStatus
        ideaSubTaskProgressUniqueId
      }
      response {
        code
        id
        message
        status
      }
    }
  }
`;