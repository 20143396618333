import {
  deleteIdeaSubTaskProgress,
  createIdeaSubTaskProgress,
  updateIdeaSubTaskProgress,
  getIdeaSubTaskProgress,
} from "@/graphql/ideas/IdeaSubtaskProgress.graphql.js";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
  state: {
    ideaprogress: [],
    ideaprogres: {},
  },
  getters: {
    getIdeaSubTaskProgresss: (state) => state.ideaprogress,
    getIdeaSubTaskProgress: (state) => state.ideaprogres,
  },
  actions: {
    async getIdeaSubTaskProgresss(context, data) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getIdeaSubTaskProgress,
          variables: {
            subTaskUniqueId: data?.ideaSubTaskUniqueId,
            uniqueId: data?.ideaSubTaskProgressUniqueId,
          },
        })
        .then((output) => {
          let { response, data } = output.data.getIdeaSubTaskProgress;

          if (response.status) {
            context.commit("setStoreItem", {
              module: "ideaSubtaskProgress",
              entity: "ideaprogress",
              data,
            });
            return data;
          } else {
            notifyError(response.message || "Failed to fetch");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async createIdeaSubTaskProgress(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: createIdeaSubTaskProgress,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.createIdeaSubTaskProgress;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "ideaSubtaskProgress",
              entity: "ideaprogress",
              uniqueKey: "ideaSubTaskUniqueId",
              data,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async updateIdeaSubTaskProgress(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: updateIdeaSubTaskProgress,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.updateIdeaSubTaskProgress;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "ideaSubtaskProgress",
              entity: "ideaprogress",
              uniqueKey: "ideaSubTaskUniqueId",
              data,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async deleteIdeaSubTaskProgress(context, uniqueId) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: deleteIdeaSubTaskProgress,
          variables: { uniqueId },
        })
        .then((output) => {
          let { response, data } = output.data.deleteIdeaSubTaskProgress;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "ideaSubtaskProgress",
              entity: "ideaprogress",
              uniqueKey: "ideaSubTaskUniqueId",
              data,
              remove: true,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
  },
};
