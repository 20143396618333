
export default [
    {
      path: "application-types",
      name: "Application Types",
      component: () =>import("./ApplicationManagement.vue"),
      meta: {
        permissions: ["can_approve_application_request"]
      }      
    },  
    {
      path: "applications-post/:applicationCategory",
      name: "Applications Posts",
      props:true,
      component: () => import("@/components/application_managements/application_management/applications/ApplicationsPost.vue"),
      meta: {
        permissions: ["can_approve_application_request"]
      }      
    },
    {
      name: "View-Application",
      path: "view-application/:applicationCategory/:applicationPostUniqueId",
      component: () => import("@/components/application_managements/application_management/applications/viewApplication.vue"),
      meta: {
        permissions: ["can_approve_application_request"]
      }    
    },
 
];