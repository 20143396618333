
import { createProfileWithAttachment, deleteProfileWithAttachment, getProfileWithAttachment, getProfileWithAttachments, updateProfileWithAttachment } from "@/graphql/profile_details/profileWithAttachment.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
        profileAttachments: [],
        profileAttachment: {},
        
    },
    getters: {
        getProfileWithAttachments: (state) => (state.profileAttachments),
        getProfileWithAttachment: (state) => (state.profileAttachment),
    },
    actions: {
        async getProfileWithAttachments(context, profileWithAttachmentUniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getProfileWithAttachment,
                variables : {profileWithAttachmentUniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.getProfileWithAttachment
                
                if (response.status) {
                    if (_.isNil(profileWithAttachmentUniqueId)) {
                        context.commit("setStoreItem", {
                            module : 'profileWithAttachmentManagement',
                            entity : 'profileAttachments',
                            data
                        })
                        return data;   
                    }else {
                        context.commit("setStoreItem", {
                            module : 'profileWithAttachmentManagement',
                            entity : 'profileAttachment',
                            data   : data[0]
                        })
                        return data[0]
                    }
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        async createProfileWithAttachment(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createProfileWithAttachment,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.createProfileWithAttachment
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'profileWithAttachmentManagement',
                        entity : 'profileAttachments',
                        uniqueKey : 'profileWithAttachmentUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    context.dispatch("getUserProfile")
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },


        async updateProfileWithAttachment (context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateProfileWithAttachment,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.updateProfileWithAttachment 
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'profileWithAttachmentManagement',
                        entity : 'profileAttachments',
                        uniqueKey : 'profileWithAttachmentUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    context.dispatch("getUserProfile")
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },


        async deleteProfileWithAttachment(context, profileWithAttachmentUniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteProfileWithAttachment,
                variables: {profileWithAttachmentUniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.deleteProfileWithAttachment
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'profileWithAttachmentManagement',
                        entity : 'profileAttachments',
                        uniqueKey : 'profileWithAttachmentUniqueId',
                        data,
                        remove : true,
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    context.dispatch("getUserProfile")
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
    },
   
}