import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";
import { getGalleryCollection, createGalleryCollection, updateGalleryCollection, deleteGalleryCollection } from  "../../graphql/repository/gallery/galleryCollection.graphql"
export default {
    state: {
        galleryCollections: [],
        galleryCollection: {}
    },
    getters: {
        getGalleryCollections: (state) => (state.galleryCollections),
        getGalleryCollection: (state) => (state.galleryCollection)
    },
    actions: {
        async getGalleryCollection(context, galleryUniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getGalleryCollection,
                variables : {galleryUniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.getGalleryCollection
                
                if (response.status) {
                    context.commit("setStoreItem", {
                        module : 'galleryCollectionManagement',
                        entity : 'galleryCollections',
                        data
                    })
                    return data;
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async createGalleryCollection(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createGalleryCollection,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.createGalleryCollection
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'galleryCollectionManagement',
                        entity : 'galleryCollections',
                        uniqueKey : 'galleryCollectionUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        
        async updateGalleryCollection(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateGalleryCollection,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.updateGalleryCollection
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'galleryCollectionManagement',
                        entity : 'galleryCollections',
                        uniqueKey : 'galleryCollectionUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        async deleteGalleryCollection(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteGalleryCollection,
                variables: {uniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.deleteGalleryCollection
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'galleryCollectionManagement',
                        entity : 'galleryCollections',
                        uniqueKey : 'galleryCollectionUniqueId',
                        data,
                        remove : true,
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
    }
}
