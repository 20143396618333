import {createRegion,deleteRegion, getAllRegions, updateRegion,} from "@/graphql/location/region.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
  state: {
    regions: [],
    region: {},
  },
  getters: {
    getAllRegions: (state) => state.regions,
    getRegion: (state) => state.region,
  },
  actions: {
    async getAllRegions(context, regionUniqueId) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getAllRegions,
          variables: { regionUniqueId },
        })
        .then((output) => {
          let { response, data } = output.data.getAllRegions;
          if (response.status) {
            if (_.isNil(regionUniqueId)) {
              context.commit("setStoreItem", {
                module: "regionManagement",
                entity: "regions",
                data,
              });
              return data;
            } else {
              context.commit("setStoreItem", {
                module: "regionManagement",
                entity: "regions",
                data: data[0],
              });
              return data[0];
            }
          } else {
            notifyError(response.message || "Failed to fetch");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
    async createRegion(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: createRegion,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.createRegion;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "regionManagement",
              entity: "regions",
              uniqueKey: "regionUniqueId",
              data,
            });
            notifySuccess(response.message || i18n.t('labels.operationSuccessful'));
            return data;
          } else {
            notifyError(response.message || i18n.t('labels.operationFailed'));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
    async updateRegion(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: updateRegion,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.updateRegion;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "regionManagement",
              entity: "regions",
              uniqueKey: "regionUniqueId",
              data,
            });
            notifySuccess(response.message || i18n.t('labels.operationSuccessful'));
            return data;
          } else {
            notifyError(response.message || i18n.t('labels.operationFailed'));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
    async deleteRegion(context, regionUniqueId) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: deleteRegion,
          variables: { regionUniqueId },
        })
        .then((output) => {
          let { response, data } = output.data.deleteRegion;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "regionManagement",
              entity: "regions",
              uniqueKey: "regionUniqueId",
              data,
              remove: true,
            });
            notifySuccess(response.message || i18n.t('labels.operationSuccessful'));
            return data;
          } else {
            notifyError(response.message || i18n.t('labels.operationFailed'));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
  },
};
