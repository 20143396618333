import gql from "graphql-tag";

const enrollmentFields = `
    deviceEnrollment
      deviceId
      newAttendanceEnrollmentUniqueId
      newAttendanceEnrollmentUser {
        firstName
        gender
        id
        institution
        lastName
        middleName
        profilePicture
        profileUniqueId
        username
    }
`;

const responseFields = `
    code
    id
    message
    status
`;

export const createNewAttendanceEnrollment = gql`
mutation CreateNewAttendanceEnrollment($input: NewAttendanceEnrollmentInputObject) {
    createNewAttendanceEnrollment(input: $input) {
            data {
                ${enrollmentFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const getEnrolledUsers = gql`
    query  GetEnrolledUsers($uniqueId: String) {
        getEnrolledUsers(uniqueId: $uniqueId) {
            data {
                ${enrollmentFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const deleteAttendanceEnrollment = gql`
mutation DeleteAttendanceEnrollment($uniqueId: String!) {
    deleteAttendanceEnrollment(uniqueId: $uniqueId) {
            data {
                ${enrollmentFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;