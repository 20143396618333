import {createTrainingDetail,updateTrainingDetail,deleteTraining ,getTrainingDetails, getMyTrainingDetails} from "@/graphql/profile_details/trainingDetails.graphql.js";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
  state: {
    trainingDetails: [],
    trainingDetail: {},
  },
  getters: {
    getTrainingDetails: (state) => state.trainingDetails,
    getTrainingDetail: (state) => state.trainingDetail,
  },
  actions: {
    async getTrainingDetails(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getTrainingDetails,
          variables: input,
        })
        .then((output) => {
          let { response, data } = output.data.getTrainingDetails;

          if (response.status) {
            if (_.isNil(input.uniqueId)) {
              context.commit("setStoreItem", {
                module: "trainingDetailsManagement",
                entity: "trainingDetails",
                data,
              });
              return data;
            } else {
              context.commit("setStoreItem", {
                module: "trainingDetailsManagement",
                entity: "trainingDetail",
                data: data[0],
              });
              return data[0];
            }
          } else {
            notifyError(response.message || "Failed to fetch");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async getMyTrainingDetails(context) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getMyTrainingDetails
        })
        .then((output) => {
          let { response, data } = output.data.getTrainingDetails;


          if (response.status) {
              context.commit("setStoreItem", {
                module: "trainingDetailsManagement",
                entity: "trainingDetails",
                data,
              });
              return data;
            
          } else {
            notifyError(response.message || "Failed to fetch");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
    
    async createTrainingDetail(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          mutation: createTrainingDetail,
          variables: { input:input },
        })
        .then((output) => {
          let { response, data } = output.data.createTrainingDetail;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "trainingDetailsManagement",
              entity: "trainingDetails",
              uniqueKey: "trainingUniqueId",
              data,
            });
            notifySuccess(response.message || i18n.t('labels.operationSuccessful'));
            context.dispatch("getUserProfile")
            return data;
          } else {
            notifyError("Please fill mandatory * fields" || i18n.t('labels.operationFailed'));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
    async updateTrainingDetail(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: updateTrainingDetail,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.updateTrainingDetail;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "trainingDetailsManagement",
              entity: "trainingDetails",
              uniqueKey: "trainingUniqueId",
              data,
            });
            notifySuccess(response.message || i18n.t('labels.operationSuccessful'));
            context.dispatch("getUserProfile")
            return data;
          } else {
            notifyError(response.message || i18n.t('labels.operationFailed'));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
    async deleteTrainingDetail(context, uniqueId) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          mutation: deleteTraining,
          variables: { uniqueId },
        })
        .then((output) => {
          let { response, data } = output.data.deleteTrainingDetail;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "trainingDetailsManagement",
              entity: "trainingDetails",
              uniqueKey: "trainingUniqueId",
              data,
              remove: true,
            });
            notifySuccess(response.message || i18n.t('labels.operationSuccessful'));
            context.dispatch("getUserProfile")
            return data;
          } else {
            notifyError(response.message || i18n.t('labels.operationFailed'));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
  },
};
