export default [
  {
    name: "Language",
    path: "language-List",
    component: () => import("./LanguageList.vue"),
    props: true,
    meta: {
      permissions: []
    }
  },
];
