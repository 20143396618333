import gql from "graphql-tag";

const response = `response {
    status
    code
    message
}`;

const data = `data {
    galleryCollectionUniqueId
      galleryCollections {
        galleryUniqueId
        galleryDescription
        galleryName
        year
      }
      galleryCollectionPath
      galleryCollectionCreatedDate
      galleryCollectionAccess
}`;

export const getPublicCollection = gql`
query GetPublicCollection($isVideo: Boolean, $galleryUniqueId: String) {
  getPublicCollection(isVideo: $isVideo, galleryUniqueId: $galleryUniqueId) {
    response {
      id
      status
      code
      message
    }
    data {
      galleryCollectionUniqueId
      galleryCollections {
        galleryUniqueId
        galleryDescription
        galleryName
        year
      }
      galleryCollectionPath
      galleryCollectionCreatedDate
      galleryCollectionAccess
    }
  }
}`;
