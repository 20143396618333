import gql from "graphql-tag";

const contactDetailsFields = `
    contactDetailsUniqueId
    contactDetailsFirstName
    contactDetailsLastName
    contactDetailsType
    contactDetailsCountry {
      countryUniqueId
      countryName
    }
    contactDetailsRegion {
      regionUniqueId
      regionName
      regionCode
    }
    contactDetailsDistrict {
      districtUniqueId
      districtName
      districtCode
    }
    contactDetailsWard {
      wardUniqueId
      wardName
      wardCode
    }
    contactDetailsAddress
    contactDetailsMobilePhone
    contactDetailsCreatedDate`;

const responseFields = `
    id
    status
    code
    message`;
    
export const createContactDetails = gql`
    mutation createContactDetails($input: ContactDetailsInputObject){
        createContactDetails(input:$input){
            data{
                ${contactDetailsFields}
            }
            response{
                ${responseFields}
            }
        }
    }
`;

export const updateContactDetails = gql`
    mutation updateContactDetails($input: ContactDetailsInputObject){
        updateContactDetails(input:$input){
            data{
                ${contactDetailsFields}
            }
            response{
                ${responseFields}
            }
        }
    }
`;

export const getUserContactDetails = gql`query getUserContactDetails($profileUniqueId: String) {
    getUserContactDetails(profileUniqueId: $profileUniqueId) {
        data{
            ${contactDetailsFields}
        }
        response{
            ${responseFields}
        }
    }
  }
`;

export const deleteContactDetails = gql`
    mutation deleteContactDetails($uniqueId: String!){
        deleteContactDetails(uniqueId:$uniqueId){
            data{
                ${contactDetailsFields}
            }
            response{
                ${responseFields}
            }
        }
    }
`;