import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";
import { deleteNotification, getUserNotifications, updateReadNotification } from "@/graphql/repository/notification/notifications.graphql.js";

export default {
    state: {
        UserNotifications: [],
        UserNotification: {},
        unreadCount: 0
    },
    getters: {
        getNotifications: (state) => (state.UserNotifications),
        getNotification: (state) => (state.UserNotification),
        getUnreadCount: (state) => (state.unreadCount)
    },
    actions: {
        async getUserNotifications(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getUserNotifications,
                variables : {uniqueId}

            })
            .then((output) => {
                let {response, data, totalUnreadNotification} = output.data.getUserNotifications
                if (response.status) {
                    
                    if (_.isNil(uniqueId)) {
                        context.commit("setStoreItem", {
                            module : 'notificationsManagement',
                            entity : 'UserNotifications',
                            data
                        })
                        context.commit("setStoreItem", {
                            module : 'notificationsManagement',
                            entity : 'unreadCount',
                            data : totalUnreadNotification
                        })
                        return data;   
                    }else {
                        context.commit("setStoreItem", {
                            module : 'notificationsManagement',
                            entity : 'UserNotification',
                            data   : data[0]
                        })
                        return data[0]
                    }
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        async updateReadNotification(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateReadNotification,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.updateReadNotification
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'notificationsManagement',
                        entity : 'UserNotifications',
                        uniqueKey : 'uniqueId',
                        data
                    })
                    context.commit("updateStoreItem", {
                        module : 'notificationsManagement',
                        entity : 'unreadCount',
                        uniqueKey : 'uniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async deleteNotification(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteNotification,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.deleteNotification
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'notificationsManagement',
                        entity : 'UserNotifications',
                        uniqueKey : 'notificationUniqueId',
                        data,
                        remove : true,
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
    }
}
