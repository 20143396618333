import {
  createConductedTraining,
  deleteConductedTraining,
  updateConductedTraining,
  getConductedTraining,
} from "@/graphql/communication/announcenent/conductedTraining.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
  state: {
    trainings: [],
    training: {},
  },
  getters: {
    getConductedTrainings: (state) => state.trainings,
    getConductedTraining: (state) => state.training,
  },
  actions: {
    async getConductedTrainings(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getConductedTraining,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.getConductedTraining;
          if (response.status) {
            context.commit("setStoreItem", {
              module: "conductedTraining",
              entity: "trainings",
              data,
            });
            return data;
          } else {
            notifyError(response.message || "Failed to fetch");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
    async createConductedTraining(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: createConductedTraining,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.createConductedTraining;
          if (response.status) {
            context.commit("updateStoreItem", {
              module: "conductedTraining",
              entity: "trainings",
              uniqueKey: "conductedTrainingUniqueId",
              data,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
    async updateConductedTraining(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: updateConductedTraining,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.updateConductedTraining;
          if (response.status) {
            context.commit("updateStoreItem", {
              module: "conductedTraining",
              entity: "trainings",
              uniqueKey: "conductedTrainingUniqueId",
              data,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
    async deleteConductedTraining(context, uniqueId) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: deleteConductedTraining,
          variables: { uniqueId },
        })
        .then((output) => {
          let { response, data } = output.data.deleteConductedTraining;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "conductedTraining",
              entity: "trainings",
              uniqueKey: "conductedTrainingUniqueId",
              data,
              remove: true,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
  },
};
