import gql from "graphql-tag";



const projectTaskFields = `
    projectTaskUniqueId
    projectTaskProjectUniqueId
    projectTaskCompletionPercent
    projectTaskName
    projectTaskHasSubTask
    projectTaskProject {
    projectUniqueId
    projectName
    projectType
    projectDescription
    projectDomain {
        domainUniqueId
        domainName
        domainDescription
    }
    projectSource
    projectCategory {
        projectCategoryUniqueId
        projectCategoryName
    }
    projectNature {
        projectNatureUniqueId
        projectNatureName
    }
    projectWeight
    projectObjectives
    projectBenefits
    projectIsActive
    projectCompletionPercent
    }
    projectTaskDescription
    projectTaskComponent {
    componentUniqueId
    componentName
    componentDescription
    componentProject {
        projectUniqueId
        projectName
        projectType
        projectDescription
        projectSource
        projectWeight
        projectObjectives
        projectBenefits
        projectIsActive
        projectCompletionPercent
    }
    componentCompletionPercent
    }
    projectTaskWeight
    projectTaskProgres
    projectTaskStartDate
    projectTaskEndDate
`;

const responseFields = `
    code
    id
    message
    status
`;

export const createProjectTask = gql`
    mutation CreateProjectTask($input: ProjectTaskInputObject) {
        createProjectTask(input: $input) {
            data {
                ${projectTaskFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const updateProjectTask = gql`
    mutation UpdateProjectTask($input: ProjectTaskInputObject) {
        updateProjectTask(input: $input) {
            data {
                ${projectTaskFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const getProjectTasks = gql`
query GetTaskDetails($componentUniqueId: String, $taskUniqueId: String) {
    getTaskDetails(componentUniqueId: $componentUniqueId, taskUniqueId: $taskUniqueId) {
            data {
                ${projectTaskFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const deleteProjectTask = gql`
    mutation DeleteProjectTask($uniqueId: String!) {
        deleteProjectTask(uniqueId: $uniqueId) {
            data {
                ${projectTaskFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;