import { getAllNonStaffUsers } from "@/graphql/human_resource/nonStaffUsers.graphql";
import { notifyError } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
  state: {
    users: [],
    user: {},
  },
  getters: {
    getAllNonStaffUsers: (state) => state.users,
    getNonStaffUser: (state) => state.user,
  },

  actions: {
    async getAllNonStaffUsers(context, userRoleUniqueId) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getAllNonStaffUsers,
          variables: {userRoleUniqueId},
        })
        .then((output) => {
          let { response, data } = output.data.getAllNonStaffUsers;

          if (response.status) {
            context.commit("setStoreItem", {
              module: "nonStaffUserManagement",
              entity: "users",
              data,
            });
            return data;
          } else {
            notifyError(response.message || "Failed to fetch");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
  },
};
