export default [
    {
        path: "project-categories",
        name: "Project Categories",
        component: () => import("@/components/setting_management/project_configurations/project_category/ProjectCategories.vue"),
        meta: {
            permissions: ["can_add_project_category"]
        }
    }, 
    {
        path: "project-natures",
        name: "Project Natures",
        component: () => import("@/components/setting_management/project_configurations/project_nature/projectNatures.vue"),
        meta: {
            permissions: ["can_add_project_nature"]
        }
    }
]