import gql from "graphql-tag";
export const getAwarenessSessions = gql`
  query getAwarenessSessions($input: FilterInputObject) {
  getAwarenessSessions(input: $input) {
    data {
      awarenessSessionAttachment
      awarenessSessionAttendance
      awarenessSessionCreatedBy {
        firstName
        gender
        id
        lastName
        middleName
        profilePicture
        profileUniqueId
        username
      }
      awarenessSessionCreatedDate
      awarenessSessionDate
      awarenessSessionDescription
      awarenessSessionPresenter {
        firstName
        gender
        id
        lastName
        middleName
        profilePicture
        profileUniqueId
        username
      }
      awarenessSessionTitle
      awarenessSessionUniqueId
    }
    response {
      code
      id
      message
      status
    }
  }
}`
export const createAwarenessSession = gql`
mutation createAwarenessSession($input: AwarenessSessionInputObject) {
  createAwarenessSession(input: $input) {
    data {
      awarenessSessionAttachment
      awarenessSessionAttendance
      awarenessSessionCreatedBy {
        firstName
        gender
        id
        lastName
        middleName
        profilePicture
        profileUniqueId
        username
      }
      awarenessSessionCreatedDate
      awarenessSessionDate
      awarenessSessionDescription
      awarenessSessionPresenter {
        firstName
        gender
        id
        lastName
        middleName
        profilePicture
        profileUniqueId
        username
      }
      awarenessSessionTitle
      awarenessSessionUniqueId
    }
    response {
      code
      id
      message
      status
    }
  }
}`
export const updateAwarenessSession = gql`
  mutation updateAwarenessSession($input: AwarenessSessionInputObject) {
    updateAwarenessSession(input: $input) {
      data {
        awarenessSessionAttachment
        awarenessSessionAttendance
        awarenessSessionCreatedBy {
          firstName
          gender
          id
          lastName
          middleName
          profilePicture
          profileUniqueId
          username
        }
        awarenessSessionCreatedDate
        awarenessSessionDate
        awarenessSessionDescription
        awarenessSessionPresenter {
          firstName
          gender
          id
          lastName
          middleName
          profilePicture
          profileUniqueId
          username
        }
        awarenessSessionTitle
        awarenessSessionUniqueId
      }
      response {
        code
        id
        message
        status
      }
    }
  }
`;
export const deleteAwarenessSession = gql`
mutation DeleteAwarenessSession($uniqueId: String!) {
  deleteAwarenessSession(uniqueId: $uniqueId) {
    data {
      awarenessSessionAttachment
      awarenessSessionAttendance
      awarenessSessionCreatedBy {
        firstName
        gender
        id
        lastName
        middleName
        profilePicture
        profileUniqueId
        username
      }
      awarenessSessionCreatedDate
      awarenessSessionDate
      awarenessSessionDescription
      awarenessSessionPresenter {
        firstName
        gender
        id
        lastName
        middleName
        profilePicture
        profileUniqueId
        username
      }
      awarenessSessionTitle
      awarenessSessionUniqueId
    }
    response {
      code
      id
      message
      status
    }
  }
}`
;