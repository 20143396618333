import { createDiasporaFieldInterests, updateDiasporaFieldInterests, getDiasporaFieldOfInterests, deleteDiasporaFieldInterests } from "@/graphql/settings/disporaIntrestsSettings.graphql"
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
        AllDiasporaIntrests: [],
        diasporaIntrests: {}
    },
    getters: {
        getDiasporaFieldOfInterests: (state) => (state.AllDiasporaIntrests),
        getOneDiasporaFieldOfInterests: (state) => (state.diasporaIntrests)
    },
    actions: {
        async getDiasporaFieldOfInterests(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getDiasporaFieldOfInterests,
                variables : {uniqueId}
            })
                .then((output) => {
                    let {response, data} = output.data?.getDiasporaFieldOfInterests

                    if (response.status) {
                        if (_.isNil(uniqueId)) {
                            context.commit("setStoreItem", {
                                module : 'diasporaIntrestsManagement',
                                entity : 'AllDiasporaIntrests',
                                data
                            })
                            return data
                        }else {
                            context.commit("setStoreItem", {
                                module : 'diasporaIntrestsManagement',
                                entity : 'diasporaIntrests',
                                data   : data[0]
                            })
                            return data[0]
                        }
                    } else {
                        notifyError(response.message || "Failed to fetch")
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },

        async createDiasporaFieldInterests(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createDiasporaFieldInterests,
                variables: {input}
            })
                .then((output) => {
                    let {response, data} = output.data.createDiasporaFieldInterests
                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: "diasporaIntrestsManagement",
                            entity: "AllDiasporaIntrests",
                            uniqueKey: "diasporaInterestsUniqueId",
                            data,
                        });
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },
        async updateDiasporaFieldInterests(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateDiasporaFieldInterests,
                variables: {input}
            })
                .then((output) => {
                    let {response, data} = output.data.updateDiasporaFieldInterests

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: "diasporaIntrestsManagement",
                            entity: "AllDiasporaIntrests",
                            uniqueKey: "diasporaInterestsUniqueId",
                            data,
                        });
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },

        async deleteDiasporaFieldInterests(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteDiasporaFieldInterests,
                variables: {uniqueId}
            })
                .then((output) => {
                    let {response, data} = output.data.deleteDiasporaFieldInterests

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: "diasporaIntrestsManagement",
                            entity: "AllDiasporaIntrests",
                            uniqueKey: "diasporaInterestsUniqueId",
                            data,
                            remove: true,
                        });
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },
    }
}
