<template>
    <v-dialog v-model="dialog" width="700" scrollable persistent attach="div#app" content-class="sm:w-full">
        <div class="p-5 intro-y bg-slate-200 dark:bg-darkmode-700">
            <div class="flex flex-col items-center p-2 border-b sm:flex-row border-slate-200/60 dark:border-darkmode-400">
                <h2 class="inline-flex text-base font-medium">
                    <Plus class="w-6 h-6 stroke-current lucide"/> {{ type }} Advertisement
                </h2>
            </div>
            <div class="grid grid-cols-12 gap-5">
                    <!-- BEGIN: Headline -->
                    <div class="col-span-12">
                        <v-text-field 
                            :background-color="isDark ? 'rgb(var(--color-darkmode-800))' : 'rgb(241 245 249 / var(--tw-bg-opacity))'"
                            v-model="advertisement.advertisementHeadline"
                            dense outlined 
                            placeholder="Headline *"
                            label="Headline *"
                        />
                    </div>
                    <!-- END: Email -->
                    <div class="col-span-12">
                        <v-text-field 
                            :background-color="isDark ? 'rgb(var(--color-darkmode-800))' : 'rgb(241 245 249 / var(--tw-bg-opacity))'"
                            v-model="advertisement.advertisementContactEmail"
                            dense outlined @keydown="$event => validEmail($event)"
                            placeholder="Advertisement Email *"
                            label="Advertisement Email *" 
                        />
                    </div>
                    <!-- END: Email -->
                    <!-- END: Advertisement phone -->
                    <div class="col-span-12">
                        <v-text-field 
                            :background-color="isDark ? 'rgb(var(--color-darkmode-800))' : 'rgb(241 245 249 / var(--tw-bg-opacity))'"
                            v-model="advertisement.advertisementContactPhone"
                            dense outlined @keydown="$event => phoneKeydown($event)"
                            placeholder="Advertisement Phone *"
                            label="Advertisement Phone *"
                        />
                    </div>
                    <!-- END: Advertisement phone -->
                    <!-- BEGIN: Approval status  -->
                    <div class="col-span-12">
                        <v-autocomplete :items="$store.getters.getAdvertisementApproval" 
                            :background-color="isDark ? 'rgb(var(--color-darkmode-800))' : 'rgb(241 245 249 / var(--tw-bg-opacity))'"
                            item-text="name" item-value="value" v-model="advertisement.advertisementApproval"
                            placeholder="Approval status" 
                            label="Approval status" 
                            outlined dense 
                        />
                    </div>
                    <!-- END: Approval status  -->

                    <!-- BEGIN: Advertisement Image --->
                    <div class="col-span-12">
                        <v-file-input :accept="['.jpeg', '.png', '.jpg']" outlined dense
                            placeholder="Attachment"
                            label="Attachment"
                            prepend-inner-icon="mdi-attachment" 
                            :background-color="isDark ? 'rgb(var(--color-darkmode-800))' : 'rgb(241 245 249 / var(--tw-bg-opacity))'"
                            prepend-icon="" v-model="advertisement.advertisementImage" @change="sendFile($event)" 
                        />
                    </div>
                    <!-- END: Advertisement Image --->

                    <!-- BEGIN: Start date -->
                    <div class="col-span-6">
                        <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y
                            max-width="290px" min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                                :background-color="isDark ? 'rgb(var(--color-darkmode-800))' : 'rgb(241 245 249 / var(--tw-bg-opacity))'"
                                v-model="advertisement.advertisementStartDate" 
                                placeholder="Start date" 
                                label="Start date" 
                                persistent-hint outlined dense append-icon="mdi-calendar" v-bind="attrs" v-on="on" />
                            </template>
                            <v-date-picker v-model="advertisement.advertisementStartDate" no-title @input="menu1 = false" :max="todayDate" />
                        </v-menu>
                    </div>
                    <!-- END: Start date -->

                    <!-- BEGIN: End date -->
                    <div class="col-span-6">
                        <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y
                            max-width="290px" min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="advertisement.advertisementEndDate"
                                    :background-color="isDark ? 'rgb(var(--color-darkmode-800))' : 'rgb(241 245 249 / var(--tw-bg-opacity))'"
                                    placeholder="End date" 
                                    label="End date" 
                                    outlined dense append-icon="mdi-calendar" v-bind="attrs" v-on="on" 
                                />
                            </template>
                            <v-date-picker v-model="advertisement.advertisementEndDate" no-title @input="menu2 = false"
                                :min="advertisement.advertisementStartDate" 
                            />
                        </v-menu>
                    </div>
                    <!-- END: End date -->

                    <!-- BEGIN: Description -->
                    <div class="col-span-12">
                        <v-textarea 
                            :background-color="isDark ? 'rgb(var(--color-darkmode-800))' : 'rgb(241 245 249 / var(--tw-bg-opacity))'"
                            v-model="advertisement.advertisementDescription"
                            label="Description *" 
                            placeholder="Description *" 
                            outlined dense no-resize
                        />
                    </div>
                    <!-- END: Description -->

                <div class="col-span-12 px-3 row">
                    <button class="btn btn-warning white--text" @click.stop="$emit('close')">Close</button>
                    <v-spacer></v-spacer>
                    <button class="btn btn-primary" @click="createAdvertisement()">Save Advertisement</button>
                </div>
            </div>
        </div>
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
props: ["Dialog", "EditItemData", "DialogTitle", "EditTaskData"],
data: () => ({
    dialog: false,
    advertisement: {},
    type: "add",
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    menu1: false,
    menu2: false,
    fileUploadData: null
}),
watch: {
    Dialog(val) {
    this.dialog = val;
    },
    DialogTitle(title) {
    this.type = title || "Add";
    },

    EditItemData(values) {
        this.advertisement.advertisementUniqueId = Object.hasOwn(values, "advertisementUniqueId") ? values.advertisementUniqueId : "";
        this.advertisement.advertisementApproval = Object.hasOwn(values, "advertisementApproval") ? values.advertisementApproval : "";        
        this.advertisement.advertisementHeadline = Object.hasOwn(values, "advertisementHeadline") ? values.advertisementHeadline : "";        
        this.advertisement.advertisementDescription = Object.hasOwn(values, "advertisementDescription") ? values.advertisementDescription : "";        
        this.advertisement.advertisementStartDate = Object.hasOwn(values, "advertisementStartDate") ? values.advertisementStartDate : "";        
        this.advertisement.advertisementEndDate = Object.hasOwn(values, "advertisementEndDate") ? values.advertisementEndDate : "";  
        this.advertisement.advertisementContactPhone = Object.hasOwn(values, "advertisementContactPhone") ? values.advertisementContactPhone : "";  
        this.advertisement.advertisementContactEmail = Object.hasOwn(values, "advertisementContactEmail") ? values.advertisementContactEmail : "";        
        this.advertisement.advertisementImage = Object.hasOwn(values, "advertisementImage") ? values.advertisementImage : "";        
    }
},
methods: {
    async createAdvertisement() {

        this.advertisement = { ...this.advertisement, advertisementImage : this.fileUploadData }

        Object.entries(this.advertisement).forEach(([key, value]) => {
            if (!value) {
            delete this.advertisement[key];
            }
        })

    if (this.DialogTitle?.toLowerCase() === "add") {
        await this.$store.dispatch("createAdvertisement",this.advertisement)
        .then(() => {
            this.advertisement = {}
            this.$emit("close")
        })

    } else {
        this.$store.dispatch("updateAdvertisement",this.advertisement).then(() => {
            this.advertisement = {}
            this.$emit("close")
        })
    }
    },
    async sendFile(file) {
      this.fileUploadData = await this.sendAttachment(file, "HUMAN_RESOURCE_SERVICE")
    },

    controlEndDate(e){
        this.endDateVisible = e
    },
}
};
</script>