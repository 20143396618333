import gql from 'graphql-tag';

const response = `response {
    id
    status
    code
    message
}`;

const data = `data {
    notificationUniqueId
    notificationUser {
        id
        profileUniqueId
        username
        firstName
        middleName
        lastName
        profilePicture
    }
    notificationType
    notificationParentUniqueId
    notificationHasRead
    notificationMessage
    notificationCreatedDate
}`;

export const getUserNotifications = gql`query GetUserNotifications($uniqueId: String, $hasRead: Boolean) {
  getUserNotifications(uniqueId: $uniqueId, hasRead: $hasRead) {
      ${response}
      ${data}
      totalUnreadNotification
    }
}`;

export const deleteNotification = gql`mutation deleteNotification($input: NotificationInputObject) {
    deleteNotification(input: $input) {
      ${response} 
      ${data}
    }
}`;
export const updateReadNotification = gql`mutation updateReadNotification($input: NotificationInputObject) {
    updateReadNotification(input: $input) {
      ${response} 
      ${data}
      totalUnreadNotification
    }
}`;