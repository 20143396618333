export default [
    {
        name : "Role Management",
        path : "roles",
        component : () => import("./Roles.vue"),
        props: true,
        meta: {
            permissions: ["can_add_role"]
        }
    }
]