export default [
    {
        path: "/diaspora-requests",
        name: "diaspora requests",
        component: () => import("./DiasporaRequests.vue"),
        meta: {
            permissions: ["can_view_attendance_list"],
        },

    },
    {
        path: "/diaspora-details/:uniqueId",
        name: "diaspora details",
        component: () => import("./DiasporaDetailsView.vue"),
        meta: {
            permissions: ["can_view_attendance_list"],
        },

    },
    {
        path: "/diaspora-request-details/:uniqueId",
        name: "diaspora request details",
        component: () => import("./DiasporaRequestsView.vue"),
        meta: {
            permissions: ["can_view_attendance_list"],
        },

    },
    {
        path: "/diaspora-list",
        name: "diaspora list",
        component: () => import("./DiasporaList.vue"),
        meta: {
            permissions: [],
        },

    },


];
