
<template>
  <div class="col-span-12 lg:col-span-9 2xl:col-span-10 ">
    <div class="flex items-center">
      <button class="mr-2 btn btn-primary-soft" @click="$router.back()">
        <ArrowLeftCircle class="w-4 h-4 mr-1 stroke-current lucide" />
        {{ $t('labels.back') }}
      </button>
      <span class="text-lg font-medium intro-y dark:text-white">
        {{ $t(`location.labels.wards`) }}
      </span>
    </div>
   
    <div class="flex flex-wrap items-center justify-between col-span-12 my-2 intro-y sm:flex-nowrap">
      <div class="w-full mt-3 sm:w-auto sm:mt-0 sm:ml-auto md:ml-0">
        <div class="relative w-56 text-slate-500">
          <input type="text" class="w-56 pr-10 form-control box" :placeholder="$t('labels.searchPlaceholder')">
          <Search class="absolute inset-y-0 right-0 w-4 h-4 my-auto mr-3 stroke-current lucide lucide-search" />
        </div>
      </div>
      
    </div>
    <div v-if="ward?.length == 0" class="col-span-12 p-5 mt-5 text-center rounded intro-x zoom-in"
      :class="isDark ? 'bg-[rgb(var(--color-darkmode-600))] text-white' : 'bg-white'">
      <span class="text-overline">{{ $t("labels.noData") }}</span>
    </div>
    <table class="table table-report" v-else>
      <thead>
        <tr>
          <th v-for="header in $t('iterables.wardTableHeaders')" :key="header.name"
            class="whitespace-nowrap dark:text-white">
            {{ header.name }}
          </th>
        </tr>
      </thead>
      <tbody
        class="border-b text-slate-700 dark:text-slate-300 bg-slate-100 dark:bg-darkmode-400/70 border-slate-200/60 dark:border-darkmode-400">
        <tr
          class="border-b intro-x text-slate-600 dark:text-slate-500 bg-slate-100 dark:bg-darkmode-400/70 border-slate-200/60 dark:border-darkmode-400"
          v-for="ward,index in ward" :key="ward.wardUnique">
        <td class="w-40">
          <div class="flex">{{ index + 1 }}</div>
        </td>
          <td>
            <span class="font-medium whitespace-nowrap">
              {{ ward?.wardName }}
            </span>
          </td>
          
          
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>


import { mapGetters } from 'vuex';

export default {
  
  props: ["districtUniqueId"],
  data: () => ({
    dialog: false,
    dialogTitle: "",
    search: "",
    selectedItem: {}
  }),
  methods: {
   

    

    view(data) {
      this.$router.push({
        name: "wards",
        params: {
          districtUniqueId: data.uniqueId
        }
      }).catch(() => { })
    },

   
  },
  computed: {
    ...mapGetters({
      ward: "getWards"
    })
  },
  mounted() {
    this.$store.dispatch("getWards", this.districtUniqueId);
  }

};
</script>
      
   
<style>

</style>