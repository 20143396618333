import gql from "graphql-tag";
import { data } from "jquery";
export const dataField = `
uniqueId
applicationBatchTitle
applicationBatchMessage
interviewDate
`
export const  createApplicationBatch = gql` 
mutation CreateApplicationBatchMutation($input: ApplicationBatchInputObject) {
    createApplicationBatchMutation(input: $input) {
      data {
        ${dataField}
      }
      response {
        id
        status
        code
        message
      }
    }
  }
`;
export const getApplicationBatches =gql`
query GetApplicationBatches {
  getApplicationBatches {
    data {
        ${dataField}
    }
    response {
      id
      status
      code
      message
    }
  }
}
`