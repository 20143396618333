import { createEducationLevel,updateEducationLevel,deleteEducationLevel, getAllEducationLevels} from "@/graphql/settings/educationLevel.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
        educations: [],
    },
    getters: {
        getAllEducationLevels: (state) => (state.educations),
    },
    actions: {
        async getAllEducationLevels(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getAllEducationLevels,
                variables : {uniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.getAllEducationLevels
                
                if (response.status) {
                    if (_.isNil(uniqueId)) {
                        context.commit("setStoreItem", {
                            module : 'educationLevelManagement',
                            entity : 'educations',
                            data
                        })
                        return data;   
                    }else {
                        context.commit("setStoreItem", {
                            module : 'educationLevelManagement',
                            entity : 'educations',
                            data   : data[0]
                        })
                        return data[0]
                    }
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async createEducationLevel(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createEducationLevel,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.createEducationLevel
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'educationLevelManagement',
                        entity : 'educations',
                        uniqueKey : 'educationLevelUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async updateEducationLevel(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateEducationLevel,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.updateEducationLevel
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'educationLevelManagement',
                        entity : 'educations',
                        uniqueKey : 'educationLevelUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async deleteEducationLevel(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteEducationLevel,
                variables: {uniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.deleteEducationLevel
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'educationLevelManagement',
                        entity : 'educations',
                        uniqueKey : 'educationLevelUniqueId',
                        data,
                        remove : true,
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
    }
}