import gql from "graphql-tag";

export const declareProfileDetails = gql`
    mutation declareProfileDetails($declarationStatus: Boolean!) {
        declareProfileDetails(declarationStatus: $declarationStatus) {
            response {
                id
                code
                message
                status
            }
        }
    }
`;