import {
  deleteIdeaTask,
  createIdeaTask,
  updateIdeaTask,
  getIdeaTask,
} from "@/graphql/ideas/ideaTask.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
  state: {
    ideatasks: [],
    ideatask: {},
  },
  getters: {
    getIdeaTasks: (state) => state.ideatasks,
    getIdeaTask: (state) => state.ideatask,
  },
  actions: {
    async getIdeaTasks(context, data) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getIdeaTask,
          variables: { ...data },
        })
        .then((output) => {
          let { response, data } = output.data.getIdeaTask;

          if (response.status) {
            context.commit("setStoreItem", {
              module: "ideaTask",
              entity: "ideatasks",
              data,
            });
            return data;
          } else {
            notifyError(response.message || "Failed to fetch");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async createIdeaTask(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: createIdeaTask,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.createIdeaTask;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "ideaTask",
              entity: "ideatasks",
              uniqueKey: "ideaTaskUniqueId",
              data,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async updateIdeaTask(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: updateIdeaTask,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.updateIdeaTask;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "ideaTask",
              entity: "ideatasks",
              uniqueKey: "ideaTaskUniqueId",
              data,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async deleteIdeaTask(context, uniqueId) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: deleteIdeaTask,
          variables: { uniqueId },
        })
        .then((output) => {
          let { response, data } = output.data.deleteIdeaTask;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "ideaTask",
              entity: "ideatasks",
              uniqueKey: "ideaTaskUniqueId",
              data,
              remove: true,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
  },
};
