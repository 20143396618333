import { notifyError } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";
import { getInstitutionReport } from "@/graphql/reports/institutionalReport.graphql.js";

export default {
  state: {
    selectedInstitutions: {},
  },
  getters: {
    getInstitutionReport: (state) => state.selectedInstitutions,
  },
  actions: {
    async getInstitutionReport(context, year) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getInstitutionReport,
          variables: { year },
        })
        .then((output) => {
          let { response, data } = output.data.getInstitutionReport;
          if (response.status) {
            context.commit("setStoreItem", {
              module: "institutionalReportManagement",
              entity: "selectedInstitutions",
              data,
            });
            return data;
          } else {
            notifyError(response.message || "Failed to fetch");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
  },
};
