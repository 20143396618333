import gql from "graphql-tag";

const projectNatureFields = `
    projectNatureName
    projectNatureUniqueId
`;

const responseFields = `
    code
    id
    message
    status
`;

export const createProjectNature = gql`
    mutation CreateProjectNature($input: ProjectNatureInputObject) {
        createProjectNature(input: $input) {
            data {
                ${projectNatureFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const updateProjectNature = gql`
    mutation UpdateProjectNature($input: ProjectNatureInputObject) {
        updateProjectNature(input: $input) {
            data {
                ${projectNatureFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const getProjectNatureDetails = gql`
    query GetProjectNatureDetails($projectNatureUniqueId: String) {
        getProjectNatureDetails(projectNatureUniqueId: $projectNatureUniqueId) {
            data {
                ${projectNatureFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const deleteProjectNature = gql`
    mutation DeleteProjectNature($uniqueId: String!) {
        deleteProjectNature(uniqueId: $uniqueId) {
            data {
                ${projectNatureFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;