import { updateAluminum,approveAluminum, getAllApprovedAlumni, getMyAluminumDetails, getAllAluminumDetails , disapproveAluminum } from "@/graphql/Alumni/alumni.graphql"
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
        AllAlumni: [],
        OneAlumni: {}
    },
    getters: {
        getAllAlumni: (state) => (state.AllAlumni),
        getOneAlumni: (state) => (state.OneAlumni)
    },
    actions: {
        async getAllAluminumDetails(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getAllAluminumDetails,
                variables : {uniqueId}
            })
                .then((output) => {
                    let {response, data} = output.data?.getAllAluminumDetails

                    if (response.status) {
                        if (_.isNil(uniqueId)) {
                            context.commit("setStoreItem", {
                                module : 'AlumniManagement',
                                entity : 'AllAlumni',
                                data
                            })
                            return data
                        }else {
                            context.commit("setStoreItem", {
                                module : 'AlumniManagement',
                                entity : 'OneAlumni',
                                data   : data[0]
                            })
                            return data[0]
                        }
                    } else {
                        notifyError(response.message || "Failed to fetch")
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },


        async getAllApprovedAlumni(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getAllApprovedAlumni,
                variables : {uniqueId}
            })
                .then((output) => {
                    let {response, data} = output.data?.getAllApprovedAlumni

                    if (response.status) {
                        if (_.isNil(uniqueId)) {
                            context.commit("setStoreItem", {
                                module : 'AlumniManagement',
                                entity : 'AllAlumni',
                                data
                            })
                            return data
                        }else {
                            context.commit("setStoreItem", {
                                module : 'AlumniManagement',
                                entity : 'OneAlumni',
                                data   : data[0]
                            })
                            return data[0]
                        }
                    } else {
                        notifyError(response.message || "Failed to fetch")
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },



        async getMyAluminumDetails(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getMyAluminumDetails,
            })
                .then((output) => {
                    let {response, data} = output.data?.getMyAluminumDetails

                    if (response.status) {
                        context.commit("setStoreItem", {
                            module : 'AlumniManagement',
                            entity : 'OneAlumni',
                            data   : data[0]
                        })
                        return data[0]
                    }
                    else {
                        notifyError(response.message || "Failed to fetch")
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },

        async updateAluminum(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateAluminum,
                variables: {input}
            })
                .then((output) => {
                    let {response, data} = output.data.updateAluminum

                    if (response.status) {
                        // context.commit("updateStoreItem", {
                        //     module: "AlumniManagement",
                        //     entity: "AllAlumni",
                        //     uniqueKey: "aluminumUniqueId",
                        //     data,
                        // });
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },

        async approveAluminum(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: approveAluminum,
                variables: input
            })
                .then((output) => {
                    let {response, data} = output.data.approveAluminum

                    if (response.status) {
                        // context.commit("updateStoreItem", {
                        //     module: "AlumniManagement",
                        //     entity: "OneAlumni",
                        //     uniqueKey: "aluminumUniqueId",
                        //     data,
                        // });
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },

        async disapproveAluminum(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: disapproveAluminum,
                variables: input
            })
                .then((output) => {
                    let {response, data} = output.data.disapproveAluminum

                    if (response.status) {
                        // context.commit("updateStoreItem", {
                        //     module: "AlumniManagement",
                        //     entity: "OneAlumni",
                        //     uniqueKey: "aluminumUniqueId",
                        //     data,
                        // });
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },

    }
}
