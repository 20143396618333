import { createApplicationPost, deleteApplicationPost, getApplicationPostDetails, updateApplicationPost,  } from "@/graphql/application/applicationPost.graphql.js";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
        applicationPosts: [],
        applicationPost:{}
    },
    getters: {
        getApplicationPostDetails: (state) => (state.applicationPosts),
        getApplicationPostDetail: (state) => (state.applicationPost)
    },
    actions: {
        async getApplicationPostDetails(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getApplicationPostDetails,
                variables : input
            })
            .then((output) => {
                let {response, data} = output.data.getApplicationPostDetails
                
                if (response.status) {
                    if (_.isNil(input?.applicationUniqueId)) {
                        context.commit("setStoreItem", {
                            module : 'applicationPostDetailsManagement',
                            entity : 'applicationPosts',
                            data
                        })
                        return data;   
                    }else {
                        context.commit("setStoreItem", {
                            module : 'applicationPostDetailsManagement',
                            entity : 'applicationPost',
                            data   : data[0]
                        })
                        return data[0]
                    }
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async createApplicationPost(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createApplicationPost,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.createApplicationPost
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'applicationPostDetailsManagement',
                        entity : 'applicationPosts',
                        uniqueKey : 'applicationPostUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async updateApplicationPost(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateApplicationPost,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.updateApplicationPost
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'applicationPostDetailsManagement',
                        entity : 'applicationPosts',
                        uniqueKey : 'applicationPostUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async deleteApplicationPost(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteApplicationPost,
                variables: {uniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.deleteApplicationPost
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'applicationPostDetailsManagement',
                        entity : 'applicationPosts',
                        uniqueKey : 'applicationPostUniqueId',
                        data,
                        remove : true,
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
    }
}