import { createWard, deleteWard,  updateWard, getWards } from "@/graphql/location/ward.graphql";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
        wards: [],
        ward: {}
    },
    getters: {
        getWards: (state) => (state.wards),
        getWard: (state) => (state.ward)
    },
    actions: {
        async getWards(context, districtUniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getWards,
                variables : {districtUniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.getWards
                
                if (response.status) {
                    context.commit("setStoreItem", {
                        module : 'wardManagement',
                        entity : 'wards',
                        data
                    })
                    return data;   
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async createWard(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createWard,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.createWard
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'wardManagement',
                        entity : 'wards',
                        uniqueKey : 'uniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async updateWard(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateWard,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.updateWard
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'wardManagement',
                        entity : 'wards',
                        uniqueKey : 'uniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async deleteWard(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteWard,
                variables: {uniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.deleteWard
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'wardManagement',
                        entity : 'wards',
                        uniqueKey : 'uniqueId',
                        data,
                        remove : true,
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
    }
}