import { createOutsideWork, deleteOutsideWork, getOutsideWorkDetails, updateOutsideWork } from "@/graphql/projects/outsideWork/outsideWork"
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
        works : [],
        work : {}
    },
    getters: {
        getWorks: (state) => (state.works),
        getWork: (state) => (state.work)
    },
    actions: {
        async getOutsideWorkDetails(context, outsideWorkUniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getOutsideWorkDetails,
                variables : {outsideWorkUniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.getOutsideWorkDetails;

                if (response.status) {
                        context.commit("setStoreItem", {
                            module : 'outsideWorkManagement',
                            entity : 'works',
                            data
                        })
                        return data
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        async createOutsideWork(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createOutsideWork,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.createOutsideWork;
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'outsideWorkManagement',
                        entity : 'works',
                        uniqueKey : 'outsideWorkUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        
        async updateOutsideWork(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateOutsideWork,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.updateOutsideWork;
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'outsideWorkManagement',
                        entity : 'works',
                        uniqueKey : 'outsideWorkUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },


        async deleteOutsideWork(context, outsideWorkUniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteOutsideWork,
                variables: {outsideWorkUniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.deleteOutsideWork;
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'outsideWorkManagement',
                        entity : 'works',
                        uniqueKey : 'outsideWorkUniqueId',
                        data,
                        remove : true,
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
    }
}