import gql from "graphql-tag";
const response = `response {
  code
  status
  message
}`
const data = `data {
  createdDate
  educationLevelName
  educationLevelUniqueId
}`
export const getAllEducationLevels = gql`query getAllEducationLevels($uniqueId: String){
	getAllEducationLevels(uniqueId: $uniqueId) {
    ${response}
    ${data}
  }
}`

export const createEducationLevel = gql`mutation createEducationLevel($input: EducationLevelInputObject){
  createEducationLevel(input:$input){
    ${response}
    ${data}
  }
}`

export const updateEducationLevel = gql`mutation updateEducationLevel($input: EducationLevelInputObject){
  updateEducationLevel(input:$input){
    ${response}
    ${data}
  }
}`

export const deleteEducationLevel = gql`mutation deleteEducationLevel($uniqueId:  String!){
  deleteEducationLevel(uniqueId: $uniqueId){
    ${response}
    ${data}
  }
}`