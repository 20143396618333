import { createPublication, deletePublication, getPublications, updatePublication } from "@/graphql/profile_details/publication.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
  state: {
    publications: [],
    publication: {},
  },
  getters: {
    getPublications: (state) => state.publications,
    getPublication: (state) => state.publication,
  },
  actions: {
    async getPublications(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getPublications,
          variables: { ...input },
        })
        .then((output) => {
          let { response, data } = output.data.getPublications;

          if (response.status) {
            if (_.isNil(input?.uniqueId)) {
              context.commit("setStoreItem", {
                module: "publicationManagement",
                entity: "publications",
                data,
              });
              return data;
            } else {
              context.commit("setStoreItem", {
                module: "publicationManagement",
                entity: "publication",
                data: data[0],
              });
              return data[0];
            }
          } else {
            notifyError(response.message || "Failed to fetch");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async createPublication(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          mutation: createPublication,
          variables: { input:input },
        })
        .then((output) => {
          let { response, data } = output.data.createPublication;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "publicationManagement",
              entity: "publications",
              uniqueKey: "publicationUniqueId",
              data,
            });
            notifySuccess(response.message || i18n.t('labels.operationSuccessful'));
            context.dispatch("getUserProfile")
            return data;
          } else {
            notifyError(response.message || i18n.t('labels.operationFailed'));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async updatePublication(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: updatePublication,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.updatePublication;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "publicationManagement",
              entity: "publications",
              uniqueKey: "publicationUniqueId",
              data,
            });
            notifySuccess(response.message || i18n.t('labels.operationSuccessful'));
            context.dispatch("getUserProfile")
            return data;
          } else {
            notifyError(response.message || i18n.t('labels.operationFailed'));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async deletePublication(context, uniqueId) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          mutation: deletePublication,
          variables: { uniqueId },
        })
        .then((output) => {
          let { response, data } = output.data.deletePublication;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "publicationManagement",
              entity: "publications",
              uniqueKey: "publicationUniqueId",
              data,
              remove: true,
            });
            notifySuccess(response.message || i18n.t('labels.operationSuccessful'));
            context.dispatch("getUserProfile")
            return data;
          } else {
            notifyError(response.message || i18n.t('labels.operationFailed'));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
  },
};
