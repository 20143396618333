import gql from "graphql-tag";

export const getInstitutionReport = gql`
  query getInstitutionReport($year: String) {
    getInstitutionReport(year: $year) {
      response {
        id
        status
        code
        message
      }
      data
    }
  }
`;
