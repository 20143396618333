import { getAllDiasporaDetails,getMyDiasporaDetails, createDiasporaDetails , updateDiasporaDetails , getAllApprovedDiasporaDetails , approveDiasporaDetails , disproveDiasporaDetails} from "@/graphql/Diaspora/diapora.graphql"
import {notifyError, notifySuccess, notifySuccessLarge} from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";
import {updateAluminum} from "@/graphql/Alumni/alumni.graphql";

export default {
    state: {
        AllDiaspora: [],
        OneDiaspora: {}
    },
    getters: {
        getAllDiasporaDetails: (state) => (state.AllDiaspora),
        getOneDiaspora: (state) => (state.OneDiaspora)
    },
    actions: {
        async getAllDiasporaDetails(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getAllDiasporaDetails,
                variables : {uniqueId}
            })
                .then((output) => {
                    let {response, data} = output.data?.getAllDiasporaDetails

                    if (response.status) {
                        if (_.isNil(uniqueId)) {
                            context.commit("setStoreItem", {
                                module : "DiasporaManagement",
                                entity : "AllDiaspora",
                                data
                            })
                            return data
                        }else {
                            context.commit("setStoreItem", {
                                module : "DiasporaManagement",
                                entity : "OneDiaspora",
                                data   : data[0]
                            })
                            return data[0]
                        }
                    } else {
                        notifyError(response.message || "Failed to fetch")
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },

        async getAllApprovedDiasporaDetails(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getAllApprovedDiasporaDetails,
                variables : {uniqueId}
            })
                .then((output) => {
                    let {response, data} = output.data?.getAllApprovedDiasporaDetails

                    if (response.status) {
                        if (_.isNil(uniqueId)) {
                            context.commit("setStoreItem", {
                                module : "DiasporaManagement",
                                entity : "AllDiaspora",
                                data
                            })
                            return data
                        }else {
                            context.commit("setStoreItem", {
                                module : "DiasporaManagement",
                                entity : "OneDiaspora",
                                data   : data[0]
                            })
                            return data[0]
                        }
                    } else {
                        notifyError(response.message || "Failed to fetch")
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },

        async getMyDiasporaDetails(context) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getMyDiasporaDetails,
            })
                .then((output) => {
                    let {response, data} = output.data?.getMyDiasporaDetails

                    if (response.status) {
                        context.commit("setStoreItem", {
                            module : "DiasporaManagement",
                            entity : "OneDiaspora",
                            data   : data[0]
                        })
                        return data[0]
                    }
                    else {
                        notifyError(response.message || "Failed to fetch")
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },

        async createDiasporaDetails(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createDiasporaDetails,
                variables: {input}
            })
                .then((output) => {
                    let {response, data} = output.data.createDiasporaDetails

                    if (response.status) {
                        notifySuccessLarge("Please check your email for activation", "Account created successfully")
                        return true
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },


        async updateDiasporaDetails(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateDiasporaDetails,
                variables: {input}
            })
                .then((output) => {
                    let {response, data} = output.data.updateDiasporaDetails

                    if (response.status) {
                        // context.commit("updateStoreItem", {
                        //     module: "DiasporaManagement",
                        //     entity: "OneDiaspora",
                        //     uniqueKey: "diasporaUniqueId",
                        //     data,
                        // });
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },

        async disproveDiasporaDetails(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: disproveDiasporaDetails,
                variables: input
            })
                .then((output) => {
                    let {response, data} = output.data.disproveDiasporaDetails

                    if (response.status) {

                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },


        async approveDiasporaDetails(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation:approveDiasporaDetails ,
                variables: input
            })
                .then((output) => {
                    let {response, data} = output.data.approveDiasporaDetails

                    if (response.status) {
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        return data
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'))
                    }
                }).finally(() => context.commit("setLoadingState", false))
        },


    }
}
