<template>
    <div class="grid grid-cols-12 gap-y-2">
        <div class="flex items-center justify-between col-span-12 sm:col-span-12 md:col-span-6">
            <button class="mr-2 btn btn-primary-soft fab dark:text-white" @click="$router.back()">
                <ArrowLeftCircle class="w-4 h-4 mr-1 stroke-current lucide intro-x" />
            </button>
            <span class="mr-auto text-xl font-bold text-primary dark:text-white">{{ pageTitle }}</span>
        </div>
        <div class="flex items-center justify-end col-span-12 sm:col-span-12 md:col-span-6" v-if="buttonText?.length">
            <template v-if="buttonText?.indexOf('.') != -1">
                <button class="z-20 mr-2 shadow-md btn btn-primary" @click="$emit('button:click')" v-if="$t(`${buttonText}.permissions`)">
                    <Plus class="flex items-center justify-center w-5 h-5 stroke-current lucide" />
                    {{  $t(`${buttonText}.title`) }}
                </button>
            </template>
            <template v-else>
                <button class="z-20 mr-2 shadow-md btn btn-primary" @click="$emit('button:click')">
                    <Plus class="flex items-center justify-center w-5 h-5 stroke-current lucide" /> {{ buttonText }}
                </button>
            </template>
        </div>
        <div class="col-span-12">
            <div class="flex flex-wrap gap-2">
                <div v-if="hasSearch">
                    <v-text-field type="text" dense outlined clearable :label="$t('labels.searchPlaceholder')"
                        :placeholder="$t('labels.searchPlaceholder')" append-icon="mdi-magnify"
                        :background-color="isDark ? 'rgb(var(--color-darkmode-800))' : 'white'"
                        @click:clear="$emit('clickSearch:clear')" @input="$emit('search:change', $event)"
                        @click:append="$emit('clickSearch:append')" />
                </div>
                <template v-for="option,index in filtering">
                    <div v-if="index+1 > 0" :key="index">
                        <v-autocomplete outlined dense clearable v-if="option?.fromGetters && !option.hasItems && hasAccess(option?.permissions)"
                            :items="$store.getters[option.items]" v-model="filterValues[option.key]"
                            item-text="name" item-value="value"
                            :background-color="isDark ? 'rgb(var(--color-darkmode-800))' : 'white'"
                            @change="$emit('filter:update', [option?.key, $event])"
                            @click:clear="$emit('clickFilter:clear')"
                            :placeholder="`Filter By ${option.title}`" 
                            :label="`Filter By ${option.title}`"/>
                        
                        <v-autocomplete outlined dense clearable v-if="!option?.fromGetters && !option.hasItems && hasAccess(option?.permissions)"
                            :items="$store.getters[option.items]" v-model="filterValues[option.key]"
                            :item-text="option.text" :item-value="option.value"
                            :background-color="isDark ? 'rgb(var(--color-darkmode-800))' : 'white'"
                            @change="$emit('filter:update', [option?.key, $event])"
                            @click:clear="$emit('clickFilter:clear')"
                            :placeholder="`Filter By ${option.title}`" 
                            :label="`Filter By ${option.title}`"/>
                            
                            
                            <v-autocomplete outlined dense clearable v-if="!option?.fromGetters && option.hasItems && hasAccess(option?.permissions)"
                                :items="option.items" v-model="filterValues[option.key]"
                                item-text="text" item-value="value"
                                :background-color="isDark ? 'rgb(var(--color-darkmode-800))' : 'white'"
                                @change="$emit('filter:update', [option?.key, $event])"
                                @click:clear="$emit('clickFilter:clear')"
                                :placeholder="`Filter By ${option.title}`" 
                                :label="`Filter By ${option.title}`"/>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PageHeader",
    props: {
        pageTitle: {
            default: "Page",
            type: String,
            required: false
        },
        buttonText: {
            default: "",
            type: String,
            required: false
        },
        hasSearch: {
            default: true,
            type: Boolean,
            required: false
        },
        filtering: {
            default: () => [],
            type: Array,
            required: false
        },
    },
    emits: [
        "search:change",
        "clickSearch:append",
        "clickSearch:clear",
        "button:click",
        "filter:update",
        "clickFilter:clear",
    ],
    data() {
        return {
            filterValues: {}
        }
    },
    mounted() {
        if (this.$route.path?.includes('application') && Object.keys(this.$store.getters.getApplicationRequestFilters)?.length) {
            this.filterValues = this.$store.getters.getApplicationRequestFilters
        }
    }
}
</script>

<style></style>