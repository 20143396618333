import gql from "graphql-tag";
const response = `response {
  status
  code
  message
  id
}`
const data = `data {
  requestApplicationHasBeenWithdrew
   requestApplicationHasArrived
   requestHasReapplied
   requestApplicationReasonsForApproval
  requestApplicationApprovalFeedback
      requestApplicationApprovalFeedbackCoordinator
      requestApplicationApprovalRemark
      requestApplicationApprovalRemarkCoordinator
      requestApplicationDescription
      requestApplicationLetter
      requestApplicationPost {
        applicationPostApplication {
          applicationCapacity
          applicationCategory
          applicationCreatedDate
          applicationDescription
          applicationIntakeEndDate
          applicationIntakeStartDate
          applicationTheme
          applicationUniqueId
        }
        applicationPostAttachment
        applicationPostBanner
        applicationPostCreatedDate
        applicationPostDescription
        applicationPostExpiryDate
        applicationPostPublishedDate
        applicationPostUniqueId
      }
      requestApplicationStatus
      requestApplicationStatusCoordinator
      requestCreatedDate
      requestRecommendationLetter
      requestUniqueId
      requestUser {
        firstName
        gender
        id
        lastName
        middleName
        profilePicture
        profileUniqueId
        username
        institution
      }
      requestApplicationCategory {
        applicationCategoryUniqueId
        applicationDescription
        applicationCategoryName
      }
}`;
export const getApplicationRequest = gql`
query getApplicationRequest($uniqueId: String, $applicationRequestEnum: ApplicationChoiceTypeEnum, $applicationRequestStatusEnum: RequestStatusTypeEnum, $applicationGenderEnum: ApplicationGenderTypeEnum, $year: String, $institutionUniqueId: String, $role: ApplicationRoleTypeEnum, $firstNPages: Int, $nextOrPrevious: Int, $searchKey: String, $arrivalStatus: Boolean  , $applicationPostUniqueId: String) {
  getApplicationRequest(uniqueId: $uniqueId, applicationRequestEnum: $applicationRequestEnum, applicationRequestStatusEnum: $applicationRequestStatusEnum, applicationGenderEnum: $applicationGenderEnum, year: $year, institutionUniqueId: $institutionUniqueId, role: $role, firstNPages: $firstNPages, nextOrPrevious: $nextOrPrevious, searchKey: $searchKey, arrivalStatus: $arrivalStatus ,applicationPostUniqueId: $applicationPostUniqueId
 
) {
    ${response}
    ${data}
    paginatorResponse {
      numberOfItems
      numberOfPages
      hasNext
      hasPrevious
    },
    dashboard {
      totalApplication
      totalFemales
      totalMales
      totalApprovedByEga
      totalRejectedByEga
      appendingApproval
      totalApprovedByCoordinator
      totalRejectedByCoordinator
      appendingApprovalCoordinator
    }
  }
}
`;

export const createApplicationRequest = gql`
mutation CreateApplicationRequest($input: ApplicationRequestInputObject) {
  createApplicationRequest(input: $input) {
      ${data}
      ${response}
    }
  }
`;

export const updateApplicationRequest = gql`
  mutation UpdateApplicationRequest($input: ApplicationRequestInputObject) {
    updateApplicationRequest(input: $input) {
      ${data}
      ${response}
    }
  }
`;

export const deleteApplicationRequest = gql`
  mutation DeleteApplicationRequest($uniqueId: String!) {
    deleteApplicationRequest(uniqueId: $uniqueId) {
      ${data}
      ${response}
    }
  }
`;

export const applicationApproval = gql`
mutation ApplicationApproval($input: ApplicationRequestInputObject) {
  applicationApproval(input: $input) {
     ${data}
     ${response}
  }
}
`;
export const getApplicationApprovalLetter = gql`
query GetApplicationApprovalLetter($requestUniqueId: String) {
  getApplicationApprovalLetter(requestUniqueId: $requestUniqueId) {
    ${response}
    data
  }
}
`;

export const updatePtHasArrived = gql`
  mutation updatePtHasArrived($input: ApplicationRequestInputObject) {
    updatePtHasArrived(input: $input) {
      data {
        requestUniqueId
        requestRecommendationLetter
        requestApplicationLetter
        requestUser {
          id
          profileUniqueId
          username
          firstName
          middleName
          lastName
          profilePicture
          gender
          institution
        }
        requestApplicationPost {
          applicationPostUniqueId
          applicationPostDescription
          applicationPostAttachment
          applicationPostBanner
          applicationPostPublishedDate
          applicationPostExpiryDate
          applicationPostApplication {
            applicationUniqueId
            applicationCategory
            applicationDescription
            applicationIntakeStartDate
            applicationIntakeEndDate
            applicationCapacity
            applicationTheme
            applicationCreatedDate
          }
          applicationPostCreatedDate
          hasApplied
          hasExpired
          hasBeenStopped
        }
        requestCreatedDate
        requestApplicationDescription
        requestApplicationStatus
        requestApplicationApprovalRemark
        requestApplicationApprovalFeedback
        requestApplicationApprovalRemarkCoordinator
        requestApplicationApprovalFeedbackCoordinator
        requestApplicationStatusCoordinator
        requestApplicationReasonsForApproval
        requestApplicationHasArrived
        requestApplicationHasBeenWithdrew
      }
      response {
        id
        status
        code
        message
      }
    }
  }
`;
export const updateInterviewStatusMutation = gql`
mutation updateInterviewStatusMutation($input: ApplicationRequestInputObject) {
  updateInterviewStatusMutation(input: $input) {
     ${data}
     ${response}
  }
}
`;
