import { createProjectPortfolio,updateProjectPortifolio,getProjectPortfolio,deleteProjectPortfolio } from "../../graphql/profile_details/projectPortifolio.js";
import { notifyError,notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";
import i18n from "@/i18n.js";

export default {
    state: {
        projectPortifolios: [],
        projectPortifolio: {}
    },
    getters: {
        getProjectPortfolio: (state) => (state.projectPortifolios),
        getprojectPortifolio: (state) => (state.projectPortifolio)
    },
    actions: {
        async fetchAllProjectPortfolio(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getProjectPortfolio,
                variables : input
            })
            .then((output) => {
                let {response, data} = output.data.getProjectPortfolio
                
                if (response.status) {
                    if (_.isNil(input?.uniqueId)) {
                        context.commit("setStoreItem", {
                            module : 'projectPortifolio',
                            entity : 'projectPortifolios',
                            data
                        })
                        return data
                    }else {
                        context.commit("setStoreItem", {
                            module : 'projectPortifolio',
                            entity : 'projectPortifolio',
                            data   : data[0]
                        })
                        return data[0]
                    }
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async createProjectPortfolio(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createProjectPortfolio,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.createProjectPortfolio
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'projectPortifolio',
                        entity : 'projectPortifolios',
                        uniqueKey : 'projectPortfolioUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    context.dispatch("getUserProfile")
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async updateProjectPortifolio(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateProjectPortifolio,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.updateProjectPortfolio


                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'projectPortifolio',
                        entity : 'projectPortifolios',
                        uniqueKey : 'projectPortfolioUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    context.dispatch("getUserProfile")
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async deleteProjectPortfolio(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteProjectPortfolio,
                variables: {uniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.deleteProjectPortfolio
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'projectPortifolio',
                        entity : 'projectPortifolios',
                        uniqueKey : 'projectPortfolioUniqueId',
                        data,
                        remove : true,
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    context.dispatch("getUserProfile")
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
    }
}