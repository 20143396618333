import gql from "graphql-tag";
export const createIdeaSubTask = gql`
  mutation createIdeaSubTask($input: IdeaSubTaskInputObject) {
    createIdeaSubTask(input: $input) {
      data {
        ideaSubTaskDescription
        ideaSubTaskName
        ideaSubTaskUniqueId
        ideaSubTaskWeight
        ideaTask {
          hasSubTask
          ideaTaskDescription
          ideaTaskName
          ideaTaskUniqueId
          ideaTaskWeight
          ideaTasksIdea {
            ideaApprovalStatus
            ideaApprovedBy {
              firstName
              gender
              id
              lastName
              middleName
              profilePicture
              profileUniqueId
              username
            }
            ideaBenefits
            ideaName
            ideaNature
            ideaObjectives
            ideaPurpose
            ideaRemark
            ideaStage
            ideaType
            ideaUniqueId
            ideaWeight
          }
        }
      }
      response {
        code
        id
        message
        status
      }
    }
  }
`;

export const updateIdeaSubTask = gql`
  mutation updateIdeaSubTask($input: IdeaSubTaskInputObject) {
    updateIdeaSubTask(input: $input) {
      data {
        ideaSubTaskDescription
        ideaSubTaskName
        ideaSubTaskUniqueId
        ideaSubTaskWeight
        ideaTask {
          hasSubTask
          ideaTaskDescription
          ideaTaskName
          ideaTaskUniqueId
          ideaTaskWeight
          ideaTasksIdea {
            ideaApprovalStatus
            ideaApprovedBy {
              firstName
              gender
              id
              lastName
              middleName
              profilePicture
              profileUniqueId
              username
            }
            ideaBenefits
            ideaName
            ideaNature
            ideaObjectives
            ideaPurpose
            ideaRemark
            ideaStage
            ideaType
            ideaUniqueId
            ideaWeight
          }
        }
      }
      response {
        code
        id
        message
        status
      }
    }
  }
`;
export const deleteIdeaSubTask = gql`
  mutation DeleteIdeaSubTask($uniqueId: String!) {
    deleteIdeaSubTask(uniqueId: $uniqueId) {
      data {
        ideaSubTaskDescription
        ideaSubTaskName
        ideaSubTaskUniqueId
        ideaSubTaskWeight
        ideaTask {
          hasSubTask
          ideaTaskDescription
          ideaTaskName
          ideaTaskUniqueId
          ideaTaskWeight
          ideaTasksIdea {
            ideaApprovalStatus
            ideaApprovedBy {
              firstName
              gender
              id
              lastName
              middleName
              profilePicture
              profileUniqueId
              username
            }
            ideaBenefits
            ideaName
            ideaNature
            ideaObjectives
            ideaPurpose
            ideaRemark
            ideaStage
            ideaType
            ideaUniqueId
            ideaWeight
          }
        }
      }
      response {
        code
        id
        message
        status
      }
    }
  }
`;

export const getIdeaSubTask = gql`
  query getIdeaSubTask($taskUniqueId: String, $uniqueId: String) {
    getIdeaSubTask(taskUniqueId: $taskUniqueId, uniqueId: $uniqueId) {
      data {
        ideaSubTaskDescription
        ideaSubTaskName
        ideaSubTaskUniqueId
        ideaSubTaskWeight
        ideaTask {
          hasSubTask
          ideaTaskDescription
          ideaTaskName
          ideaTaskUniqueId
          ideaTaskWeight
          ideaTasksIdea {
            ideaApprovalStatus
            ideaApprovedBy {
              firstName
              gender
              id
              lastName
              middleName
              profilePicture
              profileUniqueId
              username
            }
            ideaBenefits
            ideaName
            ideaNature
            ideaObjectives
            ideaPurpose
            ideaRemark
            ideaStage
            ideaType
            ideaUniqueId
            ideaWeight
          }
        }
      }
      response {
        code
        id
        message
        status
      }
    }
  }
`;
;