<template>
    <div class="dark-mode-switcher cursor-pointer shadow-md fixed bottom-0 right-0 box dark:bg-darkmode-600 border rounded-full w-40 h-10 flex items-center justify-center z-50 mb-5 mr-5" v-if="$route.path !== '/'">
        <v-switch v-model="night"
            :dark="$store.getters.getNightMode" 
            color="primary" :label="`${darkModeSwitchLabel}`"/>
    </div>
</template>

<script>
import $ from "jquery";
export default {
    name: "DarkModeSwitch",
    computed:{
        darkModeSwitchLabel(){
            if(this.$store.getters.getNightMode){
                return "Light Mode";
            }else {
                return "Dark Mode";
            }
        },
        night: {
            get(){
                return this.$store.getters.getNightMode
            }, 
            set(val) {
                this.$store.commit("setStoreItem", {
                    entity: "nightMode",
                    module: "root",
                    data: val
                })
                if (val) {
                    $("html").removeClass("light").addClass("dark");
                } else {
                    $("html").removeClass("dark").addClass("light");
                }
                location.reload()
            }
        }
    },
}
</script>