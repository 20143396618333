import {
  createFolderFile,
  deleteFolderFile,
  getFolderFileDetails,
} from "@/graphql/projects/folderFile.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
  state: {
    folders: [],
    folder: {},
  },
  getters: {
    getFolderFileDetails: (state) => state.folders,
    getFolderFileDetail: (state) => state.folder,
  },
  actions: {
    async getFolderFileDetails(context, fileUniqueId) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getFolderFileDetails,
          variables: fileUniqueId,
        })
        .then((output) => {
          let { response, data } = output.data.getFolderFileDetails;
          if (response.status) {
            if (!_.isNil(fileUniqueId)) {
              context.commit("setStoreItem", {
                module: "folderFile",
                entity: "folders",
                data,
              });
              return data;
            } else {
              context.commit("setStoreItem", {
                module: "folderFile",
                entity: "folder",
                data: data[0],
              });
              return data[0];
            }
          } else {
            notifyError(response.message || "Failed to fetch");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async createFolderFile(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: createFolderFile,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.createFolderFile;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "folderFile",
              entity: "folders",
              uniqueKey: "fileUniqueId",
              data,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async deleteFolderFile(context, uniqueId) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: deleteFolderFile,
          variables: { uniqueId },
        })
        .then((output) => {
          let { response, data } = output.data.deleteFolderFile;
          if (response.status) {
            context.commit("updateStoreItem", {
              module: "folderFile",
              entity: "folders",
              uniqueKey: "fileUniqueId",
              data: { fileUniqueId: uniqueId },
              remove: true,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
  },
};
