import gql from "graphql-tag"


const skillCategoryFields = `
    skillCategoryUniqueId
    skillCategoryName
    createdDate
`

const responseFields = `
    id
    status
    code
    message
`

export const createSkillCategory = gql`
    mutation createSkillCategory($input: SkillCategoryInputObject){
        createSkillCategory(input:$input){
            data{
                ${skillCategoryFields}
            }
            response{
                ${responseFields}
            }
        }
    }
`

export const updateSkillCategory = gql`
    mutation updateSkillCategory($input: SkillCategoryInputObject){
        updateSkillCategory(input:$input){
            data{
                ${skillCategoryFields}
            }
            response{
                ${responseFields}
            }
        }
    }
`

export const getAllSkillCategories = gql`
    query getAllSkillCategory($uniqueId: String){
        getAllSkillCategory(uniqueId:$uniqueId){
            data{
                ${skillCategoryFields}
            }
            response{
                ${responseFields}
            }
        }
    }
`

export const deleteSkillCategory = gql`
    mutation deleteSkillCategory($uniqueId: String!){
        deleteSkillCategory(uniqueId:$uniqueId){
            data{
                ${skillCategoryFields}
            }
            response{
                ${responseFields}
            }
        }
    }
`