import gql from "graphql-tag";


export const createProfessionalQualification = gql `
    mutation createProfessionalQualification($input: ProfessionalQualificationInputObject) {
        createProfessionalQualification(input: $input) {
        data {
            qualificationUniqueId
            qualificationModeOfLearning
            qualificationIssuer
            qualificationStartDate
            qualificationEndDate
            qualificationCourse
            qualificationCertification
            qualificationInstitution
            qualificationCountry {
            countryUniqueId
            countryName
            countryCreatedDate
            }
            qualificationCreatedDate
        }
        response {
            id
            status
            code
            message
        }
        }
    }
`;

export const updateProfessionalQualification = gql`
    mutation updateProfessionalQualification($input: ProfessionalQualificationInputObject) {
        updateProfessionalQualification(input: $input) {
        data {
            qualificationUniqueId
            qualificationModeOfLearning
            qualificationIssuer
            qualificationStartDate
            qualificationEndDate
            qualificationCourse
            qualificationCertification
            qualificationInstitution
            qualificationCountry {
            countryUniqueId
            countryName
            countryCreatedDate
            }
            qualificationCreatedDate
        }
        response {
            id
            status
            code
            message
        }
        }
    }
`;

export const getProfessionalQualification = gql`
    query GetProfessionalQualification($uniqueId: String) {
        getProfessionalQualification(uniqueId: $uniqueId) {
        data {
            qualificationUniqueId
            qualificationModeOfLearning
            qualificationIssuer
            qualificationStartDate
            qualificationEndDate
            qualificationCourse
            qualificationCertification
            qualificationInstitution
            qualificationCountry {
            countryUniqueId
            countryName
            countryCreatedDate
            }
            qualificationCreatedDate
        }
        response {
            id
            status
            code
            message
        }
        }
    }
`;

export const getMyProfessionalQualifications = gql`
    query GetMyProfessionalQualifications($uniqueId: String, $userProfileUniqueId: String) {
        getMyProfessionalQualifications(uniqueId: $uniqueId, userProfileUniqueId: $userProfileUniqueId) {
        response {
            id
            status
            code
            message
        }
        data {
            qualificationUniqueId
            qualificationModeOfLearning
            qualificationIssuer
            qualificationStartDate
            qualificationEndDate
            qualificationCourse
            qualificationCertification
            qualificationInstitution
            qualificationCountry {
            countryUniqueId
            countryName
            countryCreatedDate
            }
            qualificationCreatedDate
        }
        }
    }
`;

export const deleteProfessionalQualification = gql`
    mutation deleteProfessionalQualification($qualificationUniqueId: String!) {
        deleteProfessionalQualification(qualificationUniqueId: $qualificationUniqueId) {
        data {
            qualificationUniqueId
            qualificationModeOfLearning
            qualificationIssuer
            qualificationStartDate
            qualificationEndDate
            qualificationCourse
            qualificationCertification
            qualificationInstitution
            qualificationCountry {
            countryUniqueId
            countryName
            countryCreatedDate
            }
            qualificationCreatedDate
        }
        response {
            id
            status
            code
            message
        }
        }
    }
`;