import applicationRouter from "./application_management/applicationRouter";
import applicationRequestRouter from "./application_request/applicationRequestRoutes";
import myApplicationRouter from "./my_application/myApplicationRouter";
import userInformationRouter from "./user_information/userInformationRouter";
import withdrawnApplicationRouter from "./application_request/withdrawn-application/withdrawnApplicationRouter";
export default [
  {
    path: "/application",
    name: "Application",
    component: () =>
      import("@/components/application_managements/applicationMain.vue"),
    meta: {
      permissions: [],
    },

    children: [
      {
        path: "index",
        name: "Application Index",
        component: () => import("./Index.vue"),
        meta: {
          permissions: [],
        },
      },
      ...applicationRouter,
      ...applicationRequestRouter,
      ...myApplicationRouter,
      ...userInformationRouter,
      ...withdrawnApplicationRouter,
    ],
  },
];
