import gql from "graphql-tag";
export const createTrainingDetail = gql`
  mutation createTrainingDetail($input: TrainingInputObject) {
    createTrainingDetail(input: $input) {
      data {
        trainingUniqueId
        trainingName
        trainingDescription
        trainingInstitution
        trainingCertificate
        trainingStartDate
        trainingEndDate
      }
      response {
        id
        status
        code
        message
      }
    }
  }
`;

export const updateTrainingDetail = gql`
  mutation updateTrainingDetail($input: TrainingInputObject){
    updateTrainingDetail(input: $input) {
      data {
        trainingUniqueId
        trainingName
        trainingDescription
        trainingInstitution
        trainingCertificate
        trainingStartDate
        trainingEndDate
      }
      response {
        id
        status
        code
        message
      }
    }
  }
`;

export const deleteTraining = gql`
  mutation deleteTrainingDetail($uniqueId: String!) {
    deleteTrainingDetail(uniqueId: $uniqueId) {
      data {
        trainingUniqueId
        trainingName
        trainingDescription
        trainingInstitution
        trainingCertificate
        trainingStartDate
        trainingEndDate
      }
      response {
        id
        status
        code
        message
      }
    }
  }
`;

export const getTrainingDetails = gql`
  query getTrainingDetails($profileUniqueId: String, $uniqueId: String) {
    getTrainingDetails(profileUniqueId: $profileUniqueId, uniqueId: $uniqueId) {
      data {
        trainingUniqueId
        trainingName
        trainingDescription
        trainingInstitution
        trainingCertificate
        trainingStartDate
        trainingEndDate
      }
      response {
        id
        status
        code
        message
      }
    }
  }
`;

export const getMyTrainingDetails = gql`
query getTrainingDetails {
  getTrainingDetails {
    data {
      trainingUniqueId
      trainingName
      trainingDescription
      trainingInstitution
      trainingCertificate
      trainingStartDate
      trainingEndDate
    }
    response {
      id
      status
      code
      message
    }
  }
}
`

