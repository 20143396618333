import {
  createApplicationRegistration,
  deleteApplicationRegistration,
  getApplicationRegistration,
  updateApplicationRegistration,
} from "@/graphql/application/application.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
  state: {
    applicationDetails: [],
    applicationDetail: {},
  },
  getters: {
    getApplicationRegistrations: (state) => state.applicationDetails,
    getApplicationRegistration: (state) => state.applicationDetail,
  },
  actions: {
    async getApplicationRegistrations(context, uniqueId) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getApplicationRegistration,
          variables: { uniqueId },
        })
        .then((output) => {
          let { response, data } = output.data.getApplicationRegistration;

          if (response.status) {
            if (_.isNil(uniqueId)) {
              context.commit("setStoreItem", {
                module: "applicationManagement",
                entity: "applicationDetails",
                data,
              });
              return data;
            } else {
              context.commit("setStoreItem", {
                module: "applicationManagement",
                entity: "applicationDetail",
                data: data[0],
              });
              return data[0];
            }
          } else {
            notifyError(response.message || "Failed to fetch");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
    async createApplicationRegistration(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: createApplicationRegistration,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.createApplicationRegistration;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "applicationManagement",
              entity: "applicationDetails",
              uniqueKey: "applicationUniqueId",
              data,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
    async updateApplicationRegistration(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation:updateApplicationRegistration,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.updateApplicationRegistration;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "applicationManagement",
              entity: "applicationDetails",
              uniqueKey: "applicationUniqueId",
              data,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
    async deleteApplicationRegistration(context, uniqueId) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: deleteApplicationRegistration,
          variables: { uniqueId },
        })
        .then((output) => {
          let { response, data } = output.data.deleteApplicationRegistration;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "applicationManagement",
              entity: "applicationDetails",
              uniqueKey: "applicationUniqueId",
              data,
              remove: true,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
  },
};
