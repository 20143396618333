export default [
    {
        path: "alumni-skill-categories",
        name: "alumni skill categories",
        component: () => import("./alumniSkillCategories.vue"),
        meta: {
            permissions: [
                "can_view_application_details",
                "can_add_application_details",
            ],
        },
    },
    {
        path: "alumni-sub-categories/:UniqueId",
        name: "alumni sub categories",
        component: () => import("./alumni-sub-skills-categories/alumniSkillSubCategories.vue"),
        props: true,
        meta: {
            permissions: ["can_add_skills_sub_category"]
        }
    }
];
