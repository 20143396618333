
export default [
  {
    path: "/ideas",
    name: "Idea Details",
    component: () => import("@/components/idea_details/ideaDetails.vue"),
    meta: {
      permissions: []
    },
    children: [
      {
        name: "Idea Index",
        path: "index",
        component: () => import("./Index.vue"),
        meta: {
            permissions: []
          }
      },
      {
        path: "idea_management",
        name: "Idea Management",
        component: () =>
          import("@/components/idea_details/ideas/IdeaManagement.vue"),
          meta: {
            permissions: []
          }
      },
      {
        path: "idea_matuarity",
        name: "Idea Matuarity",
        component: () =>
          import("@/components/idea_details/idea_matuarity/IdeaMatuarity.vue"),
          meta: {
            permissions: []
          }
      },
      {
        path: "idea_task/:ideaUniqueId",
        name: "Idea Task",
        component: () =>
          import("@/components/idea_details/idea_task/IdeaTask.vue"),
          meta: {
            permissions: []
          }
      },
      {
        path: "idea_approval/:ideaUniqueId",
        name: "Idea Approval",
        component: () =>
          import("@/components/idea_details/idea_approval/IdeaApproval.vue"),
          meta: {
            permissions: []
          }
      },

      {
        path: "idea_subtask/:ideaTaskUniqueId",
        name: "Idea Subtask",
        component: () =>
          import("@/components/idea_details/idea_task/IdeaSubtask.vue"),
          meta: {
            permissions: []
          }
      },

      {
        path: "idea_member/:ideaUniqueId",
        name: "Idea Member",
        component: () =>
          import("@/components/idea_details/idea_task/IdeaMember.vue"),
          meta: {
            permissions: []
          }
      },
      {
        path: "task-assignment/:ideaTaskUniqueId",
        name: "Idea Assignment",
        component: () =>
          import("@/components/idea_details/idea_task/IdeaTaskAssignment.vue"),
          meta: {
            permissions: []
          }
      },
      {
        path: "idea_conceptualization/:ideaUniqueId",
        name: "Idea Conceptualization",
        component: () =>
          import(
            "@/components/idea_details/idea_conceptualization/ApprovalConceptualization.vue"
          ),
          meta: {
            permissions: []
          }
      },
      {
        path: "idea_conceptualizationAttachment/:ideaUniqueId",
        name: "Idea attachment",
        component: () =>
          import(
            "@/components/idea_details/idea_conceptualization/Conceptualization.vue"
          ),
          meta: {
            permissions: []
          }
      },
      {
        path: "idea_progress/:ideaTaskUniqueId",
        name: "Idea progress",
        component: () =>
          import("@/components/idea_details/idea_progress/IdeaProgress.vue"),
          meta: {
            permissions: []
          }
      },
      {
        path: "idea_progresssubtask/:ideaSubTaskUniqueId",
        name: "Idea progress Subtask",
        component: () =>
          import(
            "@/components/idea_details/idea_subtask_progress/IdeaSubtaskProgress.vue"
          ),
          meta: {
            permissions: []
          }
      },
    ],
  },
];
