import gql from "graphql-tag";
export const getMaturedIdeas = gql`
  query getMaturedIdeas {
    getMaturedIdeas {
      data {
        ideaApprovalStatus
        ideaApprovedBy {
          firstName
          gender
          id
          lastName
          middleName
          profilePicture
          profileUniqueId
          username
        }
        ideaBenefits
        ideaName
        ideaNature
        ideaObjectives
        ideaPurpose
        ideaRemark
        ideaStage
        ideaType
        ideaUniqueId
        ideaWeight
      }
      response {
        code
        id
        message
        status
      }
    }
  }
`;
