import gql from "graphql-tag";

const attendanceFields = `
    attendanceTimeIn
    attendanceTimeOut
    attendanceUniqueId
    attendanceUserId {
    firstName
    gender
    id
    lastName
    middleName
    profilePicture
    profileUniqueId
    username
    role
    }
    entryOrExitStatus
    lateStatus
`;

const responseFields = `
    code
    id
    message
    status
`;

export const getDailyAttendance = gql`
    query GetDailyAttendance {
        getDailyAttendance {
            data {
                ${attendanceFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const getFilteredAttendance = gql`
    query GetFilteredAttendance($input: AttendanceFilterInputObject) {
        getFilteredAttendance(input: $input) {
            data {
                ${attendanceFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export  const  getNotArrivedAttendance = gql`
query GetNotArrivedAttendance($input: AttendanceFilterInputObject) {
  getNotArrivedAttendance(input: $input) {
    data {
      id
      profileUniqueId
      username
      firstName
      middleName
      lastName
      profilePicture
      gender
      institution
      role
      dateJoined
    }
    response {
      id
      status
      code
      message
    }
  }
}

`
