import { createContactDetails, deleteContactDetails, getUserContactDetails, updateContactDetails } from "@/graphql/profile_details/contactDetails.graphql.js"
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
        contactDetails: [],
        contactDetail: {}
    },
    getters: {
        getUserContactDetails: (state) => (state.contactDetails),
        getContactDetail: (state) => (state.contactDetail)
    },
    actions: {
        async getUserContactDetails(context, profileUniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getUserContactDetails,
                variables : {profileUniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.getUserContactDetails;
                
                if (response.status) {
                    // if (_.isNil(profileUniqueId)) {
                        context.commit("setStoreItem", {
                            module : 'contactDetailsManagement',
                            entity : 'contactDetails',
                            data
                        })
                        return data;   
                    // }else {
                        // context.commit("setStoreItem", {
                        //     module : 'contactDetailsManagement',
                        //     entity : 'contactDetail',
                        //     data   : data[0]
                        // })
                        // return data[0]
                    // }
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async createContactDetails(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createContactDetails,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.createContactDetails
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'contactDetailsManagement',
                        entity : 'contactDetails',
                        uniqueKey : 'contactDetailsUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    context.dispatch("getUserProfile")
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async updateContactDetails(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateContactDetails,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.updateContactDetails
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'contactDetailsManagement',
                        entity : 'contactDetails',
                        uniqueKey : 'contactDetailsUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    context.dispatch("getUserProfile")
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async deleteContactDetails(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteContactDetails,
                variables: {uniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.deleteContactDetails
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'contactDetailsManagement',
                        entity : 'contactDetails',
                        uniqueKey : 'contactDetailsUniqueId',
                        data,
                        remove : true,
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    context.dispatch("getUserProfile")
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
    }
}