import { createProjectSubTask, deleteProjectSubTask, updateProjectSubTask, getProjectSubtasks} from "@/graphql/projects/projectSubTask.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
        projectSubtasks: [],
        projectSubtask: {}
    },
    getters: {
        getProjectSubtasks: (state) => (state.projectSubtasks),
        getProjectSubtask: (state) => (state.projectSubtask)
    },
    actions: {
        async getProjectSubtasks(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getProjectSubtasks,
                variables : {...input}
            })
            .then((output) => {
                let {response, data} = output.data.getSubTaskDetails

                if (response.status) {
                        context.commit("setStoreItem", {
                            module : 'projectSubTasksManagement',
                            entity : 'projectSubtasks',
                            data
                        })
                        return data
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        async createProjectSubTask(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createProjectSubTask,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.createProjectSubTask
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'projectSubTasksManagement',
                        entity : 'projectSubtasks',
                        uniqueKey : 'projectSubtaskUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        async updateProjectSubTask(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateProjectSubTask,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.updateProjectSubTask
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'projectSubTasksManagement',
                        entity : 'projectSubtasks',
                        uniqueKey : 'projectSubtaskUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        async deleteProjectSubTask(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteProjectSubTask,
                variables: {uniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.deleteProjectSubTask
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'projectSubTasksManagement',
                        entity : 'projectSubtasks',
                        uniqueKey : 'projectSubtaskUniqueId',
                        data,
                        remove : true,
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
    }
}