import gql from "graphql-tag";
const response = `response {
  code
  id
  message
  status
}`;

export const getAllUserProfiles = gql`
  query getAllUserProfiles($userProfileUniqueId: String, $userRoleUniqueId: String, $firstNPages: Int, $nextOrPrevious: Int, $searchKey: String) {
    getAllUserProfiles(userProfileUniqueId: $userProfileUniqueId, userRoleUniqueId: $userRoleUniqueId, firstNPages: $firstNPages, nextOrPrevious: $nextOrPrevious, searchKey: $searchKey) {
      data {
        id
        profileUniqueId
        firstName
        lastName
        middleName
        username
        profilePicture
        profileCreatedDate
        dateOfBirth
        designation
        gender
        profileIsActive
        healthStatus
        maritalStatus
        nidaNumber
        professionalismTitle
        professionalismYearsOfExperience
        hasDeclared
        profileCompletion {
          profileCompletion
        }
        profileCurrentInstitution {
          institutionName
          institutionType
          institutionUniqueId
        }
        userRole {
          roleUniqueId
          roleDescription
          roleName
        }
      }
      paginatorResponse {
        hasNext
        hasPrevious
        numberOfItems
        numberOfPages
      }
      ${response}
    }
  }
`;
export const assignUserRole = gql`
  mutation assignUserRole($input: AssignRoleInputObjects!) {
    assignUserRole(input: $input) {
      ${response}
      data {
        dateOfBirth
        designation
        firstName
        gender
        hasDeclared
        healthStatus
        id
        lastName
        maritalStatus
        middleName
        nidaNumber
        professionalismTitle
        professionalismYearsOfExperience
        profileIsActive
        profilePicture
        profileUniqueId
        staffStatus
        username
        profileCompletion {
          profileCompletion
        }
        profileCurrentInstitution {
          institutionCreatedDate
          institutionName
          institutionUniqueId
        }
        userRole {
          id
          roleDescription
          roleName
          roleUniqueId
        }
      }
    }
  }
`;
export const activateOrDeactivateUser = gql`
  mutation activateOrDeactivateUser($input: ActivateOrDeactivateUserInputObject) {
    activateOrDeactivateUser(input: $input) {
      ${response}
    }
  }
`;