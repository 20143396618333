import {
  getAluminum,
  createAluminum,
  updateAluminum,
} from "@/graphql/communication/aluminum/aluminum.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
  state: {
    alumns: [],
    alumn: {},
  },
  getters: {
    getAluminum: (state) => state.alumns,
    getAluminums: (state) => state.alumn,
  },
  actions: {
    async getAluminums(context, uniqueId) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getAluminum,
          variables:  uniqueId ,
        })
        .then((output) => {
          let { response, data } = output.data.getAluminum;

          if (response.status) {
            context.commit("setStoreItem", {
              module: "aluminum",
              entity: "alumns",
              data,
            });
            return data;
          } else {
            notifyError(response.message || "Failed to fetch");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
    async createAluminum(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: createAluminum,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.createAluminum;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "aluminum",
              entity: "alumns",
              uniqueKey: "aluminumUniqueId",
              data,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
    async updateAluminumm(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: updateAluminum,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.updateAluminum;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "aluminum",
              entity: "alumns",
              uniqueKey: "aluminumUniqueId",
              data,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
  },
};
