import gql from "graphql-tag";

const conseptualizationFields = `
   approvalDecision
      conceptNoteAttachment
      ideaConceptualizationIdeaDetails {
        ideaApprovalStatus
        ideaApprovedBy {
          firstName
          gender
          id
          lastName
          middleName
          profilePicture
          profileUniqueId
          username
        }
        ideaBenefits
        ideaName
        ideaNature
        ideaObjectives
        ideaPurpose
        ideaRemark
        ideaStage
        ideaType
        ideaUniqueId
        ideaWeight
      }
      ideaConceptualizationRemark
      ideaConceptualizationUniqueId
      internalDiscussion
      otherSupportingDocuments
`;

const responseFields = `
  code
  id
  message
  status
`;

export const createIdeaConceptualization = gql`
  mutation CreateIdeaConceptualization($input: IdeaConceptualizationInputObject) {
    createIdeaConceptualization(input: $input) {
      data {
        ${conseptualizationFields}
      }
      response {
        ${responseFields}
      }
    }
  }
`;

export const updateIdeaConceptualization = gql`
  mutation UpdateIdeaConceptualization($input: IdeaConceptualizationInputObject) {
    updateIdeaConceptualization(input: $input) {
      data {
        ${conseptualizationFields}
      }
      response {
        ${responseFields}
      }
    }
  }
`;

export const deleteIdeaConceptualization = gql`
  mutation DeleteIdeaConceptualization($uniqueId: String!) {
    deleteIdeaConceptualization(uniqueId: $uniqueId) {
      data {
        ${conseptualizationFields}
      }
      response {
        ${responseFields}
      }
    }
  }
`;

export const getIdeaConceptualization = gql`
  query GetIdeaConceptualization($ideaUniqueId: String, $uniqueId: String) {
    getIdeaConceptualization(ideaUniqueId: $ideaUniqueId, uniqueId: $uniqueId) {
      data {
        ${conseptualizationFields}
      }
      response {
        ${responseFields}
      }
    }
  }
`;
export const conceptualizationApproval = gql`
  mutation ConceptualizationApproval($input: ConceptualizationApprovalInputObject) {
    conceptualizationApproval(input: $input) {
      data {
        ${conseptualizationFields}
      }
      response {
        ${responseFields}
      }
    }
  }
`;
