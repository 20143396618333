import gql from "graphql-tag";

let response = `response{
  status
  code
  message
}`
let data = `data {
  publicationUniqueId
  publicationTitle
  publicationStandard
  publicationDate
  publicationUrl
  publicationCreatedDate
}`

export const getPublications = gql`query getPublications($profileUniqueId: String, $uniqueId: String) {
  getPublications(uniqueId: $uniqueId,profileUniqueId: $profileUniqueId ) {
    ${response}
    ${data}
  }
}`

export const createPublication = gql`mutation createPublication($input: PublicationInputObject) {
  createPublication(input: $input) {
    ${response}
    ${data}
  }
}`

export const deletePublication = gql`mutation deletePublication($uniqueId: String!) {
  deletePublication(uniqueId: $uniqueId) {
    ${response}
    ${data}
  }
}`

export const updatePublication = gql`mutation updatePublication($input: PublicationInputObject) {
  updatePublication(input: $input) {
    ${response}
    ${data}
  }
}`