
import { notifyError} from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";
import { getApplicationCategoryReport } from "@/graphql/reports/applicationCategoryReport.graphql.js"

export default {
  state: {
      CategoryReport: {},
  },
  getters: {
    getApplicationCategoryReport: (state) => (state.CategoryReport),
  },
  actions: {
      async getApplicationCategoryReport(context, year) {
          context.commit("setLoadingState", true)
          return await apolloClient.query({
              fetchPolicy: "network-only",
              query: getApplicationCategoryReport,
              variables : {year}
          })
          .then((output) => {
              let {response, data} = output.data.getApplicationCategoryReport
              if (response.status) {
                  context.commit("setStoreItem", {
                      module : 'applicationCategoryReportManagement',
                      entity : 'CategoryReport',
                      data
                  })
                  return data;
              } else {
                  notifyError(response.message || "Failed to fetch")
              }
          }).finally(() => context.commit("setLoadingState", false))
      },
    }
  }