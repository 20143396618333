import { createTaskForOutsideWork, deleteTaskForOutsideWork, getOutsideWorkTaskDetails, updateTaskForOutsideWork} from "@/graphql/projects/outsideWork/outsideWorkTask";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
        tasks : [],
        task : {}
    },
    getters: {
        getTasks: (state) => (state.tasks),
        getTask: (state) => (state.task)
    },
    actions: {
        async getOutsideWorkTaskDetails(context, outsideWorkUniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getOutsideWorkTaskDetails,
                variables : {outsideWorkUniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.getOutsideWorkTaskDetails;

                if (response.status) {
                        context.commit("setStoreItem", {
                            module : 'outsideWorkTaskManagement',
                            entity : 'tasks',
                            data
                        })
                        return data
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        async createTaskForOutsideWork(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createTaskForOutsideWork,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.createTaskForOutsideWork;
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'outsideWorkTaskManagement',
                        entity : 'tasks',
                        uniqueKey : 'outsideWorkTaskUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        
        async updateTaskForOutsideWork(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateTaskForOutsideWork,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.updateTaskForOutsideWork;
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'outsideWorkTaskManagement',
                        entity : 'tasks',
                        uniqueKey : 'outsideWorkTaskUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },


        async deleteTaskForOutsideWork(context, outsideWorkTaskUniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteTaskForOutsideWork,
                variables: {outsideWorkTaskUniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.deleteTaskForOutsideWork;
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'outsideWorkTaskManagement',
                        entity : 'tasks',
                        uniqueKey : 'outsideWorkTaskUniqueId',
                        data,
                        remove : true,
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
    }
}