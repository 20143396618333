export default [
    {
        path: "/my-alumni-details",
        name: "my alumni details",
        component: () => import("./MyAlumniDetails.vue"),
        meta: {
            permissions: [],
        },

    },
];
