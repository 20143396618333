import { createProgram, getAllPrograms, updateProgram, deleteProgram } from "@/graphql/settings/program.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
        programs: [],
        program: {}
    },
    getters: {
        getAllPrograms: (state) => (state.programs),
        getProgram: (state) => (state.program)
    },
    actions: {
        async getAllPrograms(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getAllPrograms,
                variables : input
            })
            .then((output) => {
                let {response, data} = output.data.getAllPrograms
                
                if (response.status) {
                    if (_.isNil(input?.uniqueId)) {
                        context.commit("setStoreItem", {
                            module : 'programManagement',
                            entity : 'programs',
                            data
                        })
                        return data
                    }else {
                        context.commit("setStoreItem", {
                            module : 'programManagement',
                            entity : 'program',
                            data   : data[0]
                        })
                        return data[0]
                    }
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async createProgram(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createProgram,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.createProgram
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'programManagement',
                        entity : 'programs',
                        uniqueKey : 'programUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async updateProgram(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: updateProgram,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.updateProgram
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'programManagement',
                        entity : 'programs',
                        uniqueKey : 'programUniqueId',
                        data
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
        async deleteProgram(context, uniqueId) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteProgram,
                variables: {uniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.deleteProgram
                
                if (response.status) {
                    context.commit("updateStoreItem", {
                        module : 'programManagement',
                        entity : 'programs',
                        uniqueKey : 'programUniqueId',
                        data,
                        remove : true,
                    })
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    return data
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
    }
}