import {createProposalAttachment,deleteProposalAttachment , getProposalAttachmentsData} from "@/graphql/ResearchProposal/ResearchProposalAttachment.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
        proposalAttachments: [],
    },
    getters: {
        getAllProposalAttachments: (state) => state.proposalAttachments,
    },
    actions: {
        async getProposalAttachmentsData(context, filtering = null) {
            context.commit("setLoadingState", true);
            return await apolloClient
                .query({
                    fetchPolicy: "network-only",
                    query: getProposalAttachmentsData,
                    variables: { filtering },
                })
                .then((output) => {
                    let { response, data } = output.data.getProposalAttachmentsData;
                    if (response.status) {
                            context.commit("setStoreItem", {
                                module: "ResearchProposalAttachment",
                                entity: "proposalAttachments",
                                data: data,
                            });
                            return data;
                    } else {
                        notifyError(response.message || "Failed to fetch");
                    }
                })
                .finally(() => context.commit("setLoadingState", false));
        },

        async createProposalAttachment(context, input) {
            context.commit("setLoadingState", true);
            return await apolloClient
                .mutate({
                    fetchPolicy: "no-cache",
                    mutation: createProposalAttachment,
                    variables: { input },
                })
                .then((output) => {
                    let { response, data } = output.data.createProposalAttachment;

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: "ResearchProposalAttachment",
                            entity: "proposalAttachments",
                            uniqueKey: "proposalAttachmentUniqueId",
                            data,
                        });
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'));
                        return data;
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'));
                    }
                })
                .finally(() => context.commit("setLoadingState", false));
        },
        async deleteProposalAttachment(context, proposalAttachmentUniqueId) {
            context.commit("setLoadingState", true);
            return await apolloClient
                .mutate({
                    fetchPolicy: "no-cache",
                    mutation: deleteProposalAttachment,
                    variables: { proposalAttachmentUniqueId },
                })
                .then((output) => {
                    let { response, data } = output.data.deleteProposalAttachment;

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: "ResearchProposalAttachment",
                            entity: "proposalAttachments",
                            uniqueKey: "proposalAttachmentUniqueId",
                            data,
                            remove: true,
                        });
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'));
                        return data;
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'));
                    }
                })
                .finally(() => context.commit("setLoadingState", false));
        },
    },
};
