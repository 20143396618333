import gql from "graphql-tag";

export const getAcademicInstitutionWithProgram = gql`
  query getAcademicInstitutionWithProgram($uniqueId: String) {
    getAcademicInstitutionWithProgram(uniqueId: $uniqueId) {
      response {
        id
        status
        code
        message
      }
      data {
        institutionWithProgramUniqueId
        institutionWithProgramProgram {
          programUniqueId
          programName
        }
      }
    }
  }
`;