export default [
  {
    name: "User Management",
    path: "users",
    component: () => import("./Users.vue"),
    meta: {
      permissions: ["can_view_all_user_profile","can_activate_user_account","can_assign_role","can_revoke_access"]
    }
  },
  {
    name: "Profile Preview",
    path: "user-profile-preview/:profileUniqueId",
    component: () => import("./UserProfilePreview.vue"),
    props: true,
    meta: {
      permissions: []
    }
  },
];