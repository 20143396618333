export default [
    {
        path: '/auth',
        name: 'Auth',
        component: () => import("@/components/authentication/Index.vue"),
            meta: {
                permissions: []
            },
        children: [
            {
                path: 'register',
                name: 'Register',
                component: () => import("@/components/authentication/Register.vue"),
                    meta: {
                        permissions: []
                    },
            },

            {
                path: 'activate/:token',
                name: 'Activate Account',
                component: () => import("@/components/authentication/Activation.vue"),
                    meta: {
                        permissions: []
                    },
            },
            {
                path: 'login',
                name: 'Login',
                component: () => import("@/components/authentication/Login.vue"),
                    meta: {
                        permissions: []
                    },
            },
            {
                path: 'logout',
                name: 'Logout',
                component: () => import("@/components/authentication/Logout.vue"),
                    meta: {
                        permissions: []
                    },
            },
            {
                path: 'password-reset/:token',
                name: 'Reset Password',
                component: () => import("@/components/authentication/passwordReset.vue"),
                    meta: {
                        permissions: []
                    },
            },
            {
                path: 'password-reset-email',
                name: 'Password Reset Email',
                component: () => import("@/components/authentication/PasswordResetEmail.vue"),
                    meta: {
                        permissions: []
                    },
            },
            {
                path: '/activate_user/:token/:userId',
                name: 'Custom user activation',
                component: () => import("@/components/authentication/CustomUserActivation.vue"),
                    meta: {
                        permissions: []
                    },
            }
        ]
    }
]