import gql from "graphql-tag";
const data = `data {
    applicationUniqueId
    applicationCategory
    applicationDescription
    applicationIntakeStartDate
    applicationIntakeEndDate
    applicationCapacity
    applicationTheme
    applicationCreatedDate
}`
const response = `response {
    status
    code
    message
}`

export const getApplicationRegistration = gql`query getApplicationRegistration($uniqueId: String) {
    getApplicationRegistration(uniqueId: $uniqueId) {
      ${response}
      ${data}
    }
}
`;
export const createApplicationRegistration = gql`mutation CreateApplicationRegistration($input: ApplicationInputObject) {
   createApplicationRegistration(input: $input) {
        ${response}
        ${data}
    }
}`;

export const updateApplicationRegistration = gql`mutation updateApplicationRegistration($input: ApplicationInputObject)  {
    updateApplicationRegistration(input: $input) {
        ${response}
        ${data}
    }
}`;

export const deleteApplicationRegistration = gql`mutation deleteApplicationRegistration($uniqueId: String!) {
    deleteApplicationRegistration(uniqueId: $uniqueId) {
        ${response}
        ${data}
    }
}`;  