<template>
    <div class="grid grid-cols-12 gap-2">
         <div class="flex items-center justify-between col-span-12">
        <AddAdvertisement :Dialog="dialog" :DialogTitle="dialogTitle" :EditItemData="advertisement" @close="closeDialog()"/>
         <PageHead class="col-span-12" pageTitle="Advertisements" />
                <template v-if="hasAccess(['can_add_anouncement'])">
                    <button  class="mr-2 shadow-md btn btn-primary" @click="createDialog()">
                        <Plus class="flex items-center justify-center w-5 h-5 stroke-current lucide" />
                         Add Advertisement
                    </button>
                </template>
                </div>
        <!-- <PageHead class="col-span-12" :buttonText="buttonText" :pageTitle="pageTitle" @button:click="createDialog()"/>-->
         <div v-if="advertisements?.length == 0" class="col-span-12 p-5 mt-5 text-center rounded intro-x zoom-in"
            :class="isDark ? 'bg-[rgb(var(--color-darkmode-600))] text-white' : 'bg-white'">
            <span class="text-overline">{{ $t("labels.noData") }}</span>
        </div> 
        <!-- BEGIN: Advertisement  -->
        <div class="col-span-12" v-for="(advertisement, index) in advertisements" :key="index"  >
            <div class="intro-y box mt-5 lg:mt-0">
                <div class="p-5">
                    <div class="grid grid-cols-12">
                        <div class="lg:col-span-7 col-span-12 2xl:col-span-12">
                            <div class="grid grid-cols-12">
                                <span class="col-span-4 font-medium text-cyan-700">Headline</span>
                                <span class="col-span-1 font-medium text-cyan-700">:</span> 
                                <span class="col-span-7 font-medium">{{ advertisement?.advertisementHeadline }}</span>
                            </div>
                            <div class="grid grid-cols-12">
                                <span class="col-span-4 font-medium text-cyan-700">Email</span>
                                <span class="col-span-1 font-medium text-cyan-700">:</span> 
                                <span class="col-span-7 font-medium">{{ advertisement?.advertisementContactEmail }}</span>
                            </div>
                            <div class="grid grid-cols-12">
                                <span class="col-span-4 font-medium text-cyan-700">Approval status</span>
                                <span class="col-span-1 font-medium text-cyan-700">:</span> 
                                <span class="col-span-7 font-medium">{{ advertisement?.advertisementApproval }}</span>
                            </div>
                            <div class="grid grid-cols-12">
                                <span class="col-span-4 font-medium text-cyan-700">Start date</span>
                                <span class="col-span-1 font-medium text-cyan-700">:</span> 
                                <span class="col-span-7 font-medium">{{ advertisement?.advertisementStartDate }}</span> 
                            </div>
                            <div class="grid grid-cols-12">
                                <span class="col-span-4 font-medium text-cyan-700">End date</span>
                                <span class="col-span-1 font-medium text-cyan-700">:</span> 
                                <span class="col-span-7 font-medium">{{ advertisement?.advertisementEndDate }}</span> 
                            </div>
                            <div class="grid grid-cols-12">
                                <span class="col-span-4 font-medium text-cyan-700">Description</span>
                                <span class="col-span-1 font-medium text-cyan-700">:</span> 
                                <span class="col-span-7 font-medium">{{ advertisement?.advertisementDescription }}</span> 
                            </div>
                        </div>
                        <div class="lg:col-span-5 col-span-12 2xl:col-span-12 flex justify-end items-center">
                            <button class="btn btn-primary-soft btn-sm dark:text-white" @click="showFile(advertisement?.advertisementImage)" v-if="advertisement?.advertisementImage">
                                <Eye class="w-4 h-4 mr-1 stroke-current lucide"/> View
                            </button>
                            <button class="btn btn-danger-soft btn-sm mx-1 dark:text-white" @click="deleteAdvertisement(advertisement)">
                                <TrashAlt class="lucide w-3 h-3 stroke-current mr-1" /> Delete
                            </button>
                            <button class="btn btn-primary-soft btn-sm mx-1 dark:text-white" @click="editAdvertisement(advertisement)">
                                <Edit class="lucide w-3 h-3 stroke-current mr-1"/> Edit
                            </button>
                        </div>                            
                    </div>  
                </div>
            </div>
        </div>
        <!-- END: Advertisement  -->
    </div>
</template>

<script>
import { confirmDialog } from "@/plugins/notification.service";
import PageHead from "@/views/shared/PageHead.vue";
import { mapGetters } from 'vuex';
import AddAdvertisement from './AddAdvertisement.vue';
export default {
    components: {
        AddAdvertisement,
        PageHead
    },
    data() {
        return {
            dialog: false,
            search: "",
            buttonText: "Add advertisement",
            pageTitle: "Advertisements",
            advertisement: {}
        }
    },
    methods: {
        createDialog() {
            this.$nextTick(() => {
                this.dialogTitle = "Add";
                this.dialog = true;
            });
        },

        editAdvertisement(advertisement){
            this.$nextTick(() => {
                this.dialogTitle = "Update";
                this.dialog = true;
                this.advertisement = advertisement
            });
        },

        async deleteAdvertisement(data){
            await confirmDialog(`${data?.advertisementHeadline} is about to be deleted`)
            .then(async (agree) => {
            if (agree) {
                await this.$store.dispatch("deleteAdvertisement", data?.advertisementUniqueId)
            }
        })
        },

        closeDialog(){
            this.dialog = false
            this.dialogTitle = ""
        },
    },
    computed: {
        ...mapGetters({
            advertisements: "getAdvertisements"
        })
    },
    mounted(){
        this.$store.dispatch("getAdvertisements", { endDate: null, exactlyDate: null, startDate:null })
    }
};
</script>