
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";
import i18n from "@/i18n";
import {
  getUserInformation,
  getApplicationFeedback,
  createApplicationFeedback,
  createApplicationFeedbackReplay,
} from "../../graphql/application/applicationFeedback.graphql";


export default {
  state: {
    applicationFeedbacks:[],
    applicationFeedback:{},
  },
  getters: {
    getApplicationFeedbacks: (state) => state.applicationFeedbacks,
    getApplicationFeedback: (state) => state.applicationFeedback,
  },
  actions: {
    async getApplicationFeedbacks(context, applicationRequestUniqueId) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getApplicationFeedback,
          variables: applicationRequestUniqueId ,
        })
        .then((output) => {
          let { response, data } = output.data.getApplicationFeedback;

          if (response.status) {
              context.commit("setStoreItem", {
                module: "applicationFeedbackManagement",
                entity: "applicationFeedbacks",
                data,
              });
              return data;
            
          } else {
            notifyError(response.message || "Failed to fetch");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
    async createApplicationFeedback(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: createApplicationFeedback,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.createApplicationFeedback;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "applicationFeedbackManagement",
              entity: "applicationFeedbacks",
              uniqueKey: "applicationFeedbackUniqueId",
              data,
            });
            notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
            return data;
          } else {
            notifyError(response.message || i18n.t('labels.operationFailed'));
          }
        })
        .finally(() => 
          context.commit("setLoadingState", false),
        );
    },
    async createApplicationFeedbackReplay(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: createApplicationFeedbackReplay,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.createApplicationFeedbackReplayMutation;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "applicationFeedbackManagement",
              entity: "applicationFeedbacks",
              uniqueKey: "applicationFeedbackUniqueId",
              data,
            });
            notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
            return data;
          } else {
            notifyError(response.message || i18n.t('labels.operationFailed'));
          }
        })
        .finally(() => 
          context.commit("setLoadingState", false),
        );
    },
  },
};
