export default [
    {
        path: "/my-diaspora-details",
        name: "my diaspora details",
        component: () => import("./myDiasporaDetailsView.vue"),
        meta: {
            permissions: [],
        },

    },

    {
        path: "/other-diaspora",
        name: "Other diaspora",
        component: () => import("./OtherDiasporaList.vue"),
        meta: {
            permissions: [],
        },

    },
];
