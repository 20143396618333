export default {
  state: {
    institutionTypeEnum: [
      { name: "Higher Learning", value: "HIGHER_LEARNING" },
      { name: "Health Insurance", value: "HEALTH_INSURANCE" },
    ],
    projectTypeEnum: [
      { name: "Practical Training (PT) Project", value: "PT_PROJECT" },
      { name: "RIDC Project", value: "STAFF_PROJECT" },
    ],
    skillType: [
      { name: "Beginner", value: "BEGINNER" },
      { name: "Intermediate", value: "INTERMEDIATE" },
      { name: "Advanced", value: "ADVANCED" },
    ],
    genderEnum: [
      { value: "MALE", name: "Male" },
      { value: "FEMALE", name: "Female" },
    ],
    maritalStatusEnum: [
      { name: "Married", value: "MARRIED" },
      { name: "Single", value: "SINGLE" },
      { name: "Divorced", value: "DIVORCED" },
    ],
    projectWeightEnum: [
      { value: "LIGHT", name: "Light (1 - 70)" },
      { value: "MEDIUM", name: "Medium (71 - 100)" },
      { value: "HEAVY", name: "Heavy (101 - 200)" },
    ],
    contactDetailsType: [
      { name: "Personal *", value: "PERSONAL" },
      { name: "Emergency *", value: "EMERGENCY" },
      { name: "Guardian", value: "GUARDIAN" },
      { name: "Other", value: "OTHER" },
    ],

    languageEnum: [
      { name: "Native", value: "NATIVE" },
      { name: "Intermediate", value: "INTERMEDIATE" },
      { name: "Advanced", value: "ADVANCED" },
    ],
    qualificationModeOfLearningEnum: [
      { name: "Online", value: "ONLINE" },
      { name: "Blended", value: "BLENDED" },
      { name: "Physical", value: "PHYSICAL" },
    ],
    academicQualificationStatus: [
      { name: "Graduated", value: "COMPLETED" },
      { name: "On Progress", value: "ON_PROGRESS" },
    ],
    academicQualificationCurrentYear: [
      { name: "Second Year", value: "SECOND_YEAR" },
      { name: "Third Year", value: "THIRD_YEAR" },
      { name: "First Year", value: "FIRST_YEAR" },
      { name: "Fourth Year", value: "FOURTH_YEAR" },
      { name: "Fifth Year", value: "FIFTH_YEAR" },
      { name: "Not Applicable", value: "NOT_APPLICABLE" },
    ],
    academicQualificationCourseDuration: [
      { name: "One Year", value: "ONE_YEAR" },
      { name: "Two Years", value: "TWO_YEARS" },
      { name: "Three Years", value: "THREE_YEARS" },
      { name: "Four Years", value: "FOUR_YEARS" },
      { name: "Five Years", value: "FIVE_YEARS" },
      { name: "Not Applicable", value: "NOT_APPLICABLE" },
    ],
    applicationEnum: [
      { name: "Practical Training", value: "PT" },
      { name: "Volunteering", value: "VOLUNTEER" },
      { name: "Mentorship", value: "MENTOR" },
      { name: "Mentee", value: "MENTEE" },
      { name: "Researcher", value: "RESEARCHER" },
      { name: "withdraw", value: "WITHDRAWN" },
    ],
    allowedFileTypes: [
      { type: "pdf", mime: "application/pdf" },
      { type: "txt", mime: "text/plain" },
      { type: "xla", mime: "application/msexcel" },
      { type: "xls", mime: "application/msexcel" },
      { type: "xls", mime: "application/vnd.ms-excel" },
      { type: "xlc", mime: "application/vnd.ms-excel" },
      { type: "jpe", mime: "image/jpeg" },
      { type: "avif", mime: "image/avif" },
      { type: "ico", mime: "image/vnd.microsoft.icon" },
      { type: "svg", mime: "image/svg+xml" },
      { type: "jpeg", mime: "image/jpeg" },
      { type: "webp", mime: "image/webp" },
      { type: "jpg", mime: "image/jpeg" },
      { type: "fh4", mime: "image/x-freehand" },
      { type: "fh5", mime: "image/x-freehand" },
      { type: "fhc", mime: "image/x-freehand" },
      { type: "gif", mime: "image/gif" },
      { type: "bmp", mime: "image/bmp" },
      { type: "png", mime: "image/png" },
      { type: "pps", mime: "application/mspowerpoint" },
      { type: "ppt", mime: "application/mspowerpoint" },
      { type: "ppz", mime: "application/mspowerpoint" },
      { type: "pot", mime: "application/mspowerpoint" },
      { type: "tiff", mime: "image/tiff" },
      { type: "tif", mime: "image/tiff" },
      { type: "doc", mime: "application/msword" },
      { type: "odt", mime: "application/vnd.oasis.opendocument.text" },
      { type: "ods", mime: "application/vnd.oasis.opendocument.spreadsheet" },
      {
        type: "docx",
        mime: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      },
      {
        type: "pptx",
        mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
    ],
    levelOfPerformance: [
      { name: "GPA", value: "GPA" },
      { name: "Merit System", value: "MERIT_SYSTEM" },
      { name: "Division System", value: "DIVISION_SYSTEM" },
    ],
    accademicLevel: [
      { name: "CSEE", value: "CSEE" },
      { name: "ACSEE", value: "ACSEE" },
      { name: "Certificate", value: "CERTIFICATE" },
      { name: "Diploma", value: "DIPLOMA" },
      { name: "Bachelor", value: "BACHELOR" },
      { name: "Masters", value: "MASTERS" },
      { name: "PHD", value: "PHD" },
      { name: "others", value: "OTHERS" },
    ],
    divisionEnum: [
      { name: "One", value: "ONE" },
      { name: "Two", value: "TWO" },
      { name: "Three", value: "THREE" },
      { name: "Four", value: "FOUR" },
    ],
    meritEnum: [
      { name: "Distinction", value: "DISTINCTION" },
      { name: "Merit", value: "MERIT" },
      { name: "credit", value: "CREDIT" },
      { name: "Pass", value: "PASS" },
      { name: "Four", value: "FOUR" },
    ],
    ideaNature: [
      { name: "Industrial Standard", value: "INDUSTRIAL_STANDARD" },
      { name: "Proof Of Concept", value: "PROOF_OF_CONCEPT" },
      { name: "Public Service Delivery", value: "PUBLIC_SERVICE_DELIVERY" },
    ],
    ideaType: [
      { name: "IOT", value: "IOT" },
      { name: "Mobile Application", value: "MOBILE_APPLICATION" },
      { name: "Web Application", value: "WEB_APPLICATION" },
    ],
    ideaStage: [
      { name: "Approval", value: "APPROVAL" },
      { name: "Conceptulization", value: "CONCEPTUALIZATION" },
      { name: "Registration", value: "REGISTRATION" },
    ],
    ideaApprovalStatus: [
      { name: "Approved", value: "APPROVED" },
      { name: "Disapproved", value: "DISAPPROVED" },
    ],

    requestType: [
      { name: "Absent Request", value: "ABSENT_REQUEST" },
      { name: "Leave Request", value: "LEAVE_REQUEST" },
    ],

    requestPurpose: [
      { name: "Annual Leave", value: "ANNUAL_LEAVE" },
      { name: "Emergency", value: "EMERGENCY" },
      { name: "Emergency Leave", value: "EMERGENCY_LEAVE" },
      { name: "Hospital", value: "HOSPITAL" },
      { name: "Personal", value: "PERSONAL" },
      { name: "School", value: "SCHOOL" },
      { name: "Short Leave", value: "SHORT_LEAVE" },
      { name: "Unpaid Leave", value: "UNPAID_LEAVE" },
    ],

    requestApprovalStatus: [
      { name: "APPROVED", value: "APPROVED" },
      { name: "DISAPPROVED", value: "DISAPPROVED" },
    ],
    approvalDecisionType: [
      { name: "Approved", value: "APPROVED" },
      { name: "Disapproved", value: "DISAPPROVED" },
    ],
    internalDiscussionEnum: [
      { name: "Group Of People", value: "GROUP_OF_PEOPLE" },
      { name: "Public", value: "PUBLIC" },
    ],
    ideaProgressStatus: [
      { name: "Completed", value: "COMPLETED" },
      { name: "On Hold", value: "ON_HOLD" },
      { name: "On Progress", value: "ON_PROGRESS" },
    ],

    announcementType: [
      { name: "Private", value: "PRIVATE" },
      { name: "Public", value: "PUBLIC" },
    ],

    advertisementApproval: [
      { name: "Approved", value: "APPROVED" },
      { name: "Disapproved", value: "DISAPPROVED" },
    ],
    applicationRequestStatus: [
      { name: "Pending", value: "PENDING" },
      { name: "APPROVED", value: "APPROVED" },
      { name: "DISAPPROVED", value: "DISAPPROVED" },
      { name: "CALLED FOR INTERVIEW", value: "CALLED_FOR_INTERVIEW" },
      { name: "ATTENDED FOR INTERVIEW", value: "ATTENDED_FOR_INTERVIEW" },
      {
        name: "NOT ATTENDED FOR INTERVIEW",
        value: "NOT_ATTENDED_FOR_INTERVIEW",
      },
    ],
    taskProgressStatus: [
      { name: "Completed", value: "COMPLETED" },
      { name: "On Hold", value: "ON_HOLD" },
      { name: "On Progress", value: "ON_PROGRESS" },
    ],
    disabilityStatus: [
      { name: "Has Disability", value: "HAS_DISABILITY" },
      { name: "No Disability", value: "NO_DISABILITY" },
    ],
    feedbackCategory: [
      { name: "Call For Interview", value: "CALL_FOR_INTERVIEW" },
      { name: "Normal Feedback", value: "NORMAL_FEEDBACK" },
    ],
    attandanceFilter: [
      { name: "this year", value: "THIS_YEAR" },
      { name: "this month", value: "THIS_MONTH" },
      { name: "this week", value: "THIS_WEEK" },
    ],

    requestApplication: [
      { name: "ATTENDED_FOR_INTERVIEW", value: "ATTENDED_FOR_INTERVIEW" },
      {
        name: "NOT_ATTENDED_FOR_INTERVIEW",
        value: "NOT_ATTENDED_FOR_INTERVIEW",
      },
    ],
    attandanceEnums: [
      { name: "PT", value: "PT" },
      { name: "VOLUNTEER", value: "VOLUNTEER" },
    ],
    attandanceEnum: [
      { name: "PT", value: "PT" },
      { name: "VOLUNTEER", value: "VOLUNTEER" },
      { name: "STAFF", value: "STAFF" },
      { name: "REGISTERED_USER", value: "REGISTERED_USER" },
      { name: "SYSTEM_ADMIN", value: "SYSTEM_ADMIN" },
    ],
    galleryCollectionAccessEnum: [
      { name: "PRIVATE", value: "PRIVATE" },
      { name: "PUBLIC", value: "PUBLIC" },
    ],
    currentMembersEnum: [
      { name: "VOLUNTEER", value: "VOLUNTEER" },
      { name: "STAFF", value: "STAFF" },
      { name: "STUDENTS", value: "STUDENTS" },
      { name: "PT", value: "PT" },
    ],
  },
  getters: {
    getInstitutionType: (state) => state.institutionTypeEnum,
    getProjectType: (state) => state.projectTypeEnum,
    getContactDetailsType: (state) => state.contactDetailsType,
    getGenderEnum: (state) => state.genderEnum,
    getMaritalStatusEnum: (state) => state.maritalStatusEnum,
    SKILL_TYPE: (state) => state.skillType,
    getLanguageLevelEnum: (state) => state.languageEnum,
    getQualificationModeOfLearningEnum: (state) =>
      state.qualificationModeOfLearningEnum,
    getAcademicQualificationStatus: (state) =>
      state.academicQualificationStatus,
    getAcademicQualificationCurrentYear: (state) =>
      state.academicQualificationCurrentYear,
    getAllowedFileTypes: (state) => state.allowedFileTypes,
    getApplicationEnum: (state) => state.applicationEnum,
    getAcademicQualificationCourseDuration: (state) =>
      state.academicQualificationCourseDuration,
    getlevelOfPerformance: (state) => state.levelOfPerformance,
    getAcademicLevel: (state) => state.accademicLevel,
    getAcademicDivision: (state) => state.divisionEnum,
    getMerit: (state) => state.meritEnum,
    getIdeaType: (state) => state.ideaType,
    getIdeaNature: (state) => state.ideaNature,
    getIdeaStage: (state) => state.ideaStage,
    getIdeaApprovalStatus: (state) => state.ideaApprovalStatus,
    getRequestType: (state) => state.requestType,
    getRequestPurpose: (state) => state.requestPurpose,
    getRequestApprovalStatus: (state) => state.requestApprovalStatus,
    getAnnouncementType: (state) => state.announcementType,
    getInternalDiscussionEnum: (state) => state.internalDiscussionEnum,
    getApprovalDecisionType: (state) => state.approvalDecisionType,
    getIdeaProgressStatus: (state) => state.ideaProgressStatus,
    getProjectWeights: (state) => state.projectWeightEnum,
    getapplicationApprovalIsFinal: (state) => state.applicationApprovalIsFinal,
    getAdvertisementApproval: (state) => state.advertisementApproval,
    getTaskProgressStatus: (state) => state.taskProgressStatus,
    getDisabilityStatus: (state) => state.disabilityStatus,
    getApplicationRequestStatus: (state) => state.applicationRequestStatus,
    getFeedbackCategories: (state) => state.feedbackCategory,
    getAttandanceFilter: (state) => state.attandanceFilter,
    getrequestApplication: (state) => state.requestApplication,
    getattandance: (state) => state.attandanceEnum,
    galleryCollection: (state) => state.galleryCollectionAccessEnum,
    getCurrentMembersEnum: (state) => state.currentMembersEnum,
  },
};
