export default [
  {
    path: "setting-research-areas",
    name: "Setting research areas",
    component: () => import("./researchArea.vue"),
    meta: {
      permissions: [
        "can_add_locations",
      ],
    },
  },
];
