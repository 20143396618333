import gql from "graphql-tag";
export const getPersonalCv = gql`query getPersonalCv {
  getPersonalCv {
    response {
      id
      status
      code
      message
    }
    data
  }
}`;