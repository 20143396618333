import { createProjectFolder, deleteProjectFolder, getFolderDetails} from "@/graphql/projects/projectFolder.graphql.js";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
        folders : [],
        folder : {}
    },
    getters: {
        getFolderDetails: (state) => state.folders,
        getFolder: (state) => state.folder
    },
    actions: {
        async getFolderDetails(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getFolderDetails,
                variables :  input 
            })
            .then((output) => {
                let {response, data} = output.data.getFolderDetails
                if (response.status) {
                    if (_.isNil(input.folderUniqueId)) {
                        context.commit("setStoreItem", {
                            module : 'projectFolderManagement',
                            entity : 'folders',
                            data
                        })
                        return data
                    } else {
                        context.commit("setStoreItem", {
                            module : 'projectFolderManagement',
                            entity : 'folder',
                            data   : data[0]
                        })
                        return data[0]
                    }
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        async createProjectFolder(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: createProjectFolder,
                variables: {input}
            })
            .then((output) => {
                let {response, data} = output.data.createProjectFolder
                if (response.status) {
                    notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                    if(_.isNil(input.parentFolderUniqueId)){
                        context.commit("updateStoreItem", {
                            module : 'projectFolderManagement',
                            entity : 'folders',
                            uniqueKey : 'folderUniqueId',
                            data
                        })
                    }else{
                        context.dispatch("getFolderDetails", {folderUniqueId:input.parentFolderUniqueId })
                       
                    }
                    return data
                    
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },

        async deleteProjectFolder(context, input) {
            context.commit("setLoadingState", true)
            return await apolloClient.mutate({
                fetchPolicy: "no-cache",
                mutation: deleteProjectFolder,
                variables: {uniqueId:input.folderUniqueId}
            })
            .then((output) => {
                let {response, data} = output.data.deleteProjectFolder
                if (response.status) {
                    var dataLength1 = input.parent ? input?.parent.length : 0;
                    if(dataLength1 == 0){
                        context.commit("updateStoreItem", {
                            module : 'projectFolderManagement',
                            entity : 'folders',
                            uniqueKey : 'folderUniqueId',
                            data : {folderUniqueId: input.folderUniqueId},
                            remove : true,
                        })
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
                        
                    }else{
                        context.dispatch("getFolderDetails",{folderUniqueId:input.parent});
                    }
                    return data;
                } else {
                    notifyError(response.message || i18n.t('labels.operationFailed'))
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
    }
}