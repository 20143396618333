import { ApolloClient } from 'apollo-client'
import { setContext } from "apollo-link-context";
import { createHttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { from } from "apollo-link";
import { InMemoryCache } from 'apollo-cache-inmemory'
import VueApollo from 'vue-apollo'
import store from './store'
import router from './router';
import { notifyInfoLarge } from './plugins/notification.service';

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: process.env.VUE_APP_GRAPHQL_HTTP,
})
const authLink = setContext(async (_, { headers }) => {
  const token = store.getters.getTokenState || null
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : null,
    }
  }
});
const errorLink = onError(({ graphQLErrors, networkError,  operation, forward }) => {
  if (graphQLErrors){
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        if (Object.hasOwn(extensions?.exception, "message")) {
          if ( extensions?.exception?.message.includes("Signature has expired") ) {
            if (store.getters['getLoggedUser']['rememberMe']) {
              store.dispatch("refreshToken",store.getters.getLoggedUser["refreshToken"]);
              notifyInfoLarge(`Refreshing current session...`)
              // Modify the operation context with a new token
              const oldHeaders = operation.getContext().headers;
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  authorization: "JWT " + store.getters.getTokenState,
                },
              });
              // Retry the request, returning the new observable
              return forward(operation);
            } else {
              notifyInfoLarge(`Session Expired. Logging off!`)
              store.commit("RESET_STATE", {root: true});
              router.push("/").catch(() => {});
            }
          }
        }
      }
    );
  }
});
// Create the apollo client
export const apolloClient = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({addTypename: false}),
})

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})