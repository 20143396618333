import gql from "graphql-tag";

let response = `response{
   id
   status
   code
   message
}`;


let data = `
data{
aluminSkillCategoryUniqueId
aluminSkillCategoryName
}
  `

export const  getAluminumSkillCategories = gql`
query GetAluminumSkillCategories($uniqueId: String) {
  getAluminumSkillCategories(uniqueId: $uniqueId) {
    ${response}
    ${data}
  }
}
`
export const  createAluminumProfessionSkillsCategories =gql`
mutation CreateAluminumProfessionSkillsCategories($input: AluminumProfessionSkillsCategoriesInputObject!) {
  createAluminumProfessionSkillsCategories(input: $input) {
        ${response}
        ${data}
  }
}
`

export const  updateAluminumProfessionSkillsCategories = gql`
mutation UpdateAluminumProfessionSkillsCategories($input: AluminumProfessionSkillsCategoriesInputObject!) {
  updateAluminumProfessionSkillsCategories(input: $input) {
    ${response}
    ${data}
  }
}
`

export const deleteAluminumProfessionSkillsCategories = gql`
mutation DeleteAluminumProfessionSkillsCategories($uniqueId: String!) {
  deleteAluminumProfessionSkillsCategories(uniqueId: $uniqueId) {
    ${response}
    ${data}
  }
}
`


