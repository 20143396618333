import {
  deleteIdeaSubTask,
  createIdeaSubTask,
  updateIdeaSubTask,
  getIdeaSubTask,
} from "@/graphql/ideas/IdeaSubtask.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
  state: {
    ideatasks: [],
    ideatask: {},
  },
  getters: {
    getIdeaSubTasks: (state) => state.ideatasks,
    getIdeaSubTask: (state) => state.ideatask,
  },
  actions: {
    async getIdeaSubTasks(context, data) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getIdeaSubTask,
          variables: { 
            taskUniqueId : data?.ideaTaskUniqueId,
            uniqueId: data?.ideaSubTaskUniqueId
          },
        })
        .then((output) => {
          let { response, data } = output.data.getIdeaSubTask;

          if (response.status) {
            context.commit("setStoreItem", {
              module: "ideaSubtask",
              entity: "ideatasks",
              data,
            });
            return data;
          } else {
            notifyError(response.message || "Failed to fetch");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async createIdeaSubTask(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation:createIdeaSubTask,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.createIdeaSubTask;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "ideaSubtask",
              entity: "ideatasks",
              uniqueKey: "ideaSubTaskUniqueId",
              data,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async updateIdeaSubTask(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: updateIdeaSubTask,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.updateIdeaSubTask;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "ideaSubtask",
              entity: "ideatasks",
              uniqueKey: "ideaSubTaskUniqueId",
              data,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async deleteIdeaSubTask(context, uniqueId) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: deleteIdeaSubTask,
          variables: { uniqueId },
        })
        .then((output) => {
          let { response, data } = output.data.deleteIdeaSubTask;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "ideaSubtask",
              entity: "ideatasks",
              uniqueKey: "ideaSubTaskUniqueId",
              data,
              remove: true,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
  },
};
