
<template>

  <div class="grid grid-cols-12 gap-5 intro-y">
    <div class="flex items-center justify-between col-span-12">
      <button class="mr-2 btn btn-primary-soft" @click="$router.back()">
        <ArrowLeftCircle class="w-4 h-4 mr-1 stroke-current lucide" />
        {{ $t('labels.back') }}
      </button>
      <span class="mr-auto text-lg font-medium text-primary">{{ $t('location.labels.districts') }}</span>
    </div>

    <div class="col-span-12 lg:col-span-12">
      <div class="flex justify-between intro-y">
        <div class="relative">
          <input type="text" class="w-full px-4 py-2 pr-10 form-control lg:w-64 box" placeholder="Search item...">
          <i class="absolute inset-y-0 right-0 w-4 h-4 my-auto mr-3 text-slate-500" data-lucide="search"></i>
        </div>

      </div>
      <div class="grid grid-cols-12 gap-5 mt-5 ">
        <div v-if="districts?.length == 0" class="col-span-12 p-5 text-center rounded intro-x zoom-in"
          :class="isDark ? 'bg-[rgb(var(--color-darkmode-600))] text-white' : 'bg-white'">
          <span class="text-overline">{{ $t("labels.noData") }}</span>

        </div>

        <div class="col-span-12 overflow-auto intro-y lg:overflow-visible">
          <table class="table -mt-2 table-report">
            <thead>
              <tr>
                <th v-for="header in $t('iterables.districtTableHeaders')" :key="header.name"
                  class="whitespace-nowrap dark:text-white">
                  {{ header.name }}
                </th>
              </tr>
            </thead>
            <tbody
              class="border-b text-slate-700 dark:text-slate-300 bg-slate-100 dark:bg-darkmode-400/70 border-slate-200/60 dark:border-darkmode-400">
              <tr
                class="border-b intro-x text-slate-600 dark:text-slate-500 bg-slate-100 dark:bg-darkmode-400/70 border-slate-200/60 dark:border-darkmode-400"
                v-for="district,index in districts" :key="district.districtUniqueId">
                <td class="w-40">
                  <div class="flex">{{ index + 1 }}</div>
                </td>
                <td>
                  <a href="javascript:;" class="font-medium whitespace-nowrap">
                    {{ district.districtName }} </a>
                </td>
                <td class="w-56 table-report__action">
                  <a class="flex items-center mr-3" @click="showItem(district)">
                    <Eye class="w-4 h-4 mr-1 lucide lucide-check-square" />
                    {{ $t('location.buttons.viewDistrict.title') }}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

</template>

<script>


export default {
  data() {
    return {

      selectedItem: {},
      title: ""
    }
  },
  props: ["regionUniqueId"],


  computed: {
    districts() {
      return this.$store.getters.getAllDistricts
    }
  },
  methods: {
    showItem(district) {
      this.$router.push({
        name: "Wards",
        params: {
          districtUniqueId: district.districtUniqueId
        }
      })
    }
  },
  async mounted() {
    await this.$store.dispatch("getDistricts", this.regionUniqueId)
    /*this.selectedItem = {
      countryUniqueId: this.countryUniqueId,
      regionUniqueId: this.regionUniqueId
    }*/
  }
}
</script>

<style>

</style>

