<template>
	<div class="bg-slate-100 dark:bg-darkmode-700 bg-opacity-70 rounded-lg p-1 md:p-5">
		<div class="flex items-center" v-if="hasBackButton">
			<button class="btn btn-primary-soft dark:text-white" @click="gotoRoute('Home')">
				<ArrowLeftCircle class="w-5 h-5 mr-2" /> Back
			</button>
		</div>
		<div class="grid grid-cols-12 gap-4 my-4">
			<template v-for="nav in cardItems">
				<div class="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 cursor-pointer" v-if="hasAccess(nav?.permissions)" :key="nav.title">
					<router-link tag="div" :to="nav.to"
						class="overflow-hidden rounded-lg shadow border border-cyan-50 bg-white dark:bg-darkmode-500 dark:text-slate-200 text-cyan-700 hover:text-white hover:bg-cyan-700 hover:scale-105 p-3 duration-300 transition cursor ease-in-out delay-50" >
						<div class="flex justify-center items-center">
							<div class="grid grid-cols-1 gap-4">
								<div class="flex items-center justify-center">
									<component v-if="nav?.icon" :is="nav?.icon" class="lucide w-8 h-8" />
								</div>
								<h1 class="font-medium font-sans text-base my-3">{{ nav?.title }}</h1>
							</div>
						</div>
					</router-link>
				</div>
			</template>
		</div>

	</div>
</template>

<script>
import { ArrowLeftCircle } from 'lucide-vue';

export default {
	props: {
		cardItems: {
			required: true,
			type: Array,
			default: []
		},
		hasBackButton: {
			required: false,
			type: Boolean,
			default: true
		}
	},
	components: { ArrowLeftCircle }
}
</script>