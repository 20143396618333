import gql from "graphql-tag";

export const getAllLanguages = gql`
  query getAllLanguages($uniqueId: String) {
    getAllLanguages(uniqueId: $uniqueId) {
      response {
        code
        status
        message
      }
      data {
        languageUniqueId
        languageName
        createdDate
      }
    }
  }
`;

export const updateLanguage = gql`
  mutation updateLanguage($input: LanguageInputObject) {
    updateLanguage(input: $input) {
      response {
        code
        status
        message
      }
      data {
        languageUniqueId
        languageName
        createdDate
      }
    }
  }
`;

export const createLanguage = gql`
  mutation createLanguage($input: LanguageInputObject) {
    createLanguage(input: $input) {
      response {
        code
        status
        message
      }
      data {
        languageUniqueId
        languageName
        createdDate
      }
    }
  }
`;

export const deleteLanguage = gql`
  mutation deleteLanguage($uniqueId: String!) {
    deleteLanguage(uniqueId: $uniqueId) {
      response {
        code
        status
        message
      }
      data {
        languageUniqueId
        languageName
        createdDate
      }
    }
  }
`;