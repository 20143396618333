
import { getPersonalCv } from "@/graphql/profile_details/personalCv.graphql";
import { notifyError } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
        personalResume: "",
    },
    mutations: {
        setPersonalResume:(state, data) => state.personalResume = data,
    },
    getters: {
        getPersonalResume: (state) => (state.personalResume),
    },
    actions: {
        async getPersonalCv(context) {
            context.commit("setLoadingState", true)
            await apolloClient.query({
                query: getPersonalCv,
            }).then((output) => {
                let {data, response} = output.data.getPersonalCv
                if (response.status) {
                    context.commit("setPersonalResume", data)
                } else {
                    notifyError(response.message)
                }
            }).catch(async (error) => {
                notifyError(error)
                console.error(error);
            })
            .finally(() => context.commit("setLoadingState", false))
        }
    }
}