import gql from "graphql-tag";

const skillsQualificationFields = `
  skillsQualificationUniqueId
  skillsQualificationSkillLevel
  skillsQualificationCreatedDate
  skillsQualificationSkillDescription
  skillsQualificationSkillCategory{
    skillCategoryName
    skillCategoryUniqueId
  }
  skillsQualificationSkillSubCategory{
    skillSubCategoryName
    skillSubCategoryUniqueId
  }`;

export const getSkillQualification = gql`query getSkillQualification($uniqueId:String,$userProfileUniqueId:String){
    getSkillQualification(uniqueId:$uniqueId, userProfileUniqueId:$userProfileUniqueId){
      data{
       ${skillsQualificationFields}
      }
      response{
        message
        id
        status
        code
      }
    }
  }`


  export const createSkillQualification = gql` mutation createSkillQualification($input: SkillsQualificationInputObject){
    createSkillQualification(input:$input){
      data{
       ${skillsQualificationFields}
      }
      response{
        code
        message
        status
        id
      }
    }
  }`

  
  export const deleteSkillQualification = gql`  mutation deleteSkillQualification($skillsQualificationUniqueId:String!){
    deleteSkillQualification(skillsQualificationUniqueId:$skillsQualificationUniqueId){
      data{
       ${skillsQualificationFields}
      }
      response{
        code
        message
        status
        id
      }
    }
  }`
  
 
   
  export const updateSkillQualification = gql`mutation updateSkillQualification($input: SkillsQualificationInputObject){
    updateSkillQualification(input:$input){
      data{
       ${skillsQualificationFields}
      }
      response{
        code
        message
        status
        id
      }
    }
  }`
  
  
 