import gql from "graphql-tag";

const deviceEnrollmentFields = `
    deviceId
    deviceName
    deviceUniqueId
`;

const responseFields = `
    code
    id
    message
    status
`;

export const createDevice = gql`
mutation CreateDevice($input: DeviceInputObject) {
    createDevice(input: $input) {
            data {
                ${deviceEnrollmentFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const UpdateDevice = gql`
mutation UpdateDevice($input: DeviceInputObject) {
    updateDevice(input: $input) {
            data {
                ${deviceEnrollmentFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const getDeviceDetails = gql`
 query GetDeviceDetails($deviceUniqueId: String) {
    getDeviceDetails(deviceUniqueId: $deviceUniqueId) {
            data {
                ${deviceEnrollmentFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const deleteDevice = gql`
mutation DeleteDevice($deleteDeviceUniqueId2: String!) {
    deleteDevice(uniqueId: $deleteDeviceUniqueId2) {
            data {
                ${deviceEnrollmentFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;