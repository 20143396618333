import gql from "graphql-tag";


export const getReferees = gql`query getReferees($uniqueId: String, $profileUniqueId: String) {
  getReferees(uniqueId: $uniqueId, profileUniqueId: $profileUniqueId) {
    data{
      refereeEmail
      refereeFullname
      refereeUniqueId
      refereePhoneNumber
      refereeTitle
      refereeInstitution
      refereeCreatedDate
    }
    response{
      id
      status
      code
      message
    }
  }
}
`;

export const createReferee = gql`
  mutation createReferee($input: RefereeInputObject) {
    createReferee(input: $input) {
      response {
        code
        status
        message
      }
      data {
        refereeUniqueId
        refereeFullname
        refereeEmail
        refereePhoneNumber
        refereeTitle
        refereeInstitution
        refereeCreatedDate
      }
    }
  }
`;

export const updateReferee = gql`
  mutation updateReferee($input: RefereeInputObject) {
    updateReferee(input: $input) {
      response {
        code
        status
        message
      }
      data {
        refereeUniqueId
        refereeFullname
        refereeEmail
        refereePhoneNumber
        refereeTitle
        refereeInstitution
        refereeCreatedDate
      }
    }
  }
`;

export const deleteReferee = gql`
  mutation deleteReferee($uniqueId: String!) {
    deleteReferee(uniqueId: $uniqueId) {
      response {
        code
        status
        message
      }
      data {
        refereeUniqueId
        refereeFullname
        refereeEmail
        refereePhoneNumber
        refereeTitle
        refereeInstitution
        refereeCreatedDate
      }
    }
  }
`;