import gql from "graphql-tag"


export const response = `
    response {
      id
      status
      code
      message
    }
`
export const  data = `
data {
      diasporaUniqueId
      diasporaFirstName
      diasporaMiddleName
      diasporaLastName
      diasporaProfilePicture
      diasporaGender
      diasporaEmail
      diasporaDesignation
      diasporaPhoneNumber
      diasporaPlaceOfResidence {
        countryUniqueId
        countryName
        countryCreatedDate
      }
      diasporaInterests {
        diasporaInterestsUniqueId
        diasporaInterestsName
      }
      diasporaExpertField {
        diasporaFieldOfExpertUniqueId
        diasporaFieldOfExpertName
      }
      diasporaAttachments
      diasporaReasons
      diasporaCitizenship
      diasporaHighestEducationLevelAttained
      diasporaIsApproved
      diasporaCitizenShipCountry {
        countryUniqueId
        countryName
        countryCreatedDate
      }
    }
`


export const  getAllDiasporaDetails = gql`

query GetAllDiasporaDetails($uniqueId: String) {
  getAllDiasporaDetails(uniqueId: $uniqueId) {
    ${response}
    ${data}
  }
}
`

export const  createDiasporaDetails = gql`
mutation CreateDiasporaDetails($input: DiasporaInputObject!) {
  createDiasporaDetails(input: $input) {
    ${response}
    ${data}
  }
}
`

export const getMyDiasporaDetails = gql`
query GetMyDiasporaDetails {
  getMyDiasporaDetails {
    ${response}
    ${data}
  }
}
`

export const approveDiasporaDetails = gql`
mutation ApproveDiasporaDetails($uniqueId: String!) {
  approveDiasporaDetails(uniqueId: $uniqueId) {
    ${response}
    ${data}
  }
}
`

export const updateDiasporaDetails = gql`
mutation UpdateDiasporaDetails($input: DiasporaInputObject!) {
  updateDiasporaDetails(input: $input) {
    ${response}
    ${data}
  }
}
`

export const disproveDiasporaDetails = gql`
mutation DisproveDiasporaDetails($uniqueId: String!) {
  disproveDiasporaDetails(uniqueId: $uniqueId) {
    ${response}
    ${data}
  }
}
`

export const  getAllApprovedDiasporaDetails = gql`
query GetAllApprovedDiasporaDetails {
  getAllApprovedDiasporaDetails {
   ${response}
    ${data}
  }
}
`
