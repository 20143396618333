import gql from "graphql-tag";

export const getAllPrograms = gql`
  query getAllPrograms($programCategoryUniqueId: String, $uniqueId: String) {
    getAllPrograms(programCategoryUniqueId: $programCategoryUniqueId, uniqueId: $uniqueId) {
      response {
        code
        status
        message
      }
      data {
        programUniqueId
        programName
        programCategory {
          categoryUniqueId
          categoryName
        }
        createdDate
      }
    }
  }
`;

export const updateProgram = gql`
  mutation updateProgram($input: ProgramInputObject) {
    updateProgram(input: $input) {
      response {
        code
        status
        message
      }
      data {
        programUniqueId
        programName
        programCategory {
          categoryUniqueId
          categoryName
        }
        createdDate
      }
    }
  }
`;

export const createProgram = gql`
  mutation createProgram($input: ProgramInputObject) {
    createProgram(input: $input) {
      response {
        code
        status
        message
      }
      data {
        programUniqueId
        programName
        programCategory {
          categoryUniqueId
          categoryName
        }
        createdDate
      }
    }
  }
`;

export const deleteProgram = gql`
  mutation deleteProgram($uniqueId: String!) {
    deleteProgram(uniqueId: $uniqueId) {
      response {
        code
        status
        message
      }
      data {
        programUniqueId
        programName
        programCategory {
          categoryUniqueId
          categoryName
        }
        createdDate
      }
    }
  }
`;