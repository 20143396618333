import { getGeneralAttendanceDashboard } from "@/graphql/attendance/attendanceDashboard.graphql.js";
import { notifyError } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
        allAttendances : [],
        
    },
    getters: {
        getGeneralAttendanceDashboard: (state) => (state.allAttendances),
    },
    actions: {
        async getGeneralAttendanceDashboard(context, filter) {
            context.commit("setLoadingState", true)
            return await apolloClient.query({
                fetchPolicy: "network-only",
                query: getGeneralAttendanceDashboard,
                variables : {filter}
            })
            .then((output) => {
                let {response, data} = output.data.getGeneralAttendanceDashboard;

                if (response.status) {
                        context.commit("setStoreItem", {
                            module : 'attendanceDashboardManagement',
                            entity : 'allAttendances',
                            data
                        })
                        return data
                } else {
                    notifyError(response.message || "Failed to fetch")
                }
            }).finally(() => context.commit("setLoadingState", false))
        },
    }
}