import gql from "graphql-tag";


const subTaskFields = `
    projectSubtaskDescription
    projectSubtaskName
    projectSubtaskUniqueId
    projectSubtaskWeight
    projectSubtaskTask {
        projectTaskUniqueId
    }
    projectSubtaskProgres
`;

const responseFields = `
    code
    id
    message
    status
`;

export const createProjectSubTask = gql`
    mutation CreateProjectSubTask($input: ProjectSubTaskInputObject) {
        createProjectSubTask(input: $input) {
            data {
                ${subTaskFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const updateProjectSubTask = gql`
    mutation UpdateProjectSubTask($input: ProjectSubTaskInputObject) {
        updateProjectSubTask(input: $input) {
            data {
                ${subTaskFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const getProjectSubtasks = gql`
    query GetSubTaskDetails($subTaskUniqueId: String, $taskUniqueId: String) {
        getSubTaskDetails(subTaskUniqueId: $subTaskUniqueId, taskUniqueId: $taskUniqueId) {
            data {
                ${subTaskFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const deleteProjectSubTask = gql`
    mutation DeleteProjectSubTask($uniqueId: String!) {
        deleteProjectSubTask(uniqueId: $uniqueId) {
            data {
                ${subTaskFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;