import gql from "graphql-tag";


const OutsideWorkFields = `
    outsideWorkAssignedStaff {
        firstName
        id
        lastName
        middleName
        profilePicture
        profileUniqueId
        username
    }
    outsideWorkEndDate
    outsideWorkOfficeName
    outsideWorkRegion
    outsideWorkStartDate
    outsideWorkUniqueId
`;


const responseFields = `
    code
    id
    message
    status
`;

export const createOutsideWork = gql`
    mutation CreateOutsideWork($input: OutsideWorkInputObject) {
        createOutsideWork(input: $input) {
            data {
                ${OutsideWorkFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const updateOutsideWork = gql`
    mutation UpdateOutsideWork($input: OutsideWorkInputObject) {
        updateOutsideWork(input: $input) {
            data {
                ${OutsideWorkFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const getOutsideWorkDetails = gql`
    query GetOutsideWorkDetails($outsideWorkUniqueId: String, $staffId: String) {
        getOutsideWorkDetails(outsideWorkUniqueId: $outsideWorkUniqueId, staffId: $staffId) {
            data {
                ${OutsideWorkFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const deleteOutsideWork = gql`
    mutation DeleteOutsideWork($outsideWorkUniqueId: String!) {
        deleteOutsideWork(outsideWorkUniqueId: $outsideWorkUniqueId) {
            data {
                ${OutsideWorkFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

