import {getOnGoingSurveyData , createOnGoingSurvey , updateOngoingSurvey , deleteOnGoingSurvey } from "@/graphql/ResearchProposal/ResearchSurvey.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
    state: {
        AllOnGoingSurvey : [],
        OnGoingSurvey : {},

    },
    getters: {
        getAllOnGoingSurvey : (state) => state.AllOnGoingSurvey,
        getOnGoingSurvey : (state) => state.OnGoingSurvey,

    },
    actions: {
        async getOnGoingSurveyData(context, filtering = {}) {
            context.commit("setLoadingState", true);
            return await apolloClient
                .query({
                    fetchPolicy: "network-only",
                    query: getOnGoingSurveyData,
                    variables: { filtering },
                })
                .then((output) => {
                    let { response, data } = output.data.getOnGoingSurveyData;
                    if (response.status) {
                        if (_.isNil(filtering?.surveyUniqueId)) {
                            context.commit("setStoreItem", {
                                module: "SurveyManagement",
                                entity: "AllOnGoingSurvey",
                                data,
                            });
                            return data;
                        } else {
                            context.commit("setStoreItem", {
                                module: "SurveyManagement",
                                entity: "AllOnGoingSurvey",
                                data: data,
                            });
                            return data;
                        }
                    } else {
                        notifyError(response.message || "Failed to fetch");
                    }
                })
                .finally(() => context.commit("setLoadingState", false));
        },

        async createOnGoingSurvey(context, input) {
            context.commit("setLoadingState", true);
            return await apolloClient
                .mutate({
                    fetchPolicy: "no-cache",
                    mutation: createOnGoingSurvey,
                    variables: { input },
                })
                .then((output) => {
                    let { response, data } = output.data.createOnGoingSurvey;

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: "SurveyManagement",
                            entity: "AllOnGoingSurvey",
                            uniqueKey: "surveyUniqueId",
                            data,
                        });
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'));
                        return data;
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'));
                    }
                })
                .finally(() => context.commit("setLoadingState", false));
        },
        async updateOngoingSurvey(context, input) {
            context.commit("setLoadingState", true);
            return await apolloClient
                .mutate({
                    fetchPolicy: "no-cache",
                    mutation: updateOngoingSurvey,
                    variables: { input },
                })
                .then((output) => {
                    let { response, data } = output.data.updateOngoingSurvey;

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: "SurveyManagement",
                            entity: "AllOnGoingSurvey",
                            uniqueKey: "surveyUniqueId",
                            data,
                        });
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'));
                        return data;
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'));
                    }
                })
                .finally(() => context.commit("setLoadingState", false));
        },
        async deleteOnGoingSurvey(context, surveyUniqueId) {
            context.commit("setLoadingState", true);
            return await apolloClient
                .mutate({
                    fetchPolicy: "no-cache",
                    mutation: deleteOnGoingSurvey,
                    variables: { surveyUniqueId },
                })
                .then((output) => {
                    let { response, data } = output.data.deleteOnGoingSurvey;

                    if (response.status) {
                        context.commit("updateStoreItem", {
                            module: "SurveyManagement",
                            entity: "AllOnGoingSurvey",
                            uniqueKey: "surveyUniqueId",
                            data,
                            remove: true,
                        });
                        notifySuccess(response.message || i18n.t('labels.operationSuccessful'));
                        return data;
                    } else {
                        notifyError(response.message || i18n.t('labels.operationFailed'));
                    }
                })
                .finally(() => context.commit("setLoadingState", false));
        },

    },
};
