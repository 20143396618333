import {
  
  getApplicationDetails,
  
} from "@/graphql/application/application.graphql";
import { createApplicationBatch, getApplicationBatches } from "@/graphql/application/applicationBatchApproval.graphql";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
  state: {
    applicationBatchies: [],
    applicationBatch: {},
  },
  getters: {
    getApplicationBatches: (state) => state.applicationBatches,
    getApplicationBatch: (state) => state.applicationBatch,
  },
  actions: {
    async getApplicationBatches(context, uniqueId) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getApplicationBatches,
          variables: { uniqueId },
        })
        .then((output) => {
          let { response, data } = output.data.getApplicationBatches;

          if (response.status) {
            if (_.isNil(uniqueId)) {
              context.commit("setStoreItem", {
                module: "applicationBatchApprovalManagement",
                entity: "applicationBatches",
                data,
              });
              return data;
            } else {
              context.commit("setStoreItem", {
                module: "applicationBatchApprovalManagement",
                entity: "applicationBatch",
                data: data[0],
              });
              return data[0];
            }
          } else {
            notifyError(response.message || "Failed to fetch");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
    async createApplicationBatch(context, input) {
      context.commit("setLoadingState", true)
      return await apolloClient.mutate({
          fetchPolicy: "no-cache",
          mutation: createApplicationBatch,
          variables: {input}
      })
      .then((output) => {
          let {response, data} = output.data.createApplicationBatchMutation
          
          if (response.status) {
              context.commit("updateStoreItem", {
                  module : 'applicationBatchApprovalManagement',
                  entity : 'applicationBatches',
                  uniqueKey : 'uniqueId',
                  data
              })
              notifySuccess(response.message || i18n.t('labels.operationSuccessful'))
              return data
          } else {
              notifyError(response.message || i18n.t('labels.operationFailed'))
          }
      }).finally(() => context.commit("setLoadingState", false))
  },
}
}