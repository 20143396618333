import gql from "graphql-tag";

const projectFolderFields = `
     fileUniqueId
      filePath
      fileName
      fileFolder {
        folderUniqueId
        folderName
        folderDescription
        folderProject {
          projectUniqueId
          projectName
          projectType
          projectDescription
          projectDomain {
            domainUniqueId
            domainName
            domainDescription
          }
          projectSource
          projectCategory {
            projectCategoryUniqueId
            projectCategoryName
          }
          projectNature {
            projectNatureUniqueId
            projectNatureName
          }
          projectWeight
          projectObjectives
          projectBenefits
          projectIsActive
          projectCompletionPercent
        }
        subFolders {
          folderUniqueId
          folderName
          folderDescription
        }
    }
`;

const responseFields = `
    id
    status
    code
    message
`;

export const createFolderFile = gql`
    mutation  createFolderFile($input: ProjectFileInputObject) {
        createFolderFile(input: $input) {
            data {
                ${projectFolderFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const getFolderFileDetails = gql`
    query getFolderFileDetails($folderUniqueId: String) {
        getFolderFileDetails(folderUniqueId: $folderUniqueId) {
            data {
                ${projectFolderFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;

export const deleteFolderFile = gql`
    mutation deleteFolderFile($uniqueId: String) {
        deleteFolderFile(uniqueId: $uniqueId) {
            data {
                ${projectFolderFields}
            }
            response {
                ${responseFields}
            }
        }
    }
`;
