import gql from "graphql-tag";

export const createProjectPortfolio = gql`mutation createProjectPortfolio($input: ProjectPortfolioInputObject){
    createProjectPortfolio(input:$input){
      data{
        projectPortfolioUniqueId
        projectUrl
        projectName
        projectStartDate
        projectEndDate
        projectDescription
        projectPortfolioCreatedDate
        projectPersonalDetails
      }
      response{
        message
        id
        status
        code
      }
    }
  }`


  export const getProjectPortfolio = gql`query getProjectPortfolio($uniqueId: String, $profileUniqueId: String) {
    getProjectPortfolio(uniqueId: $uniqueId, profileUniqueId: $profileUniqueId) {
      data{
        projectUrl
        projectName
        projectStartDate
        projectEndDate
        projectDescription
        projectPersonalDetails
        projectPortfolioUniqueId
        projectPortfolioCreatedDate
      }
      response{
        message
        id
        status
        code
      }
    }
  }`

  export const updateProjectPortifolio = gql`mutation updateProjectPortfolio($input: ProjectPortfolioInputObject){
    updateProjectPortfolio(input:$input){
      data{
        projectUrl
        projectName
        projectEndDate
        projectStartDate
        projectDescription
        projectPersonalDetails
        projectPortfolioUniqueId
      }
      response{
        id
        status
        code
        message
      }
    }
  }`
  

  export const deleteProjectPortfolio = gql` mutation deleteProjectPortfolio($uniqueId: String!){
    deleteProjectPortfolio(uniqueId:$uniqueId){
      data{
        projectUrl
        projectName
        projectEndDate
        projectStartDate
        projectDescription
        projectPersonalDetails
        projectPortfolioUniqueId
      }
      response{
        id
        status
        code
        message
      }
    }
  }`
  
 