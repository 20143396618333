

import { createProjectSubTaskAssignment, deleteProjectSubtaskAssignment, getSubTaskAssignmentDetails } from "@/graphql/projects/assignTask/assignSubTask.graphql";
import i18n from "@/i18n";
import { notifyError, notifySuccess } from "@/plugins/notification.service";
import { apolloClient } from "@/vue-apollo";

export default {
  state: {
    assignedSubTasks: [],
    assignedSubTask: {},
  },
  getters: {
    getSubTaskAssignmentDetails: (state) => state.assignedSubTasks,
    getSubTaskAssignmentDetail: (state) => state.assignedSubTask,
  },
  actions: {
    async getSubTaskAssignmentDetails(context, filter) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .query({
          fetchPolicy: "network-only",
          query: getSubTaskAssignmentDetails,
          variables: { filter },
        })
        .then((output) => {
          let { response, data } = output.data.getSubTaskAssignmentDetails;
          if (response.status) {
            context.commit("setStoreItem", {
              module: "assignSubTasksManagement",
              entity: "assignedSubTasks",
              data,
            });
            return data;
          } else {
            notifyError(response.message || "Failed to fetch");
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },

    async createProjectSubTaskAssignment(context, input) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: createProjectSubTaskAssignment,
          variables: { input },
        })
        .then((output) => {
          let { response, data } = output.data.createProjectSubTaskAssignment;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "assignSubTasksManagement",
              entity: "assignedSubTasks",
              uniqueKey: "taskAssignmentUniqueId",
              data,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
    async deleteProjectSubtaskAssignment(context, subTaskAssignmentUniqueId) {
      context.commit("setLoadingState", true);
      return await apolloClient
        .mutate({
          fetchPolicy: "no-cache",
          mutation: deleteProjectSubtaskAssignment,
          variables: { subTaskAssignmentUniqueId },
        })
        .then((output) => {
          let { response, data } = output.data.deleteProjectSubtaskAssignment;

          if (response.status) {
            context.commit("updateStoreItem", {
              module: "assignSubTasksManagement",
              entity: "assignedSubTasks",
              uniqueKey: "taskAssignmentUniqueId",
              data,
              remove: true,
            });
            notifySuccess(
              response.message || i18n.t("labels.operationSuccessful")
            );
            return data;
          } else {
            notifyError(response.message || i18n.t("labels.operationFailed"));
          }
        })
        .finally(() => context.commit("setLoadingState", false));
    },
  },
};